import React from 'react'
import moment from 'moment'
import Service from './service';
import { validators, dateFormat } from './constants'
import core from '../_core';
import flash from '../flash'

export const postToHubspotRegister = (consent, email, formName, firstName, lastName, country, company, jobTitle, industry) => {

  var postObj = {
    fields: [],
    skipValidation: true,
    //context: { hutk: document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, "$1") }
  };
  
  postObj.fields.push({ name: 'email', value: email});
  postObj.fields.push({ name: 'form_name', value: formName});
  let name = '';
  if (firstName.length > 0) {
    postObj.fields.push({ name: 'firstname', value: firstName});
    name += firstName;
  }
  if (lastName.length > 0) {
    postObj.fields.push({ name: 'lastname', value: lastName});
    name += ' ' + lastName;
  }
  if (country.length > 0)
    postObj.fields.push({ name: 'country', value: country});
  if (company.length > 0)
    postObj.fields.push({ name: 'company', value: company});
  if (jobTitle.length > 0)
    postObj.fields.push({ name: 'job_title', value: jobTitle}); /* job function in Hubspot */
  if (industry.length > 0) {
    postObj.fields.push({ name: 'industry', value: industry});
    postObj.fields.push({ name: 'primary_industry', value: industry});
  }
  if (consent)
    postObj.fields.push({ name: 'newsletter_subscribed', value: 'Yes'})

  var postUrl = "https://api.hsforms.com/submissions/v3/integration/submit/4843771/ab6ca279-2190-4173-8ddc-ff23e61bf1f8"; // Free Image Sign UP
  if (consent == true)
    postUrl = "https://api.hsforms.com/submissions/v3/integration/submit/4843771/debd38ea-e6b7-4e53-bd81-fbc054ceab74"; // OLD Sign Up Form

  postAllFieldsToHubspot(postObj.fields, postUrl, false)
      .then(
        data => {
          if (consent) {
            //console.log("SENDING DBL OPT IN")
            //sendDblOptIn(email, firstName);
            document.cookie = `subscribeHide=true;expires=${new Date(2100, 0, 0).toUTCString()}`
            addToNewsletterSubscribedList(name, email, '', company, formName);
          }
      });

  return false;
}

//IF U CHANGE THIS TAKE CARE TO LOOK AT THE TAILORED REACT PROJECT HUBSPOT.JS AS WELL
export const postToHubspot = (consent, email, formName, firstName, name, message) => {

  var postObj = {
    fields: [],
    skipValidation: true,
    context: { hutk: document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, "$1") }
  };

  postObj.fields.push({ name: 'email', value: email});
  postObj.fields.push({ name: 'form_name', value: formName});

  if (firstName.length > 0)
    postObj.fields.push({ name: 'firstname', value: firstName});
  
  if (name.length > 0)
    postObj.fields.push({ name: 'name', value: name});
  
  if (message.length > 0)
    postObj.fields.push({ name: 'message', value: message});

  if (consent)
    postObj.fields.push({ name: 'newsletter_subscribed', value: 'Yes'})

  var postUrl = "https://api.hsforms.com/submissions/v3/integration/submit/4843771/ab6ca279-2190-4173-8ddc-ff23e61bf1f8";

  if (formName == "Contact Us")
    postUrl = "https://api.hsforms.com/submissions/v3/integration/submit/4843771/7e3d5fda-7b9f-4893-8699-410a0bea39f8";

  else if (formName == "Login")
    postUrl = "https://api.hsforms.com/submissions/v3/integration/submit/4843771/64ed48d7-cf30-438f-a10a-b8bf8ae77132";

  postAllFieldsToHubspot(postObj.fields, postUrl, true)
    .then(
      data => {
        if (consent == true) {
          //console.log("SENDING DBL OPT IN")
          //sendDblOptIn(email, firstName);
          document.cookie = `subscribeHide=true;expires=${new Date(2100, 0, 0).toUTCString()}`
          addToNewsletterSubscribedList(name, email, '', '', formName);
        }
    });

  return false;
}

export const postLetsChat = (name, email, phone, company, formName, consent) => {
  const postUrl = "https://api.hsforms.com/submissions/v3/integration/submit/4843771/ab0fe45f-ef57-482e-a6d8-bec9031d40f9";

  let fields = [];
  fields.push({ name: 'email', value: email});
  fields.push({ name: 'form_name', value: formName});

  if (name.length > 0)
    fields.push({ name: 'name', value: name});
  
  if (phone.length > 0)
    fields.push({ name: 'phone', value: phone});

  if (company.length > 0)
    fields.push({ name: 'company', value: company});

  return postAllFieldsToHubspot(fields, postUrl, false)
    .then(
      data => {
        if (consent == true) {
          document.cookie = `subscribeHide=true;expires=${new Date(2100, 0, 0).toUTCString()}`
          addToNewsletterSubscribedList(name, email, phone, company, formName);
        }
    });

}

export const sendDblOptIn =(email, firstname) => {
  var service = new Service();
  service.get('/hubspot/sendDblOptIn?email=' + encodeURIComponent(email) + "&firstName=" + encodeURIComponent(firstname),{}).then(
    (response) => {
        console.log("sendDblOptIn resp", response);
    }
  )
  .catch(function (error) {
    // handle error
    console.log("error",error);
  })
  .then(function () {
    // always executed
    console.log("done sendDblOptIn");
  });
}

export const addToNewsletterSubscribedList = (name, email, phone, company, formName, service = new Service()) => {
  const postUrl = "https://api.hsforms.com/submissions/v3/integration/submit/4843771/a4255b07-8ac1-4644-845e-23bddf099a24";

  let fields = [];
  fields.push({ name: 'name', value: name});
  fields.push({ name: 'email', value: email});
  fields.push({ name: 'phone', value: phone});
  fields.push({ name: 'company', value: company});
  fields.push({ name: 'form_name', value: formName});
  
  return postAllFieldsToHubspot(fields, postUrl, false);
}

export const postAllFieldsToHubspot = (allFields, postUrl, includeTrackingCookie, service = new Service()) => {
  const postObj = {
    fields: allFields,
    skipValidation: true,
  };
  if (includeTrackingCookie) 
    postObj.context = { hutk: document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, "$1") }
  
  return service.post(postUrl, postObj)
    .then(
      (response) => {
      },
      (error) => {
        flash.actions.show(core.util.errorHandler(error, {500: 'Could not submit request'}));
      }
    )
}
