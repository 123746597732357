import React from 'react'
import {Link} from 'react-router-dom'

import Thumb from '../../product/components/Thumb'
import core from '../../_core'

import {Icon_ImageBundle_wt} from '../../shoppingcart/img/';

const OrderSummary = (props) => {
  const {
    order,
  } = props;

  let firstProduct = {
    fileName: order.fileName,
    thumbnailUrl: order.thumbnailUrl,
    tinyUrl: order.tinyUrl,
    title: order.title,
    type: order.type,
    durationSeconds: order.durationSeconds,
    featuredProductId: order.featuredProductId
  }
  let orderCount = order.itemCount;
  let expiresAt = undefined;
  if (order.orderType == 'bundle') {
    expiresAt = order.newExpiredAt
  }

  return (
    <div className="order-row">
      <div className="order-cell order-cell-img">
        <Thumb 
          data={firstProduct} 
          className={'productDetail'} 
          hideCaption={true} 
          routerPath={{pathname: `/order/invoice/${order.id}`, state: {return: props.location.pathname}}}
          isTiny={true} />
      </div>     
      <div className="order-cell order-cell-count">
        { orderCount }
      </div>
      <div className="order-cell order-cell-date">
        { core.util.formatDate(order.createdAt, 'D/M/YYYY')}
        { expiresAt && <React.Fragment><br />exp: {core.util.formatDate(expiresAt, 'D/M/YYYY')}</React.Fragment>}
      </div>
      <div className="order-cell order-cell-po">
        { order.poNumber ? order.poNumber : "N/A"}
      </div>
      <div className="order-cell order-cell-type">
        { order.isFree ? "free" : order.orderType ? order.orderType : ""}
      </div>
      <div className="order-cell order-cell-total"> { order.orderType == 'credit' ?
        <React.Fragment><img alt="bundle-credit-icon" src={Icon_ImageBundle_wt} className='bundle_credit_icon' />{orderCount}</React.Fragment>
        : <span className={'enMoney ' + order.currency || 'USD'}>{ order.totalUSD.toFixed(2)}</span>
      } </div>
      <div className="order-cell order-cell-view">
        <Link className="button light_grey" to={{pathname: `/order/invoice/${order.id}`, state: {return: props.location.pathname}}}>View Invoice</Link>
      </div>
    </div>
  );
};

export default OrderSummary