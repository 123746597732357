/**
 * src/modules/layout/components/TopNav.js
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import core from '../../_core'
import auth from '../../auth'
import * as cartActions from '../../shoppingcart/actions'
import * as cartConstants from '../../shoppingcart/constants'
import AddBundleToCartModal from '../../shoppingcart/components/modals/AddBundleToCartModal'

import TopNavCart from './TopNavCart'
import TopNavUser from './TopNavUser'
import TopNavStandard from './TopNavStandard'
import SearchBox from './SearchBox'
import TopNavPromo from './TopNavPromo'

import HeroLogo from '../img/logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes, faSearch } from '@fortawesome/pro-regular-svg-icons'

class TopNav extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchMode: false
    }

    this.setSearchMode = this.setSearchMode.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  setSearchMode(searchMode) {
    return () => {
      this.setState({searchMode})
    }
  }

  handleClose() {
    var trigger = document.getElementById('topbar__trigger')
    trigger.getAttribute('checked', true)
    trigger.click();
  }

  render() {
    const {
      authUser,
      auth,
      showModal,
      cart,
      itemJustAdded,
      prices,
      logout
    } = this.props

    return (
      <nav className="header">
        <AddBundleToCartModal id={cartConstants.modals.addBundleToCart} />
        <img src={HeroLogo} alt="Hero Images" className="logo" />
        <div className="topbar hidden-small">
          {/* <TopNavPromo /> */}
          <div className="flex topbar__inner">
            <Link to={'/'}><img src={HeroLogo} alt="Hero Images" className="logo" /></Link>
            <section className="topbar__content">
              <section className="topbar__main">
              </section>
              <section className="topbar__search">
              </section>
            </section>
          </div>
        </div>
        <div className="topbar hidden-large">
          <div className="flex topbar__inner">
            <Link to={'/'}><img src={HeroLogo} alt="Hero Images" className="logo" /></Link>
            
            <section className={`topbar__content ${this.state.searchMode ? 'search' : ''}`}>
              
              
              <section className="topbar__search spacer-padding-vertical-30" onClick={this.setSearchMode(true)}>
                
              </section>
            </section>
            <section className="topbar__buttons">
              <label htmlFor="topbar__trigger">
                <FontAwesomeIcon icon={faSearch} onClick={this.setSearchMode(true)}/>
                {/* <FontAwesomeIcon icon={faBars} onClick={this.setSearchMode(false)}/> */}
              </label>
            </section>
          </div>
        </div>
      </nav>
    )
  }
}

TopNav.propTypes = {
  authUser: auth.Shape,
  auth: PropTypes.string,
  showModal: PropTypes.func,
  logout: PropTypes.func,
  cart: PropTypes.array,
  itemJustAdded: PropTypes.bool
}

const stateToProps = (state) => {
  return   {
    authUser: auth.selectors.getAuth(state),
    auth: auth.selectors.getUserAuthToken(state),
    cart: state.shoppingcart.cart,
    itemJustAdded: state.shoppingcart.itemJustAdded,
    prices: state.shoppingcart.prices
  }
}

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal,
    showAddBundleToCartModal: () => cartActions.showAddBundleToCartModal(),
    logout: auth.actions.logout,
    loadFromServer: (auth) => cartActions.listPrices({token: auth})
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(
  withRouter(
    core.components.withLoading(TopNav, ['auth'])
  )
)