/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:19:05
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-05-15 13:01:20
 */

export const moduleName = 'order'

export const pageSize = 10

