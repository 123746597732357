/**
 * src/modules/auth/components/modals/index.js
 */

import ForgetPassword from './ForgetPassword'
import Login from './Login'
import ResetPassword from './ResetPassword'
import Register from './Register'
import RegisterComplete from './RegisterComplete'

export default {
  ForgetPassword,
  Login,
  ResetPassword,
  Register,
  RegisterComplete
}