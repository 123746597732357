import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons'

const Pagination = ({
  info: { offset, total, limit },
  goPage,
  filterSettings,
  changePagination,
  history,
  match,
  urlPrefix
}) => {
  const bracketSize = 10
  const showPrev = offset > 0
  const showNext = (offset + limit) < total
  const totalPages = Math.ceil((total / limit))
  const currentPage = Math.floor(offset / limit)
  const bracketStart = Math.floor(currentPage / bracketSize) * bracketSize
  const bracketEnd  = bracketStart + bracketSize
  var midPoint =  Math.floor(totalPages / 2)
  var midPointNext =  Math.floor(totalPages / 2) + 1
  var midPointPrev =  Math.floor(totalPages / 2) - 1

  if (currentPage >= 9)
  {
    midPoint= currentPage;

    midPointNext = currentPage +1;

    midPointPrev = currentPage -1;
  }

  const pageHandler = (page) => {
    return (e) => {
      e.preventDefault()
      changePagination({
        offset: page * limit,
        limit
      })
      goPage(page * limit, limit, filterSettings, history, match)
      window.scrollTo(0, 0)
      return false
    }
  }

  let pages = []

  for (let i = 0; i < totalPages; i++) {
    const key = `page-${i}`

    pages.push(
      i === currentPage ?
        <span className="page current" key={key}>{ i + 1 }</span> :
        <a href={ urlPrefix ? urlPrefix + '/' + (i+1) : '#' }
          className="page"
          key={key}
          onClick={pageHandler(i)}
        >{ i + 1 }</a>
    )
  }


  return (
    totalPages > 1 &&
    <section className='pagination flex'>
      
      <div className="pages flex wrap centered">
        {
          showPrev
            ?
            <a href={ urlPrefix ? urlPrefix + '/' + (currentPage) : '#' }
              className="page button link-icon"
              onClick={pageHandler(currentPage - 1)}
            ><FontAwesomeIcon icon={faArrowLeft} /> Previous</a>
            :
            <p className="page disabled">Previous</p>
        }
        {
          totalPages < 9 
            ?
            <div className="hidden-small">
              { pages.slice(bracketStart, bracketEnd) }
            </div>
            :
            currentPage >= totalPages - 3  && currentPage > 2
              ?
              <div className="hidden-small">
                { pages[0] }... {pages[pages.length-4]} {pages[pages.length-3]} {pages[pages.length-2]} {pages[pages.length-1]}
              </div>
              :
              totalPages >= 9 && currentPage > 2 
                ?
                <div className="hidden-small">
                  { pages[0] }&nbsp;&nbsp;&nbsp;...&nbsp;&nbsp;&nbsp;{pages[currentPage-1] }&nbsp;&nbsp;&nbsp;{pages[currentPage]}&nbsp;&nbsp;&nbsp;{pages[currentPage+1]}&nbsp;&nbsp;&nbsp;...&nbsp;&nbsp;&nbsp;{pages[pages.length-1]}
                </div>
                :
                <div className="hidden-small">
                  { pages[0] } {pages[1]} {pages[2]} {pages[3]} ...  {pages[pages.length-1]}
                </div>
          
        }
        <div className="hidden-large">
          {currentPage + 1} of {totalPages}
        </div>
        {
          showNext
            ?
            <a href={ urlPrefix ? urlPrefix + '/' + (currentPage + 2) : '#' }
              className="page button link-icon"
              disabled={showNext ? '': 'disabled'}
              onClick={pageHandler(currentPage + 1)}
            >Next <FontAwesomeIcon icon={faArrowRight} /></a>
            :
            <p className="page disabled">Next</p>
        }
      </div>
    </section>
  )
}

Pagination.propTypes = {
  info: PropTypes.object,
  filterSettings: PropTypes.object,
  goPage: PropTypes.func
}

export default Pagination;