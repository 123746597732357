/**
 * core/components/navigation/TopNavUser.js
 */

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faThLarge } from '@fortawesome/pro-light-svg-icons'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import auth from '../../auth';

import { accountLogo, boardsLogo } from '../img'

class TopNavUser extends React.Component {
  constructor(props) {
    super(props);

    this.onLogout = this.onLogout.bind(this);
  }

  onLogout(e) {
    e.preventDefault();

    const { logout, history } = this.props;
    localStorage.removeItem('id_token');
    logout();
    history.push('/');
  }

  handleClose() {
    var trigger = document.getElementById('topbar__trigger')
    trigger.getAttribute('checked', true)
    trigger.click();
  }
  render() {
    const { auth } = this.props;
    return (
      <section className="topbar__user">
        <nav className="hidden-small">
          <dl>
            <dd className="dropdown">
              <button className="link-icon ">
                <img alt="user-icon" className="topbar__icon flex center" src={accountLogo} />Account</button>
              <div className="dropdown-content">
                <nav>
                  <dl>
                    <dd><Link to={'/user/account-info'}>Account Info</Link></dd>
                    <dd><Link to={`/order/past-purchases`}>Past Purchases</Link></dd>
                    <dd><div className="separator"></div></dd>
                    <dd><button className="link" onClick={this.onLogout}>Logout</button></dd>
                  </dl>
                </nav>
              </div>
            </dd>
            <dd>
              <Link className="link-icon flex center" to={'/boards'}>
                <img alt="boards-icon" className="topbar__icon" src={boardsLogo} /> Boards</Link>
            </dd>
          </dl>
        </nav>
        <nav className="hidden-large">
          <dl>
            <dd><Link to={'/boards'} onClick={this.handleClose} >Boards</Link></dd>
            <dd><Link to={'/user/account-info'} onClick={this.handleClose} >Account Info</Link></dd>
            <dd><Link to={`/user/${auth.id}/past-purchases`} onClick={this.handleClose} >Order History</Link></dd>
            <dd><div className="separator"></div></dd>
            <dd><button className="link" onClick={this.onLogout} >Logout</button></dd>
          </dl>
        </nav>
      </section>
    )
  }
}



TopNavUser.propTypes = ({
  auth: auth.Shape,
  logout: PropTypes.func.isRequired,
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object
})

export default withRouter(TopNavUser)
