/**
* @module search
* constants.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Thu May 17 2018 09:55:43 GMT-0600 (MDT)
* @copyright None
* @last-modified Fri Jun 22 2018 17:00:21 GMT-0600 (MDT)
*/

export const moduleName = 'search'

export const pageSize = 48