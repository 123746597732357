/**
 * src/modules/auth/errorTypes.js
 */
import React from 'react'

export const loginError = {
    401: 'Unable to log in, please check your login credentials',
    403: <div>Your account is locked because of too many failed attempts. Please contact <a href='mailto:hello@heroimages.com'>hello@heroimages.com</a> to unlock.</div>
}
export const registerError = { 401: 'Failed to register', 422: 'Oops. Looks like you already have an account. Please try logging in.' }
export const emailNotFoundError = { 404: 'Email not found' }
export const passwordTokenError = { 404: 'Password has already been reset or link is invalid' }
export const requestError = { 500: 'Unable to request access, please try again late.' }
