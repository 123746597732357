/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-16 14:04:27 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-16 14:25:52
 */

import PropTypes from 'prop-types'

export const Shape = PropTypes.shape({
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  company: PropTypes.string
});

export const generate = (
  {
    id = undefined,
    firstName = undefined,
    lastName = undefined,
    email = undefined,
    phone = undefined,
    company = undefined,
    industry = undefined,

    billingFirstName = undefined,
    billingLastName = undefined,
    billingCompany = undefined,
    address1 = undefined,
    address2 = undefined,
    city = undefined,
    region = undefined,
    country = undefined,
    zipPostal = undefined,

    userBillingInfo = {hasCard: false},
    freeEnabled = undefined,

    profileCountry = undefined,
    jobTitle
  } = {}) => ({
  id,
  firstName,
  lastName,
  email,
  phone,
  company,
  industry,
  userBillingInfo,
  billingFirstName,
  billingLastName,
  billingCompany,
  address1,
  address2,
  city,
  region,
  country,
  zipPostal,
  freeEnabled,
  profileCountry,
  jobTitle
})
