/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 22:58:13
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-06-08 12:17:33
 */

import React from 'react'
import PropTypes from 'prop-types'

import product from '../../product'

import { Shape } from '../model'

const PhotoshootThumb = ({data, facets, hideCaption, style, onClick, onLoad, updateFilterAndReload, className = 'photoshoot__thumb', routerPath = undefined, showShootNumber = false, applyShootFilter = false, filters, badge}) => {
  const thumbnail = data.thumbnail || (data.products ? data.products[0] : null) ||  (data.photoshootProducts ? data.photoshootProducts[0] : null)
  const itemData = {
    ...thumbnail,
    title: data.title,
    description: data.description || (data.products && data.products.length > 0 ? data.products[0].description : null) ||  (data.photoshootProducts && data.photoshootProducts.length > 0 ? data.photoshootProducts[0].description : null),
    compUrl: data.compUrl,
    id: data.id,
    featuredProductId: thumbnail ? thumbnail.id : -1 
  }

  return <product.components.Thumb 
    data={itemData} 
    style={style} 
    onLoad={onLoad} 
    className={className} 
    hideCaption={hideCaption} 
    filters={filters} 
    onClick={onClick} 
    routerPath={routerPath} 
    facets={facets} 
    updateFilterAndReload={updateFilterAndReload}
    showShootNumber={showShootNumber}  
    applyShootFilter = {applyShootFilter}
    badge = {badge}
  />
}

PhotoshootThumb.propTypes = {
  className: PropTypes.string,
  data: Shape,
  hideCaption: PropTypes.bool,
  onClick: PropTypes.func,
  onLoad: PropTypes.func,
  style: PropTypes.object,
  routerPath: PropTypes.string
}

export default PhotoshootThumb