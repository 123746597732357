/**
 * core/components/navigation/TopNavCart.js
 */

import React from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/pro-light-svg-icons'
import PropTypes from 'prop-types'
import { accountLogo, cartLogo } from '../img'
import { Icon_ImageBundle_wt } from '../../shoppingcart/img'

class TopNavCart extends React.Component {
  constructor(props){
    super(props);
    
    this.onShowModal = this.onShowModal.bind(this)
  }

  onShowModal() {
    this.props.showAddBundleToCartModal()
  }

  render() {
    const {count, itemJustAdded, closeHandler } = this.props
    const prices = this.props.prices.sort((a,b) => {
      return a.id - b.id
    })
    
    // todo: only display first instance of bundle class
    return (
      <section className="topbar__cart">
        <nav>
          <dl>
            { this.props.auth &&
            <dd className='topbar__bundle_credit'>
              <img alt="bundle-credit-icon" src={Icon_ImageBundle_wt} className='bundle_credit_icon' />
              {/* {prices.map(price => {
                let creditCount = price.credits ? price.credits.reduce((count, credit) => count + credit.remaining, 0) : 0
                return !price.isHidden && price.bundleClass && <div key={price.id} className='bundle_credit'>
                  <div className='bundle_count'>{creditCount}</div>
                  <div className='bundle_name'>{price.name[0]}B</div>
                </div>
              })} */}
              <div className='bundle_popup'>
                <h3>Bundle and save.</h3>
                {prices.map(price => {
                  let creditCount = price.credits ? price.credits.reduce((count, credit) => count + credit.remaining, 0) : 0
                  return price.bundleClass && <p key={price.id}>
                    { creditCount 
                      ? <React.Fragment><span className='bundle_class'>{price.name}</span> <span>{creditCount} remaining</span></React.Fragment> 
                      : price.isFree && price.bundles.some(b => b.isActive)
                          ? <React.Fragment><span className='bundle_class'>{price.name}</span> more next month</React.Fragment>
                          : price.bundles.some(b => b.isActive && !b.isHidden) && <React.Fragment><span className='bundle_class'>{price.name}</span> <button type='button' className='slim' onClick={this.onShowModal}>Buy</button></React.Fragment> }
                  </p>
                })}
                <Link to='/pages/pricing' target='_blank'>See pricing options</Link>
              </div>
            </dd>}
            <dd>
              <Link  onClick={closeHandler} to={{pathname:'/shoppingcart/viewCart', state: {from: this.props.location.pathname}}}><img alt="cart-icon" className="link-icon topbar__cart_icon" src={cartLogo} /><span className={'badge' + (itemJustAdded ? ' active': '')} data-badge={count}></span></Link>
            </dd>
          </dl>
        </nav>
      </section>
    )
  }
}

TopNavCart.propTypes = ({
  count: PropTypes.number
})

/*
 * TODO: Connect to redux to get the current count
 */

export default TopNavCart
