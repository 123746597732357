/**
 * _core/components/modals/Confirm.js
 */

import React from 'react'
import PropTypes from 'prop-types'

import Modal from './Modal'

const Confirm = ({onConfirm}) => (
  <div>
    <p>
      Are you sure?
    </p>
    <div className="text-center">
      <button onClick={onConfirm}>Yes</button>
    </div>
  </div>
)

Confirm.propTypes = {
  onConfirm: PropTypes.func
}

export default Modal(Confirm)