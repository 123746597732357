/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-18 18:14:27 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-26 13:32:11
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect  } from 'react-router-dom'

import { moduleName } from './constants'
import pages from './pages'
import NotFoundPage from '../../modules/pages/components/notFoundPage'


const routes = {
  admin: [],
  user: [
    {
      path: '/shootold/:id', // inaccessible since all paths on this sub router must start with shoot/ or shoots/
      component: pages.Photoshoot,
      exact: true
    },
    {
      path: '/shoots',
      component: pages.Photoshoots,
      exact: true
    },
    {
      path: '/shoots/:pageNumber',
      component: pages.Photoshoots,
      exact: true
    },
    {
      path: '/shoots/:terms/:pageNumber',
      component: pages.Photoshoots,
      exact: true
    }
   
  ]
}

const Routes = ({ type }) => (
  <Switch>
    {
      routes[type].map((route, i) => (
        <Route {...route} key={`${moduleName}-${i}`} />
      ))
    }
    <Route
      exact
      path="/shoot/:id"
      render={props => (
        <Redirect to={`/search/shoot/${props.match.params.id}/1`} />
      )}
    />
    <Route component={NotFoundPage} />
  </Switch>
)

Routes.propTypes = {
  type: PropTypes.string.isRequired
}

export default Routes

