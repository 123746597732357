/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-16 20:45:39
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-07-04 12:11:22
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import core from '../../../_core'
import auth from '../../../auth'
import * as actions from '../../actions'
import * as orderActions from '../../../order/actions'
import * as selectors from '../../selectors'

import AddToCart from '../AddToCart'
import DownloadCartContainer from './DownloadCartContainer'

class AddToCartContainer extends React.Component {
  constructor(props) {
    super(props);
    
    this.onDownloadClick = this.onDownloadClick.bind(this)
    this.onSubmit = this.onSubmit.bind(this);
  }

  onDownloadClick(e) {
    e.preventDefault();
    this.props.downloadImage(this.props.auth)

    e.target.disabled = true;
  }

  onSubmit(selectedPrice) {
    const selectedPriceObj = selectors.getPrice(this.props.prices, parseInt(selectedPrice));
    
    if (selectedPriceObj.credits && selectedPriceObj.credits.find(credit => credit.remaining > 0)) {
      this.props.addToDownloadCart(selectedPriceObj);
    }
    else {
      this.props.addToCart(selectedPriceObj);
    }
  }


  render() {
    //console.log('AddToCartContainer', this.props)
    let isDownloading = this.props.downloading.filter(id => id == this.props.product.id)
    const showFree = (this.props.product && this.props.product.collectionType && this.props.product.collectionType.toLowerCase() === 'free')
    const isFreePremiumDownload = (this.props.product && this.props.product.isFreePremiumDownload)

    return (
      <React.Fragment>
        {/* AddBundleToCartModal is added to the DOM in TopNav.js */}
        <DownloadCartContainer from='single' downloadCart={this.props.downloadCart} price={this.props.selectedPrice} history={this.props.history} location={this.props.location} auth={this.props.auth} />
        <AddToCart {...this.props} 
          onSubmit={this.onSubmit} 
          addToCart={this.props.addToCart} 
          total={1} 
          userProductOwnership={this.props.userProductOwnership} 
          onDownloadClick={this.onDownloadClick} 
          isDownloading={isDownloading} 
          showFree={showFree}
          isFreePremiumDownload={isFreePremiumDownload}
          onFreePremiumDownloadClick={this.props.onFreePremiumDownloadClick}
          isFreePremiumDownloading={this.props.isFreePremiumDownloading}
          />
      </React.Fragment>
    )
  }
}

AddToCartContainer.propTypes = {
  auth: PropTypes.string,
  product: PropTypes.object,
  prices: PropTypes.array,
  cart: PropTypes.array
}

function stateToProps(state)
{
  return {
    auth: auth.selectors.getUserAuthToken(state),
    prices: state.shoppingcart.prices,
    cart: state.shoppingcart.cart,
    userProductOwnership: state.shoppingcart.userProductOwnership,
    downloading: state.order.downloading,
    downloadCart: state.shoppingcart.downloadCart,
    selectedPrice: state.shoppingcart.selectedPrice,
    user: state.user,
  }
}

const dispatchToProps = (dispatch, props) => (
  bindActionCreators({
    loadFromServer: (auth) => (dispatch) => {
      dispatch(actions.listPrices({token: auth}))
      dispatch(actions.getUserProductOwnership(props.product.id ? [props.product.id] : [], auth))
    },
    downloadImage: (auth) => orderActions.downloadImage(props.product.fileName, props.product.id, auth),
    addToCart: (selectedPrice) => actions.addToCart(props.product, selectedPrice),
    addToDownloadCart: (selectedPrice) => actions.addToDownloadCart([props.product], selectedPrice),
    removeJustAdded: () => actions.removeJustAdded(),
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal,
    showAddBundleToCartModal: () => actions.showAddBundleToCartModal(),
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(
  core.components.withLoading(AddToCartContainer, ['auth', 'product'])
)