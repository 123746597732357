import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, withRouter, Route } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faSearch, faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import {faTwitter, faFacebookSquare, faInstagram, faLinkedinIn} from '@fortawesome/free-brands-svg-icons'


import {withContentRect} from 'react-measure';
import { shareGenericMedia } from '../../_core/img'
import core from '../../_core'
import auth from '../../auth';
import user from '../../user';
import Subscribe from '../../user/modals/subscribe'
import SearchBox from '../../layout/components/SearchBox'
import product from '../../product'
import * as productActions from '../../product/actions'
import * as productSelectors from '../../product/selectors'

import gallery from '../../gallery';
import * as gallerySelectors from '../../gallery/selectors';
import * as photoshootSelectors from '../../photoshoot/selectors';

import photoshootComponents from '../../photoshoot/components';
import CaseStudies from '../../misc/CaseStudies'
import GenericVideo from '../../misc/GenericVideo'

//import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { PagesHomeSlide8, 
  PagesHomeSlide9,
  PagesHomeSlide10,
  PagesHomeSlide11,
  PagesHomeSlide12,
  PagesHomeSlide13,
  PagesHomeSlide14,
   
  PagesHomeSlideControlArrow,
  PagesHomeGalleries1,
  PagesHomeGalleries2,
  PagesHomeGalleries3,
  PagesHomeGalleries4,
  PagesHomeGalleries5,
  PagesHomeGalleries8,
  PagesHomeShoots1,
  PagesHomeShoots2,
  PagesHomeShoots3,
  PagesHomeShoots4,
  PagesHomeSameModel1,
  PagesHomeSameModel2,
  PagesHomeVimeoPlayButton,

  PagesHomeTailoredCustomHero,
  PagesTailoredCustomHero,
  PagesHomeGalleriesFree,
  PagesHomeNewShootsDesktop,
  PagesHomeNewShootsMobile,
} from '../img/';

import {
  HomePageVideo1,
  HomePageVideo1PlaceholderImage
} from '../video';

import Carousel from 'nuka-carousel'
import Instagram from './Instagram'
//import core from '../../_core'
import SearchInput from '../../photoshoot/components/SearchInput'
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux'
import * as actions from '../../photoshoot/actions'
import * as galleryActions from '../../gallery/actions'

//const galleryImages = [{image:PagesHomeGalleries1,title:'Business Entrepreneurs', url:'/galleries/7'},{image:PagesHomeGalleries2, title:'Education & Learning', url:'/galleries/9'},{image:PagesHomeGalleries3, title:'Green', url:'/galleries/11'},{image:PagesHomeGalleries4, title:'Medical & Science', url:'/galleries/15'},{image:PagesHomeGalleries5, title:'Best Dad Ever', url:'/galleries/25'}];
//const galleryImagesDesktop = [{image:PagesHomeGalleries1,title:'Business Entrepreneurs', url:'/galleries/7'},{image:PagesHomeGalleries3, title:'Green', url:'/galleries/11'},{image:PagesHomeGalleries4, title:'Medical & Science', url:'/galleries/15'},{image:PagesHomeGalleries2, title:'Education & Learning', url:'/galleries/9'},{image:PagesHomeGalleries5, title:'Best Dad Ever', url:'/galleries/25'}];
const shootsArr = [{image:PagesHomeShoots1,title:'Architecture Firm', url:'/shoot/198', shootNumber:198},{image:PagesHomeShoots2, title:'Farm Fresh', url:'/shoot/364', shootNumber:364},{image:PagesHomeShoots3, title:'Urban Family - Working from Home', url:'/shoot/297',shootNumber:297},{image:PagesHomeShoots4, title:'Surfing', url:'/shoot/407', shootNumber:407}];
const instafeedTarget = 'instafeed';

const vimeoClick = (event) => {

  var iframeEl = document.createElement('iframe');
  iframeEl.setAttribute('src', 'https://player.vimeo.com/video/435878255?title=0&byline=0&portrait=1&autoplay=1');
  iframeEl.style.top = '-1px'; 
  iframeEl.style.left = '-1px';
  iframeEl.style.width = 'calc(100% + 2px)';
  iframeEl.style.height = 'calc(100% + 2px)';
  iframeEl.style.backgroundColor = 'white';
  iframeEl.style.border = 'none';

  event.preventDefault();
  
  document.getElementById(event.currentTarget.id).parentNode.parentNode.appendChild(iframeEl);
  document.getElementById(event.currentTarget.id).parentNode.parentNode.style.background = '';
}

function getCookieValue(a) {
  var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
}

class PageHome extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      featuredProductUrl: null,
      latestPhotoshoot: null
    }

    this.onSubscribed = this.onSubscribed.bind(this);
  }

  componentDidUpdate(prevProps) {
    // if ( this.props.galleries.data !== prevProps.galleries.data ) {
    //   // just got the galleries - now get the featured gallerie's featured product large image
    //   const galleryData = this.props.galleries.data
    //   if (galleryData) {
    //     const galleries = core.util.sortByKey(galleryData, "id", false);
    //     const featuredGallery = galleries.find(item => item.isFeatured);
    //     if (featuredGallery) {
    //       this.props.getFeaturedProduct(featuredGallery.products[0].id, this.props.auth).then( (data) => {
    //         this.setState( {featuredProductUrl : data.data })
    //       });
    //     }
    //   }
    // }
  }
  
  componentDidMount() {
    
    document.querySelector('.topbar.hidden-small .topbar__search').classList.add('banner');
    document.querySelector('.topbar.hidden-large .topbar__buttons label svg:first-child').classList.add('banner');
    this.props.releaseProps();
    if (this.props.match.path.toLowerCase().indexOf("/register") >= 0) {
      this.props.showModal({id: auth.constants.modals.register});
    }


    // this.props.getLatestPhotoshoot(this.props.auth).then( (data) => {
    //   this.setState( { latestPhotoshoot : data.data })
    // });

    // Temporarily Removed on Nov 8, 2022 -- DO NOT DELETE
    // if (getCookieValue('subscribeHide') !== 'true') {
    //   this.setState({subscribeShow: window.setTimeout(() => this.props.showModal({id: user.constants.modals.subscribe}), 5000)})

    //   const nextShow = new Date();
    //   nextShow.setDate(nextShow.getDate() + 7)
    //   document.cookie = `subscribeHide=true;expires=${nextShow.toUTCString()}`
    // }
  }

  componentWillUnmount() {
    this.state.subscribeShow && window.clearTimeout(this.state.subscribeShow)
    document.querySelector('.topbar.hidden-small .topbar__search').classList.remove('banner');
    document.querySelector('.topbar.hidden-large .topbar__buttons label svg:first-child').classList.remove('banner');
  }

  onSubscribed() {
    this.props.hideModal()
    
    document.cookie = `subscribeHide=true;expires=${new Date(2100, 0, 0).toUTCString()}`
  }

  render() {
    const props = this.props

    /*
    const latestPhotoshoot = this.state.latestPhotoshoot

    const galleryData = this.props.galleries.data;
    console.log("GAllery Data", galleryData)
    
    // react-measure is being used to update the component on resize of this.props.measureRef
    let maxHeight = window.screen.height * .7 + 'px'
    if (window.innerWidth < 500){
      maxHeight = window.screen.height * .6 + 'px'
    }

    let gallery1, gallery3, gallery4, gallery6, gallery7 = null;
    if (galleryData) {
      const galleries = core.util.sortByKey(galleryData, "id", false);

      gallery1 = galleries.find(item => item.isFeatured) || galleries[0];
      gallery3 = galleries.find(item => item.homePageFeaturePosition == 3) || galleries[0];
      gallery4 = galleries.find(item => item.homePageFeaturePosition == 4)|| galleries[0];
      gallery6 = galleries.find(item => item.homePageFeaturePosition == 6)|| galleries[0];
      gallery7 = galleries.find(item => item.homePageFeaturePosition == 7)|| galleries[0];
    }
    const static5 = { image: PagesHomeGalleries5, title: 'Health + Wellness', url: '/galleries/171', description: 'Female nurse bandaging arm of girl patient in clinic examination room' }
    const static8 = { image: PagesHomeGalleries8, title: 'Video Gallery', url: '/galleries/172', description: 'View from above photography and drone operator equipment' }
    const freeGalleryItem = { image: PagesHomeGalleriesFree, title: 'Free Collection', url: '/search/-/Image/Free/1', description: 'Portrait of volunteers with garbage bags at riverbank' }
    
    // const galleryVideo = { src: HomePageVideo1, title: '' }
    // const galleryVideoPlaceholderImage = { src: HomePageVideo1PlaceholderImage, title: '' }

    const popularTerms = [ 'diversity', 'business', 'family', 'medical', 'celebration', 'education' ];
    */

    return(
      <section ref={this.props.measureRef} className="full-wd-page page-home">
        <div className='subscribe-modal-wrapper'>
          <Subscribe {...this.props} id={user.constants.modals.subscribe} onSubscribed={this.onSubscribed} />
        </div>
        <Helmet>
          <title>Hero Images | Royalty free stock photography</title>
          <meta name="description" content='Every strong brand has a unique story to tell. Hero Images is constantly evolving ways to make more beautiful, more relevant, and more authentic images.' />
          <meta name="type" content='Stock Photography Website' />
          <meta name="url" content='http://www.heroimages.com' />
          <meta name="image" content={shareGenericMedia} />
        </Helmet>
        
        <section className="vimeoWrapperEmbedded">
          <div style={{padding: "56.21% 0 0 0", position:"relative"}}>
            <iframe src="https://player.vimeo.com/video/647504605?h=75a9acb719&color=ffffff&title=0&byline=0&portrait=0" style={{position: 'absolute', top: 0, left:0, width: "100%",  height: "100%"}} frameBorder="0" webkitallowfullscreen="1" mozallowfullscreen="1" allowFullScreen="1">
            </iframe>
          </div>
        </section>

        <section>  
          <div className="regTextWidth text-center spacer-padding-top-20 spacer-padding-bottom-40" >  
            <h1>Great design starts with great imagery.</h1>
            <p style={{'maxWidth': '42em'}}>Check out our showreel to see highlights from our Hero collection.<br className="hidden-mobile"/> We love what we do and it shows.</p>

            <div className='spacer-margin-top-50'>
              <a href={core.constants.facebookUrl} className='spacer-margin-left-10 spacer-margin-right-10' rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebookSquare} fixedWidth={true} size="2x" /></a>
              <a href={core.constants.instagramUrl} className='spacer-margin-left-10 spacer-margin-right-10' rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} fixedWidth={true} size="2x" /></a>
              <a href={core.constants.linkedinUrl} className='spacer-margin-left-10 spacer-margin-right-10' rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faLinkedinIn} fixedWidth={true} size="2x" /></a>
            </div>
          </div>
        </section>
      </section>
    )
  }
}

const stateToProps = (state) => {
  return {
    // galleries: gallerySelectors.getGalleries(state),
    // photoshoots: photoshootSelectors.getFeatured(state),
  }
}

const dispatchToProps = (dispatch, state) => {
  return (bindActionCreators({
    ...actions,
    ...galleryActions,
    auth: auth.selectors.getUserAuthToken(state),
    releaseProps: () => actions.releasePhotoshoot(),
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal,
    loadFromServer: (auth) => (dispatch) => {
      // dispatch(galleryActions.list({onlyFeatured: true, limit: 100}, auth))
      // dispatch(actions.list({ offset: 0, limit: 25 }))
    },
    getFeaturedProduct: (productId, auth) => galleryActions.getFeaturedProduct(productId, auth),
  }, dispatch)
  )
}


export default connect(stateToProps, dispatchToProps)(
  withRouter(
    core.components.withLoading(PageHome)
  )
)