/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-17 14:32:27
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-06-22 14:54:19
 */

import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import * as actions from '../actions'
import * as model from '../model'
import {ProductIcon_AddToBoard, ProductIcon_AddToBoard_v2, ProductIcon_AddToCart,  ProductIcon_AddToCart_v2, ProductIcon_DownloadComp, BoardIcon_RemoveProduct, emptyThumb} from '../img/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird, faVideo } from '@fortawesome/pro-light-svg-icons'

import core from '../../_core'
import product from '../../product'
import auth from '../../auth'
import board from '../../board'
import gallery from '../../gallery'
import shoppingcart from '../../shoppingcart';




class Thumb extends React.Component {
  constructor(props) {
    super(props)

    this.onImageLoad = this.onImageLoad.bind(this)
    this.onDownloadClick = this.onDownloadClick.bind(this)
    this.onAddGallery = this.onAddGallery.bind(this)
    this.onAddBoard = this.onAddBoard.bind(this)

    this.state = {
      loading: true,
      videoPlay: false,
      videoLoaded: false
    }
  }

  componentDidMount() {
    if (this.props.autoPlay)
      this.setState({videoPlay: true})
  }

  onImageLoad(e) {
    const { onLoad } = this.props;
    if (onLoad) onLoad(e)

    this.setState({loading: false});
  }

  onDownloadClick(e) {
    e.preventDefault();

    if (!this.props.token) {
      this.props.showModal({id: auth.constants.modals.register});
      return;
    }

    //{core.constants.apiHost + '/order/download/' + lineItem.id}
    if(!this.state.isDownloading)
      this.props.downloadComp(this.props.data.fileName, this.props.data.id, this.props.token)

    this.setState ({
      isDownloading: true
    });
    e.target.disabled = true;
  }
  
  onAddGallery() {
    const {select, showModal, data, token, storeOperation} = this.props

    select(data)

    if (token) {
      showModal({id: gallery.constants.modals.list})
    } else {
      storeOperation({
        type: 'showModal',
        id: gallery.constants.modals.list
      })
      showModal({id: auth.constants.modals.login})
    }
  }
  
  onAddBoard() {
    const {select, showModal, data, token, storeOperation} = this.props

    select(data)

    if (token) {
      showModal({id: board.constants.modals.list})
    } else {
      storeOperation({
        type: 'showModal',
        id: board.constants.modals.list
      })
      showModal({id: auth.constants.modals.login})
    }
  }

  render() {
    const {
      data,
      facets,
      onClick,
      hideCaption,
      hasMenu,
      className = 'product__thumb',
      style,
      routerPath = undefined,
      showShootNumber,
      applyShootFilter,
      updateFilterAndReload,
      filters,
      numberOfImages = undefined,
      truncateAt = 60,
      onRemoveGalleryProduct,
      onRemoveProduct,
      authObj,
      ctaRibbonText,
      badge,
      autoPlay,
      styling
    } = this.props;

    const { loading, isDownloading } = this.state;
    
    if (this.props.isTiny && data.tinyUrl) {
      // probably not what the client wants
      //data.thumbnailUrl = data.tinyUrl
    }

    const img = {
      id: data.id || -1,
      title: data.title,
      description: data.description ? data.description : null,
      src: data.thumbnailUrl || emptyThumb,
    }

    const countImageHitsInShoot = (filter) => {
      let numResults = 0;
      if(facets && facets['photoshootId'] && facets['photoshootId'][filter])
      {
        numResults = facets['photoshootId'][filter];
      }

      return numResults;
    }

    const downloadText = (authObj && authObj.role == 'admin') ? 'Download' : 'Download comp'
    
    const isMissingOrNonProduct = !img.src || img.src.startsWith('data:') || img.src.startsWith('/')

    let videoUrl
    if (!isMissingOrNonProduct && data.type === 'video') {
      const folder = data.thumbnailUrl.substring(0, data.thumbnailUrl.lastIndexOf('/'))
      const name = data.fileName.substring(0, data.fileName.lastIndexOf('.'))
      const extension = data.fileName.substring(data.fileName.lastIndexOf('.'))

      if (folder && name && extension) {
        videoUrl = `${folder}/${name}_video480${extension}`
      }
    }

    const innerImageTag = <div className='product__thumb_img-wrapper' style={{position: 'relative', maxWidth: style && style.width, maxHeight: style && style.height, overflow: 'hidden'}}
      onMouseEnter={videoUrl && !autoPlay ? () => this.setState({videoPlay: true}) : undefined}
      onMouseLeave={videoUrl && !autoPlay ? () => this.setState({videoPlay: false}) : undefined}>
      
      <img className="product__thumb_img" itemProp="contentUrl url" crossOrigin="Anonymous"
        src={isMissingOrNonProduct ? img.src : (img.src + '?v=2020-06-22')}
        alt={img.description ? img.description : img.title}
        onClick={routerPath ? undefined : onClick}
        onLoad={this.onImageLoad}
        style={{
          visibility: loading || videoUrl && (this.state.videoPlay && this.state.videoLoaded)  ? 'hidden' : 'visible'
        }}/>
      <div className={`product__video-loader ${loading || videoUrl && (this.state.videoPlay && !this.state.videoLoaded) ? 'product__video-loader--is-active' : ''}`}><FontAwesomeIcon icon={faSpinnerThird} spin size="3x" /></div>
      {videoUrl && this.state.videoPlay && <video onCanPlay={() => this.setState({videoLoaded: true })} autoPlay loop muted className="product__thumb_img"
        width={style && style.width}
        height={style && (style.height || ((style.width + 1) / this.props.aspectRatio))}
        alt={img.description ? img.description : img.title}
        onClick={routerPath ? undefined : onClick}
        style={ styling ? styling : {objectFit: "cover", position: "absolute", top: 0, left: 0, maxWidth: style && style.height ? undefined : "100%"}}>
        <source src={videoUrl + '?v=2020-06-23'} type="video/mp4" />
      </video>}
      {videoUrl && hasMenu && <div className={"video-menu " + (this.props.isTiny ? 'tiny' : '')}>
        <div className="product__hover__icon"><FontAwesomeIcon icon={faVideo} style={{fontSize: "1.375em"}} /></div>
        <div className="product__hover__icon">{new Date((parseInt(data.durationSeconds) || 0) * 1000).toISOString().substr(14, 5)}</div>
      </div>}
    </div>

    const imageTag = routerPath
      ? <Link to={routerPath}>{innerImageTag}</Link>
      : innerImageTag

    return (
      <figure className={`product__thumb ${className}`}
        itemScope itemType="http://schema.org/ImageObject"
        title={this.props.isThumbActive !== false ? '' : 'inactive'}
        style={Object.assign({}, style, this.props.isThumbActive !== false ? {} : {opacity: .3})}
      >
        {/* {
          data.featuredProductId != -1 && <React.Fragment>
            <meta itemProp="license" content="/pages/pricing#licensing" />
            <meta itemProp="acquireLicensePage" content={`/product/${data.featuredProductId || data.id}`} />
          </React.Fragment>
        } */}
        { imageTag }
        
        {/* { badge && <div className='corner-badge'><span>{badge}</span></div> }  */}
        { ctaRibbonText && <div className='cta-ribbon-text'>{ctaRibbonText}</div> }
        {
          hasMenu && <React.Fragment>
            <div className="hover-menu">
              {
                onRemoveGalleryProduct && authObj &&  authObj.role == 'admin' &&
                <button className="link" title="Delete from gallery"  alt="Delete from gallery" ><img alt='Delete from gallery' className="product__hover__icon" onClick={() => onRemoveGalleryProduct(data.id)} src={BoardIcon_RemoveProduct} /></button>
              }
              {
                onRemoveProduct && authObj &&  authObj.id == data.userId &&
                <button className="link" title="Delete from board"  alt="Delete from board" ><img alt="Delete from board" className="product__hover__icon" onClick={() => onRemoveProduct(data.id)} src={BoardIcon_RemoveProduct} /></button>
              }
              <a href="#" onClick={this.onDownloadClick} className="link" title={downloadText} disabled={isDownloading} alt={downloadText}><img className="product__hover__icon" src={ProductIcon_DownloadComp} /></a>
              { authObj && authObj.role == 'admin' &&
                <button className="link" title="Add to gallery" alt="Add to gallery" onClick={this.onAddGallery}><img alt="Add to gallery" className="product__hover__icon" src={ProductIcon_AddToBoard} /></button>
              }
              <button className="link" title="Add to board" alt="Add to board" onClick={this.onAddBoard}><img alt="Add to board" className="product__hover__icon" src={ProductIcon_AddToBoard_v2} /></button>
              <button className="link" title="Add to cart"  alt="Add to cart" onClick={() => this.props.showAddMultipleToCartModal([data])} ><img  alt="Add to cart" className="product__hover__icon" src={ProductIcon_AddToCart_v2} /></button>
            </div>
            <div className='mobile-remove'>
              {
                onRemoveGalleryProduct && authObj && authObj.role == 'admin' &&
                <button className="link" title="Delete from gallery"  alt="Delete from gallery" ><img alt="Delete from gallery" className="product__hover__icon" onClick={() => onRemoveGalleryProduct(data.id)} src={BoardIcon_RemoveProduct} /></button>
              }
              {
                onRemoveProduct && authObj && authObj.id == data.userId &&
                <button className="link" title="Delete from board"  alt="Delete from board" ><img alt="Delete from board" className="product__hover__icon" onClick={() => onRemoveProduct(data.id)} src={BoardIcon_RemoveProduct} /></button>
              }
            </div>
          </React.Fragment>
        }
        {
          !hideCaption &&
          <figcaption>
            <h3>{core.util._truncate(img.title, truncateAt, true)}</h3>
            { 
              
              applyShootFilter && 
              <div className="filters" style={{paddingTop: 0}}>
               
                <label key={`photoshootId-${img.id}`} style={{position: 'relative'}}>
                  <input
                    type="checkbox"
                    name={`photoshootId[]`}
                    value={img.id}
                    checked={filters['photoshootId'] ? filters['photoshootId'][img.id] : false}
                    onChange={
                      () => {
                        updateFilterAndReload('photoshootId', img.id, !filters['photoshootId'][img.id])
                      }}
                  />
                  Filter by this shoot <span style={{color: '#aaa'}}> - {countImageHitsInShoot(img.id)}</span>
                </label>
              </div>
            }
            {
              numberOfImages !== undefined &&
              <p><small>{numberOfImages} Product{numberOfImages === 1? '':'s'}</small></p>
            }

          </figcaption>
        }

      </figure>
    )
  }
}

Thumb.propTypes = {
  token: PropTypes.string,
  className: PropTypes.string,
  data: model.Shape,
  hideCaption: PropTypes.bool,
  hasMenu: PropTypes.bool,
  onClick: PropTypes.func,
  onLoad: PropTypes.func,
  showModal: PropTypes.func,
  style: PropTypes.object,
  routerPath: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

const stateToProps = (state) => ({
  token: auth.selectors.getUserAuthToken(state),
  authObj: auth.selectors.getAuth(state)
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    downloadComp: product.actions.downloadComp,
    select: actions.selectProduct,
    showModal: core.actions.showModal,
    storeOperation: auth.actions.storeOperation
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(Thumb)