/**
 * src/modules/_core/model.js
 */

import PropTypes from 'prop-types'
import { pageStates } from './constants'

export const Shape = PropTypes.shape({
  modals: PropTypes.array
})

export const ModalShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  visible: PropTypes.bool
})

export const ErrorShape = PropTypes.shape({
  error: PropTypes.string,
  occurredAt: PropTypes.instanceOf(Date),
  state: PropTypes.string,
})

export const LoadingShape = PropTypes.shape({
  startedAt: PropTypes.instanceOf(Date),
  state: PropTypes.string,
})

export const InfoShape = PropTypes.shape({
  limit: PropTypes.number,
  offset: PropTypes.number,
  total: PropTypes.number
})

export const generate = (
  {
    // eslint-disable-next-line
    modals: undefined
  } = {}) => ({
  modals: []
})

export const generateModal = (
  {
    id = Number(new Date()),    //We need an id for each modal, timestamp is just a default
    visible = false,
  } = {}) => ({
  id,
  visible
})

export const generateLoading = ({
  state = pageStates.loading,
  startedAt = new Date()
} = { }) => ({
  state,
  startedAt
})

export const generateError = (error = undefined) => ({
  error,
  occurredAt: new Date(),
  state: pageStates.error
})

export const getModal = (state = generate(), id) => state.modals.find(m => m.id == id);
export const isModalVisible = modal => (modal ? modal.visible : false);
export const setModalVisibility = (state = generate(), id = undefined) => {
  return {
    ...state,
    modals: state.modals.map(m => ({...m, visible: m.id === id}))
  }
  /*
  *
  *  The id is being set by the "child" component, the reason for this was
  *  so that we could have a way of knowing the handle in child components
  *  for purposes of 'modal-open' buttons, etc.
  *
  */
}

/*
* Given a state and a new modal, return a new state with the modal in it
*/
export const addModal = (state, modal) => {
  const modals = state.modals;
  return { ...state, modals: [...modals, modal] };
}
export const delModal = (state, modal) => ({
  ...state,
  modals: [
    ...state.modals.splice(0, state.modals.findIndex(o => o.id === modal.id)),
    ...state.modals.splice(state.modals.findIndex(o => o.id === modal.id)+1)
  ]
})

export const generateInfo = ({
  limit = undefined,
  offset = undefined,
  total = undefined,
} ={}) => ({
  limit,
  offset,
  total
})