/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:46:07
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-05-15 14:27:35
 */

import core from '../_core'

class Service extends core.Service {

  getProduct(id, authToken){
    return super.get(`/product/${id}`, {}, authToken)
  }

  downloadComp(id, authToken = undefined) {
    return super.get(`/product/download/${id}`, {}, authToken);
  }

  downloadFreePremium(id, authToken = undefined) {
    return super.get(`/product/downloadFreePremium/${id}`, {}, authToken);
  }

  downloadSignedUrl(url) {
    return super.getBlob(url, {}, undefined);
  }
}



export default Service