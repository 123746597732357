/**
 * pages/components/contact.js
 */

import React from 'react';
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { PagesContactHeader, PagesContactIcon_DownloadComp, PagesContactIcon_AddToBoard } from '../img/';
import { bindActionCreators } from 'redux'
import { postToHubspot } from '../../_core/hubspot'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import core from '../../_core';
import flash from '../../flash';
import _auth from '../../auth';

import { Helmet } from 'react-helmet';

class ContactService extends core.Service {
  send(params) {
    return super.post('/send/email', params)
  }
}
class PageContact extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      form: 'contact',
      name: '',
      email: '',
      message: '',
      consent: false
    }

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.handleChangeChk = this.handleChangeChk.bind(this);
    this.postToHubspotInternal = this.postToHubspotInternal.bind(this);
  }

  onChange(e) {
    const newState = {}
    newState[e.target.name] = e.target.value

    this.setState(newState)
    this.validate(e.target)
  }

  handleChangeChk(){

    this.state.consent = !this.state.consent;

  }

  
  postToHubspotInternal(){

    postToHubspot(this.state.consent, this.state.email, "Contact Us","",this.state.name,this.state.message);

  }

  validate(formField) {
    const validator = formField.dataset['validator']

    // If there's no validator configured, make it valid
    formField.dataset['valid'] = validator ? core.util.validate(validator, formField.value) : true
  }

  onSubmit(e) {
    e.preventDefault()

    const service = new ContactService()
    const form = e.target
    const fields = form.querySelectorAll('[data-validator]')

    fields.forEach(field => (this.validate(field)))

    if (form.querySelectorAll('[data-valid="false"]').length === 0) {
      service.send(this.state)
        .then(
          () => {

            console.log("hubspot ", this.state.consent);
           
            this.postToHubspotInternal();

            this.setState({
              name: '',
              email: '',
              message: '',
              consent:false
            })
            return this.props.onSent((
              <div>
                <h1>Message received!</h1>
                <p>
                Thanks for contacting us. We’ve received your message and will contact you within 2 business days.
                </p>
              </div>
            ))
          },
          () => (
            this.props.onSent((
              <div>
                <h1>An Error has occurred</h1>
                <p>
                  Your message cannot be delivered successfully at this time. Please try again later.
                </p>
              </div>
            ))
          )
        )
    }
  }

  render() {

    const {
      auth,
      showModal

    } = this.props

    return(
      <section className="full-wd-page page-contact">
        <Helmet>
          <title>Contact Us | Hero Images</title>
          <meta name="description" content='Contact Us | Hero Images' />
        </Helmet>
        <Link to="/product/58592" className="slider" >
          <section className="full-wd-img spacer-height-vh-60" style={{backgroundImage: `url( ${PagesContactHeader})`,  backgroundPosition:'50% 70%'}}>
            <p className="slide__image_credit">286_02006</p>
          </section>
        </Link>
        <h1 className="text-center spacer-padding-top-50 pages-large-width">How can we help?</h1>
        <section className="flex space-around pages-large-width page-contact__flexwrapper spacer-margin-top-50" id="contact-form">
          {/* <div className="flex-2">    
            <form className="page-contact__form" onSubmit={this.onSubmit}>
              <h2 className="text-left" >Send us a message</h2>
              <input type="text" id="name" name="name" placeholder="Name" value={this.state.name} onChange={this.onChange} data-validator="fullName" />
              <input type="email" id="email" name="email" placeholder="Email " value={this.state.email} onChange={this.onChange} data-validator="email" />
              <input type="text" id="message" name="message" placeholder="What’s up?" value={this.state.message} onChange={this.onChange} required data-validator="text" />
              <input type="checkbox" name="consent" style={{visibility:'visible', display:'block', float:'left'}}  value={this.state.consent} onChange={this.handleChangeChk} /> I consent to receive promotional emails about your products and services.
              <div style={{clear:'both'}}></div>
              <button type="submit" className="button light_grey spacer-margin-top-60" value="Submit" >Submit</button>
            </form>
          </div> */}

          <div className="flex-1 text-center page-contact__info">
            <h2 className="spacer-padding-bottom-20" >Contact info</h2>
            {/* <p>+1 403 351 3522</p> */}
            <a href="mailto:hello@heroimages.com">hello@heroimages.com</a><br /><br />
            <p className="hero-address">400, 119 14 Street NW</p>
            <p>Calgary, AB</p>
            <p>T2N 1Z6</p>
          </div>

        </section>
        <section id="thanks-section" style={{display:'none'}}>
          Thanks for contacting us. We’ve received your message and will contact you within 2 business days.
        </section>
        
        <section className="pages-large-width spacer-margin-top-80">
          <h2 className="text-left" >FAQ</h2>
        </section>

        <section className="flex space-around pages-large-width page-contact__faq spacer-margin-top-30">
          <ul className="accordion" >
            <li>
              <label>
                <input type="checkbox"/>
                What are my payment options?< br className="hiddenForMobile" />< br className="hiddenForMobile" />
                <div className="tab-content">
                We accept Visa, Mastercard, and American Express. If you would like to arrange a more tailored payment option please don’t hesitate to <a href="mailto:hello@heroimages.com">contact us</a>.
                </div>
                <FontAwesomeIcon className='accordion-fa-arrow icon' icon={faArrowDown}/>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox"/>
                Do you protect my payment info?
                <div className="tab-content">
                Your privacy is important to us. We use Stripe for all of our credit card transactions. Stripe offers secure payment processing to many online companies just like ours. They are a PCI Service Provider Level 1 which is the highest grade of payment processing security.  
                </div>
                <FontAwesomeIcon className='accordion-fa-arrow icon' icon={faArrowDown}/>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox"/>
                How much do your images cost and how big are they?
                <div className="tab-content">
                Great question. We made a whole page dedicated to the answer <Link to="/pages/pricing">here</Link>.
                </div>
                <FontAwesomeIcon className='accordion-fa-arrow icon' icon={faArrowDown} />
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox"/>
                Why am I being charged sales tax?< br className="hiddenForMobile" />
                <div className="tab-content">
                Because we’re based in Canada we&quot;re obliged to charge our Canadian customers with Goods and Services Tax. Sorry. &lt; (Get it? Because we&quot;re Canadian.)
                </div>
                <FontAwesomeIcon className='accordion-fa-arrow icon' icon={faArrowDown} />
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox"/>
                What is your refund  policy?< br className="hiddenForMobile" />< br className="hiddenForMobile" />
                <div className="tab-content">
                You have 15 days to change your mind. We can issue a refund to your credit card or account as long as the content has never been used. The license for the image will be terminated and we ask that you delete all files from your system to avoid accidental misuse. <a href="mailto:hello@heroimages.com">Contact us</a>.  
                </div>
                <FontAwesomeIcon className='accordion-fa-arrow icon' icon={faArrowDown}/>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox"/>
                Do my bundles expire?< br className="hiddenForMobile" />< br className="hiddenForMobile" />
                <div className="tab-content">
                Bundles <strong>expire 12 months</strong> after purchase date. During that time you can keep stocking your bundle up. We load images all of the time. We’ll give you a heads up when it looks like time is running out.   
                </div>
                <FontAwesomeIcon className='accordion-fa-arrow icon' icon={faArrowDown}/>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox"/>
                How do I download my image once I purchase it?< br className="hiddenForMobile" />< br className="hiddenForMobile" />
                <div className="tab-content">
                Once you complete your purchase, you’ll be brought to an invoice page. Next to the image thumbnail you should see a download icon. Also, there will be a download link in your email receipt.  
                </div>
                <FontAwesomeIcon className='accordion-fa-arrow icon' icon={faArrowDown}/>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox"/>
                I bought an image a while ago and need to download it again. How can I do that? 
                <div className="tab-content">
                Simply log in, go under your Account and select Past Purchases and download again.
                </div>
                <FontAwesomeIcon className='accordion-fa-arrow icon' icon={faArrowDown}/>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox"/>
                What does a Royalty-Free license cover? < br className="hiddenForMobile" />< br className="hiddenForMobile" />
                <div className="tab-content">
                In the world of stock photography, a Royalty-Free license allows you to use the image you licensed in an unlimited number of projects, across any medium, for as long as you want, wherever you want. And you only pay once.  
                </div>
                <FontAwesomeIcon className='accordion-fa-arrow icon' icon={faArrowDown}/>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox"/>
                Can I download a comp or preview a file?
                <div className="tab-content">
                You can download a comp from any page using the download icon button.<img alt="download-icon" style={{maxWidth:'16px',display:'inline-block', marginBottom:'-3px'}} src={PagesContactIcon_DownloadComp}/> The image is 1024px x 683px and watermarked. 
                </div>
                <FontAwesomeIcon className='accordion-fa-arrow icon' icon={faArrowDown}/>
              </label>
            </li>            <li>
              <label>
                <input type="checkbox"/>
                The image I bought is too small, can I upgrade?
                <div className="tab-content">
                Yes you can. Just <a href="mailto:hello@heroimages.com">Send us a quick email</a> and we’ll get you the size you need.  
                </div>
                <FontAwesomeIcon className='accordion-fa-arrow icon' icon={faArrowDown}/>
              </label>
            </li>            <li>
              <label>
                <input type="checkbox"/>
                Can I get some help finding a specific image? 
                <div className="tab-content">
                For sure! We know our collection inside and out. <a href="mailto:hello@heroimages.com">Send us a quick email</a> detailing what you need and we would be happy to help you.  
                </div>
                <FontAwesomeIcon className='accordion-fa-arrow icon' icon={faArrowDown}/>
              </label>
            </li>            <li>
              <label>
                <input type="checkbox"/>
                What does “Social media use only” actually apply to?
                <div className="tab-content">
                Our social bundle is a great option for social media projects that frequently require fresh images. Images in this bundle are a smaller size and can only be used across platforms like Facebook, Instagram, Pinterest, Linkedin, Reddit, Twitter, and Tumblr, or other blog platforms. 
                </div>
                <FontAwesomeIcon className='accordion-fa-arrow icon' icon={faArrowDown}/>
              </label>
            </li>            <li>
              <label>
                <input type="checkbox"/>
                How do Boards work? < br className="hiddenForMobile" />< br className="hiddenForMobile" />
                <div className="tab-content">
                Boards are an excellent way to organize and keep track of images you like. You must be logged into your Account to use them. If you don’t have an account, create one <button className="button link" onClick={ () => showModal({id: _auth.constants.modals.register}) } >here</button>. Once you’re in, simply click on the ‘Add to Board’ icon <img alt="boards-icon" style={{maxWidth:'16px',display:'inline-block', marginBottom:'-3px'}} src={PagesContactIcon_AddToBoard}/> on any photo when rolling over the image. Choose which Board you’d like to add to, or create a new one. You can see all your Boards using the menu at the top right of any page, share them with your colleagues, and add to cart straight from there.   
                </div>
                <FontAwesomeIcon className='accordion-fa-arrow icon' icon={faArrowDown}/>
              </label>
            </li>            <li>
              <label>
                <input type="checkbox"/>
                I’m having a technical problem with this site. Who do I contact?
                <div className="tab-content">
                Nothing is worse! <a href="mailto:hello@heroimages.com">Send us a quick email</a>. We’ll work hard to sort it out quickly.
                </div>
                <FontAwesomeIcon className='accordion-fa-arrow icon' icon={faArrowDown}/>
              </label>
            </li>            <li>
              <label>
                <input type="checkbox"/>
                Is this platform mobile friendly?
                <div className="tab-content">
                It’s 2018. We know you’re on the go and working overtime and probably reading this on your phone while watching Netflix. Yes, our site has been developed to suit iOS and Android formats.  
                </div>
                <FontAwesomeIcon className='accordion-fa-arrow icon' icon={faArrowDown}/>
              </label>
            </li>
          </ul>

        </section>

      </section>
    )
  }
}

PageContact.propTypes = {
  auth: _auth.Shape, 
  showModal: PropTypes.func,
  onSent: PropTypes.func.isRequired
}


const stateToProps = (state) => {
  return   {
    auth: _auth.selectors.getAuth(state),
    isAuthenticated: !!_auth.selectors.getUserAuthToken(state)
  }
}

const dispatchToProps = (dispatch) => ({
  onSent: (message) => {
    dispatch(flash.actions.show(message))
  },
  showModal: bindActionCreators(core.actions.showModal, dispatch),
})

export default connect(stateToProps, dispatchToProps)(PageContact)
