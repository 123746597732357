import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/pro-light-svg-icons'

import core from '../../../_core'

import {Icon_ImageBundle_wt} from '../../img/';

const DownloadCartModal = (props) => {
  console.log("DownloadCartModal", props)
  return (
    <div id='downloadCartModal' className="flex centered column"> 
      <h2>Redeem {props.total} <img alt="bundle-credit-icon" src={Icon_ImageBundle_wt} className='bundle_credit_icon' /> {props.bundleName} Bundle</h2>
      <form id="checkout-form" onSubmit={props.onSubmit}>
        <p className="smallText spacer-padding-bottom-20">You have {props.remaining} <img alt="bundle-credit-icon" src={Icon_ImageBundle_wt} className='bundle_credit_icon' /> {props.name} Bundle available.</p>
        
        { props.total <= props.remaining ?
          <React.Fragment>
            <p>An email receipt will be sent after your download. You can also access past purchases in your account.</p>
            <button type="submit" className="button" value="Download" disabled={props.isDownloading}>
              Confirm and download { props.isDownloading && <span className='icon'><FontAwesomeIcon className="fa-spin" icon={faSync} /></span> }
            </button>
          </React.Fragment>
          : <React.Fragment>
            <p>You have insufficient credits to complete this purchase.</p>
            <a className="button get_bundle" onClick={props.showAddBundleToCartModal}>Buy More</a>
          </React.Fragment>
        }
      </form>
    </div>
  )
}

export default core.components.modals.Modal(DownloadCartModal)
