import React from 'react'
import * as selectors from '../selectors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import Thumb from '../../product/components/Thumb'

const CartItem = (props) => {
  const price = selectors.getPrice(props.prices, props.cartItem.priceId);

  return (    
    <div key={props.cartItem.product.id} className="cart-item-row">
      <div className="cart-item-cell cart-item-cell-img">
        <Thumb 
          key={`product-${props.cartItem.product.id}`}
          data={props.cartItem.product}
          className={'productDetail'} 
          hideCaption={true} 
          routerPath={`/product/${props.cartItem.product.id}`}
          isTiny={true} />

      </div>
      <div className="cart-item-cell cart-item-cell-description">
        {props.cartItem.product.fileName}<br />
        Royalty-Free Download<br />
        { props.cartItem.specs}
      </div>
      <div className="cart-item-cell cart-item-cell-price">
        { price ? <span className={'enMoney ' + props.currency || 'USD'}>{price.price.toFixed(2)}</span> : ''}
      </div>
      <div className="cart-item-cell cart-item-cell-remove">
        <a href="#" onClick={() => {props.onRemoveCartItem(props.cartItem); return false;}}>Remove <FontAwesomeIcon className="icon" icon={faTimes} /></a>
      </div>
    </div>
  );
}

export default CartItem;