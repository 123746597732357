/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-19 10:32:05
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-07-11 16:50:18
 */

import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons'

import {pageStates} from '../constants'
import * as model from '../model'

export default (WrappedComponent, updateOn = []) => {
  class withLoading extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        updateOn
      }
    }

    componentDidMount() {
      const { loadFromServer, auth } = this.props
      if (loadFromServer) {
        loadFromServer(auth, this.props)
      }
    }

    componentDidUpdate(prevProps) {
      const { loadFromServer, auth } = this.props
      const isNewProp = this.state.updateOn.find((key) => this.props[key] !== prevProps[key])
      // I'm fairly certain that selecting a new route always causes components to remount, but...
      const isNewUrl = this.props.match && (!prevProps.match || this.props.match.url !== prevProps.match.url)
      
      if (loadFromServer && (isNewProp || isNewUrl)) {
        loadFromServer(auth, this.props)
      }
      if (isNewUrl) {
        window.scrollTo(0, 0);
      }
    }

    render () {
      const { data } = this.props;
      return (data && data.state === pageStates.loading ) ?
        <h1 className="loadingIcon"><FontAwesomeIcon  icon={faSpinnerThird} spin /></h1> :
        <WrappedComponent {...this.props} />
    }
  }

  withLoading.propTypes = {
    data: PropTypes.oneOfType([
      model.ErrorShape,
      model.LoadingShape,
      PropTypes.array,
      PropTypes.object
    ]),
    loadFromServer: PropTypes.func.isRequired
  }

  return withLoading
}