import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import core from '../../../_core'
import auth from '../../../auth'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

import AddBundleToCart from '../AddBundleToCart'
import PriceOption from '../PriceOption'

class AddBundleToCartContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPrice: 0,
      selectedOption: 0,
      isInvoice: false
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.onOptionChange = this.onOptionChange.bind(this);
    this.onAddBundleToCartSuccess = this.onAddBundleToCartSuccess.bind(this)
  }

  onSubmit(e) {
    e.preventDefault();
    const selectedBundleObj = selectors.getBundle(this.props.prices, parseInt(this.state.selectedPrice), parseInt(this.state.selectedOption));

    this.props.addBundleToCart(selectedBundleObj, this.onAddBundleToCartSuccess, this.state.isInvoice);
  }

  onAddBundleToCartSuccess(isInvoice) {
    this.props.history.push(`/shoppingcart/checkout?bundle=true&invoice=${isInvoice}`, {from: this.props.location.pathname})
  }

  onOptionChange(selectedPrice, selectedBundle) {
    this.setState({
      selectedOption: selectedBundle,
      selectedPrice
    });
  }

  render() {
    var firstRender = true;
    const currency = this.props.user && this.props.user.chargeInCad ? 'CAD' : 'USD'
    const descriptionFragment = <span className="productDetails_qty">Single</span>

    return this.props.prices.map((price) => {
      const priceFragment = <p><span className={"enMoney " + price.currency || 'USD'}>{price.price}</span><span className="unit">per download</span></p>

      return price.isActive && !price.isHidden && <React.Fragment key={price.id}>
        {firstRender ? firstRender = false : <div className="pricingCard_verticalHR-wrapper"><div className="pricingCard_verticalHR"></div></div>}
        
        <div className="pricingCard_wrapper">
          <div className="pricingCard">
            <h2>{price.title}</h2>
            {/* <p className="pricingCard_price"><span className={"enMoney h1 " + currency}>{price.price}</span>/{price.productType}</p> */}
            <p className="pricingCard_description">{price.longDescription}</p>
            <p className="pricingCard_details">{price.specs}<br />{price.moreSpecs}<br />{price.evenMoreSpecs}</p>

            <div className="flex column price_options pricingCard_form-options">
              <PriceOption key={price.id} shortPricing={true} {...price } isAdvert={true} currency={currency} description={descriptionFragment} noIcon={true} priceFragment={priceFragment} />
            </div>

            { core.util.sortByKey(price.bundles, 'quantity', true).some(b => b.isActive && !b.isHidden) &&
              <form key={price.name} className="flex column price_options pricingCard_form-options" onSubmit={this.onSubmit} style={{marginTop: 0}}>
                <AddBundleToCart {...price} onOptionChange={this.onOptionChange} selectedOption={this.state.selectedOption} user={this.props.user} shortPricing={true} />
                <button type="submit" className="button" value="Buy Now" onClick={() => this.setState({isInvoice: false})} disabled={this.state.selectedPrice != price.id}>Buy now</button>
                {this.props.user && this.props.user.allowInvoice && <button type="submit" className="button get-invoice" value="Complete Invoice" onClick={() => this.setState({isInvoice: true})} disabled={this.state.selectedPrice != price.id}>Complete Invoice</button>}
              </form> }
          </div>
        </div>
      </React.Fragment>
    })
  }
}

AddBundleToCartContainer.propTypes = {
  auth: PropTypes.string,
  prices: PropTypes.array,
  cart: PropTypes.array
}

function stateToProps(state)
{
  return {
    auth: auth.selectors.getUserAuthToken(state),
    prices: state.shoppingcart.prices,
    cart: state.shoppingcart.cart,
    user: state.user
  }
}

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    loadFromServer: (auth) => actions.listPrices({token: auth}),
    addBundleToCart: (bundle, onSuccess, isInvoice) => actions.addBundleToCart(bundle, onSuccess, isInvoice),
    removeJustAdded: () => actions.removeJustAdded()
  }, dispatch)
)

export default withRouter(
  connect(stateToProps, dispatchToProps)(
    core.components.withLoading(AddBundleToCartContainer, ['auth'])
  )
)