/**
* @module
* actionTypes.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Thu May 31 2018 11:43:38 GMT-0600 (MDT)
* @copyright None
* @last-modified Wed Jun 20 2018 13:47:08 GMT-0600 (MDT)
*/


import { moduleName } from './constants'

const actions = [
  'get',
  'select',
  'unselect'
]

export default actions.reduce(
  (object, action) => {
    object[action] = `${moduleName}.${action}`
    return object
  },
  {}
)