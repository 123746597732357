/**
* @module search
* selectors.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Thu May 17 2018 14:07:27 GMT-0600 (MDT)
* @copyright None
* @last-modified Thu May 17 2018 14:07:27 GMT-0600 (MDT)
*/


import { moduleName } from './constants'

export const getSearch = (state) => (state[moduleName])
export const getSearchProducts = (state) => (getSearch(state).products || null)
export const getSearchPhotoshoots = (state) => (getSearch(state).photoshoots || null)
export const getSearchInfo = (state) => (getSearch(state).info || {})