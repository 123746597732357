/**
 * src/modules/flash/selectors.js
 */

import { moduleName } from './constants'

/**
 * Given a state, return the message
 * @param {*} state
 */
export const getMessage = (state) => (state[moduleName])