/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:46:07
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-05-15 14:27:35
 */

import core from '../_core'

class Service extends core.Service {

  listPrices(authToken) {
    return super.get('/price', {}, authToken);
  }

  getCoupon(code, orderType, authToken) {
    return super.get(`/coupon/code?code=${code}&orderType=${orderType}`, {}, authToken);
  }
  getPersistentCoupon(orderType, authToken) {
    return super.get(`/coupon/code?orderType=${orderType}&persistent=true`, {}, authToken);
  }

  createOrder(order, authToken) {
    return super.post('/shoppingcart/createOrder', order, authToken)
  }

  getUserProductOwnership(productIds, authToken) {
    return super.post(`/order/userProductOwnership`, {productIds}, authToken);
  }

 
}
  

export default Service