/**
 * src/modules/flash/actions.js
 */

import * as actions from './actionTypes'

export const show = (message) => ({
  type: actions.show,
  message
})

export const hide = () => ({
  type: actions.hide
})