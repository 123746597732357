/**
* @module gallery/components/containers
* Gallery.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue Jun 12 2018 13:58:07 GMT-0600 (MDT)
* @copyright None
* @last-modified Fri Jun 22 2018 15:01:29 GMT-0600 (MDT)
*/

import React from 'react'

import * as constants from '../../constants'
import modals from '../modals'

const GalleryContainer = (props) =>
  <section>
    <modals.ListGalleries id={constants.modals.list} />
  </section>

export default GalleryContainer