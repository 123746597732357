/**
* @module board/components/modals
* EditBoard.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue Jun 12 2018 10:42:17 GMT-0600 (MDT)
* @copyright None
* @last-modified Thu Jun 21 2018 14:07:53 GMT-0600 (MDT)
*/

import React from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { PropTypes } from 'prop-types'

import core from '../../../_core'
import auth from '../../../auth'

import * as actions from '../../actions'
import * as selectors from '../../selectors'
import { Shape } from '../../model'

class EditBoard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      title: props.data.data.title || ''
    }

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.deleteBoard = this.deleteBoard.bind(this)
  }

  onChange(e) {
    this.setState({
      title: e.target.value
    })
  }

  onSubmit(e) {
    e.preventDefault()

    this.props.update({
      id: this.props.data.data.id,
      token: this.props.auth,
      title: this.state.title
    }).then(
      () => {
        this.props.hideModal()
      }
    )
  }

  deleteBoard(e){
    e.preventDefault()


    this.props.destroy({
      id: this.props.data.data.id,
      token: this.props.auth,
      title: this.state.title
    }).then(
      () => {
        this.props.hideModal()
        this.props.history.push('/boards')
      }
    )
  }

  render() {
    const { title } = this.state

    return (
      <form className="form__editBoard"  onSubmit={this.onSubmit}>
        <h2>Edit board</h2>
        <fieldset>
          <input type="text" placehoder={title} onChange={this.onChange} value={title}/>
        </fieldset>
        <div className="text-left spacer-padding-top-20">
          <button type="submit">Save</button>
        </div>

        <div className="text-left spacer-padding-top-50">
          <button onClick={this.deleteBoard} className="light_grey" >Delete board</button>
        </div>
      </form>
    )
  }
}

EditBoard.propTypes = {
  auth: PropTypes.string,
  data: PropTypes.oneOfType([
    Shape,
    core.model.ErrorShape,
    core.model.LoadingShape
  ]),
  update: PropTypes.func.isRequired,
  destroy: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
}

const stateToProps = (state) => ({
  auth: auth.selectors.getUserAuthToken(state),
  data: selectors.getBoard(state)
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    update: actions.update,
    destroy: actions.destroy,
    hideModal: core.actions.hideModal
  }, dispatch)
)

export default withRouter(
  connect(stateToProps, dispatchToProps)(
    core.components.modals.Modal(EditBoard)
  )
)