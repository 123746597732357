/**
* @module shoppingcart/pages
* index.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue May 29 2018 13:08:26 GMT-0600 (MDT)
* @copyright None
* @last-modified Tue May 29 2018 13:08:26 GMT-0600 (MDT)
*/

import PastPurchases from './PastPurchases'
import Invoice from './invoice'


export default {
  PastPurchases,
  Invoice,
}