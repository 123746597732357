/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-17 15:15:23
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-06-08 12:15:23
 */

import PropTypes from 'prop-types'

import core from '../_core'
import product from '../product'

export const PhotoshootShape = PropTypes.shape({
  batch: PropTypes.string,
  createdAt: PropTypes.instanceOf(Date),
  description: PropTypes.string,
  id: PropTypes.number,
  keywords: PropTypes.string,
  liveDate: PropTypes.instanceOf(Date),
  products: PropTypes.arrayOf(product.Shape),
  thumbnail: product.Shape,
  submittedDate: PropTypes.instanceOf(Date),
  title: PropTypes.string,
  updatedAt: PropTypes.instanceOf(Date),
  verticalCategory: PropTypes.string
})

export const Shape = PropTypes.oneOfType([
  PropTypes.arrayOf(PhotoshootShape),
  core.model.ErrorShape,
  core.model.LoadingShape
])

export const generate = () => ([]);

export const generatePhotoshoot = ({
  batch = undefined,
  createdAt = undefined,
  description = undefined,
  id = undefined,
  keywords = undefined,
  liveDate = undefined,
  products = undefined,
  thumbnail = undefined,
  submittedDate = undefined,
  title = undefined,
  updatedAt = undefined,
  verticalCategory = undefined
} = {}) => ({
  batch,
  createdAt,
  description,
  id,
  keywords,
  liveDate,
  products,
  thumbnail,
  submittedDate,
  title,
  updatedAt,
  verticalCategory
})