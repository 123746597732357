/**
 * src/app/storeConfig.js
 */

import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

// Modules
import auth from '../modules/auth'
import core from '../modules/_core'
import filter from '../modules/filter'
import flash from '../modules/flash'
import photoshoot from '../modules/photoshoot'
import product from '../modules/product'
import search from '../modules/search'
import user from '../modules/user'
import gallery from '../modules/gallery'
import board from '../modules/board'
import shoppingcart from '../modules/shoppingcart'
import order from '../modules/order'

const persistConfig = {
  key: 'hero',
  storage,
  whitelist: ['auth', 'filter']
}

const cartPersistConfig = {
  key: 'shoppingcart',
  storage,
  whitelist: ['cart', 'bundleCart', 'coupon', 'galleryCart']
}

export const store = createStore(
  persistReducer(
    persistConfig,
    combineReducers({
      auth: auth.reducer,
      authOperation: auth.operationReducer,
      core: core.reducer,
      filter: filter.reducer,
      flash: flash.reducer,
      product: product.reducer,
      productSelection: product.selectionReducer,
      photoshoot: photoshoot.reducer,
      photoshootContext: photoshoot.contextReducer,
      search: search.reducer,
      user: user.reducer,
      gallery: gallery.reducer,
      galleryContext: gallery.contextReducer,
      board: board.reducer,
      shoppingcart: persistReducer(cartPersistConfig, shoppingcart.reducer),
      order: order.reducer,
      boardContext: board.contextReducer
    })
  ),
  composeWithDevTools(applyMiddleware(thunk))
)

export const persistor = persistStore(store)