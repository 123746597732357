// dead component? no PhotoshootSimple on ProductDetails page

import React from 'react'
import PropTypes from 'prop-types'
import components from '../'
import core from '../../../_core'
import product from '../../../product'

import {moduleName} from '../../constants'
import { Shape } from '../../model'

class ListSimple extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.onImageLoad = this.onImageLoad.bind(this);
  }

  onImageLoad(item, {target:img}) {
    //Set the aspect ratio in the state
    this.setState({ [`${moduleName}_${item.id}`]: (img.offsetWidth / img.offsetHeight) })
  }
  render(){
    const { data, onPhotoshootClick } = this.props;
    let listItems = [];
    

    let products = data.products
    if (this.props.params && this.props.params.filters && this.props.params.filters.type) {
      products = this.props.params.filters.type.video ? this.props.videos : this.props.stills
    }
    
    if(Array.isArray(products)) {
      listItems = products.map(item => 
        <product.components.Thumb 
          key={`product-${item.id}`}
          data={{...item, featuredProductId: -1}} // no video 
          onLoad={this.onImageLoad.bind(this, item)}
          className={'productDetail'} 
          isThumbActive={item.isActive}
          hideCaption={true} 
          onClick={onPhotoshootClick.bind(this, item)} 
          routerPath={`/product/${item.id}`}
          isTiny={true} />
      )}

    return(
      <div className="flex">{listItems}</div>
    )
  }
}

ListSimple.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(Shape),
    core.model.ErrorShape,
    core.model.LoadingShape
  ])
}

export default ListSimple