/**
* @module board/components/containers
* Board.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue Jun 12 2018 13:58:07 GMT-0600 (MDT)
* @copyright None
* @last-modified Fri Jun 22 2018 15:01:29 GMT-0600 (MDT)
*/

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import core from '../../../_core'

import * as actions from '../../actions'
import * as constants from '../../constants'
import * as model from '../../model'
import modals from '../modals'

class BoardContainer extends React.Component {
  constructor(props) {
    super(props)

    this.onCreateBoard = this.onCreateBoard.bind(this)
    this.onDeleteBoard = this.onDeleteBoard.bind(this)
    this.onEditBoard = this.onEditBoard.bind(this)
    this.onListBoards = this.onListBoards.bind(this)
  }

  onCreateBoard(e) {
    e.preventDefault();
  }

  onDeleteBoard(e) {
    e.preventDefault();
  }

  onEditBoard(e) {
    e.preventDefault();
  }

  onListBoards(e) {
    e.preventDefault();
  }
  render() {
    return (
      <section>
        <modals.CreateBoard id={constants.modals.create} />
        <modals.DeleteBoard id={constants.modals.delete} />
        <modals.EditBoard id={constants.modals.edit} />
        <modals.ListBoards id={constants.modals.list} />
      </section>
    )
  }
}

BoardContainer.propTypes = {
  board: model.Shape,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
}

const stateToProps = (state) => ({
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    ...actions,
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(BoardContainer)