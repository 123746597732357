
import React from 'react'
import PropTypes from 'prop-types'
import {
  FacebookShareButton,
  TwitterShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  EmailShareButton
} from 'react-share'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebookF, faTwitter, faGooglePlusG, faLinkedinIn, faPinterest,  faInstagram} from '@fortawesome/free-brands-svg-icons'
import {faEnvelope} from '@fortawesome/free-regular-svg-icons'

import { defaultPinterestMedia } from '../../constants'

import Modal from './Modal'

const _IWantToTrashReactShare = {
  cursor: 'pointer',
  margin: '0 10px',
  fontSize: '1.4em'
}

const Share = (props) => (
  <section>
    <h2 className="spacer-padding-bottom-20">Share</h2>
    <section className="Share-icons flex space-around" style={{color: 'black', margin: '0 -10px'}}>
      <FacebookShareButton url={props.url} >
        <div title="Share on Facebook" alt="Share on Facebook" style={_IWantToTrashReactShare}><FontAwesomeIcon icon={faFacebookF} /></div>
      </FacebookShareButton>

      <TwitterShareButton 
        url={props.url}
        title={props.title ? props.title : 'Hero Images'}>
        <div title="Share on Twitter" alt="Share on Twitter" style={_IWantToTrashReactShare}><FontAwesomeIcon icon={faTwitter} /></div>
      </TwitterShareButton>

      <GooglePlusShareButton url={props.url}>
        <div title="Share on Google+" alt="Share on Google+" style={_IWantToTrashReactShare}><FontAwesomeIcon icon={faGooglePlusG} /></div>
      </GooglePlusShareButton>

      <LinkedinShareButton 
        url={props.url} 
        title={props.title ? props.title : 'Hero Images'} 
        description={props.description 
          ? 
          `
          ${props.description} 

          ${props.url} 
          
          Hero Images | heroimages.com`
          : props.url}>
        <div title="Share on LinkedIn" alt="Share on LinkedIn" style={_IWantToTrashReactShare}><FontAwesomeIcon icon={faLinkedinIn} /></div>
      </LinkedinShareButton>

      <PinterestShareButton 
        url={props.url} 
        media={props.media || defaultPinterestMedia}
        description={props.description 
          ? 
          `
          ${props.description} 

          ${props.url} 
          
          Hero Images | heroimages.com`
          : props.url}>
        <div title="Share on Pintrest" alt="Share on Pintrest" style={_IWantToTrashReactShare}><FontAwesomeIcon icon={faPinterest} /></div>
      </PinterestShareButton>

      <EmailShareButton 
        url={props.url} 
        subject={props.title ? props.title : 'Hero Images'} 
        body={props.description 
          ? 
          `
          ${props.description} 

          ${props.url} 

          Hero Images | heroimages.com`
          : props.url}>
        <div title="Share with email" alt="Share with email" style={_IWantToTrashReactShare}><FontAwesomeIcon icon={faEnvelope} /></div>
      </EmailShareButton>
    </section>
  </section>
)

Share.propTypes = {
  url: PropTypes.string,
  media: PropTypes.string,
}

export default Modal(Share)