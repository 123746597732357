/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:48:50
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-05-22 11:35:50
 */
import * as FileDownload from 'js-file-download'

import auth from '../auth'
import core from '../_core'
// import filter from '../filter'
import flash from '../flash'

import * as actionTypes from './actionTypes'
import {moduleName, modals} from './constants'
import * as errorTypes from './errorTypes'
import * as userErrorTypes from '../user/errorTypes'
import Service from './service';


export const getOrders = (authToken, service = new Service()) => ((dispatch) => {
  if (!authToken) {
    return dispatch(core.actions.showModal({id: auth.constants.modals.login}));
  }
  return service.getOrders(authToken)
    .then(
      (response) => dispatch(getOrdersSuccess(response.data.data)),
      (error) => dispatch(flash.actions.show(core.util.errorHandler(error, userErrorTypes.getUserInfo)))
    )
})

export const getOrder = (id = '', authToken, service = new Service()) => ((dispatch) => {
  if (!authToken) {
    return dispatch(core.actions.showModal({id: auth.constants.modals.login}));
  }
  return service.getOrder(id, authToken)
    .then(
      
      (response) => dispatch(getOrderSuccess(response.data.data)),
      (error) => dispatch(flash.actions.show(core.util.errorHandler(error, userErrorTypes.getUserInfo)))
    )
})

export const downloadImage = (filename, id = '', authToken, service = new Service()) => ((dispatch) => {
  if (!authToken) {
    return dispatch(core.actions.showModal({id: auth.constants.modals.login}));
  }
  dispatch(addToDownloads(id));
  return service.downloadImage(id, authToken)
    .then(
      response => service.downloadSignedUrl(response.data.data)
        .then(
          fileResponse => {
            FileDownload(fileResponse.data, response.data.info);
            dispatch(removeFromDownloads(id));
          },
          error => dispatch(removeFromDownloads(id))
        ),
      (error) => {
        dispatch(removeFromDownloads(id));
      }
    )
})

export const downloadInvoice = (orderId, orderNumber, authToken, service = new Service()) => (async (dispatch) => {
  const redirectUrl = window.location.origin || 'https://heroimages.com';

  if (!redirectUrl.includes("localhost")) {
    return service.downloadInvoice(orderId, authToken)
      .then(
        pdfResponse => FileDownload(pdfResponse.data, pdfResponse.headers['x-suggested-filename']),
        error => dispatch(flash.actions.show(core.util.errorHandler(error, {500: 'error downloading invoice'})))
      );
  }
  else {
    dispatch(flash.actions.show("External downloading not available on localhost. Add 'downloader=true' to the page url."))
  }
})


export const getOrdersSuccess = (orders) => ({
  type: actionTypes.getOrders,
  orders
})

export const getOrderSuccess = (order) => ({
  type: actionTypes.getOrder,
  order
})

export const addToDownloads = (itemId) => ({
  type: actionTypes.addToDownloads,
  itemId
})

export const removeFromDownloads = (itemId) => ({
  type: actionTypes.removeFromDownloads,
  itemId
})