/**
 * src/modules/auth/components/Denied.js
 */

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

const Denied = ({history, timeout}) => {
  window.setTimeout(() => {
    history.push('/')
  }, timeout)

  return (
    <main>
      <h1>Access Denied</h1>
      <p className="text-center">
        You do not have permission to access this page. You will be redirected in {timeout / 1000} seconds.
      </p>
    </main>
  )
}

Denied.propTypes = {
  history: PropTypes.object,
  timeout: PropTypes.number
}

export default withRouter(Denied)