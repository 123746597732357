/**
 * src/modules/filter/service.js
 *
 * @Author: Weslie Leung
 * @Date: 2018-05-09 11:07:12
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-05-11 09:48:11
 */

import core from '../_core'

const _processFilterName = (filter) => (
  filter.split('_').map(token => (
    token.toUpperCase() == "OF" ? token : token.charAt(0).toUpperCase() + token.slice(1)
  )).join(' ')
)

class Service extends core.Service {
  getFilters() {
    return super.get('/search/filters')
      .then(
        (response) => {
          let filterFlags = {}

          // Convert an object of arrays to an object of objects with default unselected state
          if (typeof response.data.data === 'object') {
            const filterList = response.data.data
            const keys = Object.keys(filterList)

            filterFlags = keys.reduce(
              (filters, key) => {
                filters[key] = filterList[key].reduce(
                  (categoryFlags, filter) => {
                    categoryFlags[_processFilterName(filter)] = false
                    return categoryFlags
                  },
                  {}
                )
                return filters
              },
              {}
            )
          }

          return filterFlags
        }
      )
  }
}

export default Service