/**
 * src/modules/auth/components/withAuthAdmin.js
 *
 * A Higher Order Component (HOC) that determines if user is admin or not
 */

import React from 'react'
import { connect } from 'react-redux'

import core from '../../_core'

import { isUserAdmin } from '../selectors'

import Denied from './Denied'

const stateToProps = (state) => ({
  isAdmin: isUserAdmin(state)
})

export default (WrappedComponent) => {
  return connect(stateToProps)((props) => {
    return props.isAdmin ?
      (<WrappedComponent {...props}/>) :
      (<Denied timeout={core.constants.redirectTimeout}/>)
  })
}