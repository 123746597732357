/**
* @module board/components/modals
* CreateBoard.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue Jun 12 2018 10:37:56 GMT-0600 (MDT)
* @copyright None
* @last-modified Fri Jun 22 2018 16:34:49 GMT-0600 (MDT)
*/

import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import core from '../../../_core'
import auth from '../../../auth'
import flash from '../../../flash'
import product from '../../../product'

import * as actions from '../../actions'
import * as selectors from '../../selectors'
import {Shape} from '../../model'


class CreateBoard extends React.Component {
  constructor(props){
    super(props);

    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)

    this.state ={
      title: ''
    }

  }

  onSubmit(e) {
    e.preventDefault()

    this.props.create({
      productId: this.props.selectedProduct.id,
      token: this.props.auth,
      title: this.state.title
    }).then(
      (newBoard) => {
        this.props.hideModal()
        this.props.showMessage((
          <div>
            <span>Successfully added to </span>
            <Link
              to={`/boards/${newBoard.id}`}
              onClick={() => this.props.hideMessage()}
            > {newBoard.title}</Link>
          </div>
        ))
      }
    )
  }

  onChange(e){
    this.setState({
      title: e.target.value
    })
  }

  render(){
    const { selectedProduct } = this.props
    return(

      <form className="form__createBoard" onSubmit={this.onSubmit}>
        <h2>Create new board</h2>
        {
          selectedProduct && selectedProduct.thumbnailUrl &&
          <div className="flex centered align-center">
            <img crossOrigin="Anonymous" alt={selectedProduct.description} src={!selectedProduct.thumbnailUrl || selectedProduct.thumbnailUrl.startsWith('data:') || selectedProduct.thumbnailUrl.startsWith('/') ? selectedProduct.thumbnailUrl : (selectedProduct.thumbnailUrl + '?v=2020-06-22')} />
          </div>
        }
        <fieldset>
          <label>
            <input type="text" name="boardName" placeholder="Board name" value={this.state.title} onChange={this.onChange} required />
          </label>
        </fieldset>
        <div className="text-left spacer-padding-bottom-20">
          <button type="submit">Save</button>
        </div>
      </form>

    )

  }

}

CreateBoard.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(Shape),
    core.model.ErrorShape,
    core.model.LoadingShape
  ]),
  selectedProduct: PropTypes.object,
  addProduct: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  hideMessage: PropTypes.func.isRequired
}

const stateToProps = (state) => ({
  auth: auth.selectors.getUserAuthToken(state),
  selectedProduct:  product.selectors.getSelection(state),
  boards: selectors.getBoards(state),
  data: selectors.getBoards(state),
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    ...actions,
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal,
    showMessage: flash.actions.show,
    hideMessage: flash.actions.hide,
    create: actions.create
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(
  core.components.modals.Modal(CreateBoard)
)
