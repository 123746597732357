/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-16 15:01:51 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-23 15:31:10
 */


import React from 'react'
import PropTypes from 'prop-types'

import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

import core from '../../_core'
import * as model from '../model'

class EditAccountInfo extends React.Component {
  constructor(props) {
    super(props)

    this.state = model.generate(props.user);

    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.validate = this.validate.bind(this)

    this.selectCountry = this.selectCountry.bind(this);
    this.selectRegion = this.selectRegion.bind(this);
  }

  onChange(e) {
    const newState = {}

    newState[e.target.name] = e.target.value
    this.setState(newState)
    this.validate(e.target)
  }

  onSubmit(e) {
    e.preventDefault()

    const form = e.target
    const { setUserInfo, onSuccess, authToken } = this.props;

    if (form.querySelectorAll('[data-valid="false"]').length === 0) {
      setUserInfo(this.state, authToken)
        .then(
          () => onSuccess(e)
        )
    }
  }

  validate(formField) {
    const validator = formField.dataset['validator']

    // If there's no validator configured, make it valid
    formField.dataset['valid'] = validator ? core.util.validate(validator, formField.value) : true
  }

  selectCountry (val) {
    this.setState({ country: val });
  }

  selectRegion (val) {
    this.setState({ region: val });
  }

  render() {
    return (
      <form className="form__edit_account" onSubmit={this.onSubmit}>
        <h2>Edit Billing Address</h2>

        <fieldset>
          {/*
           <label>
            <div>First name</div>
            <input type="text" name="billingFirstName" placeholder="John" value={this.state.billingFirstName} onChange={this.onChange} required />
          </label>
          <label>
            <div>Last name</div>
            <input type="text" name="billingLastName" placeholder="Smith" value={this.state.billingLastName} onChange={this.onChange} required />
          </label>
          <label>
            <div>Company</div>
            <input type="text" name="billingCompany" placeholder="Hero Images" value={this.state.billingCompany} onChange={this.onChange} />
          </label> */}
          <label>
            <div>Address 1</div>
            <input type="text" name="address1" placeholder="" value={this.state.address1} onChange={this.onChange} />
          </label>
          <label>
            <div>Address 2</div>
            <input type="text" name="address2" placeholder="" value={this.state.address2} onChange={this.onChange} />
          </label>
          <label>
            <div>City</div>
            <input type="text" name="city" placeholder="" value={this.state.city} onChange={this.onChange} />
          </label>
          <label>
            <div>Country</div>
            <CountryDropdown
              value={this.state.country}
              onChange={this.selectCountry} />
          </label>
          <label>
            <div>State/Province</div>
            <RegionDropdown
              defaultOptionLabel="State/Province"
              disableWhenEmpty={true}
              country={this.state.country}
              value={this.state.region}
              onChange={this.selectRegion} />
          </label>
          <label>
            <div>Zip/Postal</div>
            <input type="text" name="zipPostal" placeholder="" value={this.state.zipPostal} onChange={this.onChange} />
          </label>

              
              

        </fieldset>

        <div className="text-center spacer-padding-bottom-40">
          <button type="submit">Submit</button>
        </div>
      </form>
    )
  }

}

EditAccountInfo.propTypes = {
  authToken: PropTypes.string,
  user: model.Shape,
  onSuccess: PropTypes.func,
  setUserInfo: PropTypes.func
}

export default core.components.modals.Modal(EditAccountInfo)