/**
* @module board/components/containers
* Single.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Mon Jun 11 2018 09:56:36 GMT-0600 (MDT)
* @copyright None
* @last-modified Wed Jul 11 2018 16:51:45 GMT-0600 (MDT)
*/

import React from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import JustifiedLayout from 'react-justified-layout'

import core from '../../../_core'
import layout from '../../../layout'
import product from '../../../product'

import { moduleName } from '../../constants'
import { Shape } from '../../model'
import * as constants from '../../constants'
import components from '../../components'
import { GalleriesIcon_Share } from '../../img'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faEllipsisH } from '@fortawesome/pro-light-svg-icons'

import {Helmet} from 'react-helmet'


class Single extends React.Component {
  constructor(props) {
    super(props);

    this.elementRef = React.createRef();
    this.onImageLoad = this.onImageLoad.bind(this);
    this.onResize = this.onResize.bind(this);

    this.state = {}
  }

  onImageLoad(item, {target:img}) {
    //Set the aspect ratio in the state
    this.setState({ [`${moduleName}_${item.id}`]: (img.offsetWidth / img.offsetHeight) })
  }

  onResize(screenSize){
    let returnWidth = this.elementRef.current && this.elementRef.current.offsetWidth ? this.elementRef.current.offsetWidth : undefined;
    return returnWidth;
  }

  componentDidUpdate() {
    this.props.onChildUpdated(this.elementRef)
  }

  render() {
    const {
      data,
      onShare,
      onEdit,
      onRemoveProduct,
      onAddBoard,
      showAddMultipleToCartModal,
      authObj
    } = this.props
    let listItems = []
    let containerWidth = this.onResize(this.props.screen.width);//this.elementRef.current && this.elementRef.current.offsetWidth ? this.elementRef.current.offsetWidth : undefined;
    const config = {
      boxSpacing: {
        horizontal: 10,
        vertical: 10
      },
      centerMode: true,
      containerPadding: {
        top: 0,
        right: 0,
        left: 0,
        bottom: 20
      },
      containerWidth,
      targetRowHeight: 275
    }
    let products = data && data.products
    let boardUser = data && data.userId
    let boardError = data && data.error

    if(Array.isArray(products)) {
      listItems = products.map(item => <product.components.Thumb
        data={{...item, userId: boardUser}}
        key={`${moduleName}-${item.id}`}
        aspectRatio={this.state[`${moduleName}_${item.id}`] || 1}
        onLoad={this.onImageLoad.bind(this, item)}
        hideCaption={true}
        hasMenu={true}
        routerPath={`/product/${item.id}`}
        onAddBoard={onAddBoard}
        showAddMultipleToCartModal={showAddMultipleToCartModal}
        onRemoveProduct={onRemoveProduct}
      />)
    }

    return (
     
      <section className="full-wd-page gallery__masonrygallery">
        <Helmet>
          <title>Board: {data.title} | Hero Images</title>
          <meta name="description"           content="Boards | Hero Images" />
        </Helmet>
        { boardError === 'Request failed with status code 404'
          ?
          <h3>This board is no longer available</h3>
          :
          <div>
            <section className="flex centered spacer-padding-top-20 gallery__header">
              {
                authObj && (authObj.id === boardUser)
                  ?
                  <Link to="/boards" className="flex button link-icon"><FontAwesomeIcon icon={faArrowLeft}/>&nbsp;Your Boards</Link>
                  :
                  <button className="flex button link-icon"></button>
              }
              <h1 className="flex centered">{data && data.title}</h1>
              <div className="flex centered align-center spacer-padding-bottom-40">
                {
                  listItems.length > 0 &&
              <a className="button spacer-padding-right-100" onClick={() => this.props.showAddMultipleToCartModal(data.products)}>Add all to cart</a>
                }
                <a onClick={onShare} className={`button link-icon ${listItems.length>0 && 'witems'}`}>Share<img alt="gallery icon" src={GalleriesIcon_Share}/></a>

                {
                  authObj && (authObj.id === boardUser) &&
                <button className="button link-icon" onClick={onEdit}>Edit&nbsp;<FontAwesomeIcon icon={faEllipsisH} /></button>
                }

              </div>
            </section>
            <section>
              {
                listItems.length === 0 &&
            <h3>This board is currently empty</h3>
              }
              <div className='grid__photoshoots' ref={this.elementRef}>
                <JustifiedLayout {...config}>
                  {listItems}
                </JustifiedLayout>
              </div>
            </section>
          </div>
        }
      </section>
    )
  }
}

Single.propTypes = {
  data: PropTypes.oneOfType([
    Shape,
    core.model.ErrorShape,
    core.model.LoadingShape
  ]),
  screen: layout.ScreenShape,
  onEdit: PropTypes.func.isRequired
}

export default core.components.withError(
  layout.components.withResize(Single)
)