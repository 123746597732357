/**
 * src/modules/auth/actions.js
 */
import core from '../_core'
import flash from '../flash'

import * as userActionTypes from '../user/actionTypes'
import * as actionTypes from './actionTypes'
import * as constants from './constants'
import * as errorTypes from './errorTypes'
import * as model from './model'
import Service from './service'
import { postToHubspot, postToHubspotRegister } from '../_core/hubspot'

export const login = (creds = model.generateCredentials(), service = new Service()) => ((dispatch) => {
  return service.login(creds)
    .then(
      (response) => {
        dispatch(onLoginSuccess(response, actionTypes.login))
        dispatch({type: userActionTypes.getUserInfo, user: response.data.data.user})
        dispatch(core.actions.hideModal())

        postToHubspot(false, creds.email, "Login", "", "", "")
        
      },
      (error) => {
        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.loginError)))
      }
    )
})

export const check = (token, service = new Service()) => ((dispatch) => {
  return service.check(token)
    .then(
      (response) => {
        response.data.data.token = token
        dispatch(onLoginSuccess(response, actionTypes.check))
        dispatch({type: userActionTypes.getUserInfo, user: response.data.data.user})
        dispatch(core.actions.hideModal())
      },
      (error) => {
        localStorage.removeItem('id_token');
        dispatch(logout())
        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.loginError)))
      }
    )
})

export const logout = () => ({
  type: actionTypes.logout
})

export const onLoginSuccess = (response, actionType) => ({
  type: actionType,
  auth: {
    id: response.data.data.user.id,
    role: response.data.data.user.role,
    token: response.data.data.token,
    user: response.data.data.user
  }
})

export const forgetPassword = ({email}, service = new Service()) => ((dispatch) => {
  return service.forgetPassword({email})
    .then(
      (response) => {
        dispatch(onForgetPasswordSuccess(response))
      },
      (error) => dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.emailNotFoundError)))
    )
})

export const onForgetPasswordSuccess = (response) => ({
  type: actionTypes.forgetPassword,
  auth: {...response.data.data, ...{requestTime: Math.floor(Date.now())}}
})

export const register = ({firstName, lastName, email, company, industry, password, newsletter, hubspotcookie, jobTitle, profileCountry}, service = new Service()) => ((dispatch) => {

  return service.register({firstName, lastName, email, company, industry, password, newsletter,hubspotcookie, jobTitle, profileCountry})
    .then(
      (response) => {
        dispatch(onRegisterSuccess(response))
        dispatch({type: userActionTypes.getUserInfo, user: response.data.data})
        dispatch(core.actions.hideModal())

        postToHubspotRegister(newsletter, email, "Signup", firstName, lastName, profileCountry, company, jobTitle, industry)
      },
      (error) => {
        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.registerError)))
        return Promise.reject(error)
      }
    )
})

export const onRegisterSuccess = (response) => ({
  type: actionTypes.register,
  auth: {
    id: response.data.data.id,
    role: response.data.data.role,
    token: response.data.token,
    user: response.data.data
  }
})

export const getPasswordResetToken = () => ({
  type: actionTypes.getPasswordResetToken
})

export const verifyPasswordResetToken = () => ({
  type: actionTypes.verifyPasswordResetToken
})

export const storeOperation = (operation) => ((dispatch) => {
  dispatch({
    type: actionTypes.storeOperation,
    operation
  })
})

export const clearOperation = () => ((dispatch) => {
  dispatch({
    type: actionTypes.clearOperation
  })
})
