/**
 * pages/components/hero-free.js
 */

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import core from '../../_core'
import * as actions from '../../auth/actions'
import * as constants from '../../auth/constants'
import * as selectors from '../../auth/selectors'
import * as model from '../../auth/model'

import modals from '../../auth/components/modals'
import auth from '../../auth/';

import {Helmet} from 'react-helmet'
import header from '../img/hero-free/header-oct-v2.jpg';
import sideHeader from '../img/hero-free/side-header.jpg';
import img01 from '../img/hero-free/img-oct-01.jpg';
import img02 from '../img/hero-free/img-oct-02.jpg';
import grid01 from '../img/hero-free/grid01.jpg';
import grid02 from '../img/hero-free/grid02.jpg';
import grid03 from '../img/hero-free/grid03.jpg';

const metaData = {
  description : `Hero Free | Hero Images`,
  keywords :'Hero Images, Stock Photography',
  title: 'Hero Free | Hero Images',
  name : 'Hero Images',
  url : window && window.location && `${window.location.protocol}//${window.location.host}/tailored-custom-libraries`,
  image : header,
  TwitterSite : '@heroimages',
  TwitterCard : 'summary',
  ogType : 'article'
}

class PageHeroFree extends React.Component {
  constructor(props) {
    super(props)

    this.onLoggingIn = this.onLoggingIn.bind(this)
    this.onLoggedIn = this.onLoggedIn.bind(this)
    this.onRegisterCompleteClose = this.onRegisterCompleteClose.bind(this)
    this.onTestClick = this.onTestClick.bind(this)
  }

  componentDidMount() {
    let token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1");
    if (token) {
      document.cookie = "token=;path=/"
    }
    else {
      token = localStorage.getItem('id_token')
    }

    if (token) {
      this.props.check(token)
        .then(
          () => this.onLoggedIn()
        )
    }
    else if (this.props.auth && this.props.auth.token) {
      this.props.check(this.props.auth.token)
        .then(
          () => this.onLoggedIn()
        )
    }
  }

  onLoggedIn() {
    const { isAuthenticated, storedOperation, showModal, clearOperation } = this.props;

    if (isAuthenticated) {
      if (storedOperation && storedOperation.type === 'showModal') {
        showModal({ id: storedOperation.id })
        clearOperation()
        this.props.history.push('/');
      }
    }
  }

  onLoggingIn(e) {
    e.preventDefault();
    const { showModal } = this.props;
    showModal({ id: constants.modals.login });
  }

  onRegisterCompleteClose(e) {
    alert('here');
    this.props.history.push('/');
  }
  onTestClick(e) {
    this.props.showModal({
      id: constants.modals.registerComplete
    })
  }

  render() {
    const loggedIn = this.props.auth && this.props.auth.token;
    const freeEnabled = loggedIn && this.props.user && this.props.user.freeEnabled;
    
    return (
      <section className="full-wd-page page--free">
        <Helmet>
          <title>{metaData.title}</title>
          <meta name="description" content={metaData.description} />
          <meta name="keywords" content={metaData.keywords} />

          <meta itemProp="name" content={metaData.name} />
          <meta itemProp="description" content={metaData.description} />
          <meta itemProp="image" content={metaData.image} />

          <meta name="twitter:card" content={metaData.TwitterCard} />
          <meta name="twitter:site" content={metaData.TwitterSite} />
          <meta name="twitter:title" content={metaData.title} />
          <meta name="twitter:description" content={metaData.description} />
          <meta name="twitter:creator" content={metaData.TwitterSite} />
          <meta name="twitter:image" content={metaData.image} />

          <meta property="og:url" content={metaData.url} />
          <meta property="og:type" content={metaData.ogType} />
          <meta property="og:title" content={metaData.title} />
          <meta property="og:description" content={metaData.description} />
          <meta property="og:image" content={metaData.image} />
        </Helmet>

        {/* <section className="hero-banner hero-banner--free" style={{ backgroundImage: `url(${header})` }}> */}
        <section className="hero-banner hero-banner--free">
          <div className="hero-banner__body hero-banner__body--free">
            <img src={sideHeader} alt="" />
            {/* <h1 className="hero-banner__heading">Sign up for The Free Stuff</h1>
            <div className="hero-banner__subheading">Choose 25 free downloads from a collection of thousands of diverse and released photos of&nbsp;people.</div> */}
          </div>
          <div className="hero-banner__form-wrapper">
            <div className="hero-banner__form">
              { !loggedIn && <React.Fragment>
                  <auth.components.RegisterForm {...this.props} onSuccess={this.onLoggedIn} onLoggingIn={this.onLoggingIn}
                     heading={<React.Fragment>Sign up for <br/> The Free Stuff</React.Fragment>}
                     introText={<p className="text-center">Choose 25 free downloads from a collection of thousands&nbsp;of&nbsp;diverse and released photos of&nbsp;people.</p>}
                     newsletterCheckboxText="" 
                     newsletterCheckboxDisclaimerText="" 
                     submitLabel="Get The Stuff"
                     showNewsletterCheckbox={false}
                     newsletterCheckboxChecked={true} />
                  <modals.RegisterComplete {...this.props} id={constants.modals.registerComplete} onClose={this.onRegisterCompleteClose} />
                </React.Fragment> }
              { loggedIn && freeEnabled &&
                  <div className="text-center">
                    <h2><strong>Congratulations!</strong></h2>
                    <p>You already have access to 25 free images.</p>
                    <p>Your credits will be topped up at the end of the month.</p>
                  </div> }
              { loggedIn && !freeEnabled &&
                  <div className="text-center"> 
                    <h2><strong>Download free images today!</strong></h2>
                    <p>Update your <Link to={'/user/account-info'}>profile</Link> and receive 25 download credits each month towards our free catalog of&nbsp;images.</p>
                  </div> }
            </div>
          </div>
        </section>

        {/* <section>
          <div className="max-large-width spacer-padding-top-70 spacer-padding-bottom-70">
            <div className="cp-strip cp-strip--alt">
              <div className="cp-strip__image-wrap cp-strip__image-wrap--50">
                <div className="cp-strip__figure">
                  <img loading="lazy" src={img01} alt="" className="cp-strip__image" width="500" height="333" />
                </div>
              </div>
              <div className="cp-strip__copy cp-strip__copy--50">
                <h2>25 Free Images.</h2>
                <p className="text-lead">As soon as you sign up, 25 credits will be added to your account. Next month, we’ll top that back up and you can download 25&nbsp;more.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg--mint">
          <div className="max-large-width spacer-padding-top-70 spacer-padding-bottom-70">
            <div className="cp-strip">
              <div className="cp-strip__image-wrap cp-strip__image-wrap--50">
                <div className="cp-strip__figure">
                  <img loading="lazy" src={img02} alt="" className="cp-strip__image" width="500" height="333" />
                </div>
              </div>
              <div className="cp-strip__copy cp-strip__copy--50 cp-strip__copy--pad-normal">
                <h2>Hello. We’re Hero.</h2>
                <p className="text-lead">Hero Images is a small team of photographers and art directors known for making some of the world’s top-performing commercial&nbsp;images.</p>
              </div>
            </div>
          </div>
        </section> */}

        <section>
          <div className="max-large-width spacer-padding-top-40 spacer-padding-bottom-40">
            <div className="row">
              <div className="col col-large-4">
                <div className="card card--free">
                  <img src={grid01} alt="A female doctor reviewing a patient's chart" className="card__img" />
                  <h3 className="card__title card__title--free">Free Images</h3>
                  <div className="card__content">
                  <p className="text-lead">As soon as you sign up, 25 credits will be added to your account. Next month, we’ll top those images back up and you can download 25&nbsp;more.</p>
                  </div>
                </div>
              </div>
              <div className="col col-large-4">
                <div className="card card--free">
                  <img src={grid02} alt="" className="card__img" />
                  <h3 className="card__title card__title--free">We're Hero</h3>
                  <div className="card__content">
                  <p className="text-lead">Hero Images is an experienced team of photographers and directors known for making some of the world’s top-performing commercial&nbsp;images.</p>
                  </div>
                </div>
              </div>
              <div className="col col-large-4">
                <div className="card card--free">
                  <img src={grid03} alt="A female doctor reviewing a patient's chart" className="card__img" />
                  <h3 className="card__title card__title--free">Full-service</h3>
                  <div className="card__content">
                    <p className="text-lead">As a full-service production house, we specialize in best-in-class visual solutions—custom shoots, premium stock, and our free subscription.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    )
  }
}

PageHeroFree.propTypes = {
  auth: model.Shape,
  isAuthenticated: PropTypes.bool,
  invitationToken: PropTypes.string,
  passwordToken: PropTypes.string,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
}

const stateToProps = (state) => ({
  auth: selectors.getAuth(state),
  storedOperation: selectors.getOperation(state),
  isAuthenticated: selectors.getUserAuthToken(state) !== null,
  user: selectors.getAuth(state) ? selectors.getAuth(state).user : {}
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    ...actions,
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(withRouter(PageHeroFree))