import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import core from '../../../_core'

class RegisterComplete extends React.Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return (
      <form className="form__login form__small">
        <h2>Thank You!</h2>
        <h3>We have received your request to receive our newsletter.</h3>
        
        <div className="text-center">
          <p>To ensure you receive the latest news, updates, special offers and other cool stuff from Hero Images, please check your mailbox for our welcome&nbsp;email.</p>
          <p>If you didn't receive it, check your <strong>Spam or Updates</strong> folder/tab and add <strong><a href="mailto:hello@heroimages.com">hello@heroimages.com</a></strong> to your list of trusted email addresses.</p>
          <hr />
          <p>You will also receive 25 free credits each month towards our vast collection of free images.</p>
        </div>
        
      </form>
    )
  }

}

RegisterComplete.propTypes = {
  registerComplete: PropTypes.func,
}

export default core.components.modals.Modal(RegisterComplete)