/**
* @module board/pages
* Boards.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Mon Jun 11 2018 09:56:36 GMT-0600 (MDT)
* @copyright None
* @last-modified Thu Jun 21 2018 14:43:05 GMT-0600 (MDT)
*/

import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import core from '../../_core'
import auth from '../../auth'
import filter from '../../filter'

import * as actions from '../actions'
import components from '../components'
import * as selectors from '../selectors'
import {Shape} from '../model'

const Boards = ({data, history, loadFromServer, changePagination, auth, boards}) => {


  const goPage = (offset, limit) => {
    loadFromServer(auth, {
      offset,
      limit
    })
  }

  const metaData = {
    description : 'Boards | Hero Images',
    keywords : 'Hero Images, Boards, Stock Photography',
    title: 'Boards | Hero Images',
    name : 'Hero Images',
    url : window && window.location && `${window.location.protocol}//${window.location.host}${history.location.pathname}`,
    TwitterSite : '@heroimages',
    TwitterCard : 'summary',
    ogType : 'article'
  }

  return (
    <section className="full-wd-page photoshoots">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description"           content={metaData.description} />
        <meta name="keywords"              content={metaData.keywords} />

        <meta itemProp="name"              content={metaData.name}/>
        <meta itemProp="description"       content={metaData.description} />

        <meta name="twitter:card"          content={metaData.TwitterCard} />
        <meta name="twitter:site"          content={metaData.TwitterSite} />
        <meta name="twitter:title"         content={metaData.title} />
        <meta name="twitter:description"   content={metaData.description} />
        <meta name="twitter:creator"       content={metaData.TwitterSite} />
        <meta name="twitter:image"         content={metaData.image}/>

        <meta property="og:url"            content={metaData.url} />
        <meta property="og:type"           content={metaData.ogType} />
        <meta property="og:title"          content={metaData.title} />
        <meta property="og:description"    content={metaData.description} />
      </Helmet>
      <section>
        <h1 className="spacer-padding-top-20" >Your Boards</h1>
      </section>
      {
        data && data.data && data.info &&
        (
          <section className="spacer-padding-bottom-80">
            <components.containers.List data={data.data} />
            <filter.components.Pagination data={data.data} info={data.info} goPage={goPage} changePagination={changePagination}/>
          </section>
        )
      }
    </section>
  )
}

Boards.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(Shape),
    core.model.ErrorShape,
    core.model.LoadingShape
  ])
}

const stateToProps = (state) => ({
  auth: auth.selectors.getUserAuthToken(state),
  boards: selectors.getBoards(state),
  data: selectors.getBoards(state),
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    ...actions,
    changePagination: ({offset, limit} = {}) => filter.actions.changePagination({offset, limit}),
    loadFromServer: (auth, {offset, limit} = {}) => actions.list({offset, limit, token: auth} )
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(
  withRouter(
    core.components.withLoading(
      core.components.withError(
        core.components.withNotFound(Boards)
      ), ['auth', 'isTailored']
    )
  )
)