/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:48:50
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-07-04 11:22:19
 */

import auth from '../auth'
import core from '../_core'
// import filter from '../filter'
import flash from '../flash'

import * as actionTypes from './actionTypes'
import {moduleName, modals} from './constants'
import * as errorTypes from './errorTypes'
import Service from './service';

export const listPrices = ({token} = {}, service = new Service()) => (dispatch) => {
  dispatch(core.actions.onLoading(moduleName))
  return service.listPrices(token)
    .then(
      //(response) => dispatch(onListSuccess(response.data.data))
      (response) => {
        let data = response.data.data
        return {data}

      }
      ,
      (error) => {
        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.listPrices)));
        dispatch(core.actions.onError(moduleName, error));
      }
    ).then(


      ({data}) => {
        dispatch(onListPricesSuccess(data))
      }
    )
}

export const onListPricesSuccess = (data) => {
  const prices = data
  const info = data.info || {}

  return {
    type: actionTypes.listPrices,
    prices,
    info
  }
}

export const getCoupon = (code, orderType, auth, service = new Service()) => (dispatch) => {
  return service.getCoupon(code, orderType, auth)
    .then(
      (response) => {
        if (response.status == 204) {
          dispatch(flash.actions.show(core.util.errorHandler({response}, errorTypes.getCoupon)));
          dispatch(removeCoupon())
        }
        else {
          dispatch(onGetCouponSuccess(response.data.data))
        }
      },
      (error) => {
        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.listPrices)));
        dispatch(core.actions.onError(moduleName, error));
      }
    )
}

export const onGetCouponSuccess = (coupon) => {
  return {
    type: actionTypes.getCoupon,
    coupon
  }
}

export const removeCoupon = () => {
  return {
    type: actionTypes.removeCoupon
  }
}

export const removePersistentCoupon = () => {
  return {
    type: actionTypes.removePersistentCoupon
  }
}

export const getPersistentCoupon = (orderType, auth, service = new Service()) => (dispatch) => {
  return service.getPersistentCoupon(orderType, auth, true)
    .then(
      (response) => {
        if (response.status == 204) {
          //dispatch(flash.actions.show(core.util.errorHandler({response}, errorTypes.getCoupon)));
          dispatch(removePersistentCoupon())
        }
        else {
          dispatch(onGetCouponSuccess(response.data.data))
        }
      },
      (error) => {
        dispatch(core.actions.onError(moduleName, error));
      }
    )
}





export const getUserProductOwnership = (productIds, authToken, service = new Service()) => (dispatch) => {
  return service.getUserProductOwnership(productIds, authToken)
    .then(
      (response) => {
        return dispatch({
          type: actionTypes.getUserProductOwnership,
          userProductOwnership: response.data.data
        })
      },
      (error) => {
        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.listPrices)));
        dispatch(core.actions.onError(moduleName, error));
      }
    )
}

export const checkout = (newOrder, authToken, onSuccess, onError, service = new Service()) => (dispatch) => {
  if (!authToken) {
    return dispatch(core.actions.showModal({id: auth.constants.modals.login}));
  }
  dispatch(core.actions.onLoading(moduleName))
  dispatch(checkoutStarted())

  return service.createOrder(newOrder, authToken)
    .then(
      (response) => {
        let data = response.data
        return {data}
      }
      ,
      (error) => {
        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.listPrices)));
        dispatch(core.actions.onError(moduleName, error));
      }
    ).then(
      ({data}) => {
        let checkoutStatus = data.data;

        if(checkoutStatus.status == "success")
        {
          onSuccess(checkoutStatus);
        }
        else if(checkoutStatus.status == "failed")
        {
          onError(checkoutStatus);
        }

        dispatch(checkoutComplete(checkoutStatus))
      }
    )
}

export const checkoutStarted = (data) => {
  return {
    type: actionTypes.checkoutStarted,
  }
}

export const checkoutComplete = (checkoutResponse) => {
  return {
    type: actionTypes.checkoutComplete,
    checkoutResponse,
  }
}

export const addToCart = (product, price) => (dispatch) => {

  const cartItem = {
    product,
    priceId: price.id,
    specs: price.name + " @ " + (price.productType === 'video' ? 'highest res available' : price.specs.split(" by ")[0])
  };

  dispatch(addObjToCart(cartItem));
  setTimeout(() => {dispatch(removeJustAdded())}, 500);
}

export const addToDownloadCart = (products, price) => (dispatch) => {
  const items = products.map(product => {
    return {
      priceId: price.id,
      pricePaid: 1,
      specs: (product.type === 'video' && price.bundleClass === 'Print + Video' ? 'Video @ highest res available' : price.name + " @ " + price.specs.split(" by ")[0]),
      fileName: product.fileName,
      productId: product.id
    }
  })

  dispatch({
    type: actionTypes.addToDownloadCart,
    items,
    price
  });
  dispatch(core.actions.showModal({id: modals.downloadCart}))
}

export const addBundleToCart = (bundle, onSuccess, isInvoice) => (dispatch) => {
  const cartItem = {
    priceId: bundle.priceId,
    bundleId: bundle.id,
    specs: bundle.specs
  };

  dispatch(addBundleObjToCart(cartItem));
  onSuccess(isInvoice);
}

export const addBundleObjToCart = (cartItem) => {
  return {
    type: actionTypes.addBundleToCart,
    cartItem
  }
}

export const addObjToCart = (cartIem) => {
  return {
    type: actionTypes.addToCart,
    cartItem: cartIem
  }
}

export const removeFromCart = (cartIem) => {
  return {
    type: actionTypes.removeFromCart,
    cartItem: cartIem
  }
}

export const clearCart = () => {
  return {
    type: actionTypes.clearCart,
  }
}

export const clearCheckout = () => {
  return {
    type: actionTypes.clearCheckout,
  }
}


export const removeJustAdded = () => {
  return {
    type: actionTypes.removeJustAdded
  }
}

export const showAddBundleToCartModal = () => (dispatch) => {
  dispatch(core.actions.showModal({id: modals.addBundleToCart}))
}

export const showAddMultipleToCartModal = (products) => (dispatch) => {
  console.log("showAddMultipleToCartModal", products)
  dispatch(addMultipleToCart(products));
  dispatch(core.actions.showModal({id: modals.addMultipleToCart}))
}

export const addMultipleToCart = (products) => {
  console.log("addMultipleToCart", products)
  return {
    type: actionTypes.addMultipleToCart,
    products
  }
}


export const addGalleryToCart = (bundle, gallery, onSuccess, isInvoice) => (dispatch) => {
  const cartItem = {
    priceId: bundle.priceId,
    bundleId: bundle.id,
    gallery: gallery
  };

  dispatch(addGalleryObjToCart(cartItem));
  onSuccess(isInvoice);
}

export const addGalleryObjToCart = (cartItem) => {
  return {
    type: actionTypes.addGalleryToCart,
    cartItem
  }
}
