/**
* @module search
* actionTypes.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Thu May 17 2018 13:42:58 GMT-0600 (MDT)
* @copyright None
* @last-modified Thu May 17 2018 14:27:15 GMT-0600 (MDT)
*/

import { moduleName } from './constants'

const actionTypes = [
  'search',
  'resetSearch',
  'searchPhotoshoots',
]

export default actionTypes.reduce(
  (exportObject, type) => {
    exportObject[type] = `${moduleName}.${type}`
    return exportObject
  },
  {}
)