/**
 * src/modules/auth/routes.js
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import { moduleName } from './constants'
import components from './components'
import NotFoundPage from '../../modules/pages/components/notFoundPage'


const routes = {
  admin: [],
  user: [
    {
      path: `/auth/resetPassword/:passwordToken`,
      component: components.ResetPasswordPage,
      isExact: true
    }
  ]
}

const Routes = ({ type }) => (
  <Switch>
    {
      routes[type].map((route, i) => (
        <Route {...route} key={`${moduleName}-${i}`} />
      ))
    }
    <Route component={NotFoundPage} />
  </Switch>
)

Routes.propTypes = {
  type: PropTypes.string.isRequired
}

export default Routes