/**
* @module gallery
* reducer.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue May 29 2018 10:38:15 GMT-0600 (MDT)
* @copyright None
* @last-modified Tue Jun 19 2018 16:46:57 GMT-0600 (MDT)
*/


import core from '../_core'

import actionTypes from './actionTypes'
import { moduleName } from './constants'
import { generate } from './model'

export const reducer = (state = {data: undefined, info: undefined, isTailored: false}, action) => {
  const loadingAction = core.actionTypes.loading(moduleName);
  const errorAction = core.actionTypes.error(moduleName);

  switch (action.type) {
  case actionTypes.setTailoredView:
    return Object.assign({}, state, {isTailored: action.isTailored})
  case actionTypes.list:
    return {
      data: action.galleries,
      info: action.info,
      isTailored: state.isTailored
    }
  case loadingAction:
    return Object.assign({}, state, core.model.generateLoading())
  case errorAction:
    return Object.assign({}, state, action.state)
  default:
    return state
  }
}

export const contextReducer = (state = null, action) => {
  const loadingAction = core.actionTypes.loading(`${moduleName}Context`);
  const errorAction = core.actionTypes.error(`${moduleName}Context`);

  switch (action.type) {
  case actionTypes.get:
    return {
      data: action.gallery,
      info: action.info
    }
  case actionTypes.addProduct:
  case actionTypes.removeProduct:
    return state && state.data.id == action.gallery.id ? {
      data: action.gallery,
      info: action.info
    } : state
  case loadingAction:
    return core.model.generateLoading()
  case errorAction:
    return action.state
  default:
    return state
  }
}
