import React from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {withContentRect} from 'react-measure';
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import core from '../../../_core'
import auth from '../../../auth'
import Thumb from '../../../product/components/Thumb'
import RelatedGalleries from '../RelatedGalleries'

import { Shape } from '../../model'
import { GalleriesIcon_Share } from '../../img'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'

import * as cartActions from '../../../shoppingcart/actions'

const _targetFigureHeight = 300
const _justifiedMargin = 5

class Single extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      aspectRatios: [],
      isInvoice: false,
    };

    this.justifyLayout = this.justifyLayout.bind(this)
    this.onBuyButtonClick = this.onBuyButtonClick.bind(this)
    this.onAddGalleryToCartSuccess = this.onAddGalleryToCartSuccess.bind(this)
  }

  onAddGalleryToCartSuccess(isInvoice) {
    this.props.history.push(`/shoppingcart/checkout?gallery=true&invoice=${isInvoice}`, {from: this.props.location.pathname})
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.setState({layout: undefined})
    }
    if (this.props.data && this.props.data.products.length > 0 && this.props.data.products.length === this.state.aspectRatios.filter(e => e).length && this.props.contentRect.client.width &&
        (this.state.aspectRatios !== prevState.aspectRatios || this.props.contentRect.client.width !== prevProps.contentRect.client.width)
    ) {
      this.justifyLayout()
    }
  }

  onImageLoad({target:img}, index) {
    const ratios = [...this.state.aspectRatios]
    const ratio = img.naturalHeight && img.naturalWidth ? img.naturalWidth / img.naturalHeight : 1
    ratios[index] = ratio
    this.setState({aspectRatios: ratios})
  }

  justifyLayout() {
    const targetRowRatio = this.props.contentRect.client.width / _targetFigureHeight
    const marginRatio = _justifiedMargin*2 / _targetFigureHeight
    let rowSize = 0
    let rowRatio = 0
    let layout = []
    var index;
    for (index = 0; index < this.state.aspectRatios.length; index++) {
      const cur = this.state.aspectRatios[index]
      const next = index + 1 < this.state.aspectRatios.length ? this.state.aspectRatios[index + 1] : 0
      rowSize++
      rowRatio += cur
      if (rowRatio + next / 2 + rowSize * marginRatio >= targetRowRatio) {
        layout.push({rowSize, rowHeight: (this.props.contentRect.client.width - rowSize * _justifiedMargin*2) / rowRatio})
        rowSize = 0
        rowRatio = 0
      }
    }
    if (rowRatio > 0) {
      layout.push({rowSize, rowHeight: Math.min(this.props.contentRect.client.width / rowRatio, layout.length > 0 ? Math.max(...layout.map(l => l.rowHeight)) : _targetFigureHeight)})
    }

    this.setState({ layout })
  }
  
  onBuyButtonClick(e) {
    const galleryObj = this.props.data;

    let bundleObj = null;
    for (let i=0; i < this.props.prices.length; i++) {
      const priceObj = this.props.prices[i];
      if (priceObj && priceObj.bundles) {
        bundleObj = priceObj.bundles.find(b => b.hiddenType === 'FEATURED_GALLERY');
        if (bundleObj) break;
      }
    }

    this.props.addGalleryToCart(bundleObj, galleryObj, this.onAddGalleryToCartSuccess, this.state.isInvoice);
  }

  render() {
    const { data, onShare, showAddMultipleToCartModal, onRemoveGalleryProduct } = this.props;
    const products = data && Array.isArray(data.products) ? data.products : []
    let rowIndex = 0
    let colIndex = -1

    return (
      <section className="full-wd-page gallery__masonrygallery">
        <section className="flex justified spacer-padding-top-20 gallery__header gallery__header--featured">
          <Link to="/galleries" className="flex button link-icon back-link"><FontAwesomeIcon icon={faArrowLeft}/>&nbsp;Galleries</Link>
          <div className="title">
            <h1 style={{marginTop: 0}}>{data && (data.isFeatured ? 'Gallery of the Month' : data.title)}</h1>
            {/* { data.isFeatured &&
              <React.Fragment>
                <h4>
                  Each month, we’re curating a gallery of 25 images around a relevant theme.<br/>
                  <strong>Buy&nbsp;the gallery for&nbsp;$4999.</strong> That's 50% off the price of single&nbsp;images.
                </h4>
                <p><a className="button spacer-padding-right-100" onClick={() => this.onBuyButtonClick()}>Buy this Gallery</a></p>
              </React.Fragment> } */}
          </div>
          <div className="flex centered align-center spacer-padding-bottom-40 actions">
            
            <a onClick={onShare} className="button link-icon" style={{marginLeft: '2rem'}}>Share<img alt="gallery-icon" src={GalleriesIcon_Share}/></a>
          </div>
        </section>
        
        <section>
          <div ref={this.props.measureRef} style={{margin: -_justifiedMargin}}>
            { products.map((product, index) => {
              if (this.state.layout && colIndex + 1 < this.state.layout[rowIndex].rowSize) {
                colIndex++
              }
              else {
                colIndex = 0
                rowIndex++
              }
              const ratio = this.state.aspectRatios[index] || 1.5
              const height = this.state.layout ? this.state.layout[rowIndex].rowHeight : _targetFigureHeight
              return <Thumb
                key={index}
                onLoad={(e) => this.onImageLoad(e, index)}
                className='justified-figure'
                style={{
                  margin: _justifiedMargin,
                  width: ratio * height - 1, // -1 is to deal with zoom
                  maxHeight: this.state.layout ? 'none' : _targetFigureHeight // purely for fallback
                }}
                aspectRatio={ratio}
                data={product}
                hideCaption={true}
                hasMenu={true}
                routerPath={`/product/${product.id}`}
                showAddMultipleToCartModal={showAddMultipleToCartModal}
                onRemoveGalleryProduct={onRemoveGalleryProduct}
              />
            })}
          </div>
        </section>
        {
          data && data.relatedGalleries && data.relatedGalleries.length > 0 &&
          <section className="text-left flex start spacer-padding-top-40">
            <h3>Related Galleries</h3>
          </section>
        }

        <RelatedGalleries
          data={data && data}
          galleriesArr={data && data.relatedGalleries}
        />
      </section>
    )
  }
}

Single.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(Shape),
    core.model.ErrorShape,
    core.model.LoadingShape
  ]),
}

function stateToProps(state)
{
  return {
    prices: state.shoppingcart.prices,
    cart: state.shoppingcart.cart,
    user: state.user,
    product: state.product
  }
}

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    loadFromServer: (auth) => cartActions.listPrices({token: auth}),
    addGalleryToCart: (gallery, bundle, onSuccess, isInvoice) => cartActions.addGalleryToCart(gallery, bundle, onSuccess, isInvoice),
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(
  withContentRect('client')(Single)
)


//export default withContentRect('client')(Single)