/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-18 18:14:27 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-26 13:32:11
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import { moduleName } from './constants'
import pages from './pages'
import NotFoundPage from '../../modules/pages/components/notFoundPage'


const routes = {
  admin: [],
  user: [
    {
      path: '/shoppingcart/view',
      component: pages.ViewCart,
      isExact: true
    },
    {
      path: '/shoppingcart/checkout',
      component: pages.Checkout,
      isExact: true
    },    
    {
      path: '/shoppingcart',
      component: pages.ViewCart,
      isExact: true
    },
  ]
}

const Routes = ({ type }) => (
  <Switch>
    {
      routes[type].map((route, i) => (
        <Route {...route} key={`${moduleName}-${i}`} />
      ))
    }
    <Route component={NotFoundPage} />
  </Switch>
)

Routes.propTypes = {
  type: PropTypes.string.isRequired
}

export default Routes

