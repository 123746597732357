/**
* @module search/pages
* Search.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Thu May 17 2018 14:00:13 GMT-0600 (MDT)
* @copyright None
* @last-modified Wed Jul 11 2018 15:48:36 GMT-0600 (MDT)
*/

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGameConsoleHandheld, faSpinnerThird } from '@fortawesome/pro-light-svg-icons'

import core from '../../_core'
import filter from '../../filter'
import product from '../../product'
import shoppingcart from '../../shoppingcart'
import components from '../components'

import * as actions from '../actions'
import * as productActions from '../../product/actions'
import * as selectors from '../selectors'
import {pageSize} from '../constants'

import TailoredCalloutBG from '../img/tailoredCallOutBG.jpg'

class Search extends React.Component {

  constructor(props){
    super(props);
  
    this.updateFilter = this.updateFilter.bind(this);
    this.updateFilterAndReload = this.updateFilterAndReload.bind(this);
    
    
    props.setDefaults('relevance', this.props.defaultLimit || pageSize)
  }


  updateFilter(category, filter, flag, allowMultiple = true) {
    const payload = Object.assign({}, this.props.filters)

    if (!allowMultiple && flag) {
      const keys = Object.keys(payload[category])
      for (const key of keys) {
        payload[category][key] = false
      }
    }
    payload[category][filter] = flag
    this.props.changeFilters(payload)
    return payload
  }

  reload(payload) {
    // this is dead code???
    //console.log("TOBY RELOADDDDDING",payload)
    this.props.updateProducts(0, this.props.defaultLimit, payload, this.props.history, this.props.match)
    window.scrollTo(0, 0)
  }

  updateFilterAndReload(category, filter, flag, allowMultiple = true) {
    //console.log("Toby updateFilterAndReload", filter)

    const location = this.props.location.pathname.split('/')
    const oldType = this.props.match.params.type
    const oldCollectionType = this.props.match.params.collectionType
    if (category === 'type' && flag && (oldType === 'Video' || oldType === 'Image')) {
      location[location.length - 3] = filter // location of the type param
      this.props.history.push(location.join('/'))
    } else if (category === 'collectionType' && flag && (oldCollectionType === 'Premium' || oldCollectionType === 'Free' || oldCollectionType === 'All')) {
      location[location.length - 2] = filter // location of the type param
      this.props.history.push(location.join('/'))
    }
    else {
      this.props.updateProducts(0, this.props.defaultLimit, {
        filters: this.updateFilter(category, filter, flag, allowMultiple),
        sort: this.props.sort,
        terms: this.props.terms,
        modelIds: this.props.modelIds
      }, this.props.history, this.props.match)
    }
    window.scrollTo(0, 0)
  }

  resetSearchFromUrl(terms, pageNumber, useDefaultSort = false)
  {
    let modelIds = this.props.match.params.modelIds;
    let shootId = this.props.match.params.shootId;
    let type = this.props.match.params.type;
    let collectionType = this.props.match.params.collectionType;
    
    
    if (!(type === 'Image' || type === 'Video'))
      type = 'Image'
    
    if (!(collectionType === 'Premium' || collectionType === 'Free' || collectionType === 'All'))
      collectionType = 'All'
    
    if (!pageNumber) {
      pageNumber = 1
    }

    let collectionTypeSlug = 'All'
    if (this.props.filters.collectionType && this.props.filters.collectionType === 'Free')
      collectionTypeSlug = 'Free'
    else  if (this.props.filters.collectionType && this.props.filters.collectionType === 'Premium')
      collectionTypeSlug = 'Premium'
    
    let typeSlug = this.props.filters && this.props.filters.type && (this.props.filters.type.Video ? 'Video' : 'Image') || 'Image'

    const location = this.props.location.pathname.split('/')
    const oldType = this.props.match.params.type
    const oldCollectionType = this.props.match.params.collectionType
    if (!oldType && !oldCollectionType) {
      location.splice(location.length - 1, 0, typeSlug)
      location.splice(location.length - 1, 0, collectionTypeSlug)
      this.props.history.replace(location.join('/'))
    }
    else if (!oldType) {
      location.splice(location.length - 2, 0, typeSlug)
      this.props.history.replace(location.join('/'))
    }
    else if (!oldCollectionType) {
      location.splice(location.length - 1, 0, collectionTypeSlug)
      this.props.history.replace(location.join('/'))
    }
    else {
      this.props.resetStateAndSearch( pageNumber - 1, this.props.defaultLimit || pageSize, 'relevance', terms, modelIds, shootId, type, collectionType, this.props);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.shootId !== this.props.match.params.shootId || this.props.defaultLimit !== prevProps.defaultLimit) {
      this.props.setDefaults('relevance', this.props.defaultLimit || pageSize)
    }

    const curTerm = this.props.match.params.terms === '-' ? null : this.props.match.params.terms
    const oldType = this.props.filters.type && Object.keys(this.props.filters.type).find(type => this.props.filters.type[type])
    const oldCollectionType = this.props.filters.collectionType && Object.keys(this.props.filters.collectionType).find(collectionType => this.props.filters.collectionType[collectionType])
    if ((this.props.match.params.terms && curTerm !== this.props.terms)
      || (this.props.match.params.type && this.props.match.params.type !== prevProps.match.params.type && this.props.match.params.type !== oldType)
      || (this.props.match.params.collectionType && this.props.match.params.collectionType !== prevProps.match.params.collectionType && this.props.match.params.collectionType !== oldCollectionType)
      || (this.props.match.params.pageNumber && parseInt(this.props.match.params.pageNumber, 10) !== (Math.floor(this.props.pagination.offset / this.props.pagination.limit) + 1)) ) {
      //console.log("TOBY DID RECEIVE NEW PROPS", this.props.pagination, this.props.match.params.pageNumber, (Math.floor(this.props.pagination.offset / this.props.pagination.limit) + 1))
      this.resetSearchFromUrl(curTerm, this.props.match.params.pageNumber);
    }
  }

  componentDidMount() {
    console.log("TOBY DID MOUNT")
    const curTerm = this.props.match.params.terms === '-' ? null : this.props.match.params.terms
    this.resetSearchFromUrl(curTerm, this.props.match.params.pageNumber, true);
  }

  get paginationUrlPrefix() {
    let location = this.props.location.pathname.split('/');
    if (location[location.length - 1] === '')
      location.pop();
    if (!isNaN(parseInt(location[location.length - 1])))
      location.pop();
  
    return location.join('/');
  }
  
  render() {
    const props = this.props

    if (!props.data) {
      return (null)
    }

    // var searchTitle = "Search Results | Hero Images";
    // if (props.data.relatedPhotoShoots && props.data.relatedPhotoShoots.data && props.data.relatedPhotoShoots.data.photoshoots && props.data.relatedPhotoShoots.data.photoshoots.length == 1)
    //   searchTitle = props.data.relatedPhotoShoots.data.photoshoots[0].title + " | Hero Images";

    let type = this.props.match.params.type;
    if (!(type === 'Image' || type === 'Video'))
      type = 'Image'

    const isShoot = props.match.params.shootId;
    let numResults = props.data.products && props.data.products.info ? props.data.products.info.total : '';
    let term = props.terms || (isShoot && props.data && props.data.relatedPhotoShoots && props.data.relatedPhotoShoots.data.photoshoots && props.data.relatedPhotoShoots.data.photoshoots.length > 0 ? props.data.relatedPhotoShoots.data.photoshoots[0].title : '');
    let photoOrVideo = type === 'Image' ? "Photos" : 'Videos';
    let searchTitle = `${numResults} Best ${core.util.capitalizeFirstLetter(term)} ${photoOrVideo} | Explore More Stock Photos by Hero Images`;
    let metaDescription = `Use ${term.toLowerCase()} royalty-free stock ${photoOrVideo.toLowerCase()} to elevate your brand. Diverse, fully released, and high quality. Premium, Free and Custom. New shoots being loaded every month!`;

    return (
      <section className="photoshoot__wrapper">
        <Helmet>
          <title>{searchTitle}</title>
          <meta name="description" content={metaDescription} />

          {isShoot && <meta name="robots" content="noindex" />}

          <link href={`https://heroimages.com${this.paginationUrlPrefix}/1`} rel="canonical" />
        </Helmet>
        <div>
          <shoppingcart.components.containers.AddMultipleToCart />
          <components.ContainerFilterableResults paginationUrlPrefix={this.paginationUrlPrefix} {...props} rawData={props.data} data={props.data.products} updateFilterAndReload={this.updateFilterAndReload} updateFilter={this.updateFilter}/>
          {
            this.props.loading ?
              <h1 className="loadingIcon"><FontAwesomeIcon  icon={faSpinnerThird} spin /></h1> 
              :
              <div>
                <div className='tailored-callout' style={{background: `url(${TailoredCalloutBG}) center center / cover no-repeat `}}>
                  <h3>Not finding what you need?</h3>
                  <p>
                    We&#39;ve created a way to offer custom imagery for the price of stock. With shoots happening monthly you&#39;ll have plenty of options.<br />
                    Visit <a className='' href={core.constants.tailoredUrl} target='_blank' rel="noopener noreferrer">heroimages.com/tailored</a> to learn how you can influence a shoot!
                  </p>
                </div>
                {/* <components.ContainerResults {...props} data={props.data.relatedPhotoShoots} products={props.data.products} onPhotoshootClick={null} updateFilterAndReload={this.updateFilterAndReload} updateFilter={this.updateFilter}/> */}
              </div>
          }
        </div>
      </section>
    )
  }
}


const stateToProps = (state) => ({
  data: selectors.getSearch(state),
  terms: filter.selectors.getTerms(state),
  shootId: filter.selectors.getShootId(state),
  filters: filter.selectors.getFilters(state),
  pagination: filter.selectors.getPagination(state),
  sort: filter.selectors.getSort(state),
  modelIds: filter.selectors.getModelIds(state),
  loading: filter.selectors.getSearchLoading(state)
})

const dispatchToProps = (dispatch) => {
  return bindActionCreators({
    ...actions,
    changeTerms: filter.actions.changeTerms,
    changePhotoshoot: filter.actions.changePhotoshoot,
    changeFilters: filter.actions.changeFilters,
    resetFilters: filter.actions.resetFilters,
    changeSort: filter.actions.changeSort,
    changeModelIds: filter.actions.changeModelIds,
    setDefaults: filter.actions.setDefaults,
    updateProducts: (offset, limit, params, history, match) => {
      //console.log("Toby updateProducts", params, history, match)
      return actions.search({...params, offset, limit, history, match})
    },
    resetStateAndSearch: (offset, defaultLimit, defaultSort, terms, modelIds, shootId, type, collectionType, props) => {
      //console.log("Toby resetStateAndSearch", offset, defaultLimit, defaultSort, terms, modelIds, shootId, type, collectionType, props)
      return actions.resetStateAndSearch(offset, defaultLimit, defaultSort, terms, modelIds, shootId, type, collectionType, props)
    },
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal,
    showAddMultipleToCartModal: (products) => shoppingcart.actions.showAddMultipleToCartModal(products),
  }, dispatch)
}

export default withRouter(
  connect(stateToProps, dispatchToProps, (stateProps, dispatchProps, ownProps) => {
    return Object.assign(
      {},
      stateProps,
      dispatchProps,
      ownProps
      // {
      //   loadFromServer: (auth, incomingProps) => {
      //     console.log("Toby UPdating PRoducts", incomingProps)
      //     return dispatchProps.search({
      //       terms: ownProps.match.params.terms,
      //       modelIds: stateProps.modelIds,
      //       filters: stateProps.filters,
      //       sort: stateProps.sort,
      //       limit: stateProps.pagination.limit,
      //       offset: stateProps.pagination.offset
      //     })
      //   }
      // }
    )
  })(
    core.components.withError(
      Search
    )
  )
)