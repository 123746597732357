/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:48:35
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-05-09 11:53:42
 */

import * as constants from './constants'

export const listPrices = `${constants.moduleName}.listPrices`
export const getCoupon = `${constants.moduleName}.getCoupon`
export const removeCoupon = `${constants.moduleName}.removeCoupon`
export const getUserProductOwnership = `${constants.moduleName}.getUserProductOwnership`
export const addToCart = `${constants.moduleName}.addToCart`
export const removeFromCart = `${constants.moduleName}.removeFromCart`
export const clearCart = `${constants.moduleName}.clearCart`
export const removeJustAdded = `${constants.moduleName}.removeJustAdded`
export const checkoutComplete = `${constants.moduleName}.checkoutComplete`
export const checkoutStarted = `${constants.moduleName}.checkoutStarted`
export const clearCheckout = `${constants.moduleName}.clearCheckout`
export const addMultipleToCart = `${constants.moduleName}.addMultipleToCart`
export const addBundleToCart = `${constants.moduleName}.addBundleToCart`
export const addToDownloadCart = `${constants.moduleName}.addToDownloadCart`
export const addGalleryToCart = `${constants.moduleName}.addGalleryToCart`

export const removePersistentCoupon = `${constants.moduleName}.removePersistentCoupon`