/**
 * src/modules/_core/components/analytics/Analytics.js
 *
 * A base class that when instantiated issues a pageView to GA
 *
 * This class interfaces with the react-ga service to send
 * page view statistics to Google Analytics.
 * 
 */

import { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const Analytics = (props) => {
  // google tracking
  useEffect(() => {
    const page = props.location.pathname + props.location.search;
    ReactGA.set({ page });
    ReactGA.pageview(page);
  }, [props.location.pathname, props.location.search])

  // hubspot tracking
  useEffect(() => {
    if (window._hsq) {
      const page = props.location.pathname + props.location.search;
      window._hsq.push(['setPath', page]);
      window._hsq.push(['trackPageView']);
      console.log(`[hubspot-tracking] with path: ${page}`);
    }
  }, [props.location.pathname, props.location.search])

  // hubspot chat widget
  const [isReady, setReady] = useState(!!window.HubSpotConversations);
  
  window.hsConversationsOnReady = [
    () => {console.log('[hubspot-conversations] ready'); setReady(true)},
  ];
  useEffect(() => {
    if (!isReady) return;

    const page = props.location.pathname + props.location.search;
    const widget = window.HubSpotConversations.widget
    const status = widget.status();
    if (status && status.loaded) {
      widget.refresh();
    }
    else {
      widget.load();
    }
    console.log(`[hubspot-conversations] with path: ${page}`);
  }, [isReady, props.location.pathname, props.location.search])

  return ""
}

Analytics.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withRouter(Analytics)