/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 19:58:42
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-06-20 13:50:33
 */

import core from '../_core'

import actionTypes from './actionTypes'
import { moduleName } from './constants'
import { generate } from './model'

export const reducer = (state = generate(), action) => {
  const loadingAction = core.actionTypes.loading(moduleName);
  const errorAction = core.actionTypes.error(moduleName);

  switch (action.type) {
  case actionTypes.get:
    return  action.product
  case loadingAction:
    return core.model.generateLoading()
  case errorAction:
    return action.state
  default:
    return state
  }
}

export const selectionReducer = (state = null, action) => {
  switch (action.type) {
  case actionTypes.select:
    return action.product
  case actionTypes.unselect:
    return null
  default:
    return state
  }
}
