/**
 * src/modules/auth/constants.js
 */

export const moduleName = 'auth'

export const roles = {
  admin: 'admin',
  user: 'user'
}

export const modals = {
  login: 'login',
  forgetPassword: 'forgetPassword',
  register: 'register',
  resetPassword: 'resetPassword',
  getPasswordResetToken: 'getPasswordResetToken',
  verifyPasswordResetToken: 'verifyPasswordResetToken',
  registerComplete: 'registerComplete',
}