/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-18 18:09:43 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-22 13:10:15
 */

//import List from './List'
import Single from './Single'

export default {
  //List,
  Single
}