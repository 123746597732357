/**
 * src/modules/flash/components/containers/Flash.js
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Message from '../modals/Message'

import * as constants from '../../constants'
import * as actions from '../../actions'
import { getMessage } from '../../selectors'

const FlashContainer = ({message, onHide}) => {
  if (message) {
    if (message.shouldStay) {
      return <Message onClose={onHide} message={message.message} shouldStay={message.shouldStay} id={constants.moduleName} initialOpen={true}/>
    } else {
      return <Message onClose={onHide} message={message} shouldStay={false} id={constants.moduleName} initialOpen={true}/>
    }
  } else {
    return null
  }
}

FlashContainer.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  onHide: PropTypes.func
}

const stateToProps = (state) => ({
  message: getMessage(state)
})

const dispatchToProps = (dispatch) => ({
  onHide: () => {
    dispatch(actions.hide())
  }
})

export default connect(stateToProps, dispatchToProps)(FlashContainer)
