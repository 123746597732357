import React, { Component } from 'react';
import { withContentRect } from 'react-measure';
import styled from 'styled-components';

import ManagedOrientedImage from './managedOrientedImage';

const _targetHeight = 200
const _margin = 5
const Tile = styled.div`
  display: inline-block;
  position: relative;

  &.selectable {
    border: 2px solid transparent;
    cursor: pointer;

    &.selected {
      border-color: black;
    }
    & > * {
      pointer-events: none;
    }
  }
`

class JustifiedLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aspectRatios: [],
      layout: undefined,
      lastLayout: undefined,
      lastEntryWidth: undefined,
      loopTimeout: undefined
    };
    
    this.targetHeight = this.props.targetHeight || _targetHeight
    this.margin = this.props.justifiedMargin || _margin

    this.justifyLayout = this.justifyLayout.bind(this)
  }

  componentDidMount() {
    this.setState({
      aspectRatios: [],
      layout: undefined,
      lastLayout: undefined,
      lastEntryWidth: undefined,
      loopTimeout: undefined
    })
  }

  componentDidUpdate(prevProps, prevState) {
    this.targetHeight = this.props.targetHeight || _targetHeight
    this.margin = this.props.justifiedMargin || _margin
    

    if (this.props.tiles && this.props.tiles !== prevProps.tiles && this.state.aspectRatios.length) {
      var newAspectRatios = []
      // dragging just involves swapping images so we don't have to reload aspectRatios
      if (this.props.drag && this.props.drag.source > -1 && this.props.drag.destination !== prevProps.drag.destination) {
        newAspectRatios = [...this.state.aspectRatios]
        const current = prevProps.drag.destination > -1 ? prevProps.drag.destination : this.props.drag.source
        const destination = this.props.drag.destination > -1 ? this.props.drag.destination : this.props.drag.source
        if (current !== destination) {
          const moving = newAspectRatios.splice(current, 1)[0]
          if (destination === newAspectRatios.length) {
            newAspectRatios.push(moving)
          }
          else {
            newAspectRatios.splice(destination, 0, moving)
          }
        }
      }
      this.setState({
        aspectRatios: newAspectRatios,
        layout: undefined,
        lastLayout: undefined,
        lastEntryWidth: undefined,
        loopTimeout: undefined
      })
    }
    
    if (this.props.tiles && this.props.tiles.length > 0 && this.props.tiles.length === this.state.aspectRatios.filter(e => e).length && this.props.contentRect.client.width &&
        (this.state.aspectRatios !== prevState.aspectRatios || this.props.contentRect.client.width !== prevProps.contentRect.client.width)
    ) {
      this.justifyLayout()
    }
  }

  componentWillUnmount() {
    this.state.loopTimeout && window.clearTimeout(this.state.loopTimeout)
  }

  onImageLoad(aspectRatio, index) {
    if (this.state.aspectRatios[index] === aspectRatio) return

    const ratios = [...this.state.aspectRatios]
    ratios[index] = aspectRatio || 1
    this.setState({aspectRatios: ratios})
  }

  justifyLayout() {
    const containerWidth = this.props.contentRect.entry && this.props.contentRect.entry.width > -1 ? this.props.contentRect.entry.width : (this.props.contentRect.client.width - 1);
    let schroedingersScrollbarWith = 0
    if (this.state.loopTimeout && this.state.lastEntryWidth) {
      schroedingersScrollbarWith = Math.abs(containerWidth - this.state.lastEntryWidth)
    }
    const bulk = (this.margin + (this.props.imageContainerBulk || 0)) * 2
    const targetRowRatio = containerWidth / this.targetHeight
    let rowSize = 0
    let rowRatio = 0
    let layout = []
    var index;
    for (index = 0; index < this.state.aspectRatios.length; index++) {
      const cur = this.state.aspectRatios[index]
      rowSize++
      rowRatio += cur
      if (index + 1 < this.state.aspectRatios.length && rowRatio + this.state.aspectRatios[index + 1] / 2 + (rowSize * bulk + schroedingersScrollbarWith) / this.targetHeight >= targetRowRatio) {
        const rowHeight = (containerWidth - (rowSize * bulk + schroedingersScrollbarWith)) / rowRatio
        layout.push({rowSize, rowHeight: Math.floor(rowHeight) })
        rowSize = 0
        rowRatio = 0
      }
    }
    if (rowRatio > 0) {
      const maxRowHeight = layout.length > 0 ? Math.max(...layout.map(l => l.rowHeight)) : this.targetHeight * 1.5
      const rowHeight = Math.min(maxRowHeight, (containerWidth - (rowSize * bulk + schroedingersScrollbarWith)) / rowRatio)
      layout.push({rowSize, rowHeight: Math.floor(rowHeight) })
    }

    // resizing alters the container height, which can create a scrollbar, which alters container width, which alters container height, which can hide the scrollbar, which...
    // toggling the browser window imitates this extreme edge case so a timeout is necessary to distinguish from the non-looping scenario
    const isLoop = this.state.lastLayout && !layout.find((row, index) => row.rowSize !== this.state.lastLayout[index].rowSize || row.rowHeight !== this.state.lastLayout[index].rowHeight)

    this.setState({
      layout,
      lastLayout: this.state.layout,
      lastEntryWidth: isLoop ? containerWidth : undefined,
      loopTimeout: isLoop ? window.setTimeout(() => this.setState({lastLayout: undefined, loopCount: 0, loopTimeout: undefined}), 100) : undefined
    })
  }

  render() {
    const tiles = this.props.tiles || []
    const renderWithLayout = this.state.layout && tiles.length === this.state.aspectRatios.filter(e => e).length
    let rowIndex = 0
    let colIndex = -1

    return tiles.length > 0 ? <div ref={this.props.measureRef} className='justifiedLayout' style={{margin: -this.margin}, {textAlign: this.props.textAlign ?? 'left'}}>
      { tiles.map((tile, index) => {
        if (this.props.mapTile) {
          tile = this.props.mapTile(tile)
        }
        // iterate row-column index
        const renderTileWithLayout = renderWithLayout && this.state.layout[rowIndex]
        if (renderTileWithLayout && colIndex + 1 < this.state.layout[rowIndex].rowSize) {
          colIndex++
        }
        else {
          colIndex = 0
          rowIndex++
        }
        const key = this.props.tileKey ? this.props.tileKey(tile) : index
        // for caller
        const rowHeight = renderTileWithLayout ? this.state.layout[rowIndex].rowHeight : this.targetHeight;
        const width = renderTileWithLayout ? this.state.aspectRatios[index] * this.state.layout[rowIndex].rowHeight : 'auto'
        // the image
        var imgAttributes = {
          'data-index': index,
          'data-rowheight': rowHeight
        };
        var dragCover;
        if (this.props.drag) {
          imgAttributes.draggable = "true";
          imgAttributes.onDragStart = (e) => {
            e.dataTransfer.setData('text/plain', tile.thumbnailUrl);
            this.props.drag.onDragStart(e)
          };
          imgAttributes.onDragEnter = (e) => {this.setState({covered: undefined}); this.props.drag.onDragUpdate(e)};
          imgAttributes.onDragOver = (e) => e.preventDefault();
          imgAttributes.onDrop = this.props.drag.onDragEnd;
          imgAttributes.onDragEnd = this.props.drag.onDragEnd;
          if (this.props.drag.destination === index) {
            const prevRowHeight = renderTileWithLayout && rowIndex !== 0 ? this.state.layout[rowIndex - 1].rowHeight : this.targetHeight;
            const prevWidth = renderTileWithLayout && colIndex !== 0 ? this.state.aspectRatios[index - 1] * this.state.layout[rowIndex].rowHeight : width
            const nextRowHeight = renderTileWithLayout && rowIndex + 1 !== this.state.layout.length ? this.state.layout[rowIndex + 1].rowHeight : this.targetHeight;
            const nextWidth = renderTileWithLayout && colIndex + 1 !== this.state.layout[rowIndex].rowSize ? this.state.aspectRatios[index + 1] * this.state.layout[rowIndex].rowHeight : width
            dragCover = <div data-index={index}
              onDragEnter={(e) => this.setState({covered: true})}
              onDragOver={(e) => {e.preventDefault(); this.state.covered || this.setState({covered: true});}}
              onDrop={this.props.drag.onDragEnd}
              onDragLeave={(e) => this.state.covered && this.props.drag.onDragOutside()} // onDragOutside fires outside the justifiedLayout
              style={{
                zIndex: 1,
                position: 'absolute',
                borderRadius: '30%',
                left: renderTileWithLayout ? -prevWidth * .3 : '-30%',
                top: -prevRowHeight * .3,
                width: renderTileWithLayout ? (prevWidth * .3 + nextWidth * .3 + width) : '160%',
                height: prevRowHeight * .3 + nextRowHeight * .3 + rowHeight
              }} />
          }
        }
        const imgNode = <React.Fragment>
          <ManagedOrientedImage {...this.props.attributes} src={tile.thumbnailUrl} alt={tile.description}
            attributes={imgAttributes}
            knownAspectRatio={this.state.aspectRatios[index]}
            onLoad={(aspectRatio) => this.onImageLoad(aspectRatio, index)}
            height={rowHeight}
            width={width}
            rotation={tile.rotation} />
          {dragCover}
        </React.Fragment>

        return <Tile key={key}
          title={this.props.tileSelection && this.props.selectionMode !== 'Default' ? `${this.props.tileSelection.selectedRowKeys.indexOf(key) > -1 ? 'deselect' : 'select'}${this.props.tileSelection.columnTitle ? ` for ${this.props.tileSelection.columnTitle.toLowerCase()}` : ''}` : ''}
          className={this.props.tileSelection && this.props.selectionMode !== 'Default' ? `selectable ${this.props.tileSelection.selectedRowKeys.indexOf(key) > -1 ? 'selected' : ''}` : ''}
          onClick={this.props.tileSelection && this.props.selectionMode !== 'Default' ? () => this.props.tileSelection.onSelect(tile, this.props.tileSelection.selectedRowKeys.indexOf(key) === -1) : undefined}
          style={{
            ...this.props.style,
            margin: this.margin - (this.props.tileSelection && this.props.selectionMode !== 'Default' ? 2 : 0),
            borderWidth: this.props.tileSelection && this.props.selectionMode !== 'Default' ? 2 : 0,
            padding: 0,
            maxWidth: `calc(100% - 2 * ${this.margin}px)`
          }}>
          {this.props.renderImageContainer ? this.props.renderImageContainer(imgNode, tile, rowHeight, width, index) : imgNode}
        </Tile>
      })}
    </div> : <div style={{padding: 6, textAlign: 'center', color: 'rgba(0, 0, 0, 0.45)'}}>No data</div>
  }
}

export default withContentRect('client')(JustifiedLayout)
