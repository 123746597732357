/**
* @module board
* service.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Mon Jun 11 2018 09:56:36 GMT-0600 (MDT)
* @copyright None
* @last-modified Thu Jun 21 2018 10:28:39 GMT-0600 (MDT)
*/

import core from '../_core'

import { pageSize } from './constants'

class Service extends core.Service {
  list({offset = 0, limit = pageSize, token} = {}) {
    return super.get('/board', {
      pageSize: limit,
      offset: offset
    }, token)
  }
  get(id) {
    return super.get(`/board/${id}`)
  }

  create({title, token} = {}) {
    return super.post('/board', {title}, token)
  }

  update({id, title, token} = {}) {
    return super.put(`/board/${id}`, {title}, token)
  }

  delete({id, token} = {}) {
    return super.delete(`/board/${id}`, token)
  }

  addProduct(id, {productId, token} = {}) {
    return super.post(`/board/${id}/product/${productId}`, null, token)
  }

  removeProduct(id, {productId, token} = {}) {
    return super.delete(`/board/${id}/product/${productId}`, token)
  }
}

export default Service