/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-16 14:26:33 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-16 14:27:00
 */


import { moduleName } from './constants'

/**
 * Given a state, return user data
 * @param {object} state
 */
export const getUser = (state) => (state[moduleName])