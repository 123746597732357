/**
 * src/modules/auth/components/modals/ResetPassword.js
 */

import React from 'react'
import PropTypes from 'prop-types'

import core from '../../../_core'
import { passwordTokenError } from '../../errorTypes'
import {moduleName} from '../../constants'

class ResetPassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      passwordToken: this.props.match.params.passwordToken,
      password: '',
      confirmPassword: ''
    }


    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.validate = this.validate.bind(this)
    this.togglePassword = this.togglePassword.bind(this)
  }

  componentDidMount() {
    this.props.verifyPassword(this.state.passwordToken)
      .then(
        (response) => {this.setState({verified: true, user: response.data.data})},
        (error) => {
          this.setState({error: core.util.errorHandler(error, passwordTokenError)});
          //this.props.onClose()
          //this.props.onError(moduleName, core.util.errorHandler(error, passwordTokenError))
        }
      )
  }

  onSubmit(e) {
    e.preventDefault()

    const form = e.target

    if (this.validate()) {
      return this.props.resetPassword(this.state)
        .then(
          () => {this.setState({done: true})},
          (error) => {
            this.setState({error: core.util.errorHandler(error, passwordTokenError)});
            // this.props.onClose()
            // this.props.onError(moduleName, core.util.errorHandler(error, passwordTokenError))
          }
        )
    }
  }

  onChange(e) {
    const newState = {}

    newState[e.target.name] = e.target.value
    this.setState(newState)
    //this.validate(e.target)
  }

  validate() {
    return (this.state.password && this.state.password.length >= 6) && (this.state.password === this.state.confirmPassword);
    // if(this.formField)
    // {
    //   const validator = formField.dataset['validator']

    //   // If there's no validator configured, make it valid
    //   formField.dataset['valid'] = validator ? core.util.validate(validator, formField.value) : true
    // }
  }

  togglePassword(e) {
    e.preventDefault()

    const passwordInput = document.querySelector(`[name="${e.target.dataset.toggleInput}"]`)

    if (passwordInput) {
      const isHidden = passwordInput.type === 'password'
      passwordInput.type = isHidden ? 'text' : 'password'
      e.target.innerHTML = isHidden ? 'Hide' : 'Show'
    }
  }

  render() {
    if (this.state.verified) {
      if (this.state.done) {
        return (
          <section className="text-center">
            <h1>Thank you</h1>
            <div>Please log-in with the new password</div>
            <div className="spacer-padding-top-40">
              <button onClick={this.props.onLoggingIn}>Log-in</button>
            </div>

          </section>
        )
      } else {
        return (
          <form className="form__small" onSubmit={this.onSubmit}>
            <h1>Hello {this.state.user.firstName}</h1>

            <fieldset>
              <label>
                <div>New Password</div>
                <input type="password" name="password" placeholder="password" value={this.state.password} onChange={this.onChange} maxLength="72" required />
                <a onClick={this.togglePassword} data-toggle-input="password" href="#">Show</a>
              </label>
              <label>
                <div>Confirm Password</div>
                <input type="password" name="confirmPassword" placeholder="confirm" value={this.state.confirmPassword} onChange={this.onChange} maxLength="72" required />
                <a onClick={this.togglePassword} data-toggle-input="confirmPassword" href="#">Show</a>
              </label>
            </fieldset>


            <div>
              <button type="submit" disabled={!this.validate()}>Update</button>
            </div>
          </form>
        )
      }
    } else {
      if (this.state.error) {
        return ( <section>
          <h3>{this.state.error}</h3>
        </section>)
      } else {
        return (
          <section>
            <h1>Verifying...</h1>
          </section>
        )
      }
    }
  }
}

ResetPassword.propTypes = {
  //passwordToken: PropTypes.string.isRequired,
  //service: PropTypes.object.isRequired,
  onLoggingIn: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default core.components.modals.Modal(ResetPassword)