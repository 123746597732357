import React from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/pro-light-svg-icons'

import {Icon_ImageBundle_wt} from '../img/';
import {ProductIcon_DownloadComp} from '../../product/img/';

const getIconFragment = (props) => {
  if (props.iconFragment)
    return props.iconFragment
  if (props.isAdvert)
    return <img alt="bundle-credit-icon" src={Icon_ImageBundle_wt} className='isAdvert bundle_credit_icon' />
  else if (props.isOwned)
    return props.isDownloading && props.isDownloading.length ?
      <FontAwesomeIcon className="isOwned icon fa-spin" icon={faSync} />
      : <a href="#" className="link-icon" onClick={props.onDownloadClick}> <img alt="download-icon" src={ProductIcon_DownloadComp} className='isOwned' data-price-id={props.id} /></a>
  else if (props.handleRadioChange)
    return <React.Fragment>
      <input
        type="radio" 
        name='priceOption' 
        value={props.id}
        checked={props.selectedOption == props.id}
        // checked={this.state.selectedOption === 'priceOption2'} 
        onChange={props.handleRadioChange}
      />
      <span className="checkmark"/>
    </React.Fragment>
}
const getDescriptionFragment = (props) => {
  const remaining = props.credits && props.credits.length ? props.credits.reduce((count, credit) => count + credit.remaining, 0) : 0

  if (props.isAdvert || props.isOwned)
    return props.description;
  else if (props.totalPrice)
    return <React.Fragment>
      <span className={"enMoney " + props.currency || 'USD'}>{props.totalPrice}</span>
      { props.savings && !props.shortPricing ? <span className="productBundleSavings">Save ${props.savings}</span> : ''}
    </React.Fragment>
  else if (props.credits && props.credits.length) {
    return (props.isFree 
          ? <React.Fragment>{props.description} <br/> <strong>{moment().format('MMMM').toUpperCase()} CREDITS</strong> {remaining} remaining</React.Fragment>
          : <React.Fragment><b>PREPAID</b> {remaining} remaining</React.Fragment> )
  }
  else
    return props.description
}

const getPriceFragment = (props) => {
  if (props.isAdvert || props.isOwned)
    return props.priceFragment
  else if (props.totalPrice)
    return <p><span className={"enMoney " + props.currency || 'USD'}>{props.bundledPrice}</span><span className="unit">per download</span></p>
  else if (props.credits && props.credits.length)
    return <p>1 <img src={Icon_ImageBundle_wt} className='bundle_credit_icon' /></p>
  else
    return <p><span className={"enMoney " + props.currency || 'USD'}>{props.price}</span></p>
}

const PriceOption = (props) => {
  const labelStyle = {
    ...((props.isAdvert || props.isOwned) && {cursor:'auto'}),
    ...(props.isAdvert && props.noIcon && {paddingLeft: 0})
  }
  return (
    <label className="flex justified" style={labelStyle}>
      {!props.noIcon && getIconFragment(props)}
      <div className='productDetails__priceDescription'>
          { !props.shortPricing && <React.Fragment>
          <h3 className='text-left'>{props.credits && props.credits.length ? props.name : props.name}</h3>
          <p>{props.credits && props.credits.length && (props.bundleClass === 'Print + Video' || props.bundleClass === 'Comp') ? 'highest res available' : props.specs }</p>
          </React.Fragment> }
          { props.shortPricing && <React.Fragment>
            {props.quantity && <p className="productDetails_qty">{props.quantity} pack</p>}
          </React.Fragment> }
          <p className="productDetails_description">{getDescriptionFragment(props)}</p>
          
      </div>
      <div className="productDetails__price text-right">{getPriceFragment(props)}</div>
    </label>
  );
}

export default PriceOption;