/**
 * src/modules/auth/components/modals/Login.js
 */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import core from '../../_core'

class LetsChatComplete extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <section className="text-center">
        <h1>Thanks!</h1>
        <div>We look forward to working with you. We’ll get back to you within 1 business day.</div>
        <button className="spacer-margin-top-40" onClick={this.props.onClose}>Okay</button>
      </section>
    )
  }
}

LetsChatComplete.propTypes = {
}

const dispatchToProps = (dispatch) => (
  bindActionCreators({
  }, dispatch)
)

export default connect(null, dispatchToProps)(
  core.components.modals.Modal(LetsChatComplete)
)