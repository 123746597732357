/**
* @module gallery/components/modals
* ListGalleries.js
*
* @description List all the galleries that belong to a user
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue Jun 12 2018 10:37:35 GMT-0600 (MDT)
* @copyright None
* @last-modified Fri Jun 22 2018 16:34:45 GMT-0600 (MDT)
*/

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import core from '../../../_core'
import auth from '../../../auth'
import flash from '../../../flash'
import product from '../../../product'

import * as actions from '../../actions'
import * as selectors from '../../selectors'
import {Shape} from '../../model'
import emptyThumb from '../../img/emptyThumb.jpg'
import CustomScroll from 'react-custom-scroll';

const _onSelect = (props, galleryId) =>
  props.addProduct(galleryId, props.selectedProduct.id, props.auth)
    .then(
      (targetGallery) => {
        props.hideModal()
        props.unselectProduct()
        props.showMessage(
          <div>
            <span>Successfully added to </span>
            <Link
              to={`/galleries/${targetGallery.id}`}
              onClick={props.hideMessage}
            > {targetGallery.title}</Link>
          </div>
        )
      }
    )

const ListGalleries = (props) => {
  const galleries = props.data && props.data.data ? props.data.data : []
  return (
    <div className="galleries__modalList">
      <div>
        <h2>Add to gallery</h2>
      </div>
      <div className="flex start content">
        <div className="selectedProduct">
          <img crossOrigin="Anonymous" alt="selected-product" src={!props.selectedProduct.thumbnailUrl || props.selectedProduct.thumbnailUrl.startsWith('data:') || props.selectedProduct.thumbnailUrl.startsWith('/') ? props.selectedProduct.thumbnailUrl : (props.selectedProduct.thumbnailUrl + '?v=2020-06-22') } />
        </div>
        <div className="list_wrapper">
          { props.isAdmin && <label htmlFor='tailoredView' className='button link-icon' style={{marginBottom: 15}}>
            <input type='checkbox' id='tailoredView' checked={props.isTailored} onChange={(e) => props.setTailoredView(e.target.checked)} />
            &nbsp;view tailored galleries
          </label> }
          <h3 className="text-left">{`${galleries.length > 0 ? 'Select gallery:': ''}`} </h3>
          { galleries.length > 0 ?
            <CustomScroll flex="1">
              <ul className="list spacer-padding-bottom-40"> { galleries.map(gallery =>
                <li className="flex align-center" key={gallery.id}
                  onClick={() => _onSelect(props, gallery.id)}
                  title={gallery.isActive !== false ? '' : 'inactive'}
                  style={{cursor: 'pointer', opacity: gallery.isActive !== false ? 'initial' : .3}}
                >
                  <img crossOrigin="Anonymous" alt="gallery-first-product" src={gallery.products.length ? (!gallery.products[0].thumbnailUrl || gallery.products[0].thumbnailUrl.startsWith('data:') || gallery.products[0].thumbnailUrl.startsWith('/') ? gallery.products[0].thumbnailUrl : (gallery.products[0].thumbnailUrl + '?v=2020-06-22')) : emptyThumb} />
                  <div>
                    <p><small>{gallery.title}</small></p>
                  </div>
                </li>
              )}</ul>
            </CustomScroll> :
            <p >There are currently no galleries.</p>
          }
        </div>
      </div>
    </div>
  )
}

ListGalleries.propTypes = {
  auth: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(Shape),
    core.model.ErrorShape,
    core.model.LoadingShape
  ]),
  selectedProduct: PropTypes.object,
  addProduct: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  hideMessage: PropTypes.func.isRequired,
  unselectProduct: PropTypes.func.isRequired
}

const stateToProps = (state) => ({
  auth: auth.selectors.getUserAuthToken(state),
  isAdmin: auth.selectors.isUserAdmin(state),
  selectedProduct:  product.selectors.getSelection(state),
  data: selectors.getGalleries(state),
  isTailored: selectors.getGalleries(state).isTailored
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    ...actions,
    loadFromServer: (auth, {isTailored} = {}) => actions.list({limit: 200, isTailored}, auth),
    unselectProduct: product.actions.unselectProduct,
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal,
    showMessage: flash.actions.show,
    hideMessage: flash.actions.hide
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(
  core.components.modals.Modal(
    core.components.withLoading(
      core.components.withError(ListGalleries), ['auth', 'isTailored']
    )
  )
)