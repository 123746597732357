import React, { Component } from 'react';

/* global window */

export default (WrappedComponent) => {
  class withResize extends Component {
    constructor(props) {
      super(props)
      this.state = {
        width: window.innerWidth,
        height: window.innerHeight
      };
      this.onWindowResize = this.onWindowResize.bind(this)
      this.onChildUpdated = this.onChildUpdated.bind(this)
    }

    onWindowResize() {
      const windowWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      const windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight

      if (!this.resizeTimeout && (windowWidth !== this.state.width && windowHeight !== this.state.height) ) {
        this.resizeTimeout = setTimeout(() => {
          this.resizeTimeout = null;
          this.setState({
            width: windowWidth,
            height: windowHeight
          })
          // The actualResizeHandler will execute at a rate of 15fps
        }, 66);
      }
    }

    UNSAFE_componentWillMount() {
      window.addEventListener('resize', this.onWindowResize)
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onWindowResize)
    }

    onChildUpdated(ref) {
      const dom = ref.current
      if (dom && this.state.width !== dom.clientWidth) {
        this.setState({
          width: dom.clientWidth,
          height: dom.clientHeight
        })
      }
    }

    render() {
      return <WrappedComponent {...this.props} screen={this.state} onChildUpdated={this.onChildUpdated}/>
    }
  }

  return withResize
}