/**
 * core/components/navigation/TopNavUser.js
 */

import React from 'react'
import PropTypes from 'prop-types'

import auth from '../../auth';

const TopNavStandard = ({ showModal }) => (
  <section className="topbar__user">
    <nav>
      <dl>
        <dd>
          <button className="link" onClick={() => showModal({id: auth.constants.modals.register})}>Create account</button>
        </dd>
        <dd>
          <button className="link" onClick={() => showModal({id: auth.constants.modals.login})}>Log in</button>
        </dd>
      </dl>
    </nav>
  </section>
)

TopNavStandard.propTypes = ({
  showModal: PropTypes.func,
})

export default TopNavStandard
