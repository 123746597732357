/**
 * src/modules/auth/components/modals/Login.js
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import core from '../../_core'
import flash from '../../flash'
import { postLetsChat } from '../../_core/hubspot'

class LetsChat extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      name: '',
      email: '',
      phone: '',
      company: '', 
      consent: false,
      submitted: false
    }

    this.onChange = this.onChange.bind(this);
    this.validate = this.validate.bind(this);
    this.handleChangeChk = this.handleChangeChk.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  validate(formField) {
    const validator = formField.dataset['validator']

    // If there's no validator configured, make it valid
    formField.dataset['valid'] = validator ? core.util.validate(validator, formField.value) : true
  }

  onChange(e) {
    const newState = {}

    newState[e.target.name] = e.target.value
    this.setState(newState)
    this.validate(e.target)
  }

  onSubmit(e) {
    e.preventDefault()

    const fields = [
      { name: 'email', value: this.state.email },
      { name: 'name', value: this.state.name },
      { name: 'form_name', value: core.constants.formNames.letsChat },
      { name: 'phone', value: this.state.phone },
      { name: 'company', value: this.state.company },
    ]

    // const firstName = this.state.name.indexOf(' ') > 0 
    //                     ? this.state.name.substring(0, this.state.name.indexOf(' ')) 
    //                     : this.state.name;

    postLetsChat(this.state.name, this.state.email, this.state.phone, this.state.company, core.constants.formNames.letsChat, this.state.consent)
      .then(() => {
        window.lintrk('track', { conversion_id: 11401004 });
        if (this.props.onSubmitted)
          this.props.onSubmitted();
      });
    
  }

  handleChangeChk(){
    this.state.consent = !this.state.consent;
  }

  render() {
  
    return (
      <form onSubmit={this.onSubmit}>
        <h1>Let's Chat</h1>

        <fieldset>
          <label>
            <div>Full Name</div>
            <input type="text" name="name" value={this.state.name} onChange={this.onChange} required placeholder="Full name" data-validator="fullName" />
          </label>
          <label>
            <div>Email</div>
            <input type="text" name="email" value={this.state.email} onChange={this.onChange} required placeholder="name@email.com" data-validator="email" />
          </label>
          <label>
            <div>Phone</div>
            <input type="text" name="phone" value={this.state.phone} onChange={this.onChange} required placeholder="555-555-5555" data-validator="phone" />
          </label>
          <label>
            <div>Company</div>
            <input type="text" name="company" value={this.state.company} onChange={this.onChange} placeholder="Company name" required/>
          </label>
          <input type="checkbox" name="consent" style={{visibility:'visible', display:'block', float:'left', marginTop:'5px'}}  value={this.state.consent} onChange={this.handleChangeChk} /> <div style={{float:'left', width:'80%', marginLeft:'15px'}}> I consent to receive promotional emails about your products and services.</div>
          <div style={{clear:'both'}}></div>
        </fieldset>

        <div className="text-center">
          <button type="submit">Let's Chat</button>
        </div>
      </form>
    )
  }
}

LetsChat.propTypes = {
}

const dispatchToProps = (dispatch) => (
  bindActionCreators({
  }, dispatch)
)

export default connect(null, dispatchToProps)(
  core.components.modals.Modal(LetsChat)
)