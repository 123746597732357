/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-18 18:15:47 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-22 13:11:59
 */

import Photoshoot from './Photoshoot'
import Photoshoots from './Photoshoots'
import PhotoshootSimple from './photoshootSimple'

export default {
  Photoshoot,
  Photoshoots,
  PhotoshootSimple
}