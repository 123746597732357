/**
* @module search
* service.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Thu May 17 2018 09:54:50 GMT-0600 (MDT)
* @copyright None
* @last-modified Wed Jul 11 2018 15:45:33 GMT-0600 (MDT)
*/
import core from '../_core'
import filter from '../filter'

import { pageSize } from './constants'

const modelIdsToParams = (ids) => {

  const params = ids.map(id => `modelIds[]=${id}`)

  return params.join('&')
}

class Service extends core.Service {
  search(index = 'products', {offset = 0, limit = pageSize, filters, sort, modelIds, terms = null}) {
    let queryString = `index=${index}&pageSize=${limit}`

    

    if(terms && terms.length > 0 && terms.indexOf("shoot-") == 0)
      terms = null;

    queryString += offset ? `&offset=${offset}` : ''
    queryString += filters ? `&${filter.helpers.filtersToParams(filters)}` : ''
    queryString += terms !== null ? `&terms=${terms}` : ''
    queryString += sort ? `&sort=${sort}` : ''
    queryString += modelIds ? `&${modelIdsToParams(modelIds)}` : ''
    console.log("Toby Searching", terms, modelIds, queryString)
    return super.get(`/search/search?${queryString}`)
  }
}

export default Service