import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTwitter, faFacebookSquare, faInstagram, faLinkedinIn} from '@fortawesome/free-brands-svg-icons'
//import { Link } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link';

import core from '../../_core'
import user from '../../user'

const TopNavPromo = () => (
  <div className='topbar__promo'>
    <div className='topbar__promo-text'>
      Get 50% off when you purchase any large still and clip together. Use <span className='topbar__promo-code'>MOVE50</span> at checkout.
    </div>
  </div>
)

TopNavPromo.propTypes = {
}

export default TopNavPromo