/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-16 13:37:31 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-23 16:51:17
 */

import auth from '../auth'
import core from '../_core'
import flash from '../flash'
import Service from './service';

import * as actionTypes from './actionTypes'
import * as errorTypes from './errorTypes'
import {generate} from './model'
import {moduleName, modals} from './constants'

export const getUserInfo = (authObject, service = new Service()) => ((dispatch) => {
  if (!authObject) {
    return dispatch(core.actions.showModal({id: auth.constants.modals.login}));
  }
  return service.getUserInfo(authObject.id)
    .then(
      (response) => dispatch(onSuccess(actionTypes.getUserInfo, response)),
      (error) => dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.getUserInfo)))
    )
})
  
export const setUserInfo = (user = generate(), authToken = '', service = new Service()) => ((dispatch) => {
  if (!authToken) {
    return dispatch(core.actions.showModal({id: auth.constants.modals.login}));
  }
  return service.setUserInfo(user, authToken)
    .then(
      (response) => dispatch(onSuccess(
        actionTypes.setUserInfo,
        response
      )),
      (error) => dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.setUserInfo)))
    )
})

export const subscribe = ({email}, service = new Service()) => ((dispatch) => {
  return service.subscribe({email})
    .then(
      () => dispatch(flash.actions.show('Subscribed')),
      (error) => dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.getUserInfo)))
    )
})

export const deleteCreditCard = (id = '', authToken = '', service = new Service()) => ((dispatch) => {
  if (!authToken) {
    return dispatch(core.actions.showModal({id: auth.constants.modals.login}));
  }
  return service.deleteCreditCard(id, authToken)
    .then(
      (response) => {dispatch(onDeleteCreditCardSuccess())},
      (error) => dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.getUserInfo)))
    )
})

export const onSuccess = (type, response) => ({
  type,
  user: response.data.data
})

export const getUserBillingInfo = (authToken, service = new Service()) => (dispatch) => {
  if (!authToken) {
    return dispatch(core.actions.showModal({id: auth.constants.modals.login}));
  }
  dispatch(core.actions.onLoading(moduleName))
  return service.getUserBillingInfo(authToken)
    .then(
      //(response) => dispatch(onListSuccess(response.data.data))
      (response) => {
        dispatch(onGetUserBillingInfoSuccess(response.data))
      },
      (error) => {
        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.getUserInfo)));
        dispatch(core.actions.onError(moduleName, error));
      }
    )
}

export const onGetUserBillingInfoSuccess = (data) => {
  return {
    type: actionTypes.getUserBillingInfo,
    data,
  }
}

export const onDeleteCreditCardSuccess = (data) => {
  return {
    type: actionTypes.deleteCreditCardSuccess,
  }
}

