/**
 * src/modules/_core/selectors.js
 */

import { moduleName } from './constants'
import * as model from './model'

/**
 * Given a state, return modal state data
 * @param {object} state
 * @param {object} props
 */
export const getModals = (state) => state[moduleName].modals;
export const getModal = (state, props) => getModals(state).find(m => m.id === props.id)

/**
 * Given a state, return if modal is visible
 * @param {object} state
 * @param {object} id
 */
export const isModalVisible = (state, props) => model.isModalVisible(getModal(state, props))