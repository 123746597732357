/**
 * src/modules/auth/selectors.js
 */

import { moduleName } from './constants'
import { isAdmin, getAuthToken } from './model'

/**
 * Given a state, return auth data
 * @param {object} state
 */
export const getAuth = (state) => (state[moduleName])

/**
 * Given a state, return if authenticated user is an admin
 * @param {object} state
 */
export const isUserAdmin = (state) => (
  isAdmin(getAuth(state))
)

/**
 * Given a state, return the auth token
 * @param {object} state
 */
export const getUserAuthToken = (state) => (
  getAuthToken(getAuth(state))
)

export const getOperation = (state) => (state[`${moduleName}Operation`])