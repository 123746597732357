/**
 * src/modules/auth/components/withAuthOwner.js
 *
 * A Higher Order Component (HOC) that determines if entity belongs to logged in user
 */

import React from 'react'
import { connect } from 'react-redux'

import core from '../../_core'

import { getAuth } from '../selectors'

import Denied from './Denied'

const stateToProps = (state) => ({
  auth: getAuth(state)
})

export default (WrappedComponent) => {
  return connect(stateToProps)((props) => {
    return props.auth && props.auth.id === props.ownerId ?
      (<WrappedComponent {...props}/>) :
      (<Denied timeout={core.constants.redirectTimeout}/>)
  })
}
