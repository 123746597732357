/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:43:20
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-06-19 17:03:47
 */

import {moduleName} from './constants'

export const getPhotoshoots = (state) => (state[moduleName])
export const getPhotoshoot = (state) => (state[`${moduleName}Context`])

export const getFeatured = (state, id) => {
  const photoshoots = getPhotoshoots(state)
  if (!Array.isArray(photoshoots)) {
    return photoshoots
  }

  if (id) {
    const photoshoot = getPhotoshoot(state)
    return (photoshoot && photoshoot.thumbnail) || null
  }

  return photoshoots.map(
    photoshoot => {
      photoshoot.products = [photoshoot.thumbnail]
      return photoshoot
    })
}