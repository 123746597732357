/**
 * src/modules/_core/utilities.js
 *
 * This is meant to be imported as an interface to this module
 */

import * as actions from './actions'
import * as actionTypes from './actionTypes'
import * as constants from './constants'
import components from './components'
import * as model from './model'
import reducer from './reducer'
import * as selectors from './selectors'
import * as util from './utilities'
import Service from './service'
import * as img from './img'

export default {
  actions,
  actionTypes,
  constants,
  components,
  model,
  reducer,
  selectors,
  util,
  Service,
  img
}