/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-22 12:01:32
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-07-11 16:50:26
 */

import * as actionTypes from './actionTypes'
import { defaultLimit } from './constants'
import * as model from './model'

const reducer = (state = model.generate(), action) => {
  let filters, actionCats, stateCats

  switch (action.type) {

  case actionTypes.loadFilters:
  {
    filters = {}
    actionCats = Object.keys(action.filters)
    stateCats = Object.keys(state.filters)
  
    for (const cat of actionCats) {
      if(Object.keys(action.filters[cat]).length) {
        // we are loading the object structure, don't override already assigned object state
        filters[cat] = Object.assign({}, action.filters[cat], state.filters[cat] || {});
      }
    }

    for (const cat of stateCats) {
      if(!filters[cat])
        filters[cat] = state.filters[cat];
    }

    return Object.assign({}, state, {filters})
  }
  case actionTypes.changeFilters:
    console.log("%cchange filters", 'background-color:yellow', state.filters);
    filters = JSON.parse(JSON.stringify(state.filters))
    actionCats = Object.keys(action.filters)

    actionCats.forEach(cat => {
      var catFilters = Object.keys(action.filters[cat])

      catFilters.forEach(filter => {
        if(!filters[cat])
          filters[cat] = {};
        
        filters[cat][filter] = action.filters[cat][filter]
      })
    })

    return Object.assign({}, state, {
      filters,
      pagination: {offset: 0, limit: state.defaultPageSize || defaultLimit}
    })

  case actionTypes.resetFilters: {
    return Object.assign({}, state, {
      filters: model.resetFilters(state.filters),
      areFiltersHidden: true,
      pagination: {offset: 0, limit: state.defaultPageSize || defaultLimit}
    })
  }
  case actionTypes.updateLoading:
    return Object.assign({}, state, {
      loading: action.loading
    })

  case actionTypes.clearFilters: {
    filters = JSON.parse(JSON.stringify(state.filters))
    stateCats = Object.keys(state.filters)

    stateCats.forEach(cat => {
      let catFilters2 = Object.keys(state.filters[cat])

      catFilters2.forEach(filter => {
        if(!filters[cat])
          filters[cat] = {};
        
        filters[cat][filter] = false
      })
    })

    return Object.assign({}, state, {
      filters,
      shootId: null,
      sort: state.filters.photoshootId && Object.values(state.filters.photoshootId).length > 0 ? state.defaultSort || null : state.sort,
      pagination: {offset: 0, limit: state.defaultPageSize || defaultLimit}
    });
  }



  case actionTypes.resetTerms:
    return Object.assign({}, state, {
      terms: null,
      sort: state.defaultSort || null,
      pagination: {offset: 0, limit: state.defaultPageSize || defaultLimit}
    })

  case actionTypes.resetModelIds:
    return Object.assign({}, state, {
      modelIds: null,
      sort: state.defaultSort || null,
      pagination: {offset: 0, limit: state.defaultPageSize || defaultLimit}
    })

  case actionTypes.resetSort:
    return Object.assign({}, state, {
      sort: state.defaultSort || null,
      pagination: {offset: 0, limit: state.defaultPageSize || defaultLimit}
    })

  case actionTypes.setDefaults:
    return Object.assign({}, state, {
      defaultSort: action.defaultSort,
      defaultPageSize: action.defaultPageSize
    })

  case actionTypes.resetPagination:
    return Object.assign({}, state, {
      pagination: {offset: 0, limit: state.defaultPageSize || defaultLimit}
    })

  case actionTypes.changePagination:
    return Object.assign({}, state, {
      pagination: action.pagination
    })

  case actionTypes.changeTerms:
    return Object.assign({}, state, {
      terms: action.terms,
      modelIds: null,
      sort: state.defaultSort || null,
      pagination: {offset: 0, limit: state.defaultPageSize || defaultLimit}
    })

  case actionTypes.changePhotoshoot:

    console.log("%creseting filters", "background-color:green", state.filters)
    var newFilter = model.resetFilters(state.filters);

    if(action.photoshootId)
    {
      newFilter.photoshootId = {};
      newFilter.photoshootId[action.photoshootId] = true
    }

    return Object.assign({}, state, {
      terms: null,
      modelIds: null,
      shootId: action.photoshootId,
      filters: newFilter,
      sort: state.defaultSort || null,
      pagination: {offset: 0, limit: state.defaultPageSize || defaultLimit}
    })

  case actionTypes.changeModelIds:
    console.log("TOBY changeModelIds", action.modelIds)
    return Object.assign({}, state, {
      modelIds: action.modelIds,
      filters: model.resetFilters(state.filters),
      terms: null,
      sort: state.defaultSort || null,
      pagination: {offset: 0, limit: state.defaultPageSize || defaultLimit}
    })

  case actionTypes.changeSort:
    return Object.assign({}, state, {
      sort: action.sort,
      pagination: {offset: 0, limit: state.defaultPageSize || defaultLimit}
    })

  case actionTypes.changeType:
    filters = {...state.filters}
    if (!filters.type) {
      filters.type = { Image: false, Video: false }
    }
    filters.type[action.mediaType] = true

    return Object.assign({}, state, {
      filters
    })

  case actionTypes.toggleFilters:
    return Object.assign({}, state, {
      areFiltersHidden: !state.areFiltersHidden
    })

  case actionTypes.changeCollectionType:
    console.log("ActionTypes", action)
    filters = {...state.filters}
    if (!filters.collectionType) {
      filters.collectionType = { Premium: false, Free: false }
    }
    if (action.collectionType === 'All')
      delete filters.collectionType;
    else
      filters.collectionType[action.collectionType] = true
      
    return Object.assign({}, state, {
      filters
    })

  default:
    return state

  }
}

export default reducer