/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-16 15:01:51 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-23 15:31:10
 */


import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

import core from '../../_core'
import * as model from '../model'
import * as cartActions from '../../shoppingcart/actions'
import * as constants from '../constants';

import UserService from '../../user/service';

class EditAccountInfo extends React.Component {
  constructor(props) {
    super(props)

    this.state = model.generate(props.user);
    this.state.industries = []
    this.state.jobTitles = []

    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.validate = this.validate.bind(this)
    this.onDeleteCreditCard = this.onDeleteCreditCard.bind(this)
    this.selectCountry = this.selectCountry.bind(this)
    this.selectRegion = this.selectRegion.bind(this)
  }

  componentDidMount() {
    const service = new UserService()
    service.listIndustries().then((resp) => {
      if (resp.data && resp.data.data)
        this.setState({
          industries: resp.data.data
        })
    })
    service.listJobTitles().then((resp) => {
      if (resp.data && resp.data.data)
        this.setState({
          jobTitles: resp.data.data
        })
    })
  }

  onChange(e) {
    const newState = {}

    newState[e.target.name] = e.target.value
    this.setState(newState)
    this.validate(e.target)
  }

  selectCountry(val){
    this.setState({'country' : val})
  }

  selectRegion(val){
    this.setState({'region' : val})
  }

  onSubmit(e) {
    e.preventDefault()

    const form = e.target
    const { setUserInfo, authToken } = this.props;

    if (form.querySelectorAll('[data-valid="false"]').length === 0) {
      setUserInfo(this.state, authToken)
        .then(
          (data) => {
            if (this.state.freeEnabled != data.user.freeEnabled)
              this.props.showModal({id: constants.modals.freeEnabledModal});
            else
              this.props.hideModal()
              
            this.props.listPrices({ token: this.props.authToken})
          }
        )
    }
  }

  onDeleteCreditCard(e) {
    e.preventDefault()
    this.props.onDeleteCreditCard(e);
    this.setState({
      userBillingInfo: {hasCard: false}
    });
    
  }

  validate(formField) {
    const validator = formField.dataset['validator']

    // If there's no validator configured, make it valid
    formField.dataset['valid'] = validator ? core.util.validate(validator, formField.value) : true
  }

  render() {

    return (
      <form className="form__edit_account" onSubmit={this.onSubmit}>
        <h2>Edit Account</h2>

        <fieldset>
          <label>
            <div>First name*</div>
            <input type="text" name="firstName" placeholder="John" value={this.state.firstName || ""} onChange={this.onChange} required />
          </label>
          <label>
            <div>Last name*</div>
            <input type="text" name="lastName" placeholder="Smith" value={this.state.lastName || ""} onChange={this.onChange} required />
          </label>
           <label>
            <CountryDropdown
              defaultOptionLabel="Country*"
              value={this.state.profileCountry || ""}
              onChange={(val) => this.setState({ profileCountry: val })} />
           </label>
          <label>
            <div>Email address*</div>
            <input type="email" name="email" placeholder="name@email.com" value={this.state.email || ""} onChange={this.onChange} required data-validator="email" />
          </label>
          <label>
            <div>Company Name*</div>
            <input type="text" name="company" placeholder="" value={this.state.company || ""} onChange={this.onChange} required />
          </label>
          <label>
            <div>Job Title</div>
            {this.state.industries && <select name="jobTitle" value={this.state.jobTitle || ""} onChange={e => this.setState({ jobTitle: e.target.value })} >
              <option>select one</option>
              { this.state.jobTitles.map(option => (
                <option key={option.name} value={option.name}>
                  {option.name}
                </option>
              ))}
             </select> }
           </label>
           <label>
            <div>Primary Industry</div>
             {this.state.industries && <select name="industry" value={this.state.industry || ""} onChange={e => this.setState({ industry: e.target.value })} >
              <option>select one</option>
              { this.state.industries.map(option => (
                <option key={option.name} value={option.name}>
                  {option.name}
                </option>
              ))}
             </select> }
           </label>
          <label>
            <div>Phone</div>
            <input type="phone" name="phone" placeholder="555-555-5555" value={this.state.phone || ""} onChange={this.onChange} />
          </label>
        </fieldset>

        {this.state.userBillingInfo && this.state.userBillingInfo.hasCard && 
        <div style={{marginBottom: '60px'}}>
          <h2>Payment Info</h2>

          <dl className='dl-horizontal'>
            <dt>{this.state.userBillingInfo.brand}</dt>
            <dd>*****************{this.state.userBillingInfo.lastFour} <button style={{marginLeft: '10px'}} onClick={this.onDeleteCreditCard}>Delete</button>    </dd>
          </dl>
        </div>
        }

        <h2>Edit Billing Address</h2>

        <fieldset>
          <label>
            <div>First Name</div>
            <input type="text" name="billingFirstName" placeholder="" value={this.state.billingFirstName || ""} onChange={this.onChange} />
          </label>
          <label>
            <div>Last Name</div>
            <input type="text" name="billingLastName" placeholder="" value={this.state.billingLastName || ""} onChange={this.onChange} />
          </label>
          <label>
            <div>Company</div>
            <input type="text" name="billingCompany" placeholder="" value={this.state.billingCompany || ""} onChange={this.onChange} />
          </label>
          <label>
            <div>Address 1</div>
            <input type="text" name="address1" placeholder="" value={this.state.address1 || ""} onChange={this.onChange} />
          </label>
          <label>
            <div>Address 2</div>
            <input type="text" name="address2" placeholder="" value={this.state.address2 || ""} onChange={this.onChange} />
          </label>
          <label>
            <div>City</div>
            <input type="text" name="city" placeholder="" value={this.state.city || ""} onChange={this.onChange} />
          </label>
          <label>
            <div>Country</div>
            <CountryDropdown
              value={this.state.country || ""}
              onChange={(val) => this.selectCountry(val)} />
          </label>
          <label>
            <div>State/Province</div>
            <RegionDropdown
              defaultOptionLabel="State/Province"
              disableWhenEmpty={true}
              country={this.state.country || ""}
              value={this.state.region || ""}
              onChange={(val) => this.selectRegion(val)} />
          </label>
          <label>
            <div>Zip/Postal</div>
            <input type="text" name="zipPostal" placeholder="" value={this.state.zipPostal || ""} onChange={this.onChange} />
          </label>
        </fieldset>

        <div className="text-center spacer-padding-bottom-40">
          <button type="submit">Submit</button>
        </div>
      </form>
    )
  }

}

EditAccountInfo.propTypes = {
  authToken: PropTypes.string,
  user: model.Shape,
  onSuccess: PropTypes.func,
  setUserInfo: PropTypes.func,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
}

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    listPrices: cartActions.listPrices,
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal
  }, dispatch)
)

export default connect(null, dispatchToProps)(
  core.components.modals.Modal(EditAccountInfo)
)