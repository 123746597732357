/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-18 21:42:04 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-18 23:19:35
 */

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'
import NotFound from './NotFound'

import { ErrorShape } from '../model'

const ErrorComponent = ({data}) => (

  data.error.includes('404')
    ?
    <NotFound />
    :
    <div className='alert__error'>
      <h1><FontAwesomeIcon icon={faExclamationTriangle} /></h1>
      <p>{data.error}</p>    
      <p><small>{data.occurredAt.toString()}</small></p>
    </div>
)

ErrorComponent.propTypes = {
  data: ErrorShape
}

export default ErrorComponent