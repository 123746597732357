/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-17 15:14:50 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-18 20:00:28
 */

import ProductDetails from './ProductDetails'

export default {
  ProductDetails
}