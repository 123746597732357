/**
 * src/modules/auth/components/Login.js
 */

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import core from '../../../_core'
import auth from '../../../auth'

import * as actions from '../../actions'
import * as orderActions from '../../../order/actions'
import * as selectors from '../../selectors'
import * as constants from '../../constants'
import modals from '../modals'
import DownloadCartContainer from './DownloadCartContainer'

class AddMultipleToCart extends React.Component {
  constructor(props) {
    super(props)

    this.onDownloadClick = this.onDownloadClick.bind(this)

    this.showAddBundleToCartModal = this.showAddBundleToCartModal.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.multipleAddToCart.find(product => prevProps.downloading.includes(product.id)) && !this.props.multipleAddToCart.find(product => this.props.downloading.includes(product.id))) {
      this.props.hideModal();
    }
  }

  onDownloadClick(e) {
    e.preventDefault();
    const productsToDownload = this.props.multipleAddToCart.filter(
      product => this.props.userProductOwnership.find(ownedProduct => ownedProduct.productId == product.id && ownedProduct.priceIds.includes(e.target.getAttribute('data-price-id')))
    )

    productsToDownload.forEach(product =>  {
      this.props.downloadImage(this.props.auth, product)
    });

    e.target.disabled = true;
  }

  showAddBundleToCartModal() {
    this.props.showAddBundleToCartModal();
  }

  onSubmit(selectedPrice) {
    const selectedPriceObj = selectors.getPrice(this.props.prices, parseInt(selectedPrice));
    const productsToPurchase = this.props.multipleAddToCart.filter(
      product => !this.props.userProductOwnership || !this.props.userProductOwnership.length || this.props.userProductOwnership.find(ownedProduct => ownedProduct.productId == product.id && ownedProduct.highestResolution < selectedPriceObj.megapixels && ownedProduct.productType === selectedPriceObj.productType)
    )

    this.props.hideModal();
    if (selectedPriceObj.credits && selectedPriceObj.credits.find(credit => credit.remaining > 0)) {
      this.props.addToDownloadCart(productsToPurchase, selectedPriceObj);
    }
    else {
      productsToPurchase.forEach(product =>  {
        this.props.addToCart(product, selectedPriceObj)
      });
    }
  }

  onCancel(e) {
    e.preventDefault();
    this.props.hideModal();
  }

  render() {
    let isDownloading = this.props.downloading.filter(id => this.props.multipleAddToCart.find(product => product.id == id))
    return (
      <section>
        <DownloadCartContainer from='multiple' downloadCart={this.props.downloadCart} price={this.props.selectedPrice} history={this.props.history} location={this.props.location} auth={this.props.auth} />
        <modals.AddMultipleToCartModal id={constants.modals.addMultipleToCart}
          multipleAddToCart={this.props.multipleAddToCart}
          prices={this.props.prices}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
          showAddBundleToCartModal={this.showAddBundleToCartModal}
          userProductOwnership={this.props.userProductOwnership}
          onDownloadClick={this.onDownloadClick}
          isDownloading={isDownloading}
          user={this.props.user} />
      </section>
    )
  }
}

AddMultipleToCart.propTypes = {
  auth: PropTypes.string
}

const stateToProps = (state) => ({
  auth: auth.selectors.getUserAuthToken(state),
  prices: state.shoppingcart.prices,
  multipleAddToCart: state.shoppingcart.multipleAddToCart,
  userProductOwnership: state.shoppingcart.userProductOwnership,
  downloading: state.order.downloading,
  downloadCart: state.shoppingcart.downloadCart,
  selectedPrice: state.shoppingcart.selectedPrice,
  user: state.user
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    addToCart: (product, selectedPrice) => actions.addToCart(product, selectedPrice),
    addToDownloadCart: (products, selectedPrice) => actions.addToDownloadCart(products, selectedPrice),
    loadFromServer: (auth, throughProps) => (dispatch) => {
      dispatch(actions.listPrices({token: auth}))
      dispatch(actions.getUserProductOwnership(throughProps.multipleAddToCart.map(product => product.id), auth))
    },
    downloadImage: (auth, product) => orderActions.downloadImage(product.fileName, product.id, auth),
    showAddBundleToCartModal: () => actions.showAddBundleToCartModal(),
    hideModal: core.actions.hideModal
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(
  withRouter(
    core.components.withLoading(AddMultipleToCart, ['auth', 'multipleAddToCart'])
  )
)