/**
* @module board
* selectors.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Mon Jun 11 2018 09:56:36 GMT-0600 (MDT)
* @copyright None
* @last-modified Tue Jun 19 2018 12:28:02 GMT-0600 (MDT)
*/

import {moduleName} from './constants'

export const getBoards = (state) => (state[moduleName])
export const getBoard = (state) => (
  state[`${moduleName}Context`]
)
