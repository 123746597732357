/**
 * src/modules/auth/components/index.js
 */

import Auth from './containers/Auth'
import modals from './modals'
import ResetPasswordPage from './pages/ResetPassword'
import withAuthAdmin from './withAuthAdmin'
import withAuthOwner from './withAuthOwner'
import withAuthUser from './withAuthUser'
import RegisterForm from './RegisterForm'

export default {
  Auth,
  modals,
  ResetPasswordPage,
  withAuthAdmin,
  withAuthOwner,
  withAuthUser,
  RegisterForm
}