/**
* @module gallery
* actionTypes.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue May 29 2018 10:38:47 GMT-0600 (MDT)
* @copyright None
* @last-modified Wed Jun 20 2018 13:21:03 GMT-0600 (MDT)
*/

import { moduleName } from './constants'

const actions = [
  'list',
  'get',
  'create',
  'update',
  'delete',
  'addProduct',
  'removeProduct',
  'selectProduct'
]

export default actions.reduce(
  (object, action) => {
    object[action] = `${moduleName}.${action}`
    return object
  },
  {}
)