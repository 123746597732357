/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-17 14:32:27
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-06-22 14:54:19
 */

import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird, faVideo } from '@fortawesome/pro-light-svg-icons'

class GenericVideo extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      videoLoaded: false
    }
  }

  componentDidMount() {
  }
  
  render() {
    const {
      style,
      video,
      image,
      styling,
    } = this.props;

    return (
      <div className='product__thumb_img-wrapper' style={{position: 'relative', maxWidth: style && style.width, maxHeight: style && style.height, overflow: 'hidden', display: 'flex'}}>
        <img className="product__thumb_img" itemProp="contentUrl url" crossOrigin="Anonymous"
          src={image.src}
          alt={image.title}
          style={{
            visibility: video.src && (this.state.videoLoaded)  ? 'hidden' : 'visible'
          }}/>
        <div className={`product__video-loader ${video.src && (!this.state.videoLoaded) ? 'product__video-loader--is-active' : ''}`}><FontAwesomeIcon icon={faSpinnerThird} spin size="3x" /></div>
        {video.src && <video onCanPlay={() => this.setState({videoLoaded: true })} autoPlay loop muted className="product__thumb_img"
          width={style && style.width}
          height={style && (style.height || ((style.width + 1) / this.props.aspectRatio))}
          alt={video.title}
          style={ styling ? styling : {objectFit: "cover", position: "absolute", top: 0, left: 0, maxWidth: style && style.height ? undefined : "100%"}}>
          <source src={video.src} type={video.type ? video.type : 'video/mp4'} />
        </video>}
      </div>
    )
  }
}

GenericVideo.propTypes = {
}

export default GenericVideo