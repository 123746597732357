/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:16:34
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-05-09 16:39:54
 */

import React from 'react'
import PropTypes from 'prop-types'

import core from '../../../_core'
// import layout from '../../../layout'
// import product from '../../../product'

// import { moduleName } from '../../constants'
import { Shape } from '../../model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird, faVideo } from '@fortawesome/pro-light-svg-icons'
import { withContentRect } from 'react-measure';
// import { isBoolean } from 'util';

class Single extends React.Component {
  constructor(props) {
    super(props);

    this.onImageLoad = this.onImageLoad.bind(this);
    this.state = {
      loading: true,
      orientation: 'orientation-horizontal',
      videoPlay: false,
      videoLoaded: false
    }
    

  }

  onImageLoad(e) {
    const { onLoad } = this.props;
    if (onLoad) onLoad(e)
    //this.setState({ [`${moduleName}_${item.id}`]: (img.offsetWidth / img.offsetHeight) })
    let orientation = e.target.offsetWidth && e.target.offsetWidth < e.target.offsetHeight ? 'orientation-vertical' : 'orientation-horizontal'
    this.setState({loading: false, orientation});
  }

  render() {
    const { productdata, structuredData } = this.props;
    const { loading, orientation } = this.state;

    const isMissingOrNonProduct = !productdata.compUrl || productdata.compUrl.startsWith('data:') || productdata.compUrl.startsWith('/')
    
    let videoUrl
    if (!isMissingOrNonProduct && productdata.type === 'video') {
      const folder = productdata.compUrl.substring(0, productdata.compUrl.lastIndexOf('/'))
      const name = productdata.fileName.substring(0, productdata.fileName.lastIndexOf('.'))
      const extension = productdata.fileName.substring(productdata.fileName.lastIndexOf('.'))

      if (folder && name && extension) {
        videoUrl = `${folder}/${name}_video480${extension}`
      }
    }
    
    const entry = this.props.contentRect.entry
    const containerWidth = entry && entry.width > -1 ? entry.width : (this.props.contentRect.client.width - 1);
    const containerHeight = entry && entry.height > -1 ? entry.height : (this.props.contentRect.client.height - 1);

    const imageTag = <div ref={this.props.measureRef} style={{position: 'relative'}}>
      <img className={orientation} itemProp="contentUrl url" crossOrigin="Anonymous"
        src={isMissingOrNonProduct ? productdata.compUrl : (productdata.compUrl + '?v=2020-06-22')}
        alt={productdata.description}
        onLoad={this.onImageLoad}
        style={{
          visibility: loading || videoUrl && this.state.videoPlay && this.state.videoLoaded  ? 'hidden' : 'visible'
        }}/>
      <div className={`product__video-loader ${loading || videoUrl && (this.state.videoPlay && !this.state.videoLoaded) ? 'product__video-loader--is-active' : ''}`}><FontAwesomeIcon icon={faSpinnerThird} spin size="3x" /></div>
      {videoUrl && <video onCanPlay={() => this.setState({videoLoaded: true })}
        autoPlay
        loop
        controls
        disablePictureInPicture 
        controlslist="nodownload"
        alt={productdata.title}
        width={containerWidth || undefined}
        height={containerHeight || undefined}
        style={{objectFit: "cover", position: "absolute", top: 0, left: 0, display: 'block'}}>
        <source src={videoUrl + '?v=2020-06-22'} type="video/mp4" />
      </video>}
    </div>

    return (
      <div className="productDetails__single_img" itemScope itemType="http://schema.org/ImageObject">
        { structuredData && <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script> }
        { !structuredData && <React.Fragment>
            <meta itemProp="license" content="/pages/pricing#licensing" />
            <meta itemProp="acquireLicensePage" content={`/product/${productdata.id}`} />
          </React.Fragment>}
        
        {/* {productdata.collectionType && productdata.collectionType.toLowerCase() === 'free' && <div className='corner-badge'><span>Free</span></div> } */}
        {imageTag}
      </div>
    )
  }
}

Single.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(Shape),
    core.model.ErrorShape,
    core.model.LoadingShape
  ])
}

export default withContentRect('client')(Single)