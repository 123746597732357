/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-16 20:45:39
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-16 21:01:48
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import core from '../../_core'
import * as actions from '../actions'
import { postToHubspot } from '../../_core/hubspot'
import auth from '../../auth';

class Subscribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      EMAIL: '',
      consent:false
    }

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.handleChangeChk = this.handleChangeChk.bind(this);
    this.postToHubspotInternal = this.postToHubspotInternal.bind(this);
  }

  validate(formField) {
    const validator = formField.dataset['validator']

    // If there's no validator configured, make it valid
    formField.dataset['valid'] = validator ? core.util.validate(validator, formField.value) : true
  }

  onChange(e) {
    const newState = {}

    newState[e.target.name] = e.target.value
    this.setState(newState)
    this.validate(e.target)
  }

  handleChangeChk(){

    this.state.consent = !this.state.consent;

  }

  onSubmit() {
    document.cookie = `subscribeHide=true;expires=${new Date(2100, 0, 0).toUTCString()}`

    document.getElementById('subscribe-footer').submit()
  }


  postToHubspotInternal(){
    const { showModal } = this.props;

    postToHubspot(true, this.state.EMAIL, "Newsletter Footer","","","");

    if(true)
    {
      document.getElementById('subscribe-footer-container').style.display = "none";
      document.getElementById('subscribe-footer-thanks').style.display = "block";

      showModal({id: auth.constants.modals.registerComplete});
    }

  }


  render() {
    return (
      <form id='subscribe-footer' className="form__subscribe spacer-margin-bottom-50" noValidate>
     
        <div id="subscribe-footer-container">
          <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="Sign up for news and updates" value={this.state.EMAIL} onChange={this.onChange} required data-validator="email"/>
          <button type="button" disabled={!this.state.EMAIL} onClick={this.postToHubspotInternal}>Submit</button>

         
          <div style={{position: 'absolute', left: '-5000px'}} aria-hidden='true' aria-label="Please leave the following three fields empty">
            <label htmlFor="mce-EMAIL2">Email: </label>
            <input type="email" name="MASKEMAIL" defaultValue='' tabIndex="-1" placeholder="youremail@gmail.com" id="mce-EMAIL2"/>
          </div>
        </div>

        <div id="subscribe-footer-thanks"  style={{display:'none'}}>Thank you for subscribing!</div>

      </form>
 
    )
  }
}

Subscribe.propTypes = {
  subscribe: PropTypes.func
}


const dispatchToProps = (dispatch) => (
  bindActionCreators({
    subscribe: actions.subscribe,
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal
  }, dispatch)
)

export default connect(null, dispatchToProps)(Subscribe)