/**
 * src/modules/filter/components/ResetFilter.js
 */

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as selectors from '../selectors'
import * as actions from '../actions'

class ResetFilter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      terms: props.terms
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.terms !== state.terms) {
      props.resetSort()
      props.resetPagination()
      return {
        terms: props.terms
      }
    } else {
      return state
    }
  }

  render() {
    return null
  }
}

const stateToProps = state => ({
  filters: selectors.getFilters(state),
  terms: selectors.getTerms(state)
})

const dispatchToProps = dispatch => (
  bindActionCreators({
    resetFilters: actions.resetFilters,
    resetSort: actions.resetSort,
    resetTerms: actions.resetTerms,
    resetPagination: actions.resetPagination
  }, dispatch)
)

ResetFilter.propTypes = {
  terms: PropTypes.string,
  filters: PropTypes.object
}

export default withRouter(connect(stateToProps, dispatchToProps)(ResetFilter))