/**
* @module search/pages
* index.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Thu May 17 2018 14:00:23 GMT-0600 (MDT)
* @copyright None
* @last-modified Thu May 17 2018 14:00:23 GMT-0600 (MDT)
*/

import Search from './Search'
import SearchShoots from './SearchShoots'
import SearchModels from './SearchModels'

export default {
  Search,
  SearchShoots,
  SearchModels
}