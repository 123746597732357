/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:16:34
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-05-15 12:58:16
 */

// dead component? no, on productDetails page

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import core from '../../_core'

import * as actions from '../actions'
import components from '../components'
import { Shape } from '../model'
import * as selectors from '../selectors'

class Photoshoot extends React.Component {
  constructor(props) {
    super(props)

    this.getSimpleList = this.getSimpleList.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      // dirty way to send back if products are available for parent component
      if (this.props.onCheckProductsAvailable) {
        let products = [];
        if (this.props.params && this.props.params.filters && this.props.params.filters.type) {
          products = this.props.params.filters.type.video ? this.props.data.videos : this.props.data.stills
        }
        
        const hasProducts = products && products.length > 0
        this.props.onCheckProductsAvailable(hasProducts)
      }
    }
  }

  getSimpleList(data, onPhotoshootClick){
    return <components.containers.ListSimple {...this.props} data={data} stills={this.props.data.stills} videos={this.props.data.videos} onPhotoshootClick={onPhotoshootClick} />
  }

  render() {

    const { data } = this.props.data
    const { id, params } = this.props

    return (
       
      <div>
        {
          this.getSimpleList(this.props.data.data,this.props.onPhotoshootClick)
        }  
      </div>
      
    )
  }
}

Photoshoot.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(Shape),
    core.model.ErrorShape,
    core.model.LoadingShape
  ]),
  match: PropTypes.object,
  photoshoot: Shape,
  id:PropTypes.number,
  params:PropTypes.object
}

const stateToProps = (state, props) => {
  const stateData = selectors.getPhotoshoot(state, props.id ? props.id : props.match.params.id)

  return {
    data: stateData,
  }
}

const dispatchToProps = (dispatch, props) => (
  bindActionCreators({
    ...actions,
    loadFromServer: () => actions.getPhotoshoot(props.id ? props.id : props.match.params.id, props.params ? props.params : {}),
    // updateProducts: (offset, limit, params) => (
    //   actions.filterProducts(props.id ? props.id : props.match.params.id, {...params, offset, limit})
    // )
  }, dispatch)
)

export default withRouter(
  connect(stateToProps, dispatchToProps)(
    core.components.withLoading(
      core.components.withError(
        core.components.withNotFound(Photoshoot)
      )
    )
  )
)