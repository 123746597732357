import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import core from '../modules/_core'
import auth from '../modules/auth'
import filter from '../modules/filter'
import flash from '../modules/flash'
import user from '../modules/user'
import layout from '../modules/layout'
import photoshoot from '../modules/photoshoot'
import productDetail from '../modules/product'
import pages from '../modules/pages'
import tailoredCustomLibraries from '../modules/pages/components/tailored-custom-libraries'
import heroFree from '../modules/pages/components/hero-free'
import limitless from '../modules/pages/components/limitless'
import search from '../modules/search'
import gallery from '../modules/gallery'
import board from '../modules/board'
import shoppingcart from '../modules/shoppingcart'
import order from '../modules/order'
import _core from '../modules/_core'

import HomePage from '../modules/pages/components/home'
import PageUnderMaintenance from '../modules/pages/components/under-maintenance'
import NotFoundPage from '../modules/pages/components/notFoundPage'
import ScrollToTop from '../modules/_core/components/ScrollToTop';

import * as constants from '../modules/_core/constants'

import '../modules/_core/_styles.scss'

const inMaintenanceMode = false;

const RootRouter = () => (
  <Router>
    <ScrollToTop>
      { inMaintenanceMode && <section>
        <PageUnderMaintenance />
      </section> }
      { !inMaintenanceMode && <section>
        <core.components.Analytics/>
        <layout.components.TopNav />
        <auth.components.Auth />
        {/* <filter.components.ResetFilter /> */}
        <board.components.containers.Board/>
        <gallery.components.containers.Gallery/>
        <Switch>

          <Route path="/" component={HomePage} exact={true} />
          
          <Route path="/(.*)" exact={true} render={() => (
            <Redirect to="/"/>
          )} />

          <Route component={NotFoundPage} />
        </Switch>
        <flash.components.FlashContainer />
        <layout.components.Footer />
      </section> }
    </ScrollToTop>
  </Router>
)

export default RootRouter