/**
 * src/modules/_core/actions.js
 */

import * as actionTypes from './actionTypes'
import * as model from './model'

export const destroyModal = (modal = model.generateModal()) => ({
  type: actionTypes.destroyModal,
  modal
});

export const initModal = (modal = model.generateModal()) => ({
  type: actionTypes.initModal,
  modal
});

export const showModal = (modal = model.generateModal()) => ({
  type: actionTypes.showModal,
  modal
});

export const hideModal = () => ({
  type: actionTypes.hideModal
});

export const onLoading = (store, state = model.generateLoading()) => ({
  type: actionTypes.loading(store),
  state
})

export const onError = (store, error) => ({
  type: actionTypes.error(store),
  state: model.generateError(error.message)
})