/**
 * src/modules/auth/index.js
 *
 * This is meant to be imported as an interface to this module
 */

import * as actions from './actions'
import * as constants from './constants';
import components from './components'
import { moduleName } from './constants'
import { reducer, operationReducer } from './reducer'
import Routes from './routes'
import * as selectors from './selectors'
import { Shape } from './model'
import Service from './service'

export default {
  actions,
  components,
  constants,
  moduleName,
  reducer,
  operationReducer,
  Routes,
  selectors,
  Shape,
  Service
}