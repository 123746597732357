/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:48:50
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-07-11 16:51:22
 */

import core from '../_core'
import flash from '../flash'

import * as actionTypes from './actionTypes'
import {moduleName} from './constants'
import * as errorTypes from './errorTypes'
import Service from './service';

const _renameSingleProductsArr = (data) =>{
  data.products = data.photoshootProducts
  data.photoshootProducts = undefined
  return data
}

export const releasePhotoshoot = () => ({
  type: actionTypes.resetPhotoshoots
})

export const list = ({terms, offset, limit}, service = new Service()) => (dispatch) => {
  dispatch(core.actions.onLoading(moduleName))

  return service.list({terms, offset, limit})
    .then(
      //(response) => dispatch(onListSuccess(response.data.data))
      (response) => {
        let info = response.data.info || {}
        let photoshoots = response.data.data.photoshoots || response.data.data

        dispatch(onListSuccess({
          photoshoots: photoshoots.map(photoshoot => _renameSingleProductsArr(photoshoot)),
          info
        }))
      }
      ,
      (error) => {

        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.list)));
        dispatch(core.actions.onError(moduleName, error));
      }
    )
}

export const getPhotoshoot = (id, params = {},  service = new Service()) => (dispatch) => {
  dispatch(core.actions.onLoading(`${moduleName}Context`))

  return service.getPhotoshoot(id, params)
    .then(
      //(response) => dispatch(onUpdatePhotoshoot(response.data.data, response.data.info)),
      (response) => {
        const data = _renameSingleProductsArr(response.data.data)
        const info = response.data.info
        
        info.type = params.filters && params.filters.type && (params.filters.type.video ? 'video' : 'image')
        return {data, info}
      },
      (error) => {
        !error.toString().includes('404') && dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.list)));
        dispatch(core.actions.onError(moduleName, error));
        dispatch(core.actions.onError(`${moduleName}Context`, error));
        return error
      }
    )
    .then(
      (response) => {
        response && response.data &&
            dispatch(onUpdatePhotoshoot(response.data, response.info))
      }
    )
}

export const filterProducts = (id, params = {}, service = new Service()) => (dispatch) => {
  dispatch(core.actions.onLoading(moduleName))

  return service.filterProducts(id, params)
    .then(
      //(response) => dispatch(onUpdatePhotoshoot(response.data.data, response.data.info)),
      (response) =>{
        const data = _renameSingleProductsArr(response.data.data)
        const info = response.data.info
        return {data, info}
      },
      (error) => {
        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.list)));
        dispatch(core.actions.onError(moduleName, error));
      }
    )
    .then(
      ({data, info}) => dispatch(onUpdatePhotoshoot(data, info))
    )
}

export const onUpdatePhotoshoot = (photoshoot, info) => ({
  type: actionTypes.getPhotoshoot,
  photoshoot,
  info
})

export const onListSuccess = (data) => {
  const photoshoots = data.photoshoots || data
  const info = data.info || {}

  return {
    type: actionTypes.list,
    photoshoots,
    info
  }
}

export const getLatestPhotoshoot = (authToken, service = new Service()) => (dispatch) => {
  dispatch(core.actions.onLoading(moduleName))

  return service.getLatestPhotoshoot(authToken)
    .then(
      (response) => {
        let data = response.data.data
        let info = response.data.info
        return {
          data,
          info
        }
      },
      (error) => {
        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.list)));
        dispatch(core.actions.onError(moduleName, error));
      }
    )
}