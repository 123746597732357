/**
 * src/modules/product/components/SearchTerm.js
 *
 * Search term component that is displayed under the image in productDetails
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const SearchTerm = ({term, lastItem, type}) => {
  return (<span className={lastItem ? 'last' : ''}>
    <Link to={`/search/${encodeURI(term.trim())}/${type === 'video' ? 'Video' : 'Image'}/1`}
      className="button link productDetail__searchterm"
    >{term}</Link>
  </span>)
}

SearchTerm.propTypes = {
  term: PropTypes.string.isRequired
}

export default SearchTerm