/*
 * @Author: Mike Cottingham 
 * @Date: 2018-05-02 11:19:51 
 * @Last Modified by:   Mike Cottingham 
 * @Last Modified time: 2018-05-02 11:19:51 
 */

import PropTypes from 'prop-types'


export const ScreenShape = PropTypes.shape({
  width: PropTypes.number,
  height: PropTypes.number
})