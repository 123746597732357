/**
* @module gallery
* selectors.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue May 29 2018 10:38:09 GMT-0600 (MDT)
* @copyright None
* @last-modified Tue Jun 19 2018 16:41:21 GMT-0600 (MDT)
*/

import {moduleName} from './constants'

export const getGalleries = (state) => (state[moduleName])
export const getGallery = (state) => (state[`${moduleName}Context`])
