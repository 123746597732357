/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-22 12:01:23
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-07-11 14:38:54
 */

import { moduleName } from './constants'

export const getFilterSettings = state => state[moduleName]
export const getFilters = state => getFilterSettings(state).filters
export const getTerms = state => getFilterSettings(state).terms
export const getFiltersHidden = state => getFilterSettings(state).areFiltersHidden
export const getPagination = state => getFilterSettings(state).pagination
export const getSort = state => getFilterSettings(state).sort
export const getModelIds = state => getFilterSettings(state).modelIds
export const getShootId = state => getFilterSettings(state).shootId
export const getSearchLoading = state => getFilterSettings(state).loading || false;
