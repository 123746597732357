/**
* @module search/pages
* SearchShoots.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Thu May 17 2018 14:00:13 GMT-0600 (MDT)
* @copyright None
* @last-modified Wed Jul 11 2018 15:48:36 GMT-0600 (MDT)
*/

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import core from '../../_core'
import filter from '../../filter'

import * as actions from '../actions'

class SearchShoots extends React.Component {

  constructor(props){
    super(props);

  
  }

  componentDidMount() {
    const props = this.props
    
    if(props.match.params.shootId) {
      let shootId = props.match.params.shootId;
    
      if(!isNaN(parseInt(shootId)))
      {
        props.changePhotoshoot(shootId)
        props.history.push('/search')
      }
    }
  }

  render() {
    return (null);
  }
}


const stateToProps = (state) => ({
  filters: filter.selectors.getFilters(state),
  loading: filter.selectors.getSearchLoading(state)
})

const dispatchToProps = (dispatch) => {
  return bindActionCreators({
    ...actions,
    changePhotoshoot: filter.actions.changePhotoshoot,
  }, dispatch)
}

export default withRouter(
  connect(stateToProps, dispatchToProps, (stateProps, dispatchProps, ownProps) => {
    return Object.assign(
      {},
      stateProps,
      dispatchProps,
      ownProps,
      {}
    )
  })(
    core.components.withError(
      SearchShoots
    )
  )
)