/**
 * pages/components/about.js
 */

import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import core from '../../_core';
import user from '../../user';

import {Helmet} from 'react-helmet'
import header from '../img/custom-libraries/header.jpg';
import videoPreview from '../img/custom-libraries/video-preview.jpg';
import reason01 from '../img/custom-libraries/reason01.jpg';
import reason02 from '../img/custom-libraries/reason02.jpg';
import reason03 from '../img/custom-libraries/reason03.jpg';
import reason04 from '../img/custom-libraries/reason04.jpg';
import reason05 from '../img/custom-libraries/reason05.jpg';
import reason06 from '../img/custom-libraries/reason06.jpg';

import CaseStudies from '../../misc/CaseStudies';
import { hideModal } from '../../_core/actionTypes';

const metaData = {
  description : `Tailored Custom Libraries | Hero Images`,
  keywords :'Hero Images, Stock Photography',
  title: 'Tailored Custom Libraries | Hero Images',
  name : 'Hero Images',
  url : window && window.location && `${window.location.protocol}//${window.location.host}/tailored-custom-libraries`,
  image : header,
  TwitterSite : '@heroimages',
  TwitterCard : 'summary',
  ogType : 'article'
}

class PageCustomLibraries extends React.Component {

  constructor(props) {
    super(props)

    this.onClose = this.onClose.bind(this)
    this.onLetsChatSubmitted = this.onLetsChatSubmitted.bind(this)
  }

  onLetsChatSubmitted() {
    this.props.showModal({ id: user.constants.modals.letsChatComplete })
  }
  onClose() {
    this.props.hideModal()
  }


  render() {
    const { showModal } = this.props;
    
    return (
      <section className="full-wd-page page--libs">
        
        <Helmet>
          <title>{metaData.title}</title>
          <meta name="description"           content={metaData.description} />
          <meta name="keywords"              content={metaData.keywords} />

          <meta itemProp="name"              content={metaData.name}/>
          <meta itemProp="description"       content={metaData.description} />
          <meta itemProp="image"             content={metaData.image} />

          <meta name="twitter:card"          content={metaData.TwitterCard} />
          <meta name="twitter:site"          content={metaData.TwitterSite} />
          <meta name="twitter:title"         content={metaData.title} />
          <meta name="twitter:description"   content={metaData.description} />
          <meta name="twitter:creator"       content={metaData.TwitterSite} />
          <meta name="twitter:image"         content={metaData.image}/>

          <meta property="og:url"            content={metaData.url} />
          <meta property="og:type"           content={metaData.ogType} />
          <meta property="og:title"          content={metaData.title} />
          <meta property="og:description"    content={metaData.description} />
          <meta property="og:image"          content={metaData.image} />
        </Helmet>

        <user.modals.LetsChat {...this.props} id={user.constants.modals.letsChat} onSubmitted={this.onLetsChatSubmitted} />
        <user.modals.LetsChatComplete {...this.props} id={user.constants.modals.letsChatComplete} onClose={this.onClose} />

        <section className="hero-banner hero-banner--libs" style={{backgroundImage: `url(${header})`}}>
          <div className="hero-banner__body hero-banner__body--libs">
            <h1>You really can have it all.</h1>
            <p className="hero-banner__body">Custom image solutions from&nbsp;Hero.</p>
            <button className="button button--cta" onClick={() => showModal({id: user.constants.modals.letsChat})}>Let's Chat</button>
          </div>
        </section>

        <section>
          <div className="regTextWidth text-center spacer-padding-top-20 spacer-padding-bottom-20">
            <p className="text-lead">Tell us what you want — scenes, casting, locations, props, and moods — and we’ll shoot hundreds of exclusive, on-brand, and visually stunning images your brand&nbsp;deserves.</p>
          </div>

          <div className="regTextWidth">
            <div className="row">
              <div className="col col--ksp card">
                <img src={reason01} alt="Female doctor with medical record sitting examination room" className="card__img" />
                <h3 className="card__title card__title--ksp">Custom Content</h3>
                <div className="card__content">
                  <div className="fader-block">
                    <input type="checkbox" id="faderCheck01" className="fader-block__state-checkbox" />
                    <label htmlFor="faderCheck01" className="fader-block__fade"></label>
                    <div className="fader-block__cotent">
                      <p>Anybody can snap a photo. It takes one of the world’s most prolific image producers to create an endless supply of top-performing images. Our custom image library service was designed for global companies, fast-paced agencies, and busy brands that need a reliable source for hard-to-find images. You can speak directly with our production team to customize every aspect of the images we shoot for&nbsp;you.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col--ksp card">
                <img src={reason02} alt="Portrait confident female fashion designers in studio" className="card__img" />
                <h3 className="card__title card__title--ksp">Brand Cohesion</h3>
                <div className="card__content">
                  <div className="fader-block">
                    <input type="checkbox" id="faderCheck02" className="fader-block__state-checkbox" />
                    <label htmlFor="faderCheck02" className="fader-block__fade"></label>
                    <div className="fader-block__cotent">
                      <p>It isn’t easy to create a brand. It’s even harder to manage one, and to keep a consistent aesthetic while branded content is being created by different teams, for different channels. Images play a huge role in how recognizable your brand is. With a custom image library, you can guarantee a consistent visual aesthetic by giving your teams shared access to a vast selection of pre-approved&nbsp;images.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col--ksp card">
                <img src={reason03} alt="Senior couple playing solitaire on digital tablet and knitting in living room" className="card__img" />
                <h3 className="card__title card__title--ksp">Photos &amp; Footage</h3>
                <div className="card__content">
                  <div className="fader-block">
                    <input type="checkbox" id="faderCheck03" className="fader-block__state-checkbox" />
                    <label htmlFor="faderCheck03" className="fader-block__fade"></label>
                    <div className="fader-block__cotent">
                      <p>It takes a lot of preparation and planning to pull off an on-location photo shoot. We have experts casting, styling, staging, and shooting every photo — so you never have to worry about those costs or logistics.  We shoot video clips at the same time, so your custom image library will contain everything you need to create cohesive campaigns and brand collateral across all marketing&nbsp;channels.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col--ksp card">
                <img src={reason04} alt="Affectionate lesbian brides kissing at wedding reception in barn" className="card__img" />
                <h3 className="card__title card__title--ksp">Image Exclusivity</h3>
                <div className="card__content">
                  <div className="fader-block">
                    <input type="checkbox" id="faderCheck04" className="fader-block__state-checkbox" />
                    <label htmlFor="faderCheck04" className="fader-block__fade"></label>
                    <div className="fader-block__cotent">
                      <p>Great images can become synonymous with popular brands. And nobody likes seeing images they’ve used being used in competitor advertising. When Hero shoots a custom image library for you, you’ll have a private supply of hundreds of new, high quality, on-brand images that your competitors won’t have access&nbsp;to.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col--ksp card">
                <img src={reason05} alt="People volunteering, cleaning up garbage and tire in stream" className="card__img" />
                <h3 className="card__title card__title--ksp">Rights &amp; Clearances</h3>
                <div className="card__content">
                  <div className="fader-block">
                    <input type="checkbox" id="faderCheck05" className="fader-block__state-checkbox" />
                    <label htmlFor="faderCheck05" className="fader-block__fade"></label>
                    <div className="fader-block__cotent">
                      <p>Reputation matters. As industry experts, we know the ins and outs of image licensing. Relying on our in-house team to make images from start to finish ensures they are free of licensing confusion and usage restrictions, and are fully released. When you purchase a custom image library from Hero Images, you have those&nbsp;assurances.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col--ksp card">
                <img src={reason06} alt="Combine harvesters and trailer in rural field at dusk, harvesting crop" className="card__img" />
                <h3 className="card__title card__title--ksp">Volume Pricing</h3>
                <div className="card__content">
                  <div className="fader-block">
                    <input type="checkbox" id="faderCheck06" className="fader-block__state-checkbox" />
                    <label htmlFor="faderCheck06" className="fader-block__fade"></label>
                    <div className="fader-block__cotent">
                      <p>Because our time on set is so productive, we’re able to produce hundreds of selects and pass the savings on to you with volume pricing. Requesting a custom image library from Hero isn’t just the easiest way to acquire hundreds of high quality images for your brand — it’s also the most&nbsp;affordable.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="full-wd-section column">
          <div className="regTextWidth text-center spacer-padding-top-80 spacer-padding-bottom-10" >  
            <h1>Custom Clients We Love</h1>
            <p style={{'maxWidth': '42em'}}>We've collaborated with some pretty great brands.<br className="hidden-mobile"/> Here's a look at some of the projects.</p>
          </div>

          <CaseStudies />

        </section>

        <section id="behindthescenes" className="full-wd-section column">
          <div className="regTextWidth text-center spacer-padding-top-30 spacer-padding-bottom-10 spacer-margin-horizontal-20">
            <h1>Behind the scenes</h1>
            <p className="text-lead">Ever wondered what happens behind the scenes at a photo shoot for commercial images? Watch this video to see how we create images for the world’s most interesting brands.</p>
          </div>

          <div className="embed-responsive embed-responsive--16x9">
          <iframe id="tailoredVideo" src="https://player.vimeo.com/video/444680441?color=ffffff&title=0&byline=0&portrait=0" allow="autoplay" frameBorder="0" webkitallowfullscreen="1" mozallowfullscreen="1" allowFullScreen="1" className="embed-responsive__item"></iframe>
          </div>

          {/* <div className="embed-responsive embed-responsive--16x9 tailored-video-embed"
            style={{backgroundImage: `url(${videoPreview})`}}>
            <iframe id="tailoredVideo" style={{display: playing ? 'block' : 'none' }} src="https://player.vimeo.com/video/444680441?color=ffffff&title=0&byline=0&portrait=0" allow="autoplay" frameBorder="0" webkitallowfullscreen="1" mozallowfullscreen="1" allowFullScreen="1" className="embed-responsive__item"
              onLoad={(e) => {
                  setPlaying(false);
                  window.player = new window.Vimeo.Player(e.target); 
                  window.player.on('ended', () => setPlaying(false));
              }}></iframe>
            <div className="tailored-video-embed__button-wrap" id="tailoredVideoButtonWrap" style={{display: playing ? 'none' : 'flex' }}>
              <button className="button button--cta" id="tailoredVideoButton"
                onClick={() => { setPlaying(true); }}>Watch the video</button>
            </div>
          </div> */}
          <div className="regTextWidth text-center spacer-padding-top-20 spacer-padding-bottom-10 spacer-margin-horizontal-20">
            {/* <p><a className="button button--cta" href='mailto:Tailored@heroimages.com?subject=tailored+custom+libraries'>Let's work together</a></p> */}
            <p><button className="button button--cta" onClick={() => showModal({id: user.constants.modals.letsChat})}>Let's Chat</button></p>
          </div>
        </section>
      </section>
    )
  }
}
PageCustomLibraries.propTypes = ({
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal
  }, dispatch)
)

export default connect(null, dispatchToProps)(PageCustomLibraries)

