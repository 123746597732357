/**
* @module board/pages
* Board.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Mon Jun 11 2018 09:56:36 GMT-0600 (MDT)
* @copyright None
* @last-modified Thu Jun 21 2018 15:16:25 GMT-0600 (MDT)
*/

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import shoppingcart from '../../shoppingcart'

import core from '../../_core'
import auth from '../../auth'

import * as constants from '../constants'
import * as actions from '../actions'
import components from '../components'
import * as selectors from '../selectors'
import { Shape } from '../model'

import {Helmet} from 'react-helmet'

const Board = (props) => {
  const { data, showModal, removeProduct, auth } = props
  const thumbnail = data && data.products && data.products.length > 0 && data.products[0].thumbnailUrl

  const _onShare = (e) => {
    e.preventDefault()
    showModal({id: core.constants.modals.share});
  }

  const _onEdit = () => {
    showModal({id: constants.modals.edit})
  }

  const _onRemoveProduct = (productId) => {
    removeProduct({
      id: data.id,
      productId,
      token: auth})
  }
  //console.log('thumbnail'+thumbnail)
  return (
    <section className="board__wrapper">
      <Helmet>
        <title>Boards | Hero Images</title>
        <meta name="description"  content="Hero Images Boards" />
      </Helmet>
      <core.components.modals.Share
        {...props}
        id={core.constants.modals.share}
        url={window.location.href}
        media={thumbnail !== false ? thumbnail : ''}
        title={`Board: ${data.title} | Hero Images`}
        description={`Checkout my ${data.title} board`}
      />
      <shoppingcart.components.containers.AddMultipleToCart />
      <components.containers.Single
        {...props}
        data={data}
        onShare={_onShare}
        onEdit={_onEdit}
        onRemoveProduct={_onRemoveProduct}
      />
    </section>
  )
}


Board.propTypes = {
  data: PropTypes.oneOfType([
    Shape,
    core.model.ErrorShape,
    core.model.LoadingShape
  ]),
  auth: PropTypes.string
}

const stateToProps = (state, props) => {
  const stateData = selectors.getBoard(state, props.match.params.id)

  return {
    ...stateData,
    auth: auth.selectors.getUserAuthToken(state),
    authObj: auth.selectors.getAuth(state)
  }

}

const dispatchToProps = (dispatch,props) => (
  bindActionCreators({
    ...actions,
    loadFromServer: () => actions.get(props.id ? props.id : props.match.params.id),
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal,
    showAddMultipleToCartModal: (products) => shoppingcart.actions.showAddMultipleToCartModal(products),
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(
  withRouter(
    core.components.withLoading(
      core.components.withError(
        core.components.withNotFound(Board)
      )
    )
  )
)