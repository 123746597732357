/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-18 13:31:00 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-22 14:06:31
 */
/**
 * src/modules/_core/components/index.js
 */

import Analytics from './Analytics'
import ErrorComponent from './ErrorComponent'
import modals from './modals'
import NotFound from './NotFound'
import withError from './withError'
import withLoading from './withLoading'
import withNotFound from './withNotFound'

export default {
  Analytics,
  ErrorComponent,
  modals,
  NotFound,
  withError,
  withLoading,
  withNotFound
}