import React, { Component } from 'react';

import emptyThumb from "../img/emptyThumb.jpg";

const _cssRotation = {
  '-2': undefined,
  '-1': undefined,
  1: undefined,
  3: 'rotate(180deg)',
  6: 'rotate(90deg) translateY(-50%)',
  8: 'rotate(270deg) translateX(-100%) translateY(-50%)'
}
const _isInvertedAxis = (exifValue) => exifValue === 6 || exifValue === 8

class ManagedOrientedImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aspectRatio: undefined
    };
    
    this.onImageLoad = this.onImageLoad.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.knownAspectRatio === undefined && prevProps.knownAspectRatio !== this.props.knownAspectRatio && this.state.aspectRatio) {
      // simulate the image's onLoad event
      this.props.onLoad && this.setState({reload: window.setTimeout(() => this.props.onLoad(this.state.aspectRatio), 0)})
    }
  }

  onComponentWillUnmount() {
    this.state.reload && window.clearTimeout(this.state.reload)
  }

  onImageLoad({target:img}) {
    if (!img.src.endsWith(this.props.src || emptyThumb)) return

    const ratio = img.naturalHeight && img.naturalWidth ? img.naturalWidth / img.naturalHeight : 1
    const realRatio = _isInvertedAxis(this.props.rotation) ? 1 / ratio : ratio

    this.setState({aspectRatio: realRatio})

    this.props.onLoad && this.props.onLoad(realRatio)
  }

  render() {
    const rotation = this.props.rotation || 1
    const containerWidth = rotation > 3 && (this.props.width === null || this.props.width === 'auto') ? this.props.height * (this.state.aspectRatio || 1) : this.props.width
    const height = this.props.height

    return <div className='orientedImage' style={{...this.props.style, overflow: 'hidden', textAlign: 'center', height, width: containerWidth, maxWidth: '100%', position: 'relative'}}>
      <img {...this.props.attributes} crossOrigin="Anonymous" src={this.props.src ? (this.props.src.startsWith('data:') || this.props.src.startsWith('/')) ? this.props.src : (this.props.src + '?v=2020-06-22') : emptyThumb} alt={this.props.alt || 'image with no description'} title={this.props.title}
        onClick={this.props.onClick}
        onLoad={(e) => this.onImageLoad(e)}
        onError={(e) => this.onImageLoad(e)}
        style={rotation > 3 ? {
          position: 'absolute',
          width: height,
          transformOrigin: 'top left',
          transform: _cssRotation[rotation],
          imageOrientation: "none"
        } : {
          display: 'inline-block',
          height,
          transform: _cssRotation[rotation],
          imageOrientation: "none"
        }}>
      </img>
    </div>
  }
}

export default ManagedOrientedImage
