/**
 * pages/components/about.js
 */

import React from 'react';
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import {faInstagram} from '@fortawesome/free-brands-svg-icons';
import {
  PagesStock101Header, 
  PagesStock101BodyShot, PagesStock101HeadShot,
  PagesStock101Grid1, PagesStock101Grid2,
  PagesStock101Grid3,  PagesStock101Grid4,
  PagesStock101HITW
} from '../img/';
//import core from '../../_core'
import Carousel from 'nuka-carousel'


const onResize = () => {
  const list = window.document.querySelector('.slider-list');
  let largestSlide = Math.max(...[...list.childNodes].map(node => node.querySelector('.slide').offsetHeight))
  list.style.height = largestSlide + 50 +'px';
}

const beforeSlide = (currentSlide, lastSlide) => {
  const list = window.document.querySelector('.slider-list');
  const nextSlide = list.childNodes[lastSlide].querySelector('.slide');
  list.style.height = nextSlide.offsetHeight +50 + 'px';
}


const metaData = {
  description : `Call for Models | Hero Images`,
  keywords :'Hero Images, Stock Photography',
  title: 'Call for Models | Hero Images',
  name : 'Hero Images',
  url : window && window.location && `${window.location.protocol}//${window.location.host}/pages/call-for-models`,
  image : PagesStock101Header,
  TwitterSite : '@heroimages',
  TwitterCard : 'summary',
  ogType : 'article'
}



class PageStock101 extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      hotfix: false
    }
  }

  componentDidMount() {
    this.setState({hotfix: true})
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hotfix !== this.state.hotfix) {
      const list = window.document.querySelector('.slider-list');
      list.style.height = list.childNodes[0].querySelector('.slide').offsetHeight + 50 +'px';
    }
  }

  render() {
    return <section className="full-wd-page page-stock101">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description"           content={metaData.description} />
        <meta name="keywords"              content={metaData.keywords} />

        <meta itemProp="name"              content={metaData.name}/>
        <meta itemProp="description"       content={metaData.description} />
        <meta itemProp="image"             content={metaData.image} />

        <meta name="twitter:card"          content={metaData.TwitterCard} />
        <meta name="twitter:site"          content={metaData.TwitterSite} />
        <meta name="twitter:title"         content={metaData.title} />
        <meta name="twitter:description"   content={metaData.description} />
        <meta name="twitter:creator"       content={metaData.TwitterSite} />
        <meta name="twitter:image"         content={metaData.image}/>

        <meta property="og:url"            content={metaData.url} />
        <meta property="og:type"           content={metaData.ogType} />
        <meta property="og:title"          content={metaData.title} />
        <meta property="og:description"    content={metaData.description} />
        <meta property="og:image"          content={metaData.image} />
      </Helmet>
      <Link to="/product/49922" className="slider" >
        <section className="full-wd-img spacer-height-vh-60" style={{backgroundImage: `url( ${PagesStock101Header})`,  backgroundPosition:'50% 70%'}}>
          <p className="slide__image_credit">238_02489</p>
        </section>
      </Link>

      {/* <section className="full-wd-img spacer-height-vh-70" style={{backgroundImage: `url( ${PagesAboutProcess1})`}}>
        <h1 className="text-light regTextWidth">We spend hours researching trends, both existing and emerging.</h1>
      </section> */}
      <section className="spacer-padding-top-50">
        <h1 className="header-text-width">What is a stock photo? <br/>You see them everyday.</h1>
        <p className="regTextWidth">Stock photos are ready-to-use images often seen in advertising, marketing materials, and even in your social media feed. Every time you open a magazine, see a billboard, or thumb through a brochure, you’re probably looking at stock images. </p>
        <div className="spacer-padding-top-20 HITW-img text-center regTextWidth"><img alt="collage of places where stock photos are being used" src={PagesStock101HITW} /></div>
      </section>  
      <section>  
        <div className="regTextWidth spacer-padding-top-50 spacer-padding-bottom-20"> 
          <h2 className="text-left">Who are the people in stock photos? People just like you. </h2>
          <p>We are always looking for fresh, new faces to use in our shoots. Many of the people we cast have never had their picture taken professionally. Just ask our friends, cousins, co-workers, local barista… you get the picture. People enjoy seeing faces they can relate to. </p>
          <p>As a Hero model, you can look forward to a few fun hours on set with us and you’ll walk away with new friends, some great photos, and money for your time. Interested? Submit your image to <a href="mailto:casting@heroimages.com">casting@heroimages.com</a> and follow our page on <a href="https://www.facebook.com/HeroImages/">Facebook</a> for upcoming casting calls and Hero news. </p>
        </div>
      </section>
    
      <section className="full-wd-section">
        <div className="flex BTS-grid">
          <img alt="on set with photographers taking photos of cowboy riding a horse" src={PagesStock101Grid1}/>
          <img alt="on set with a photographer taking photos of a wedding in a yard" src={PagesStock101Grid2}/>
          <img alt="on set with a photographer taking photos of birthday party in a yard" src={PagesStock101Grid3}/>
          <img alt="on set with a photographer taking photos of group of climbers" src={PagesStock101Grid4}/>
        </div>
      </section>

      <section>
        <div className="regTextWidth spacer-padding-vertical-50"> 
          <h2 className="text-left">How do I become a Hero model? It’s easy!</h2>
          <p>Just grab your phone and choose a few recent pictures or take a couple selfies and send them, along with your age, to <a href="mailto:casting@heroimages.com">casting@heroimages.com</a>. Ideally, we need two pictures of you:</p>
          <div className="flex start spacer-padding-vertical-30 page-stock101_modelExamples">
            <div>
              <img alt="a example model headshot photo" src={PagesStock101HeadShot}/>
              <p className="text-center">1. A picture of you smiling with your teeth showing</p>
            </div>
            <div>
              <img alt="an example model bodyshot photo" src={PagesStock101BodyShot}/>
              <p className="text-center">2. A full body picture</p>
            </div>
          </div>
          <p>As well, we hold open casting calls two to three times per year to meet future Hero models in person. Make sure to follow us on <a href="https://www.facebook.com/HeroImages/">Facebook</a> to keep track of upcoming casting calls and other Hero events.</p>
          <h3 className="text-left"><strong>We look forward to hearing from you!</strong></h3>
        </div>
      </section>

      <h2 className="text-center">Testimonials</h2>

      <section className="full-wd-section page-stock101_testimonials">
        <Carousel
          onResize={onResize}
          beforeSlide={beforeSlide}
          //afterSlide does not fire properly
          swiping={true}
          dragging={true}
          autoplay={true}
          speed={1000}
          autoplayInterval={10000}
          wrapAround={true}
          cellAlign={'center'}
          framePadding={'30px'}
          

          renderCenterLeftControls={({ previousSlide }) => (
            <button className='customButton' onClick={previousSlide}>&lt;</button>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <button className='customButton' onClick={nextSlide}>&gt;</button>
          )}
        >  
          <div className=" slide " >
            <div className="flex column center align-centered">
              <h2 className="max-large-width">“Hero Images is like working with family. They are supportive, kind and very easy to work with. The whole experience is uplifting, professional and fun. Thank you so much.”</h2>
              <p className="text-center spacer-padding-top-20">Caren J.</p>
            </div>
          </div>
          <div className="slide" >
            <div className="flex column center">
              <h2 className="max-large-width">“Thanks for having me on the shoot. You always make us feel like part of the team and everyone is a joy to be around. My wife and I try our best to only have things and do things that add value to our lives… and doing this does. It’s awesome to be around great people.”</h2>
              <p className="text-center spacer-padding-top-20">Doug C.</p>
            </div>
          </div>
          <div className="slide" >
            <div className="flex column center ">
              <h2 className="max-large-width">“I just wanted to send a quick note to say thank you to you and your team. Everyone was so welcoming and accommodating. Both my kids and I had such a wonderful experience. I was so impressed with how well the team worked with the girls, and made the whole shoot feel more like a fun day hanging with friends rather than work. We hope to work with you again in the future.”</h2>
              <p className="text-center spacer-padding-top-20">Melanie G.</p>
            </div>
          </div>
          <div className="slide" >
            <div className="flex column center">
              <h2 className="max-large-width">“Let me just say wow. I just saw the images from our shoot and they are stunning. I wanted to take the time to say thank you to the team at Hero Images for such a wonderful experience. The crew made everyone feel so comfortable during the family shoot and we had an amazing time. Thanks and I hope to work with you again.”</h2>
              <p className="text-center spacer-padding-top-20">Patricia T.</p>
            </div>
          </div>
        </Carousel>
      </section>

      {/* <section className="full-wd-img spacer-height-vh-70" style={{backgroundImage: `url( ${PagesAboutProcess1})`}}>
        <h1 className="text-light regTextWidth">We spend hours researching trends, both existing and emerging.</h1>
      </section> */}


    </section>
  }
}

const instafeedTarget = 'instafeed';
export default PageStock101