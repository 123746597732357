/**
 * src/modules/auth/components/modals/Login.js
 */

import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import core from '../../../_core'
import * as constants from '../../constants'

import RegisterForm from '../RegisterForm'

class Register extends React.Component {
  constructor(props) {
    super(props)

    this.onFreePageNavigate = this.onFreePageNavigate.bind(this)
  }

  onFreePageNavigate(e) {
    if (this.props.hideModal)
      this.props.hideModal();
  }

  render() {
    console.log(this.props)
    return (
      <div className="form__login form__small">
        <RegisterForm {...this.props} onSuccess={this.props.onLoggedIn} onLoggingIn={this.props.onLoggingIn}
          heading="Create account"
          newsletterCheckboxText="Also, give me 25 Free images* a month, along with inspiring newsletters and exciting updates. I can unsubscribe whenever I choose"
          newsletterCheckboxDisclaimerText={<React.Fragment>*From a collection of over 50,000 images. Digital use only. More info <strong><Link to={`/hero-free/`} onClick={this.onFreePageNavigate}>here</Link></strong>. </React.Fragment>}
          submitLabel="Get The Stuff"
          showNewsletterCheckbox={true}
          newsletterCheckboxChecked={false} />
      </div>
    )
  }

}

Register.propTypes = {
  register: PropTypes.func,
  onLoggingIn: PropTypes.func,
  onSuccess: PropTypes.func
}

export default core.components.modals.Modal(Register)