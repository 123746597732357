/**
* @module search
* model.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Thu May 17 2018 14:10:56 GMT-0600 (MDT)
* @copyright None
* @last-modified Thu May 17 2018 14:13:26 GMT-0600 (MDT)
*/


import PropTypes from 'prop-types'

import core from '../_core'
import product from '../product'
import photoshoot from '../photoshoot'

export const Shape = PropTypes.oneOfType([
  PropTypes.arrayOf(product.Shape),
  PropTypes.arrayOf(photoshoot.Shape),
  core.model.ErrorShape,
  core.model.LoadingShape
])

export const generate = () => ([])