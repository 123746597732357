/**
* @module board
* reducer.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Mon Jun 11 2018 09:56:36 GMT-0600 (MDT)
* @copyright None
* @last-modified Fri Jun 22 2018 14:07:03 GMT-0600 (MDT)
*/

import core from '../_core'

import actionTypes from './actionTypes'
import { logout } from '../auth/actionTypes'
import { moduleName } from './constants'
import { generate } from './model'

export const reducer = (state = generate(), action) => {
  const loadingAction = core.actionTypes.loading(moduleName)
  const errorAction = core.actionTypes.error(moduleName)
  let boards

  switch (action.type) {
  case actionTypes.list:
    return {
      data: action.boards,
      info: action.info
    }
  case actionTypes.create:
    return {
      data: [].concat(state.data, [action.board])
    }
  case actionTypes.update:
  case actionTypes.addProduct:
  case actionTypes.removeProduct:
    boards = (state.data || []).map(
      b => (b.id === action.board.id ? action.board : b)
    )

    return {
      data: [].concat(boards),
      info: state.info
    }
  case actionTypes.delete:
    boards = state.data.filter(
      b => (b.id !== action.board.id)
    )
    return {
      data: [].concat(boards)
    }
  case logout:
    return generate()
  case loadingAction:
    return core.model.generateLoading()
  case errorAction:
    return action.state
  default:
    return state
  }
}

export const contextReducer = (state = null, action) => {
  const loadingAction = core.actionTypes.loading(`${moduleName}Context`);
  const errorAction = core.actionTypes.error(`${moduleName}Context`);

  switch (action.type) {
  case actionTypes.get:
  case actionTypes.update:
  case actionTypes.removeProduct:
    return {
      data: action.board,
      info: action.info
    }
  case actionTypes.delete:
    return null
  case loadingAction:
    return core.model.generateLoading()
  case errorAction:
    return action.state
  default:
    return state
  }
}