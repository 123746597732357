/**
 * src/modules/auth/reducer.js
 */

import * as actionTypes from './actionTypes'
import { generate } from './model'

export const reducer = (state = generate(), action) => {
  
  switch (action.type) {
  case actionTypes.login:
  case actionTypes.forgetPassword:
  case actionTypes.register:
  case actionTypes.check:
    return action.auth
  case actionTypes.logout:
  case actionTypes.getPasswordResetToken:
  case actionTypes.verifyPasswordResetToken:
    return generate()
  default:
    return state
  }
}

/**
 * Maintain last attempted operation before logged in
 * @param {*} state
 * @param {*} action
 */
export const operationReducer = (state = null, action) => {
  switch (action.type) {
  case actionTypes.clearOperation:
    return null
  case actionTypes.storeOperation:
    return action.operation
  default:
    return state
  }
}