/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:43:20
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-05-08 14:57:04
 */

import {moduleName} from './constants'


export const getPrice = (prices, id) => {

  if (!Array.isArray(prices)) {
    return prices;
  }

  return prices.find(
    p => p && p.id === Number(id)
  );
}

export const getBundle = (prices, priceId, bundleId) => {
  var price = getEntity(prices, priceId);
  
  return (price ? getEntity(price.bundles, bundleId) : undefined);
}

export const getEntity = (object, id) => {
  return (Array.isArray(object)
    ? object.find(e => e && e.id === Number(id))
    : (object && object.id === Number(id) ? object : undefined)
  );
}