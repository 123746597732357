/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 19:58:42
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-05-22 11:55:45
 */

import core from '../_core'

import * as actionTypes from './actionTypes'
import { logout } from '../auth/actionTypes'
import { moduleName } from './constants'
import {generate, resetCheckoutStatus} from './model'

const reducer = (state = generate(), action) => {
  const loadingAction = core.actionTypes.loading(moduleName);
  const errorAction = core.actionTypes.error(moduleName);

  switch (action.type) {
  case actionTypes.listPrices:
    return Object.assign({}, state, {prices: action.prices});
  case actionTypes.getCoupon:
    return Object.assign({}, state, {coupon: action.coupon});
  case actionTypes.removeCoupon:
    return Object.assign({}, state, {coupon: undefined});
  case actionTypes.getUserProductOwnership:
    return Object.assign({}, state, {userProductOwnership: action.userProductOwnership});
  case actionTypes.addBundleToCart:
    return Object.assign({}, state, {bundleCart: [action.cartItem]});
  case actionTypes.addToCart:
    return addToCart(state, action);
  case actionTypes.addToDownloadCart:
    return Object.assign({}, state, {downloadCart: action.items, selectedPrice: action.price})
  case actionTypes.removeFromCart:
    return removeFromCart(state, action);
  case actionTypes.clearCart:
    return Object.assign({}, state, {cart: []});
  case actionTypes.removeJustAdded:
    return Object.assign({}, state, {itemJustAdded: false});
  case actionTypes.checkoutStarted:
    return Object.assign({}, state, {checkoutStatus: {status: 'sent'}});
  case actionTypes.checkoutComplete:
    return Object.assign({}, state, {checkoutStatus: action.checkoutResponse});
  case actionTypes.clearCheckout:
    return Object.assign({}, state, {checkoutStatus: resetCheckoutStatus()});
  case actionTypes.addMultipleToCart: 
    console.log("%c HERE", "background-color:yellow", action.products)
    return Object.assign({}, state, {multipleAddToCart: action.products});
  case actionTypes.addGalleryToCart:
      return Object.assign({}, state, {galleryCart: [action.cartItem]});
  case actionTypes.removePersistentCoupon:
    if (state.coupon && state.coupon.users)
      return Object.assign({}, state, {coupon: undefined});
    return state;
  case logout:
    return Object.assign({}, state, {coupon: null, prices: state.prices.map(price => {price.credits = []; return price})});
  case errorAction:
    return action.state
  default:
    return state
  }
}

const addToCart = (state, action) => {
  
  let retVal = Object.assign({}, state);
  retVal.cart = [...state.cart];

  for(var i = 0; i < retVal.cart.length; i++) {
    if(retVal.cart[i].product.id == action.cartItem.product.id) {
      retVal.cart.splice(i, 1);
      i--;
    }
  }

  retVal.cart.push(Object.assign({}, action.cartItem));
  retVal.itemJustAdded = true;
  return retVal;
}

const removeFromCart = (state, action) => {
  
  let retVal = Object.assign({}, state);
  retVal.cart = [...state.cart];

  for(var i = 0; i < retVal.cart.length; i++) {
    if(retVal.cart[i].product.id == action.cartItem.product.id) {
      retVal.cart.splice(i, 1);
      i--;
    }
  }

  retVal.itemJustAdded = true;
  return retVal;
}


export default reducer
