/**
 * src/modules/auth/components/Login.js
 */

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import core from '../../../_core'

import * as actions from '../../actions'
import * as constants from '../../constants'
import * as selectors from '../../selectors'
import * as model from '../../model'

import modals from '../modals'
import auth from '../..';
import { showModal } from '../../../_core/actionTypes';

class AuthContainer extends React.Component {
  constructor(props) {
    super(props)

    this.onLoggingIn = this.onLoggingIn.bind(this)
    this.onLoggedIn = this.onLoggedIn.bind(this)
    this.onForgetPassword = this.onForgetPassword.bind(this)
    this.onForgetPasswordSuccess = this.onForgetPasswordSuccess.bind(this)
    this.onRegister = this.onRegister.bind(this)
    this.onRegisterComplete = this.onRegisterComplete.bind(this)
  }

  componentDidMount() {
    
    let token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1");
    if (token) {
      document.cookie = "token=;path=/"
    }
    else {
      token = localStorage.getItem('id_token')
    }

    if (token) {
      this.props.check(token)
        .then(
          () => this.onLoggedIn()
        )
    }
    else if (this.props.auth && this.props.auth.token) {
      this.props.check(this.props.auth.token)
        .then(
          () => this.onLoggedIn()
        )
    }
  }

  onLoggedIn() {
    const { isAuthenticated, storedOperation, showModal, clearOperation } = this.props;

    if (isAuthenticated) {
      if (storedOperation && storedOperation.type === 'showModal') {
        showModal({id: storedOperation.id})
        clearOperation()
      } 
      else {
        //this.props.history.push('/')
      }
    }
  }



  onLoggingIn(e) {
    e.preventDefault();
    const { showModal } = this.props;
    showModal({id: constants.modals.login});
  }

  onForgetPassword(e) {
    e.preventDefault();
    const { showModal } = this.props;
    showModal({id: constants.modals.forgetPassword});
  }

  onRegister(e) {
    e.preventDefault();
    const { showModal } = this.props;
    showModal({id: constants.modals.register});
  }

  onForgetPasswordSuccess() {
    this.props.hideModal();
  }

  onRegisterComplete(e) {
    e.preventDefault();
    
    const { showModal } = this.props;
    showModal({ id: auth.constants.modals.registerComplete});

    this.onLoggedIn(e);
  }
  

  render() {
    return (
      <section>
        <modals.Login {...this.props} id={constants.modals.login} onSuccess={this.onLoggedIn} onForgetPassword={this.onForgetPassword} onRegister={this.onRegister}/>
        <modals.ForgetPassword {...this.props} id={constants.modals.forgetPassword} onSuccess={this.onForgetPasswordSuccess} onLoggingIn={this.onLoggingIn}/>
        <modals.Register {...this.props} id={constants.modals.register} onSuccess={this.onLoggedIn} onLoggingIn={this.onLoggingIn}/>
        <modals.RegisterComplete {...this.props} id={constants.modals.registerComplete} />
      </section>
    )
  }
}

AuthContainer.propTypes = {
  auth: model.Shape,
  isAuthenticated: PropTypes.bool,
  invitationToken: PropTypes.string,
  passwordToken: PropTypes.string,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
}

const stateToProps = (state) => ({
  auth: selectors.getAuth(state),
  storedOperation: selectors.getOperation(state),
  isAuthenticated: selectors.getUserAuthToken(state) !== null,
  user: selectors.getAuth(state) ? selectors.getAuth(state).user : {}
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    ...actions,
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(withRouter(AuthContainer))