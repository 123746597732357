import React from 'react';
import Instafeed from 'react-instafeed';
import { Instagram_userId, Instagram_clientId, Instagram_accessToken } from '../../_core/constants'

const instafeedTemplate = `<li><a href='{{link}}' target='_blank' class='instafeed__item'  style='background-image:url("{{image}}")'><span></span></a></li>`
const instafeedTarget = 'instafeed';

class Instagram extends React.Component {

  componentDidMount() {

  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div>
        <Instafeed
          limit='6'
          ref={this.ref}
          resolution='standard_resolution'
          sortBy='most-recent'
          target={instafeedTarget}
          template={instafeedTemplate}
          userId={Instagram_userId}
          clientId={Instagram_clientId}
          accessToken={Instagram_accessToken}
        />

        {this.props.children}

      </div>
    )
  }
}
export default Instagram;