/**
* @module gallery/components
* Gallery.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Thu May 31 2018 09:27:06 GMT-0600 (MDT)
* @copyright None
* @last-modified Wed Jun 20 2018 11:22:22 GMT-0600 (MDT)
*/

import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons'

import core from '../../_core'
import auth from '../../auth'
import shoppingcart from '../../shoppingcart'

import * as actions from '../actions'
import components from '../components'
import * as selectors from '../selectors'
import {Shape} from '../model'

const Gallery = (props) => {
  const { data, showModal, auth, removeProduct } = props
  const thumbnail = data && data.products && data.products.length > 0 && data.products[0].thumbnailUrl

  const _onShare = (e) => {
    e.preventDefault()
    showModal({id: core.constants.modals.share});
  }

  const _onRemoveGalleryProduct = (productId) => removeProduct(data.id, productId, auth)

  let metaData = {}
  if(data)
    metaData = {
      description : data.title + " | Hero Images",
      keywords : 'Hero Images, Gallery, Stock Photography',
      title: `${data.title} | Hero Images`,
      name : 'Hero Images',
      url : window && window.location && `${window.location.protocol}//${window.location.host}${props.location.pathname}`,
      image : data.products && data.products.length > 0 ? data.products[0].thumbnailUrl : null,
      TwitterSite : '@heroimages',
      TwitterCard : 'summary',
      ogType : 'article'
    }

  return (
    <section className="gallery__wrapper">
      {(!data || (core.pageStates && data.state === core.pageStates.loading)) ? 
        <h1 className="loadingIcon"><FontAwesomeIcon  icon={faSpinnerThird} spin /></h1> 
        :
        <section>
          <Helmet>
            <title>{metaData.title}</title>
            
            <meta name="robots" content="noindex" />

            <meta name="description"           content={metaData.description} />
            <meta name="keywords"              content={metaData.keywords} />

            <meta itemProp="name"              content={metaData.name}/>
            <meta itemProp="description"       content={metaData.description} />
            <meta itemProp="image"             content={metaData.image} />

            <meta name="twitter:card"          content={metaData.TwitterCard} />
            <meta name="twitter:site"          content={metaData.TwitterSite} />
            <meta name="twitter:title"         content={metaData.title} />
            <meta name="twitter:description"   content={metaData.description} />
            <meta name="twitter:creator"       content={metaData.TwitterSite} />
            <meta name="twitter:image"         content={metaData.image}/>

            <meta property="og:url"            content={metaData.url} />
            <meta property="og:type"           content={metaData.ogType} />
            <meta property="og:title"          content={metaData.title} />
            <meta property="og:description"    content={metaData.description} />
            <meta property="og:image"          content={metaData.image} />
          </Helmet>

          <core.components.modals.Share
            {...props}
            id={core.constants.modals.share}
            url={window.location.href}
            media={thumbnail !== false ? thumbnail : ''}
            title={`Gallery: ${data.title} | Hero Images`}
            description={`Checkout this gallery from Hero Images`}
          />
          <shoppingcart.components.containers.AddMultipleToCart />
          <components.containers.Single
            {...props}
            data={data}
            onShare={_onShare}
            onRemoveGalleryProduct={ _onRemoveGalleryProduct}
          />
          
        </section>
      }
    </section>
  )
}


Gallery.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(Shape),
    core.model.ErrorShape,
    core.model.LoadingShape
  ])
}

const stateToProps = (state, props) => {
  const stateData = selectors.getGallery(state, props.match.params.id)

  return {
    ...stateData,
    auth: auth.selectors.getUserAuthToken(state),
  }
}

const dispatchToProps = (dispatch,props) => (
  bindActionCreators({
    ...actions,
    loadFromServer: () => actions.get(props.id ? props.id : props.match.params.id),
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal,
    showAddMultipleToCartModal: (products) => shoppingcart.actions.showAddMultipleToCartModal(products),
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(
  withRouter(
    core.components.withLoading(
      core.components.withError(Gallery)
    )
  )
)