import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSync } from '@fortawesome/pro-light-svg-icons'
import {ProductIcon_DownloadComp} from '../../product/img/';
import {Icon_ImageBundle_wt} from '../../shoppingcart/img/';

import Thumb from '../../product/components/Thumb'

class OrderedItems extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isDownloading: false
    };

    this.onDownloadClick = this.onDownloadClick.bind(this)
  }

  onDownloadClick(e) {
    e.preventDefault();
    //{core.constants.apiHost + '/order/download/' + lineItem.id}
    this.props.onDownloadImage(this.props.lineItem.product.fileName, this.props.lineItem.product.id, this.props.authToken)

    this.setState ({
      isDownloading: true
    });
    e.target.disabled = true;
  }

  render() {

    const {
      lineItem,
      orderType
    } = this.props;

    const product = lineItem.product

    return (
      <div className="order-row">
        <div className="order-cell order-cell-img">
          <Thumb
            key={`product-${product.id}`}
            data={product}
            className={'productDetail'}
            isThumbActive={!product.isRestricted}
            hideCaption={true}
            routerPath={`/product/${product.id}`}
            isTiny={true} />
        </div>
        <div className="order-cell order-cell-download">
          {
            product && !product.isRestricted && !this.props.downloading.includes(product.id) &&
            <a href="#" className="link-icon" onClick={this.onDownloadClick}> <img alt="download-icon" src={ProductIcon_DownloadComp}/></a>
          }
          {
            product && !product.isRestricted && this.props.downloading.includes(product.id) &&
            <FontAwesomeIcon className="icon fa-spin" icon={faSync} />
          }
          {
            product && product.isRestricted &&
            <span>Image no longer available</span>
          }
        </div>
        <div className="order-cell order-cell-description">
          {product.fileName}<br />
          {orderType == 'credit' ? "Redeemed with " + lineItem.credit.price.name + " Bundle"  : "Royalty-Free Download" }
          <br />
          {orderType == 'credit' ?
            (product.type === 'video' && lineItem.credit.bundleClass === 'Print + Video' ? 'Video @ highest res available' : lineItem.credit.price.name + " @ " + lineItem.credit.price.specs.split(" by ")[0])
            : lineItem.price.name + " @ " + (lineItem.price.productType === 'video' ? 'highest res available' : lineItem.price.specs.split(" by ")[0])
          }
        </div>
        <div className="order-cell order-cell-total"> { orderType == 'credit' ?
          <React.Fragment><img alt="bundle-credit-icon" src={Icon_ImageBundle_wt} className='bundle_credit_icon' />1</React.Fragment>
          : <span className={'enMoney ' + this.props.currency || 'USD'}>{ lineItem.pricePaid.toFixed(2)}</span>
        } </div>
      </div>
    )
  }
}

export default OrderedItems