/**
 * src/modules/auth/components/modals/Login.js
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import core from '../../_core'
import * as actions from '../actions'
import { postToHubspot } from '../../_core/hubspot'

class Subscribe extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      EMAIL: '',
      FNAME: ''
    }

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.handleChangeChk = this.handleChangeChk.bind(this);
    this.postToHubspotInternal = this.postToHubspotInternal.bind(this);
  }


  validate(formField) {
    const validator = formField.dataset['validator']

    // If there's no validator configured, make it valid
    formField.dataset['valid'] = validator ? core.util.validate(validator, formField.value) : true
  }

  onChange(e) {
    const newState = {}

    newState[e.target.name] = e.target.value
    this.setState(newState)
    this.validate(e.target)
  }

  onSubmit() {
    this.props.onSubscribed()

    document.getElementById('subscribe-popup').submit()
  }


  handleChangeChk(){

    this.state.consent = !this.state.consent;
    
  }

  postToHubspotInternal(){

    console.log("POSTING TO HUBSPOT")

    this.props.onSubscribed();

    postToHubspot(true,this.state.EMAIL, "Subscribe Popup",this.state.FNAME,"","");

  }

  render() {
    return (
      <form id='subscribe-popup' className="modal-subscribe" action="https://heroimages.us12.list-manage.com/subscribe/post" method="POST" noValidate>
        <input type="hidden" name="u" readOnly value="476f5c6150cb8b986c4a4a116"/>
        <input type="hidden" name="id" readOnly value="9da4b7d233"/>
        <input type="hidden" name="SOURCE" id="SOURCE" readOnly value="popup" />
        <h3>Like seeing what&apos;s fresh and getting great deals?</h3>
        <span>
          <h4>Subscribe now and get both straight to your inbox.</h4>
        </span>
        
        <div className='row'>
          <label htmlFor="mce-EMAIL">Email Address</label>
          <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" value={this.state.EMAIL} onChange={this.onChange} required data-validator="email"/>
        </div>
        <div className='row'>
          <label htmlFor="mce-FNAME">First Name</label>
          <input type="text" value={this.state.firstName} name="FNAME" id="mce-FNAME" onChange={this.onChange} />
        </div>
        <div style={{textAlign: 'center'}}>
          <button type="button" disabled={!this.state.EMAIL} onClick={this.postToHubspotInternal}>Subscribe</button>
        </div>

        <div style={{position: 'absolute', left: '-5000px'}} aria-hidden='true' aria-label="Please leave the following three fields empty">
          <label htmlFor="mce-EMAIL2">Email: </label>
          <input type="email" name="b_476f5c6150cb8b986c4a4a116_0284ccea97"tabIndex="-1" defaultValue="" placeholder="youremail@gmail.com" id="mce-EMAIL2"/>
        </div>
 

      </form>
    )
  }
}

Subscribe.propTypes = {
  subscribe: PropTypes.func
}

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    subscribe: actions.subscribe
  }, dispatch)
)

export default connect(null, dispatchToProps)(
  core.components.modals.Modal(Subscribe)
)