/**
* @module gallery
* actions.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue May 29 2018 10:38:29 GMT-0600 (MDT)
* @copyright None
* @last-modified Wed Jun 20 2018 13:25:39 GMT-0600 (MDT)
*/

import core from '../_core'
import auth from '../auth'
import flash from '../flash'

import { moduleName } from './constants'
import actionTypes from './actionTypes'
import errorTypes from './errorTypes'
import Service from './service'

const _renameSingleProductsArray = (data) => {
  data.products = data.galleryProducts
  data.galleryProducts = undefined
  return data
}

const _compileResponseData = (responseData) => {
  const gallery = _renameSingleProductsArray(responseData.data)
  const info = responseData.info
  return {gallery, info}
}

export const setTailoredView = (isTailored) => (dispatch) => {
  return dispatch({
    type: actionTypes.setTailoredView,
    isTailored
  })
}

export const list = ({offset, limit, isTailored, onlyFeatured} = {}, authToken, service = new Service()) => (dispatch) => {

  dispatch(core.actions.onLoading(moduleName))

  return service.list({offset, limit, isTailored, onlyFeatured}, authToken)
    .then(
      (response) => {
        let galleries = response.data.data.map(
          gallery => _renameSingleProductsArray(gallery)
        )
        let info = response.data.info
        return {
          galleries,
          info
        }
      },
      (error) => {
        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.list)));
        dispatch(core.actions.onError(moduleName, error));
      }
    )
    .then(
      (data) => dispatch(onList(data))
    )
}

export const get = (id, service = new Service()) => (dispatch) => {
  dispatch(core.actions.onLoading(`${moduleName}Context`))

  return service.get(id)
    .then(
      //(response) => dispatch(onGet(response.data.data, response.data.info)),
      (response) => {
        response.data.data.relatedGalleries = response.data.data.relatedGalleries ?
          response.data.data.relatedGalleries.map( relatedGallery => {
            const gdata  = _renameSingleProductsArray(relatedGallery)
            return gdata
          }
          ) : []
        const data  = _renameSingleProductsArray(response.data.data)
        const info = response.data.info
        return {data, info}
      },
      (error) => {
        !error.toString().includes('404') && dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.list)));
        dispatch(core.actions.onError(moduleName, error));
      }
    )
    .then(
 
      (response) => {
        response && response.data &&
        dispatch(onGet(response.data, response.info))
      }

    )
}

export const addProduct = (id, productId, token, service = new Service()) => (dispatch) => {
  if (!token) {
    return dispatch(core.actions.showModal({id: auth.constants.modals.login}));
  }

  return service.addProduct(id, productId, token)
    .then(
      response => _compileResponseData(response.data)
    )
    .then(
      ({gallery, info}) => {
        dispatch({
          type: actionTypes.addProduct,
          gallery,
          info
        })
        return gallery
      },
      (error) => {
        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.list)));
        dispatch(core.actions.onError(moduleName, error));
      }
    )
}

export const removeProduct = (id, productId, token, service = new Service()) => (dispatch) => {
  if (!token) {
    return dispatch(core.actions.showModal({id: auth.constants.modals.login}));
  }

  return service.removeProduct(id, productId, token)
    .then(
      response => {console.log(response.data.data.products, response.data.data.galleryProducts); return _compileResponseData(response.data)}
    )
    .then(
      ({gallery, info}) => dispatch({
        type: actionTypes.removeProduct,
        gallery,
        info
      }),
      (error) => {
        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.list)));
        dispatch(core.actions.onError(moduleName, error));
      }
    )
}

export const getFeaturedProduct = (productId, authToken, service = new Service()) => (dispatch) => {
  dispatch(core.actions.onLoading(moduleName))

  return service.getFeaturedProduct(productId, authToken)
    .then(
      (response) => {
        let data = response.data.data
        let info = response.data.info
        return {
          data,
          info
        }
      },
      (error) => {
        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.list)));
        dispatch(core.actions.onError(moduleName, error));
      }
    )
}

export const onGet = (gallery, info) => ({
  type: actionTypes.get,
  gallery,
  info
})

export const onList = (data) => {
  const galleries = data.galleries || data
  const info = data.info || {}

  return {
    type: actionTypes.list,
    galleries,
    info
  }
}