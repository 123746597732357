/**
* @module search
* errorTypes.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Thu May 17 2018 13:44:50 GMT-0600 (MDT)
* @copyright None
* @last-modified Thu May 17 2018 13:44:50 GMT-0600 (MDT)
*/

export const search = {500: 'Unexpected server error'}
