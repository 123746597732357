/**
* @module gallery
* index.js
*
* @description A gallery is a collection of products put together by the Hero Images team.
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Mon Apr 30 2018 10:51:58 GMT-0600 (MDT)
* @copyright None
* @last-modified Tue May 29 2018 13:17:42 GMT-0600 (MDT)
*/

import * as actions from './actions'
import actionTypes from './actionTypes'
import components from './components'
import * as constants from './constants'
import * as errorTypes from './errorTypes'
import * as model from './model'
import pages from './pages'
import { reducer, contextReducer } from './reducer'
import Routes from './routes'
import * as selectors from './selectors'
import Service from './service'
import './gallery.scss'

export default {
  actions,
  actionTypes,
  components,
  constants,
  errorTypes,
  model,
  pages,
  reducer,
  contextReducer,
  Routes,
  selectors,
  Service,
  Shape: model.Shape
}