import React from 'react'
import PropTypes from 'prop-types'
// import { Helmet } from 'react-helmet';
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import OrderedItems from '../components/OrderedItems'
import CreditHistoryItem from '../components/CreditHistoryItem'
import html2pdf from 'html2pdf.js'

import core from '../../_core'
import auth from '../../auth'
import HeroLogo from '../../layout/img/logo.svg'

import * as userActions from '../actions'
import * as selectors from '../selectors'

function onNextFrame(callback) {
  window.setTimeout(function () {
      window.requestAnimationFrame(callback)
  })
}

class Invoice extends React.Component {

  constructor(props){
    super(props);

    this.state = {
    };

    this.onDownloadClick = this.onDownloadClick.bind(this)
  }

  onDownloadClick(e) {
    e.preventDefault();
    
    if (this.props.location && this.props.location.search.includes("downloader")) {
      onNextFrame(() => {
        var opt = {
          margin: 0.75,
          pagebreak: { mode: 'css' },
          filename: `invoice-${this.props.order.orderNumber}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2, useCORS: true},
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        
        html2pdf()
          .from(document.getElementById('invoice'))
          .set(opt)
          .save();
      });
    }
    else {
      // invokes an external browser service which ensures a consistent rendering of the document
      this.props.downloadInvoice(this.props.order.id, this.props.order.orderNumber, this.props.auth)
    }
  }


  render(){
    const {
      order,
      downloading,
      downloadImage,
      auth
    } = this.props;

    const isPaymentSuccess = this.props.location && this.props.location.search.includes("success") && order.orderType != 'credit'
    const returnLocation = this.props.location && this.props.location.state && this.props.location.state.return ? this.props.location.state.return : undefined
   
    return(
      <section id="invoice" className="full-wd-page view-cart">
        <div className='order-title'>
          <h1>Invoice #{order.orderNumber}</h1>
          <img src={HeroLogo} alt="Hero Images" className="logo" />
        </div>
        <h2 id="notifications">
          {isPaymentSuccess && <span>Payment Successful!</span>}
          {isPaymentSuccess && returnLocation && <br />}
          {returnLocation && <Link className="button light-gray capitalize back-button" to={returnLocation}>Return to {returnLocation.startsWith('/order') ? 'Past Purchases' : 'Shopping'}</Link>}
        </h2>
        {order.id && <div id="print-button" className="text-center">
          <button onClick={this.onDownloadClick} className="button light-gray capitalize">Download PDF</button>
        </div>}
        <section className="flex align-baseline justified spacer-padding-top-20">
          {order.id && 
          <section className="flex space-around regTextWidth page__invoice__flexwrapper">
            <div className="section-title">
              <h3>Order Summary</h3>
            </div>

            
            <div className="summary">
              <div className="summary-left">
                <div>Order Date:</div>
                {order.subtotalUSD >= 0 && <div>Subtotal:</div>}
                {order.discountUSD > 0 && <div>Discount:</div>}
                {order.federalTaxLabel && <div>{order.federalTaxLabel}:</div>}
                {order.regionalTaxLabel && <div>{order.regionalTaxLabel}:</div>}
                <div>Total:</div>
                <div>Payment Method:</div>
              </div>
              <div className="summary-right" style={{textAlign: 'right'}}>
                <div>{ core.util.formatDate(order.createdAt, core.constants.dateFormatWithTime)}</div>
                {order.subtotalUSD >= 0 && <div><span className={'enMoney ' + order.currency || 'USD'}>{ order.subtotalUSD.toFixed(2) }</span></div>}
                {order.discountUSD > 0 && <div>(<span className={'enMoney ' + order.currency || 'USD'}>{order.discountUSD.toFixed(2)}</span>)</div>}
                {order.federalTaxLabel && order.federalTaxUSD >= 0 && <div><span className={'enMoney ' + order.currency || 'USD'}>{order.federalTaxUSD.toFixed(2)}</span></div>}
                {order.regionalTaxLabel && order.regionalTaxUSD >= 0 && <div><span className={'enMoney ' + order.currency || 'USD'}>{order.regionalTaxUSD.toFixed(2)}</span></div>}
                <div><span className={'enMoney ' + order.currency || 'USD'}>{order.totalUSD.toFixed(2)}</span></div>
                {order.isInvoice ? <div>Invoice</div> : <div>{order.ccBrand} ************{order.ccLast4}</div>}
              </div>
            </div>
            
            <div className="summary collapse-on-small">
              <div className="summary-left">
                <div className="section-title">
                  <h3>Billing info</h3>
                </div>
                <div>{order.firstName + ' ' + order.lastName}</div>
                {order.company &&
                <div>{order.company}</div>
                }
                <div>{order.address1}</div>
                {order.address2 &&
                <div>{order.address2}</div>
                }
                <div>{order.city + ', ' + order.region + ' ' + order.zipPostal}</div>
                <div>{order.country}</div>
              </div>
              <div className="summary-right">
                <div className="section-title">
                  <h3>Order notes</h3>
                </div>

                <div className="summary" style={{marginBottom: 0}}>
                  <div className="summary-left" style={{minWidth: '150px'}}>
                    <div>Project:</div>
                  </div>
                  <div className="summary-right text-right" style={{paddingLeft: 5}}>
                    <div>{order.project ? order.project.replace(/-/g, "\u2011") : "N/A"}</div>
                  </div>
                </div>
                <div className="summary" style={{marginBottom: 0}}>
                  <div className="summary-left" style={{minWidth: '150px'}}>
                    <div>PO#:</div>
                  </div>
                  <div className="summary-right text-right" style={{paddingLeft: 5}}>
                    <div style={{ whiteSpace: 'nowrap'}}>{order.poNumber ? order.poNumber.replace(/ \//g, "\u00A0/") : "N/A"}</div>
                  </div>
                </div>
                <div className="summary" style={{marginBottom: 0}}>
                  <div className="summary-left" style={{minWidth: '150px'}}>
                    <div>Licence owner:</div>
                  </div>
                  <div className="summary-right text-right" style={{paddingLeft: 5}}>
                    <div>{order.licenceOwner ? order.licenceOwner.replace(/-/g, "\u2011") : "N/A"}</div>
                  </div>
                </div>
                {order.isInvoice && <div className="summary" style={{marginBottom: 0}}>
                  <div className="summary-left" style={{minWidth: '150px'}}>
                    <div>Purchaser&apos;s Name:</div>
                  </div>
                  <div className="summary-right text-right" style={{paddingLeft: 5}}>
                    <div>{order.purchaserName.replace(/ \//g, "\u00A0/") || "N/A"}</div>
                  </div>
                </div>}
              </div>
            </div>

            <div className="summary">
              <div className="summary-left">
                <div className="section-title">
                  <h3>Billing info</h3>
                </div>
                <div className="order-summaries">
                  <div className="order-row">
                    <div className="order-cell order-cell-img order-header">Image:</div>
                    <div className="order-cell order-cell-download order-header"></div>
                    <div className="order-cell order-cell-description order-header">Description:</div>
                    <div className="order-cell order-cell-total order-header">Price:</div>
                  </div>
                  
                  {order.lineItems.map(lineItem =>
                    <OrderedItems key={lineItem.id} lineItem={lineItem} downloading={downloading} onDownloadImage={downloadImage} authToken={auth} orderType={order.orderType} currency={order.currency} />
                  )}
                  {order.creditHistory.map(historyItem => historyItem.productId ?
                    <OrderedItems key={historyItem.id} lineItem={historyItem} downloading={downloading} onDownloadImage={downloadImage} authToken={auth} orderType={order.orderType} currency={order.currency} />
                    : <CreditHistoryItem key={historyItem.id} currency={order.currency} {...historyItem} />
                  )}
                </div>
                <div className="receipt-footer">
                  <p>
                    {order.isInvoice && <React.Fragment><span>Please pay invoices within 30 days.</span><br /></React.Fragment>}
                    <span style={{fontSize: "smaller"}}>*GST Number: 83355 3084 RT0001</span>
                  </p>
                  <hr style={{borderWidth: ".5px", borderBottom: 0}} />
                  <p className='text-center hero-address'>400, 119 14th St NW Calgary, AB, Canada T2N 1Z6 | © Hero Images, All rights reserved.</p>
                </div>
              </div>
            </div>

          </section>
          }
        </section>
      </section>
    )
  }
}

Invoice.propTypes = {
  history: PropTypes.object
}

function stateToProps(state)
{
  return {
    order: state.order.order,
    downloading: state.order.downloading,
    auth: auth.selectors.getUserAuthToken(state),
  }
}

const dispatchToProps = (dispatch, props) => (
  bindActionCreators({
    ...userActions,
    loadFromServer: (auth) => userActions.getOrder(props.match.params.id, auth)
  }, dispatch)
)



export default connect(stateToProps, dispatchToProps)(
  withRouter(
    core.components.withLoading(Invoice, ['auth'])
  )
)
