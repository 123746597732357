/**
 * src/modules/auth/components/modals/ShoppingCartError.js
 */

import React from 'react'

import core from '../../../_core'


const ShoppingCartError = (props) => {
  return (
    <div className="form__retrievePasswor">
      <h2>Payment Error</h2>
      {props.errorString}
    
      <div style={{marginTop: '20px'}}>
        <button  onClick={() => props.hideModal()}>Close </button>
      </div>
    </div>
  )
}

export default core.components.modals.Modal(ShoppingCartError)