/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-17 15:14:50
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-18 20:00:28
 */

import * as actions from './actions'
import * as actionTypes from './actionTypes'
import components from './components'
import * as constants from './constants'
import * as errorTypes from './errorTypes'
import * as model from './model'
import pages from './pages'
import { reducer, contextReducer } from './reducer';
import Routes from './routes'
import * as selectors from './selectors'
import Service from './service'
import './photoshoot.scss'

export default {
  actions,
  actionTypes,
  components,
  constants,
  errorTypes,
  model,
  pages,
  reducer,
  contextReducer,
  Routes,
  selectors,
  Service,
  Shape: model.Shape
}