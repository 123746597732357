/**
* @module board/components/containers
* index.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Mon Jun 11 2018 09:56:36 GMT-0600 (MDT)
* @copyright None
* @last-modified Mon Jun 11 2018 09:56:36 GMT-0600 (MDT)
*/

import Board from './Board'
import List from './List'
import Single from './Single'

export default {
  Board,
  List,
  Single
}