/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-17 14:40:09
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-06-21 10:45:49
 */


import PropTypes from 'prop-types';

import core from '../_core'

export const ProductShape = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  compUrl: PropTypes.string,
  fileName:PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  verticalCategory: PropTypes.string,
  modelAgeRange: PropTypes.string,
  peopleCount: PropTypes.number,
  photoshoots: PropTypes.array,
  galleries: PropTypes.array,
  createdAt: PropTypes.instanceOf(Date),
  collectionType: PropTypes.string
})

export const Shape = PropTypes.oneOfType([
  PropTypes.arrayOf(ProductShape),
  ProductShape,
  core.model.ErrorShape,
  core.model.LoadingShape
])

export const generate = ({
  id = undefined,
  title = undefined,
  thumbnailUrl = undefined,
  compUrl = undefined,
  fileName = undefined,
  description = undefined,
  type = undefined,
  verticalCategory = undefined,
  modelAgeRange = undefined,
  peopleCount = undefined,
  photoshoots = undefined,
  galleries = undefined,
  createdAt = undefined,
  collectionType = undefined
} = {}) => ({
  id,
  title,
  thumbnailUrl,
  compUrl,
  fileName,
  description,
  type,
  verticalCategory,
  modelAgeRange,
  peopleCount,
  photoshoots,
  galleries,
  createdAt,
  collectionType
})