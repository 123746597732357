/**
 * src/modules/filter/components/Tag.js
 *
 * Tag component that is displayed in the search/photoshoot results header
 */

import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

const Tag = ({category, filter, onClick, filterName}) => {
  return (
    <span
      className="filter__header__tag"
      onClick={() => {onClick(category, filter, false)}}
    >{category === 'photoshootId' ? `Shoot '${filterName}'`: category === 'type' && filter === 'Image' ? 'Stills' : category === 'duration' ? `Duration:${filter}` : filter} <FontAwesomeIcon icon={faTimes} /></span>
  )
}

Tag.propTypes = {
  category: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired  
}

export default Tag