/**
* @module filter
* helpers.js
*
* @description Helper functions for this module
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Thu May 17 2018 13:07:26 GMT-0600 (MDT)
* @copyright None
* @last-modified Thu Jul 12 2018 12:50:54 GMT-0600 (MDT)
*/

/**
 * Takes a nested filters object (categories and filters booleans), unwraps it
 * and constructs a string of query parameters to append to a GET request
 * Example:
 *   {gender: {Male: true, Female: false}, age: {Baby: true, Child: false}} ->
 *   'gender[]=Male&baby[]=Baby'
 * @param {object} filters
 * @returns {string}
 */
export const filtersToParams = (filters = {}) => (
  Object.keys(filters).reduce(
    (query, category) => {
      return query.concat(
        Object.keys(filters[category])
          .filter(filter => filters[category][filter])
          .map(filter => `${category}[]=${category === 'duration' ? encodeURIComponent(`{"${filter}":${filters[category][filter]}}`) : filter}`)
      )
    }, [])
    .join('&')
)

export const filtersToArray = filters => (
  Object.keys(filters).reduce(
    (array, category) => (
      array.concat(
        Object.keys(filters[category])
          .filter(
            filter => filters[category][filter]
          )
          .map(
            filter => ({category, filter})
          )
      )
    ),
    []
  )
)