/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-22 12:43:27
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-07-05 16:27:31
 */

import PropTypes from 'prop-types'

import { defaultLimit } from './constants'

const setHidden = window.innerWidth > 1024 ? false : true

export const generate = ({
  filters = {},
  terms = '',
  modelIds = null,
  areFiltersHidden = setHidden,
  sort = 'relevance',
  pagination = {
    offset: 0,
    limit: defaultLimit
  }
} = {}) => ({
  filters,
  terms,
  modelIds,
  areFiltersHidden,
  sort,
  pagination
})

/**
 * Reset filters within categories to unselected (false) state
 * @param {object} filters
 * @return {object}
 */
export const resetFilters = (filters = {}) => (
  
  Object.keys(filters).reduce(
    (newFilters, category) => {
      newFilters[category] = Object.keys(filters[category]).reduce(
        (categoryFlags, filter) => {
          categoryFlags[filter] = false
          return categoryFlags
        },
        {}
      )
      return newFilters
    },
    {}
  )
)

export const Shape = PropTypes.object