/**
* @module search
* index.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Thu May 17 2018 09:55:08 GMT-0600 (MDT)
* @copyright None
* @last-modified Thu May 17 2018 15:15:58 GMT-0600 (MDT)
*/

import * as actions from './actions'
import actionTypes from './actionTypes'
import components from './components'
import * as constants from './constants'
import reducer from './reducer'
import Routes from './routes'
import * as selectors from './selectors'
import './search.scss'

export default {
  actions,
  actionTypes,
  components,
  constants,
  moduleName: constants.moduleName,
  reducer,
  Routes,
  selectors
}