/**
 * src/modules/_core/constants.js
 */

/* eslint no-undef: "off" */

export const moduleName = 'core'

export const timeout = 80000
export const redirectTimeout = 10000
export const apiHost = process.env.REACT_APP_API_HOST

// todo: all of these env variables need to be defined in the dev.azure / pipeline / library / variable group

//Social Media
export const twitterUrl = process.env.REACT_APP_TWITTER_URL || 'https://twitter.com/heroimages'
export const facebookUrl = process.env.REACT_APP_FACEBOOK_URL || 'https://www.facebook.com/HeroImages/'
export const instagramUrl = process.env.REACT_APP_INSTAGRAM_URL || 'https://www.instagram.com/heroimages/'
export const linkedinUrl = process.env.REACT_APP_LINKEDIN_URL || 'https://www.linkedin.com/company/hero-images/'
export const blogUrl = process.env.REACT_APP_BLOG_URL || 'https://heroimages.com/blog/'
export const tailoredUrl = process.env.REACT_APP_TAILORED_URL || 'https://heroimages.com/tailored/'
export const Instagram_userId = process.env.REACT_APP_INSTAGRAM_USER_ID || '21285424'
export const Instagram_clientId = process.env.REACT_APP_INSTAGRAM_CLIENT_ID || '7df1ef580456416ca2b2a40f0f527d78'
export const Instagram_accessToken = process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN || '21285424.7df1ef5.62ccb0146a774864b3f79619dca089e3'
export const Stripe_public_key = process.env.REACT_APP_STRIPE_PUBLIC_KEY || 'pk_live_09f8rr5n09NyGZp8HgYWaqQW' //'pk_test_uDUUxHahNJhtO67NHevSkEnm'

export const s3ShortendUrl = process.env.REACT_APP_S3_SHORTENED_URL || 'images.heroimages.com'

export const dateFormat = 'MMMM DD, YYYY'
export const dateFormatWithTime = 'MMMM DD, YYYY h:mm A'
export const defaultPageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE || 24

export const inMaintenanceMode = (process.env.REACT_APP_IN_MAINTENANCE_MODE == 'true') || false

// Validators
export const validators = {
  fullName: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/,
  name_wPunctuation: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/,
  name: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/,
  phone: /^\+?([0-9]+(\s|-)?)+$/,
  email: /^\S+@(\S+\.)+\S+$/,
  number: /^[0-9]*$/,
  text: /^\S[\S\s]*\S$/
}

export const pageStates = {
  loading: 'LOADING',
  error: 'ERROR',
}

export const pluralLabels = {
  none: 'No results',
  one: 'result',
  many: 'results'
}

export const modals = {
  share: 'share'
}

export const formNames = {
  letsChat: 'Lets Chat Form'
}

export const defaultPinterestMedia = 'https://s3-us-west-2.amazonaws.com/com.uppercut.hero-images/assets/IMG-public/PintrestGenericMedia.jpg'