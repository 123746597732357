/**
* @module board/components/modals
* index.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue Jun 12 2018 13:40:36 GMT-0600 (MDT)
* @copyright None
* @last-modified Mon Jun 18 2018 14:42:55 GMT-0600 (MDT)
*/

import CreateBoard from './CreateBoard'
import DeleteBoard from './DeleteBoard'
import EditBoard from './EditBoard'
import ListBoards from './ListBoards'

export default {
  CreateBoard,
  DeleteBoard,
  EditBoard,
  ListBoards
}