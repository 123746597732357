import React from 'react'
import PropTypes from 'prop-types'
import {withContentRect} from 'react-measure';

import core from '../../../_core'
import { Shape } from '../../model'
import Thumb from '../../../product/components/Thumb'

const _targetFigureHeight = 300
const _justifiedMargin = 5

class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      aspectRatios: []
    };

    this.justifyLayout = this.justifyLayout.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.setState({layout: undefined})
    }
    if (this.props.data && this.props.data.length > 0 && this.props.data.length === this.state.aspectRatios.filter(e => e).length && this.props.contentRect.client.width &&
        (this.state.aspectRatios !== prevState.aspectRatios || this.props.contentRect.client.width !== prevProps.contentRect.client.width)
    ) {
      this.justifyLayout()
    }
  }

  onImageLoad({target:img}, index) {
    const ratios = [...this.state.aspectRatios]
    const ratio = img.naturalHeight && img.naturalWidth ? img.naturalWidth / img.naturalHeight : 1
    ratios[index] = ratio
    this.setState({aspectRatios: ratios})
  }

  justifyLayout() {
    const targetRowRatio = this.props.contentRect.client.width / _targetFigureHeight
    const marginRatio = _justifiedMargin*2 / _targetFigureHeight
    let rowSize = 0
    let rowRatio = 0
    let layout = []
    var index;
    for (index = 0; index < this.state.aspectRatios.length; index++) {
      const cur = this.state.aspectRatios[index]
      rowSize++
      rowRatio += cur
      if (index + 1 < this.state.aspectRatios.length && rowRatio + this.state.aspectRatios[index + 1] / 2 + rowSize * marginRatio >= targetRowRatio) {
        layout.push({rowSize, rowHeight: (this.props.contentRect.client.width - rowSize * _justifiedMargin*2) / rowRatio})
        rowSize = 0
        rowRatio = 0
      }
    }
    if (rowRatio > 0) {
      layout.push({rowSize, rowHeight: Math.min((this.props.contentRect.client.width - rowSize * _justifiedMargin*2) / rowRatio, layout.length > 0 ? Math.max(...layout.map(l => l.rowHeight)) : _targetFigureHeight)})
    }

    this.setState({ layout })
  }

  render() {
    const { data } = this.props;
    const boards = data && Array.isArray(data) ? data : []
    let rowIndex = 0
    let colIndex = -1

    if (boards.length > 0) {
      return (
        <div ref={this.props.measureRef} style={{margin: `0 -${_justifiedMargin}px`}}>
          { boards.map((board, index) => {
            if (this.state.layout && colIndex + 1 < this.state.layout[rowIndex].rowSize) {
              colIndex++
            }
            else {
              colIndex = 0
              rowIndex++
            }
            const ratio = this.state.aspectRatios[index] || 1.5
            const height = this.state.layout ? this.state.layout[rowIndex].rowHeight : _targetFigureHeight
            return <Thumb
              key={index}
              onLoad={(e) => this.onImageLoad(e, index)}
              className='justified-figure'
              style={{
                margin: `0 ${_justifiedMargin}px 40px ${_justifiedMargin}px`,
                width: ratio * height - 1, // -1 is to deal with zoom
                maxHeight: this.state.layout ? 'none' : _targetFigureHeight + 60 // purely for fallback
              }}
              data={ {...board.products[0], featuredProductId: board.products[0] ? board.products[0].id : -1, id: board.id, title: board.title} }
              routerPath={`/boards/${board.id}`}
              hideCaption={false}
              showShootNumber={false}
              numberOfImages={board.products.length}
              truncateAt={40}
            />
          })}
        </div>
      )
    } else {
      return (
        <h1>No results found</h1>
      )
    }

  }
}

List.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(Shape),
    core.model.ErrorShape,
    core.model.LoadingShape
  ])
}

export default withContentRect('client')(List)