
import React from 'react'
import PropTypes from 'prop-types'
import JustifiedLayout from 'react-justified-layout'

import core from '../../../_core'
import layout from '../../../layout'

import product from '../../../product'

//import components from '../'
import {moduleName} from '../../constants'
import { Shape } from '../../model'

class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.elementRef = React.createRef();
    this.onImageLoad = this.onImageLoad.bind(this);
  }

  onImageLoad(item, {target:img}) {
    //Set the aspect ratio in the state
    this.setState({ [`${moduleName}_${item.id}`]: (img.offsetWidth / img.offsetHeight) })
  }

  onResize(screenSize, rowHeight){
    // shrink rowheight for mobile
    if (screenSize < 1000 && rowHeight){
      if(rowHeight === 300){
        return rowHeight*.4;
      }else if(rowHeight < 300){
        return rowHeight*.6;
      }
    }else if (screenSize > 1000 && rowHeight){
      return rowHeight;
    }
    let returnWidth = this.elementRef.current && this.elementRef.current.offsetWidth ? this.elementRef.current.offsetWidth : undefined;
    return returnWidth;
  }

  componentDidUpdate() {
    this.props.onChildUpdated(this.elementRef)
  }

  render() {
    const { data, rowHeight = 300, showShootNumber = false, style, hideCaption } = this.props;
    let listItems = [];
    let containerWidth =this.onResize(this.props.screen.width)// this.elementRef.current && this.elementRef.current.offsetWidth ? this.elementRef.current.offsetWidth : undefined;
    let targetHeight=this.onResize(this.props.screen.width, rowHeight)

    const config = {
      boxSpacing: {
        horizontal: 10,
        vertical: 100
      },
      centerMode: true,
      containerPadding: {
        top: 0,
        right: 0,
        left: 0,
        bottom: 20
      },
      containerWidth,//: containerWidth > 1350 ? 1350 : containerWidth,
      targetRowHeight: targetHeight
    }

    if(Array.isArray(data)) {
      listItems = data.filter( item =>
        ( item.products.length > 0)
      ).map( item =>
          <product.components.Thumb
            data={ {...item.products[0], featuredProductId: item.products[0].id, id: item.id, title: item.title} }
            key={`${moduleName}-${item.id}`}
            aspectRatio={this.state[`${moduleName}_${item.id}`] || 1}
            style={style}
            onLoad={this.onImageLoad.bind(this, item)}
            className={'gallery__thumb'}
            isThumbActive={item.isActive}
            ctaRibbonText={ item.isFeatured ? 'Get the gallery. Save 50%' : null }
            hideCaption={hideCaption}
            routerPath={`/galleries/${item.id}`}
            showShootNumber={showShootNumber}/>
      )
    } else {
      return (null)
    }

    if (data.length > 0) {
      return (
        <div className='grid__galleries spacer-padding-bottom-80' ref={this.elementRef}>
          <JustifiedLayout {...config}>
            {listItems}
          </JustifiedLayout>
        </div>
      )
    } else {
      return (
        <h1>No results found</h1>
      )
    }

  }
}

List.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(Shape),
    core.model.ErrorShape,
    core.model.LoadingShape
  ]),
  onPhotoshootClick: PropTypes.func,
  onChildUpdated: PropTypes.func,
  rowHeight: PropTypes.number,
  showShootNumber: PropTypes.bool,
  hideCaption: PropTypes.bool
}

export default layout.components.withResize(List)