/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:19:05
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-05-15 13:01:20
 */

export const moduleName = 'shoppingcart'

export const pageSize = 10


export const modals = {
  shoppingCartError: 'shoppingCartError',
  addMultipleToCart: 'addMultipleToCart',
  addBundleToCart: 'addBundleToCart',
  downloadCart: 'downloadCart',
  couponError: 'couponError'
}