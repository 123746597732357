/**
* @module board
* index.js
*
* @description A board is a collection of products put together by a user.
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Mon Jun 11 2018 09:56:36 GMT-0600 (MDT)
* @copyright None
* @last-modified Wed Jun 20 2018 13:49:11 GMT-0600 (MDT)
*/

import * as actions from './actions'
import actionTypes from './actionTypes'
import components from './components'
import * as constants from './constants'
import * as errorTypes from './errorTypes'
import * as model from './model'
import pages from './pages'
import { reducer, contextReducer } from './reducer'
import Routes from './routes'
import * as selectors from './selectors'
import Service from './service'
import './board.scss'

export default {
  actions,
  actionTypes,
  components,
  constants,
  errorTypes,
  model,
  pages,
  reducer,
  contextReducer,
  Routes,
  selectors,
  Service,
  Shape: model.Shape
}