/**
* @module board/components/modals
* ListBoards.js
*
* @description List all the boards that belong to a user
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue Jun 12 2018 10:37:35 GMT-0600 (MDT)
* @copyright None
* @last-modified Fri Jun 22 2018 16:34:45 GMT-0600 (MDT)
*/

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import core from '../../../_core'
import auth from '../../../auth'
import flash from '../../../flash'
import product from '../../../product'

import * as actions from '../../actions'
import * as selectors from '../../selectors'
import * as constants from '../../constants'
import {Shape} from '../../model'
import emptyThumb from '../../img/emptyThumb.jpg'
import CustomScroll from 'react-custom-scroll';

const ListBoards = (props) => {
  const { selectedProduct, auth, addProduct, unselectProduct, hideModal, showModal } = props
  const boards = props.data && props.data.data ? props.data.data : []

  const _onSelect = (boardId) => {
    addProduct({
      id: boardId,
      productId: selectedProduct.id,
      token: auth
    }).then(
      (targetBoard) => {
        unselectProduct()
        hideModal()
        props.showMessage((
          <div>
            <span>Successfully added to </span>
            <Link
              to={`/boards/${targetBoard.id}`}
              onClick={() => props.hideMessage()}
            > {targetBoard.title}</Link>
          </div>
        ))
      }
    )
  }

  const _onCreateNewBoard = () => {
    hideModal()
    showModal({id: constants.modals.create});
  }

  return (
    <div className="boards__modalList">
      <div>
        <h2>Add to board</h2>
      </div>
      <div className="flex start content">
        <div className="boards__modalList_selectedProduct">
          {
            selectedProduct && selectedProduct.thumbnailUrl &&
            <img crossOrigin="Anonymous" alt={selectedProduct.description} src={!selectedProduct.thumbnailUrl || selectedProduct.thumbnailUrl.startsWith('data:') || selectedProduct.thumbnailUrl.startsWith('/') ? selectedProduct.thumbnailUrl : (selectedProduct.thumbnailUrl + '?v=2020-06-22')} />
          }
        </div>
        <div className="boards__modalList_list_wrapper">
          <h3 className="text-left">{`${boards.length > 0 ? 'Select board:': ''}`} </h3>
          {
            boards.length > 0 
              ?
              <CustomScroll flex="1">
                <ul className="boards__modalList_list spacer-padding-bottom-40">
                  {
                    boards.map(board => (
                      <li className="flex align-center" key={`board-${board.id}`} onClick={_onSelect.bind(this, board.id)}>
                        <img crossOrigin="Anonymous" alt={board.products.length>0 ? board.products[0].description : ''} src={board.products.length>0 ? (!board.products[0].thumbnailUrl || board.products[0].thumbnailUrl.startsWith('data:') || board.products[0].thumbnailUrl.startsWith('/') ? board.products[0].thumbnailUrl : (board.products[0].thumbnailUrl + '?v=2020-06-22')) : emptyThumb} />
                        <div>
                          <p><small>{board.title}</small></p>
                          <p><small>{`${board.products.length} ${board.products.length === 1 ? 'product' : 'products'}`}</small></p>
                        </div>
                      </li>
                    ))
                  }
                </ul>
              </CustomScroll>
              :
              <p >There are currently no boards.</p>

          }
          
          <div className="boards__modalList_list_button">
            <button className="button" onClick={_onCreateNewBoard.bind(this)} >Create new board</button>
          </div>
        </div>
      </div>
    </div>
  )
}

ListBoards.propTypes = {
  auth: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(Shape),
    core.model.ErrorShape,
    core.model.LoadingShape
  ]),
  selectedProduct: PropTypes.object,
  addProduct: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  hideMessage: PropTypes.func.isRequired,
  unselectProduct: PropTypes.func.isRequired
}

const stateToProps = (state) => ({
  auth: auth.selectors.getUserAuthToken(state),
  selectedProduct:  product.selectors.getSelection(state),
  boards: selectors.getBoards(state),
  data: selectors.getBoards(state),
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    ...actions,
    loadFromServer: (auth) => actions.list({token: auth}),
    unselectProduct: product.actions.unselectProduct,
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal,
    showMessage: flash.actions.show,
    hideMessage: flash.actions.hide
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(
  core.components.modals.Modal(
    core.components.withLoading(
      core.components.withError(ListBoards), ['auth']
    )
  )
)