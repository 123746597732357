import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import core from '../../_core'
import board from '../../board'
import gallery from '../../gallery'
import shoppingcart from '../../shoppingcart'
import _auth from '../../auth'
// import filter from '../../filter'
// import product from '../../product'

import * as actions from '../actions'
import components from '../components'
import { Shape } from '../model'
import * as selectors from '../selectors'
import photoshoot from '../../photoshoot'
import * as constants from '../constants'
import AddToCartContainer from '../../shoppingcart/components/containers/AddToCartContainer'
import RelatedGalleries from '../../gallery/components/RelatedGalleries'
import {ProductIcon_DownloadComp, ProductIcon_AddToBoard, ProductIcon_AddToBoard_v2, ProductIcon_Share, ProductIcon_Link} from '../img/';

class ProductDetails extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      selectedOption: null,
      hasProductsOne: true,
      hasProductsTwo: true,
      isDownloading: false,
      isFreePremiumDownloading: false
    };

    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onPhotoshootClick = this.onPhotoshootClick.bind(this);
    this.onDownloadClick = this.onDownloadClick.bind(this)
    this.onAddGallery = this.onAddGallery.bind(this)
    this.onAddBoard = this.onAddBoard.bind(this)

    this.onCheckProductsAvailableOne = this.onCheckProductsAvailableOne.bind(this)
    this.onCheckProductsAvailableTwo = this.onCheckProductsAvailableTwo.bind(this)

    this.onFreePremiumDownloadClick = this.onFreePremiumDownloadClick.bind(this)
  }
  
  onDownloadClick(e) {
    e.preventDefault();

    if (!this.props.auth) {
      this.props.showModal({id: _auth.constants.modals.register});
      return;
    }

    //{core.constants.apiHost + '/order/download/' + lineItem.id}
    if(!this.state.isDownloading)
      this.props.downloadComp(this.props.data.fileName, this.props.data.id, this.props.auth)

    this.setState ({
      isDownloading: true
    });
    e.target.disabled = true;
  }

  onFreePremiumDownloadClick(e) {
    if (this.state.isFreePremiumDownloading)
      return;

    e.preventDefault();
    this.setState ({
      isFreePremiumDownloading: true
    });

    this.props.downloadFreePremium(this.props.data.fileName, this.props.data.id, this.props.auth).then(
      resp => {
        this.setState ({
          isFreePremiumDownloading: false
        });
      }
    )

    e.target.disabled = true;
  }

  onPhotoshootClick(product) {
    const { history } = this.props;
    history.push(`/product/${product.id}`)

  }

  handleRadioChange(e){
    this.setState({
      selectedOption: e.target.isdownloadin
    })
  }

  handleSubmit(e){
    e.preventDefault();
  }

  onShare(props){
    return (e) => {
      e.preventDefault()
      const { showModal } = props;
      showModal({id: core.constants.modals.share});
    }
  }
  
  onAddGallery() {
    const {select, showModal, data, auth, storeOperation} = this.props

    select(data)

    if (auth) {
      showModal({id: gallery.constants.modals.list})
    } else {
      storeOperation({
        type: 'showModal',
        id: gallery.constants.modals.list
      })
      showModal({id: _auth.constants.modals.login})
    }
  }

  onAddBoard() {
    const {select, showModal, data, auth, storeOperation} = this.props

    select(data)

    if (auth) {
      showModal({id: board.constants.modals.list})
    } else {
      storeOperation({
        type: 'showModal',
        id: board.constants.modals.list
      })
      showModal({id: _auth.constants.modals.login})
    }

  }

  onCheckProductsAvailableOne(hasProducts) {
    //console.log("Has Products One", hasProducts)
    this.setState ({
      hasProductsOne: hasProducts
    });
  }
  onCheckProductsAvailableTwo(hasProducts) {
    //console.log("Has Products Two", hasProducts)
    this.setState ({
      hasProductsTwo: hasProducts
    });
  }


  render(){
    const {data, history, authObj, prices} = this.props;
    const { isDownloading, hasProductsOne, hasProductsTwo } = this.state;
    const keywordArray = data.productKeywords && data.productKeywords.length > 0 ? data.productKeywords.map(keyword => keyword.value) : []
    const photoshootsArr = data.photoshoots && data.photoshoots.filter(s => s.isActive != false).length === 1 ? data.photoshoots.filter(s => s.isActive != false)[0] : []
    const galleriesArr =  data.galleries  && data.galleries.length > 0 ? data.galleries : []
    const modelArr = data.models && data.models.length > 0 ? data.models : []
    const windowLocation = window && window.location

    
    const title = `${data.description} ${data.type === 'video' ? 'stock video' : 'stock photo'} `
    const pageUrl = `${windowLocation.protocol}//${windowLocation.host}${this.props.location.pathname}`
    const licenseUrl = `${windowLocation.protocol}//${windowLocation.host}/pages/pricing#licensing`
    const width = data.width && data.width.length > 0 
                    ?  data.width 
                    : keywordArray.includes('vertical') ? 4480 : 6720
    const height = data.height && data.height.length > 0 
                    ?  data.height 
                    : keywordArray.includes('vertical') ? 6720 : 4480
    const metaDesc = `Download the Royalty Free ${data.type === 'video' ? 'stock video' : 'stock photo'} ${data.description}. Find more royalty free lifestyle ${data.type === 'video' ? 'videos' : 'photos'} by Hero Images.`

    let videoUrl
    if (data.type === 'video' && data.thumbnailUrl ) {
      const folder = data.thumbnailUrl.substring(0, data.thumbnailUrl.lastIndexOf('/'))
      const name = data.fileName.substring(0, data.fileName.lastIndexOf('.'))
      const extension = data.fileName.substring(data.fileName.lastIndexOf('.'))
      if (folder && name && extension) {
        videoUrl = `${folder}/${name}_video480${extension}`
      }
    }
                      
    const metaData = {
      description : metaDesc,
      keywords : keywordArray.join(','),
      title: `${data.title} | Hero Images`,
      name : 'Hero Images',
      url : pageUrl,
      image : data.compUrl,
      TwitterSite : '@heroimages',
      TwitterCard : 'summary',
      ogType : 'article'
    }

    let structuredData = {
      '@context': 'https://schema.org',
      '@type': data.type === 'video' ? 'VideoObject' : 'ImageObject',
      'license': licenseUrl,
      'acquireLicensePage': pageUrl,
      'name': title,
      'uploadDate': data.createdAt,
      'contentUrl': data.type === 'video' ? videoUrl : data.compUrl,
      'description': metaDesc,
      'caption': metaDesc,
      'representativeOfPage': 'http://schema.org/True',
      'width': width,
      'height': height,
      'datePublished': data.updatedAt,
      'isAccessibleForFree': data.collectionType === 'free' ? 'http://schema.org/True' : 'http://schema.org/False',
      'isFamilyFriendly':	'http://schema.org/True',
      'author': {
        '@type': 'Organization',
        'name': 'Hero Images'
      },
      'thumbnail': {
        '@type': 'ImageObject',
        'url': data.thumbnailUrl
      }
    }

    if (data.type === 'video') {
      if (data.durationSeconds)
        structuredData.durationSeconds = 'PT' + data.durationSeconds + 'S'
      structuredData.thumbnailUrl = data.thumbnailUrl
    }

    return(
      <section className="full-wd-page productDetails" style={data.isRestricted === true ? {pointerEvents: 'none', opacity: .5} : null}>
        <Helmet>
          <title>{ title + ' | Hero Images' }</title>
          
          <link rel="canonical" href={metaData.url} />

          <meta name="description"           content={metaData.description} />
          <meta name="keywords"              content={metaData.keywords} />

          <meta itemProp="name"              content={metaData.name}/>
          <meta itemProp="description"       content={metaData.description} />
          <meta itemProp="image"             content={metaData.image} />

          <meta name="twitter:card"          content={metaData.TwitterCard} />
          <meta name="twitter:site"          content={metaData.TwitterSite} />
          <meta name="twitter:title"         content={metaData.title} />
          <meta name="twitter:description"   content={metaData.description} />
          <meta name="twitter:creator"       content={metaData.TwitterSite} />
          <meta name="twitter:image"         content={metaData.image}/>

          <meta property="og:url"            content={metaData.url} />
          <meta property="og:type"           content={metaData.ogType} />
          <meta property="og:title"          content={metaData.title} />
          <meta property="og:description"    content={metaData.description} />
          <meta property="og:image"          content={metaData.image} />
        </Helmet>
        
        <core.components.modals.Share
          {...this.props}
          id={core.constants.modals.share}
          url={window.location.href}
          media={data.thumbnailUrl}
          title={`${data.title} | ${data.fileName} | Hero Images`}
          description={data.description}
        />
        <section className="flex justified productDetails__wrapper">
          <section className="flex column productDetails__imgAndTerms">
            <components.containers.Single
              {...this.props}
              productdata={data}
              structuredData={structuredData}
            />
            <div className="productDetails__keywords hidden-small spacer-padding-top-20">
              <div>
                {
                  Array.isArray(keywordArray) &&
                  keywordArray
                    .map( (term, i) => {
                      return <components.SearchTerm
                        term={term}
                        lastItem={keywordArray.length-1 !== i ? false : true}
                        key={`hiddenSmall-${term}`}
                        type={data.type}
                      />
                    })
                }
              </div>
            </div>
          </section>
          <section className="flex start wrap column productDetails__titleAndCommerce">
            <h1 className="h2 text-left">{data.isRestricted === true ? <b style={{color: 'red'}}>[REMOVED]</b> : ''}{data.description} - stock photo</h1>
            <p className="spacer-padding-bottom-40">{data.fileName}</p>
            <AddToCartContainer product={data} history={history} onFreePremiumDownloadClick={this.onFreePremiumDownloadClick} isFreePremiumDownloading={this.state.isFreePremiumDownloading} />
            <div className="flex centered spacer-padding-top-40">
              <a href="#" onClick={this.onDownloadClick} target="_blank" rel="noopener noreferrer" className="button link-icon" disabled={isDownloading}  >Download {authObj && authObj.role == 'admin' ? '' : 'comp'} <img src={ProductIcon_DownloadComp} title="Download comp"  alt="Download comp" /></a>
              { authObj && authObj.role == 'admin' &&
                <button className="button link-icon" onClick={this.onAddGallery}>Add to gallery <img src={ProductIcon_AddToBoard} title="Add to gallery" alt="Add to gallery" /></button>
              }
              <button className="button link-icon" onClick={this.onAddBoard}>Add to board <img src={ProductIcon_AddToBoard_v2} title="Add to board" alt="Add to board" /></button>
              <button onClick={this.onShare(this.props)} className="button link-icon"  >Share <img src={ProductIcon_Share} title="Share"  alt="Share" /></button>
            </div>
          </section>
        </section>
        
        { photoshootsArr.id && hasProductsOne && <div>
            <section className="spacer-padding-top-60"><h3 className="text-left spacer-padding-horizontal-20"><Link className="nostyle" to={`/search/shoot/${photoshootsArr.id}/${data.type === 'video' ? 'Video' : 'Image'}/1`}>More {data.type === 'video' ? 'video' : 'stills'} from shoot '{photoshootsArr.title}'</Link></h3></section>
            <section className="full-wd-section productDetails__productStrip">
              <photoshoot.pages.PhotoshootSimple onCheckProductsAvailable={this.onCheckProductsAvailableOne} id={photoshootsArr.id} onPhotoshootClick={this.onPhotoshootClick} params={{limit:15, filters: { type: { image: data.type === 'image', video: data.type === 'video'}}}} />
            </section>
            <div className="flex end spacer-margin-right-20 spacer-padding-top-20"><Link className="button link-icon" to={`/search/shoot/${photoshootsArr.id}/${data.type === 'video' ? 'Video' : 'Image'}/1`}>View all <img alt="view-all-icon" src={ProductIcon_Link} /></Link></div>
          </div> }
          { photoshootsArr.id && hasProductsTwo && <div>
            <section className="spacer-padding-top-60"><h3 className="text-left spacer-padding-horizontal-20"><Link className="nostyle" to={`/search/shoot/${photoshootsArr.id}/${data.type === 'video' ? 'Image' : 'Video'}/1`}>{data.type === 'video' ? 'Stills' : 'Video'} from shoot '{photoshootsArr.title}'</Link></h3></section>
            <section className="full-wd-section productDetails__productStrip">
              <photoshoot.pages.PhotoshootSimple onCheckProductsAvailable={this.onCheckProductsAvailableTwo} id={photoshootsArr.id} onPhotoshootClick={this.onPhotoshootClick} params={{limit:15, filters: { type: { image: data.type === 'video', video: data.type === 'image'}}}} />
            </section>
            <div className="flex end spacer-margin-right-20 spacer-padding-top-20"><Link className="button link-icon" to={`/search/shoot/${photoshootsArr.id}/${data.type === 'video' ? 'Image' : 'Video'}/1`}>View all <img alt="view-all-icon" src={ProductIcon_Link} /></Link></div>
          </div> }
        {
          modelArr.length > 0 &&
            <div>
              <section className="spacer-padding-top-40"><h3 className="text-left spacer-padding-horizontal-20"><Link className="nostyle" to={`/search/models/${modelArr.modelSeachString}/${data.type === 'video' ? 'Video' : 'Image'}/1`}>Same Model</Link></h3></section>
              <section className="full-wd-section productDetails__productStrip">
                {                                           
                  modelArr.modelProductSet.filter(item => item.type === data.type).slice(0,20).map(item =>

                    <components.Thumb 
                      key={`model-${item.id}`}
                      data={{...item, featuredProductId: -1}} 
                      className={'modelThumb'} 
                      hideCaption={true} 
                      routerPath={`/product/${item.id}`}
                      isTiny={true} />
                  )

                }
              </section>
              <div className={`flex ${modelArr.modelProductSet.length > 9? 'end spacer-margin-right-20 ' : 'start spacer-margin-left-40 '} spacer-padding-top-20`}><Link className="button link-icon" to={`/search/models/${modelArr.modelSeachString}/${data.type === 'video' ? 'Video' : 'Image'}/1`}>View all <img alt="view-all-icon" src={ProductIcon_Link} /></Link></div>
            </div>

        }

        { galleriesArr.length  > 0
          &&
          <div>
            <section className="spacer-padding-top-40"><h3 className="text-left spacer-padding-horizontal-20">Related Galleries</h3></section>
            <div className="productDetails__galleryStrip">
              <RelatedGalleries
                data={data && data}
                galleriesArr={galleriesArr}
                galleryItemClass={'productDetails'}
              />
            </div>
          </div>
        }

        <section className="spacer-margin-all-20 ">
          <div className="productDetails__keywords hidden-large spacer-padding-top-20">
            <div>
              {
                Array.isArray(keywordArray) &&
                keywordArray
                  .map( (term, i) => {
                    return <components.SearchTerm
                      term={term}
                      lastItem={keywordArray.length-1 !== i ? false : true}
                      key={`hiddenLarge-${term}`}
                      type={data.type}
                    />
                  })
              }
            </div>
          </div>
        </section>
      </section>
    )
  }

}

ProductDetails.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(Shape),
    core.model.ErrorShape,
    core.model.LoadingShape
  ])
  // info: core.model.InfoShape,
  // match: PropTypes.object,
  // photoshoot: Shape,
  // featured: product.Shape,
  // id: PropTypes.number
}


const stateToProps = (state) => {
  const stateData = selectors.getProduct(state)

  return {
    data: stateData,
    auth: _auth.selectors.getUserAuthToken(state),
    authObj: _auth.selectors.getAuth(state),
    prices: state.shoppingcart.prices
  }
}

const dispatchToProps = (dispatch, props) => (
  bindActionCreators({
    ...actions,
    // loadFromServer: (auth) => actions.getProduct(props.match.params.id, auth),
    loadFromServer: (auth) => (dispatch) => {
      dispatch(actions.getProduct(props.match.params.id, auth),)
    },
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal,
    select: actions.selectProduct,
    storeOperation: _auth.actions.storeOperation
  }, dispatch)
)


export default withRouter(
  connect(stateToProps, dispatchToProps)(
    core.components.withLoading(
      core.components.withError(
        core.components.withNotFound(ProductDetails)
      )
    )
  )
)
