/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-16 13:36:44 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-16 16:41:32
 */

import './user.scss'

import Routes from './routes'
import * as actions from './actions'
import components from './components'
import * as constants from './constants'
import modals from './modals'
import * as model from './model'
import reducer from './reducer'
import Service from './service'

export default {
  actions,
  components,
  constants,
  modals,
  model,
  moduleName: constants.moduleName,
  reducer,
  Routes,
  Shape: model.Shape,
  Service
}