import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import core from '../../_core'
import auth from '../../auth'
import * as actions from '../actions'

const PricingAdvert = (props) => {
  var firstRender = true;
  const currency = props.user && props.user.chargeInCad ? 'CAD' : 'USD'

  return props.prices.map((price) => price.isActive &&
    <React.Fragment key={price.id}>
      {firstRender ? firstRender = false : <div className="pricingCard_verticalHR"></div>}
      <div className="pricingCard_wrapper">
        <div className="pricingCard">
          <h2>{price.name}</h2>
          <p className="pricingCard_price"><span className={"enMoney h1 " + currency}>{price.price}</span>/{price.productType}</p>
          <p className="pricingCard_description">{price.longDescription}</p>
          <p className="pricingCard_details">{price.specs}<br />{price.moreSpecs}<br />{price.evenMoreSpecs}</p>
        </div>
      </div>
    </React.Fragment>
  )
};

PricingAdvert.propTypes = {
  auth: PropTypes.string,
  prices: PropTypes.array
}

function stateToProps(state)
{
  return {
    auth: auth.selectors.getUserAuthToken(state),
    prices: state.shoppingcart.prices,
    user: state.user
  }
}

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    loadFromServer: (auth) => actions.listPrices({token: auth})
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(
  core.components.withLoading(PricingAdvert, ['auth'])
)