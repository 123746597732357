/**
* @module board/components/modals
* DeleteBoard.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Mon Jun 18 2018 11:32:47 GMT-0600 (MDT)
* @copyright None
* @last-modified Thu Jun 21 2018 14:43:37 GMT-0600 (MDT)
*/

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { PropTypes } from 'prop-types'

import core from '../../../_core'
import auth from '../../../auth'

import * as actions from '../../actions'
import * as selectors from '../../selectors'

const DeleteBoard = (props) => {
  const onCancel = () => props.hideModal()

  const onConfirm = () => {
    props.destroy({
      id: props.data.data.id,
      token: props.auth
    }).then(
      () => {
        props.history.push('/boards')
      }
    )
  }

  return (
    <section>
      <div>Are you sure?</div>
      <button onClick={onConfirm}>Yes</button>
      <button onClick={onCancel}>No</button>
    </section>
  )
}

DeleteBoard.propTypes = {
  auth: PropTypes.string,
  data: PropTypes.object,
  history: PropTypes.object.isRequired,
  destroy: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
}

const stateToProps = (state) => ({
  auth: auth.selectors.getUserAuthToken(state),
  data: selectors.getBoard(state)
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    destroy: actions.destroy,
    hideModal: core.actions.hideModal
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(
  withRouter(
    core.components.modals.Modal(DeleteBoard)
  )
)