/**
* @module board
* constants.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Mon Jun 11 2018 09:56:36 GMT-0600 (MDT)
* @copyright None
* @last-modified Fri Jun 22 2018 17:00:01 GMT-0600 (MDT)
*/

export const moduleName = 'board'

export const pageSize = 24

export const modals = {
  create: 'board-create',
  edit: 'board-edit',
  delete: 'board-delete',
  list: 'board-list'
}