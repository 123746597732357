/**
* @module gallery/components/modals
* index.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue Jun 12 2018 13:40:36 GMT-0600 (MDT)
* @copyright None
* @last-modified Mon Jun 18 2018 14:42:55 GMT-0600 (MDT)
*/

import ListGalleries from './ListGalleries'

export default {
  ListGalleries
}