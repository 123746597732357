/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-17 15:15:23 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-02 10:48:20
 */

import PropTypes from 'prop-types'

import core from '../_core'
import product from '../product'

export const generate = ({
  prices = [],
  orders = [],
  order = {},
  downloading = [],
} = {}) => ({
  prices,
  orders,
  order,
  downloading,
})

