/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:16:34
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-06-19 16:37:04
 */

// dead component?

import React from 'react'
import PropTypes from 'prop-types'
import JustifiedLayout from 'react-justified-layout'

import core from '../../../_core'
import layout from '../../../layout'
import product from '../../../product'

import { moduleName } from '../../constants'
import { Shape } from '../../model'
import { isBoolean } from 'util';

class Single extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.elementRef = React.createRef();
    this.onImageLoad = this.onImageLoad.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  onImageLoad(item, {target:img}) {
    //Set the aspect ratio in the state
    this.setState({ [`${moduleName}_${item.id}`]: (img.offsetWidth / img.offsetHeight) })
  }

  onResize(screenSize){
    let returnWidth = this.elementRef.current && this.elementRef.current.offsetWidth ? this.elementRef.current.offsetWidth : undefined;
    return returnWidth;
  }

  componentDidUpdate() {
    this.props.onChildUpdated(this.elementRef)
  }

  render() {
    const { data, showAddMultipleToCartModal } = this.props;
    let listItems = [];
    let containerWidth = this.onResize(this.props.screen.width);//this.elementRef.current && this.elementRef.current.offsetWidth ? this.elementRef.current.offsetWidth : undefined;
    const config = {
      boxSpacing: {
        horizontal: 10,
        vertical: 10
      },
      centerMode: true,
      containerPadding: {
        top: 0,
        right: 0,
        left: 0,
        bottom: 20
      },
      containerWidth,
      targetRowHeight: 250
    }

    if(Array.isArray(data.products)) {
      listItems = data.products.map(item => <product.components.Thumb
        className="photoshoot__thumb"
        data={item}
        key={`${moduleName}-${item.id}`}
        isThumbActive={item.isActive}
        aspectRatio={this.state[`${moduleName}_${item.id}`] || 1}
        onLoad={this.onImageLoad.bind(this, item)}
        hideCaption={true}
        hasMenu={true}
        routerPath={`/product/${item.id}`}
        showAddMultipleToCartModal={showAddMultipleToCartModal}
      />)
    }

    return (
      <div className='grid__photoshoots' ref={this.elementRef}>
        <JustifiedLayout {...config}>
          {listItems}
        </JustifiedLayout>
      </div>
    )
  }
}

Single.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(Shape),
    core.model.ErrorShape,
    core.model.LoadingShape
  ]),
  screen: layout.ScreenShape
}

export default layout.components.withResize(Single)