/**
* @module filter
* Header.js
*
* @description Component that appears at the top of the filtered product results
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Wed May 16 2018 11:13:57 GMT-0600 (MDT)
* @copyright None
* @last-modified Wed Jul 11 2018 15:26:11 GMT-0600 (MDT)
*/

import React from 'react'
import PropTypes from 'prop-types'
import core from '../../_core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons'

import { defaultLimit } from '../constants'

import { filtersToArray } from '../helpers'
import Tag from './Tag'
import { Link } from 'react-router-dom'

const Header = ({
  data,
  rawData,
  info,
  sort,
  pagination,
  terms,
  filters,
  modelIds,
  toggleFilters,
  changeFilters,
  clearFilters,
  resetModelIds,
  changeSort,
  updateProducts,
  areFiltersHidden,
  shootId,
  loading,
  history,
  match
}) => {

  const _clearFilters = () => {    
    const payload = Object.assign({}, filters)
    Object.keys(payload).forEach(cat => {
      Object.keys(payload[cat]).forEach(fil => {
        payload[cat][fil] = false;
      })
    })

    console.log("Clear", match.url.toLowerCase().indexOf("/shoot/") , match.url.toLowerCase())
    if(match && match.url.toLowerCase().indexOf("/shoot/") > 0)
    {
      let url = "/search/1";
      if(history.location.pathname != url)
        history.push(url);
      history = null;
      match = null;
    }

    updateProducts(0, (pagination && pagination.limit) || defaultLimit, {
      filters: payload,
      modelIds,
      sort,
      terms
    }, history,match)
  }

  const _updateFilter = (category, filter, flag) => {
    const payload = Object.assign({}, filters)


    if(category == "modelIds")
    {
      resetModelIds();
      modelIds = "";

      let url = "/search/1";
      if(history.location.pathname != url)
        history.push(url);
      history = null;
      match = null;

    }
    else
      payload[category][filter] = flag
    changeFilters(payload)
    updateProducts(0, (pagination && pagination.limit) || defaultLimit, {
      filters: payload,
      modelIds,
      sort,
      terms
    }, history,match)

    return payload
  }

  const _updateSort = (sort) => {
    changeSort(sort)
    updateProducts(0, (pagination && pagination.limit) || defaultLimit, {
      filters,
      sort,
      terms,
      modelIds
    }, history,match)
  }

  const _sortHandler = (sort) => {
    return (e) => {
      e.preventDefault()
      _updateSort(sort)
    }
  }

  let resultType = '';
  if (filters.type && filters.type['Video'] === true)
    resultType = 'video';
  else if (filters.type && filters.type['Image'] === true)
    resultType = 'photo';

  let imageCount = info.total;
  const termsTitle = data.title || terms ? `for "${data.title || terms}"` : 'found'
  let headerTitle = data.title ? `${imageCount} image${info.total>0?'s':''} in Shoot "${data.title}"` : `${core.util.pluralize(core.constants.pluralLabels, info.total)} ${termsTitle}`;
  if(imageCount > 10000)
    headerTitle = `over 10,000 results ${termsTitle}`

  if (headerTitle.includes('result'))
    headerTitle = [headerTitle.slice(0, headerTitle.indexOf('result')), resultType + ' ', headerTitle.slice(headerTitle.indexOf('result'))].join('');

  let locationStr = window.location.pathname;
  if (resultType === 'photo') {
    locationStr = locationStr.substring(0, locationStr.toLowerCase().lastIndexOf('/image/')) + '/Video/' + locationStr.substring(locationStr.toLowerCase().lastIndexOf('/image/') + 7);
    locationStr = locationStr.replace('/Free/', '/Premium/').replace('/free/', '/Premium/');
  }
  else
    locationStr = locationStr.substring(0, locationStr.toLowerCase().lastIndexOf('/video/')) + '/Image/' + locationStr.substring(locationStr.toLowerCase().lastIndexOf('/video/') + 7);
  
  
  return (
    <header className="filter__header">
      {
        areFiltersHidden &&
        <div className="filter__header__btn__wrapper">
          <a href="#" className=" button" onClick={(e) => {e.preventDefault(); toggleFilters()}}>Show filters</a>
        </div>
      }
      {
        loading && 
        <h1 className="loadingIcon"><FontAwesomeIcon  icon={faSpinnerThird} spin />  Loading...</h1>
      }
      {
        !loading &&
        <div>
          <h1>{headerTitle}</h1>
          { (data.title || terms) && <p style={{marginTop: '0.5rem'}}>see <Link to={locationStr}>{resultType === 'photo' ? 'video' : 'image'} results</Link> for "{data.title || terms}"</p> }
        </div>
      }
      {
        !loading && 
          <section className="filter__header__sort">
            <h4>Sort by:</h4>
            <a href="#" className={sort === 'relevance' || sort == null ? 'active': ''} onClick={_sortHandler('relevance')}>Relevance</a>
            <a href="#" className={sort === 'date' ? 'active': ''} onClick={_sortHandler('date')}>Most Recent</a>
          </section>
      }
      {
        !loading && 
          <section className="filter__header__tags">
            {
              filtersToArray(filters)
                .map(
                  filter => (
                    <Tag
                      category={filter.category}
                      filter={filter.filter}
                      onClick={_updateFilter}
                      filterName={shootId ? rawData.relatedPhotoShoots && rawData.relatedPhotoShoots.data.photoshoots[0] ? rawData.relatedPhotoShoots.data.photoshoots[0].title : shootId : undefined}
                      key={filter.category+filter.filter}
                    />
                  )
                )
            }        
            { modelIds && 
                <Tag
                  category="modelIds"
                  filter="Models"
                  onClick={_updateFilter}
                  key={"modelIds"+modelIds}
                />
            }
            {
              (filtersToArray(filters).length > 0) && 
                <a href="#" style={{fontWeight: 'normal', color: '#aaa'}} onClick={(e) => {e.preventDefault(); _clearFilters();}}>Clear Filters</a>
            }
          </section>
      }
    </header>
  )
}

Header.propTypes = {
  data: PropTypes.object,
  info: PropTypes.object,
  toggleFilters: PropTypes.func.isRequired,
  changeSort: PropTypes.func.isRequired
};

export default Header;