/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-19 10:32:05
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-05-08 14:40:23
 */

import React from 'react'
import PropTypes from 'prop-types'

import {pageStates} from '../constants'
import NotFound from './NotFound'
import * as model from '../model'

export default (WrappedComponent) => {

  const withNotFound = (props) => {
    return (!props.data) ?
      <NotFound />
      :
      <WrappedComponent {...props} />
  }

  withNotFound.propTypes = {
    data: PropTypes.oneOfType([
      model.ErrorShape,
      model.LoadingShape,
      PropTypes.array,
      PropTypes.object,
    ])
  }

  return withNotFound
}