/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-16 13:37:48 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-16 16:25:59
 */

import {moduleName} from './constants'

export const getUserInfo = `${moduleName}.getUserInfo`
export const setUserInfo = `${moduleName}.setUserInfo`
export const getUserBillingInfo = `${moduleName}.getUserBillingInfo`
export const deleteCreditCardSuccess = `${moduleName}.deleteCreditCardSuccess`

