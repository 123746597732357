/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-16 13:35:15 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-16 15:09:22
 */

export const moduleName = 'user'

export const modals = {
  editAccountInfo: 'editAccontInfo',
  editBillingInfo: 'editBillingInfo',
  subscribe: 'subscribe',
  freeEnabledModal: 'freeEnabledModal',
  letsChat: 'letsChat',
  letsChatComplete: 'letsChatComplete'
}
