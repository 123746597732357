import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faTimes } from '@fortawesome/pro-light-svg-icons'

import core from '../../_core'
import * as actions from '../actions'
import * as constants from '../constants'
import modals from './modals'

// import StripeSimpleCheckout from '../components/StripeSimpleCheckout'

class CartOrderSummary extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      newCouponCode: '',
      errorString: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onActivateCouponClicked = this.onActivateCouponClicked.bind(this);
    this.removeCoupon = this.removeCoupon.bind(this);
  }

  componentDidMount() {
    this.checkCoupon();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.auth != this.props.auth) {
      this.checkCoupon();
    }
  }

  checkCoupon()  {
    this.props.getPersistentCoupon(this.props.auth)
      .then( () => {
        if (this.props.coupon && !this.props.coupon.users) {
          this.props.getCoupon(this.props.coupon.code, this.props.auth)
            .then( () => {
              if (this.props.coupon.code.toUpperCase() === 'MOVE50' && !this.validMove50Cart())
                this.props.removeCoupon();
            })
        }
      });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  removeCoupon(e) {
    e.preventDefault()
    this.props.removeCoupon()
  }

  onActivateCouponClicked(e) {
    e.preventDefault()

    if (this.state.newCouponCode.toUpperCase() === 'MOVE50' && !this.validMove50Cart()) {
      this.setState({
        errorString: 'You must have both a Large+ image and a Video in your cart to use this coupon.'
      });
      this.props.showModal({id: constants.modals.couponError});
      return;
    }

    this.props.getCoupon(this.state.newCouponCode, this.props.auth)
  }

  validMove50Cart() {
    let hasVideo = false;
    let hasLargeImage = false;
    this.props.cart.forEach( item => {
      if (item.priceId === 3) hasLargeImage = true;
      if (item.priceId === 4) hasVideo = true;

      if (hasLargeImage && hasVideo)
        return true;
    });

    return (hasLargeImage && hasVideo);
  }

  render() {
    
    return (    
      <React.Fragment>
        <modals.CouponErrorModal id={constants.modals.couponError} errorString={this.state.errorString} />
      
        <div className="cart-order-summary">
          <h2>Order summary</h2>

          <div className="cart-order-row">
            <div className="cart-order-cell">
              <div className="cart-order-title">
                Subtotal:
              </div>
            </div>
            <div className="cart-order-cell numbers">
              <span className={'enMoney ' + this.props.currency || 'USD'}>{this.props.totals.subTotal}</span>
            </div>
          </div>

          {this.props.coupon && <div className="cart-order-row">
            <div className="cart-order-cell">
              <div className="cart-order-title">
                Discount:
              </div>
            </div>
            <div className="cart-order-cell numbers">
              (<span className={'enMoney ' + this.props.currency || 'USD'}>{this.props.totals.discount}</span>)
            </div>

            { !this.props.coupon.users &&
            <a onClick={this.removeCoupon} className='coupon' title={this.props.coupon.code}>
              (
              {this.props.coupon.flatDiscount > 0 && <React.Fragment>-<span className={'enMoney ' + this.props.currency || 'USD'}>{this.props.coupon.flatDiscount}</span></React.Fragment>}
              {this.props.coupon.percentDiscount > 0 && <React.Fragment>{this.props.coupon.flatDiscount > 0 && ', '}{this.props.coupon.percentDiscount}% off</React.Fragment>}
              ) <FontAwesomeIcon className="icon" icon={faTimes} />
            </a>}
            { this.props.coupon.users && <div className='coupon'>
              (
              {this.props.coupon.flatDiscount > 0 && <React.Fragment>-<span className={'enMoney ' + this.props.currency || 'USD'}>{this.props.coupon.flatDiscount}</span></React.Fragment>}
              {this.props.coupon.percentDiscount > 0 && <React.Fragment>{this.props.coupon.flatDiscount > 0 && ', '}{this.props.coupon.percentDiscount}% off</React.Fragment>}
              ) </div> }
          </div>}

          <div className="cart-order-row">
            <div className="cart-order-cell">
              <div className="cart-order-title">
                {this.props.totals.federalTaxLabel}:
              </div>
            </div>
            <div className="cart-order-cell numbers">
              {isNaN(this.props.totals.federalTaxUSD) ? "TBD" : <span className={'enMoney ' + this.props.currency || 'USD'}>{this.props.totals.federalTaxUSD}</span>}
            </div>
          </div>

          {this.props.totals.regionalTaxLabel && <div className="cart-order-row">
            <div className="cart-order-cell">
              <div className="cart-order-title">
                {this.props.totals.regionalTaxLabel}:
              </div>
            </div>
            <div className="cart-order-cell numbers">
              {isNaN(this.props.totals.regionalTaxUSD) ? "TBD" : <span className={'enMoney ' + this.props.currency || 'USD'}>{this.props.totals.regionalTaxUSD}</span>}
            </div>
          </div> }

          <div className="cart-order-row">
            <div className="cart-order-cell">
              <div className="cart-order-title">
                Total:
              </div>
            </div>
            <div className="cart-order-cell numbers">
              <span className={'enMoney ' + this.props.currency || 'USD'}>{this.props.totals.total}</span>
            </div>
          </div>

          {!this.props.coupon && <React.Fragment>
            <div className="flex align-center wrap discount_apply spacer-padding-bottom-40">
              <input type="text" name="newCouponCode" placeholder="Discount Code" value={this.state.newCouponCode} onChange={this.handleInputChange} />
              <button className='button' type='button' value='activateCoupon' onClick={this.onActivateCouponClicked} disabled={!this.state.newCouponCode}>Apply</button>
            </div>
            {/* <p className='spacer-padding-bottom-40'>Looking for a code? <Link to='/pages/discount'>Try here.</Link></p> */}
          </React.Fragment>}
          
          

          <button onClick={this.props.onCheckoutClicked} type="button" className="button" value="Checkout" disabled={this.props.disabled || this.props.isSubmitted}>
            {this.props.isSubmitted ?
              <FontAwesomeIcon className="icon fa-spin" icon={faSync} />
              : this.props.checkoutText ? this.props.checkoutText : this.props.showInvoiceOption ? "Checkout via Credit Card" : "Checkout"}
          </button>
          {this.props.showInvoiceOption && <button onClick={this.props.onCheckoutClicked} type="button" className="button get-invoice" value="Invoice" disabled={this.props.disabled || this.props.isSubmitted}>Checkout via Invoice</button>}
          {this.props.disclaimer && 
            <div className='disclaimer'>By clicking complete purchase you are agreeing to our <Link to={{pathname:'/pages/eula'}} target="_blank"><strong>End User License Agreement</strong></Link></div>
          }
          {/* <StripeSimpleCheckout /> */}

        </div>
      </React.Fragment>
    );
  }
}

export default CartOrderSummary;
