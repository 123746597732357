/**
 * src/modules/auth/components/modals/Login.js
 */

import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'

import core from '../../../_core'

class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: ''
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.validate = this.validate.bind(this)
  }

  onChange(e) {
    const newState = {}

    newState[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState(newState)
    this.validate(e.target)
  }

  onCheck(e) {
    const newState = {}

    newState[e.target.name] = e.target.checked
    this.setState(newState)
    this.validate(e.target)
  }

  onSubmit(e) {
    e.preventDefault()

    const form = e.target
    const { login, onSuccess } = this.props;

    if (form.querySelectorAll('[data-valid="false"]').length === 0) {
      login(this.state)
        .then(
          () => onSuccess(e)
        )
    }
  }

  validate(formField) {
    const validator = formField.dataset['validator']

    // If there's no validator configured, make it valid
    formField.dataset['valid'] = validator ? core.util.validate(validator, formField.value) : true
  }

  togglePassword(e) {
    e.preventDefault()

    const passwordInput = document.querySelector(`[name="${e.target.dataset.toggleInput}"]`)

    if (passwordInput) {
      const isHidden = passwordInput.type === 'password'
      passwordInput.type = isHidden ? 'text' : 'password'
      e.target.innerHTML = isHidden ? 'Hide' : 'Show'
    }
  }

  render() {
    return (
      <form className="form__login form__small" onSubmit={this.onSubmit}>
        <h2>Log in</h2>

        <fieldset>
          <label>
            <input type="email" name="email" placeholder="Email" value={this.state.email} onChange={this.onChange} required data-validator="email" />
          </label>
          <label>
            <input type="password" name="password" placeholder="Password" value={this.state.password} onChange={this.onChange} maxLength="72" required />
            <a href="#" onClick={this.togglePassword} data-toggle-input="password">Show</a>
          </label>
          <label className="flex">
            <div className="checkbox">
              <input id="rememberMe" type="checkbox" name="rememberMe" value={this.state.rememberMe} onChange={this.onChange} />
              <label htmlFor="rememberMe"></label>
            </div>
            <label htmlFor="rememberMe" className="checkbox-label">Remember Me</label>
          </label>
        </fieldset>

        <div className="spacer-padding-bottom-15">
          <button type="submit">Submit</button>
        </div>
        <div>
          <small><p>Forgot your password? <button className="link" onClick={this.props.onForgetPassword}><small>Reset it.</small></button></p>
            <p>Don&lsquo;t have an account? <button className="link" onClick={this.props.onRegister}><small>Get one.</small></button></p>
          </small>
        </div>
      </form>
    )
  }

}

Login.propTypes = {
  login: PropTypes.func,
  onForgetPassword: PropTypes.func,
  onRegister: PropTypes.func
}

export default withRouter(
  core.components.modals.Modal(Login)
)