/**
* @module gallery/components
* index.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue May 29 2018 13:05:31 GMT-0600 (MDT)
* @copyright None
* @last-modified Tue May 29 2018 13:05:31 GMT-0600 (MDT)
*/


export default {
  
}

