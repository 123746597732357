/**
 * src/modules/auth/actionTypes.js
 */

import { moduleName } from './constants'

export const forgetPassword = `${moduleName}.forgetPassword`
export const getPasswordResetToken = `${moduleName}.getPasswordResetToken`
export const login = `${moduleName}.login`
export const check = `${moduleName}.check`
export const logout = `${moduleName}.logout`
export const register = `${moduleName}.register`
export const verifyPasswordResetToken = `${moduleName}.verifyPasswordResetToken`

export const storeOperation = `${moduleName}.storeOperation`
export const clearOperation = `${moduleName}.clearOperation`