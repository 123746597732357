/**
 * pages/components/limitless.js
 */

 import React, { useState, useEffect } from 'react';

 import {Helmet} from 'react-helmet'
 import header from '../img/limitless/header.jpg';
 import videoPreview from '../img/custom-libraries/video-preview.jpg';
 import descDesktop from '../img/limitless/desc-desktop.jpg';
 import descMobile from '../img/limitless/desc-mobile.jpg';
 
 import { faInstagram } from '@fortawesome/free-brands-svg-icons';
 import Instagram from './Instagram'
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
 import core from '../../_core'
 
 const instafeedTarget = 'instafeed';

 const metaData = {
   description : `Limitless Status | Hero Images`,
   keywords :'Hero Images, Stock Photography',
   title: 'Limitless Status | Hero Images',
   name : 'Hero Images',
   url : window && window.location && `${window.location.protocol}//${window.location.host}/tailored-custom-libraries`,
   image : header,
   TwitterSite : '@heroimages',
   TwitterCard : 'summary',
   ogType : 'article'
 }
 
const PageLimitless = () => {
  const [playing, setPlaying] = useState(null);

  useEffect(() => {
    //window.$zoho.salesiq.ready= () => window.$zoho.salesiq.floatbutton.visible("hide");
    document.querySelector('body').classList.add('no-search');

    return () => {
      //window.$zoho.salesiq.floatbutton.visible("show");
      document.querySelector('body').classList.remove('no-search');
    }
  }, [])
  useEffect(() => {
    if (playing && window.player) {
      window.player.play();
    }
  }, [playing, window.player])

  return (
    <section className="full-wd-page page--limitless">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <meta name="keywords" content={metaData.keywords} />

        <meta itemProp="name" content={metaData.name} />
        <meta itemProp="description" content={metaData.description} />
        <meta itemProp="image" content={metaData.image} />

        <meta name="twitter:card" content={metaData.TwitterCard} />
        <meta name="twitter:site" content={metaData.TwitterSite} />
        <meta name="twitter:title" content={metaData.title} />
        <meta name="twitter:description" content={metaData.description} />
        <meta name="twitter:creator" content={metaData.TwitterSite} />
        <meta name="twitter:image" content={metaData.image} />

        <meta property="og:url" content={metaData.url} />
        <meta property="og:type" content={metaData.ogType} />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <meta property="og:image" content={metaData.image} />
      </Helmet>

      <section className="hero-banner hero-banner--limitless" style={{ backgroundImage: `url(${header})` }}>
        <div className="hero-banner__body hero-banner__body--limitless">
          <h1>You really can have it all.</h1>
          <p>When you need great images to do great work, <br className="hidden-mobile" /> the last thing you need is something holding you back.</p>
          <a className="button button--cta" href='mailto:tailored@heroimages.com?subject=Limitless+access'>Let's talk</a>
        </div>
      </section>

      <section>
        <div className="regTextWidth spacer-padding-top-30 spacer-padding-bottom-20">
          <h1 className="text-center"><strong>Limitless&trade; Status</strong></h1>
          <p className="text-lead">
              Hero Images gives you every perk we offer with no minimum purchase, members-only discounts, exceptional service, early access, and the ability to custom order images from our world-class production&nbsp;team.
          </p>
        </div>

        <div className="regTextWidth">

          <div className="row">
            <div className="col col--limitless-info-graphic spacer-margin-bottom-30">
              <picture>
                  <source srcset={descDesktop} media="(min-width: 768px)" />
                  <source srcset={descMobile} />
                  <img src={descDesktop} class="" alt="" />
              </picture>
            </div>
            <div className="col col--limitless-info-copy">
              <h3 className="text-left spacer-margin-bottom-5"><strong>Flexible Pricing</strong></h3>
              <ul className="list-bulleted spacer-margin-top-0 spacer-margin-bottom-40">
                <li>Customizable annual or monthly payment plans.</li>
                <li>Enjoy discounts on everything in our collections.</li>
                <li>Save even more with volume purchase packages.</li>
              </ul>

              <h3 className="text-left spacer-margin-bottom-5"><strong>Exceptional Service</strong></h3>
              <ul className="list-bulleted spacer-margin-top-0 spacer-margin-bottom-40">
                <li>Get help from the team that makes the images.</li>
                <li>Save time by using our free image research service.</li>
                <li>Have your needs anticipated with proactive lightboxes.</li>
              </ul>

              <h3 className="text-left spacer-margin-bottom-5"><strong>Exclusive Access</strong></h3>
              <ul className="list-bulleted spacer-margin-top-0 spacer-margin-bottom-40">
                <li>Enjoy early access to new content.</li>
                <li>Supercharge pitches with big unwatermarked comps.</li>
                <li>Use market freeze &amp; exclusive purchasing rights.</li>
              </ul>

              <h3 className="text-left spacer-margin-bottom-5"><strong>Tailored&trade; Content</strong></h3>
              <ul className="list-bulleted spacer-margin-top-0 spacer-margin-bottom-40">
                <li>Member-only access to custom solutions.</li>
                <li>Supply a creative brief and we'll fulfill it.</li>
                <li>Hear about upcoming shoots and submit requests.</li>
              </ul>

              <h3 className="text-left spacer-margin-bottom-5"><strong>Exceptional Service</strong></h3>
              <ul className="list-bulleted spacer-margin-top-0 spacer-margin-bottom-40">
                <li>Provide download access to your entire team.</li>
                <li>Manage your account with purchase order invoicing.</li>
                <li>Confusion-free licensing &mdash; license once, use forever.</li>
              </ul>
            </div>
          </div>
          
          
        </div>
        <div className="regTextWidth text-center">
          <p className="text-lead">Learn more and get Limitless&trade; access&nbsp;today.</p>
          <p><a className="button button--cta" href='mailto:Tailored@heroimages.com?subject=Limitless+access'>Let's Talk</a></p>
        </div>
      </section>

      <section id="showreel">
        <div className="regTextWidth text-center spacer-padding-top-80 spacer-padding-bottom-10" >
          <h1>We’re image obsessed.</h1>
          <p style={{ 'maxWidth': '42em' }}>Check out our showreel to see highlights from our Hero collection.<br className="hidden-mobile" /> We love what we do and it shows.</p>
        </div>
      </section>
      <section className="vimeoWrapperEmbedded">
        <div style={{ padding: "56.21% 0 0 0", position: "relative" }}>
          <iframe src="https://player.vimeo.com/video/647504605?h=75a9acb719&color=ffffff&title=0&byline=0&portrait=0" style={{ position: 'absolute', top: 0, left: 0, width: "100%", height: "100%" }} frameBorder="0" webkitallowfullscreen="1" mozallowfullscreen="1" allowFullScreen="1">
          </iframe>
        </div>
      </section>
      <Instagram>
        <section className="text-center spacer-padding-top-60 spacer-padding-bottom-20">
          <a href={core.constants.instagramUrl} target="_blank" rel="noopener noreferrer" className=" h3 "><strong>@heroimages <FontAwesomeIcon icon={faInstagram} /></strong></a>
        </section>
        <ul className="image-grid full-wd-grid-instagram" id={instafeedTarget}>
        </ul>
      </Instagram>
    </section>
  )
}

export default PageLimitless