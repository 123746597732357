/**
 * src/modules/flash/reducer.js
 */

import * as actions from './actionTypes'

export default (state = null, action) => {
  switch (action.type) {
  case actions.show:
    return action.message
  case actions.hide:
    return null
  default:
    return state
  }
}