/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-16 17:28:09
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-28 13:33:38
 */

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTwitter, faFacebookSquare, faInstagram, faLinkedinIn} from '@fortawesome/free-brands-svg-icons'
//import { Link } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link';

import core from '../../_core'
import user from '../../user'

class Footer extends React.Component {
  constructor(props) {

    super(props)

  }

  render() {

    const terms1 = ['Business meeting', 'Happy people', 'Wellness', 'Seniors']
    const terms2 = ['Portraits', 'LGBTQ', 'Lifestyle', 'Finance']
    const terms3 = ['Technology', 'Leadership', 'Support', 'Environment']

    return (
      <div>
        <footer className='footer'>
          <div className='footer__block'>
            <div className='spacer-margin-left-30 spacer-margin-right-30'>
              <small> © {new Date().getFullYear()} Hero Images. All rights reserved.</small>
            </div>
          </div>
          <div className='footer__block footer__block--middle'>
            <div className='spacer-margin-left-30 spacer-margin-right-30'>
              <strong><a href="mailto:hello@heroimages.com">hello@heroimages.com</a></strong>
            </div>
          </div>
          <div className="footer__block">
            <div className="text-center spacer-margin-left-30 spacer-margin-right-30">
              400, 119 14 Street NW<br/>
              Calgary, AB<br/>
              T2N 1Z6
            </div>
          </div>
        </footer>
        
        <div className="tailored-footer">
          <nav>
            <dl className="flex centered">
              <dd><Link to="/pages/contact">Contact</Link></dd>
              <dd><Link to="/pages/privacy">Privacy</Link></dd>
              <dd><Link to="/pages/terms">Terms of use</Link></dd>
              <dd><Link to="/pages/eula">EULA</Link></dd>
              <dd>&copy; <a href="https://heroimages.com">Hero Images</a> {new Date().getFullYear()}</dd>
            </dl>
          </nav>
        </div>
      </div>
    )
  }
}

Footer.propTypes = {
}

export default Footer