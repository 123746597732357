/**
 * _core/components/modals/index.js
 */

import Confirm from './Confirm'
import Modal from './Modal'
import Share from './Share'

export default {
  Confirm,
  Modal,
  Share
}