/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:46:07
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-05-15 14:27:35
 */
import core from '../_core'

class Service extends core.Service {

  getOrders(authToken) {
    return super.get(`/order/`, {}, authToken)
  }
  getOrder(id, authToken) {
    return super.get(`/order/${id}`, {}, authToken)
  }

  downloadImage(id, authToken) {
    return super.get(`/order/download/${id}`, {}, authToken);
  }

  downloadSignedUrl(url) {
    return super.getBlob(url, {}, undefined);
  }

  downloadInvoice(orderId, authToken) {
    return super.getBlob(`/order/invoice/${orderId}`, {}, authToken)
  }
}
  

export default Service