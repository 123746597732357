/**
* @module board/components
* index.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Mon Jun 11 2018 09:56:36 GMT-0600 (MDT)
* @copyright None
* @last-modified Mon Jun 11 2018 12:49:11 GMT-0600 (MDT)
*/

import containers from './containers'
import modals from './modals'
import './board.scss'

export default {
  containers,
  modals
}