/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:48:35
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-05-09 11:53:42
 */

import * as constants from './constants'

export const list = `${constants.moduleName}.list`
export const getPhotoshoot = `${constants.moduleName}.getPhotoshoot`
export const resetPhotoshoots = `${constants.moduleName}.resetPhotoshoots`
export const getLatestPhotoshoot = `${constants.moduleName}.getLatestPhotoshoot`