/**
* @module search
* reducers.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Thu May 17 2018 14:07:42 GMT-0600 (MDT)
* @copyright None
* @last-modified Fri May 18 2018 15:20:37 GMT-0600 (MDT)
*/

import actionTypes from './actionTypes'
import { generate } from './model'

const reducer = (state = generate(), action) => {
  switch (action.type) {
  case actionTypes.search:
    var {type, products, photoshoots, relatedPhotoShoots} = action

    return {
      products,
      photoshoots,
      relatedPhotoShoots,
    }

  case actionTypes.resetSearch:
  
    return {
      products: {},
      photoshoots: {},
      relatedPhotoShoots: {},
    }
  
  default:
    return state
  }
}

export default reducer
