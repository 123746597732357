import React from 'react'
import PriceOption from './PriceOption'

class AddBundleToCart extends React.Component {
  constructor(props) {
    super(props);

    this.onOptionChange = this.onOptionChange.bind(this);
  }

  onOptionChange(e) {
    this.props.onOptionChange(this.props.id, e.target.value);
  }


  render() {
    const currency = this.props.user && this.props.user.chargeInCad ? 'CAD' : 'USD'
    
    return <React.Fragment>{this.props.bundles.map(bundle => 
      bundle.isActive && !bundle.isHidden && <PriceOption key={bundle.id} shortPricing={this.props.shortPricing} handleRadioChange={this.onOptionChange} selectedOption={this.props.selectedOption} {...bundle} currency={currency} />
    )}</React.Fragment>
  }
}

export default AddBundleToCart;