import React from 'react';
import notFoundHeader from '../img/NotFoundHeader.jpg'

const NotFound = () => {
  return (
    <section className="full-wd-page page-contact">
      <section className="full-wd-img spacer-height-vh-80 delayedFadeIn" style={{backgroundImage: `url( ${notFoundHeader})`}}>
        <h1 className="text-light header-text-width spacer-padding-horizontal-20">Page not found</h1>
      </section>
    </section>
  );
};

export default NotFound;