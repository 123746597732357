/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-22 12:00:04
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-07-05 16:42:01
 */

import core from '../_core'

import * as actionTypes from './actionTypes'
import { defaultLimit, moduleName } from './constants'
import Service from './service'

export const loadFilters = (service = new Service()) => (dispatch) => {
  dispatch(core.actions.onLoading)
  return service.getFilters()
    .then(
      (filters) => {
        console.log("TOBY2", filters)
        dispatch(onLoadFilters(filters))
      },
      (error) => {
        console.log("TOBY3", error)
        dispatch(core.actions.onError(moduleName, error));
      }
    )
}

export const onLoadFilters = (filters = {}) => ({
  type: actionTypes.loadFilters,
  filters
})

export const changeFilters = (filters = {}) => ({
  type: actionTypes.changeFilters,
  filters
})

export const changePagination = (pagination = {offset: 0, limit: defaultLimit}) => ({
  type: actionTypes.changePagination,
  pagination
})

export const changeTerms = (terms = '', reload = false) => ({
  type: actionTypes.changeTerms,
  reload,
  terms
})

export const changeModelIds = (modelIds = '') => ({
  type: actionTypes.changeModelIds,
  modelIds
})

export const changePhotoshoot = (photoshootId = '') => ({
  type: actionTypes.changePhotoshoot,
  photoshootId
})

export const changeSort = (sort = '') => ({
  type: actionTypes.changeSort,
  sort
})

export const changeType = (type = 'Image') => ({
  type: actionTypes.changeType,
  mediaType: type
})

export const changeCollectionType = (type = 'All') => ({
  type: actionTypes.changeCollectionType,
  collectionType: type
})

export const toggleFilters = () => ({
  type: actionTypes.toggleFilters
})

export const resetFilters = () => ({
  type: actionTypes.resetFilters
})

export const clearFilters = () => ({
  type: actionTypes.clearFilters
})

export const resetTerms = () => ({
  type: actionTypes.resetTerms
})

export const resetModelIds = () => ({
  type: actionTypes.resetModelIds
})

export const resetSort = () => ({
  type: actionTypes.resetSort
})

export const setDefaults = (defaultSort, defaultPageSize) => ({
  type: actionTypes.setDefaults,
  defaultSort,
  defaultPageSize
})

export const resetPagination = () => ({
  type: actionTypes.resetPagination
})

export const loadStarted = () => {
  return {
    type: actionTypes.updateLoading,
    loading: true
  }
}

export const loadEnded = () => {
  return {
    type: actionTypes.updateLoading,
    loading: false
  }
}
