/**
 * src/modules/_core/service.js
 */

import axios from 'axios'

import * as constants from './constants'

const compileAuthorizationHeaders = (token) => {
  const headers = {}

  if (token) {
    headers['authorization'] = `jwt ${token}`
  }

  return headers
}

class Service {
  constructor(host, timeout) {
    this._instance = axios.create({
      baseURL: host || constants.apiHost,
      timeout: timeout || constants.timeout
    })
  }

  get(endpoint, query, token) {
    return this._instance.get(endpoint, {
      params: query,
      headers: compileAuthorizationHeaders(token)
    })
  }

  getBlob(endpoint, query, token) {
    return this._instance.get(endpoint, {
      params: query,
      headers: compileAuthorizationHeaders(token),
      responseType: 'blob'
    })
  }


  post(endpoint, data, token) {
    return this._instance.post(endpoint, data, {
      headers: compileAuthorizationHeaders(token)
    })
  }

  put(endpoint, data, token) {
    return this._instance.put(endpoint, data, {
      headers: compileAuthorizationHeaders(token)
    })
  }

  delete(endpoint, token) {
    return this._instance.delete(endpoint, {
      headers: compileAuthorizationHeaders(token)
    })
  }
}

export default Service