/**
 * src/app/App.js
 */

/* global process:true */

import React from 'react';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import RootRouter from './RootRouter'
import { store, persistor } from './storeConfig'

import './Polyfill'

ReactGA.initialize(process.env.REACT_APP_GA_ID, { debug: process.env.NODE_ENV === 'development' });

const App = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <RootRouter />
    </PersistGate>
  </Provider>
)

export default App