/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-16 15:01:51 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-23 15:31:10
 */


import React from 'react'
import core from '../../_core'
import * as model from '../model'

class FreeEnabledModal extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <form className="form__login form__small">
        <h2>Welcome to Free!</h2>
        <h3>Thank you for updating your profile.</h3>
        
        <div className="text-center">
          <p>You will now receive 25 free credits each month towards our vast collection of free images.</p>
        </div>
        
      </form>
    )
  }
}

export default core.components.modals.Modal(FreeEnabledModal)