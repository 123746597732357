/**
 * src/modules/auth/components/modals/index.js
 */

import ShoppingCartError from './ShoppingCartError'
import AddMultipleToCartModal from './AddMultipleToCartModal'
import AddBundleToCartModal from './AddBundleToCartModal'
import DownloadCartModal from './DownloadCartModal'
import CouponErrorModal from './CouponErrorModal'

export default {
  ShoppingCartError,
  AddMultipleToCartModal,
  AddBundleToCartModal,
  DownloadCartModal,
  CouponErrorModal
}