/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-16 14:23:25
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-05-17 15:16:25
 */

import * as actionTypes from './actionTypes'
import { logout } from '../auth/actionTypes'
import { generate } from './model'

const reducer = (state = generate(), action) => {
  switch (action.type) {
  case actionTypes.getUserBillingInfo:
    return Object.assign({}, state, {userBillingInfo: action.data});   
  case actionTypes.deleteCreditCardSuccess:
    return Object.assign({}, state, {userBillingInfo: {hasCard: false}});   
  case actionTypes.getUserInfo:
  case actionTypes.setUserInfo:
    return Object.assign({}, state, action.user)
  case logout:
    return generate()
  default:
    return state
  }
}

export default reducer