/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:16:34
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-07-12 15:01:58
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import logo_heroimages from '../img/logo_heroimages@2x.png'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faSearch } from '@fortawesome/pro-light-svg-icons';

import core from '../../_core'
import filter from '../../filter'

import * as actions from '../actions'
import components from '../components'
import {defaultLimit} from '../../filter/constants'
//import { moduleName } from '../constants'
import { Shape } from '../model'
import * as selectors from '../selectors'
//import PhotoshootsHeader from '../img/PhotoshootsHeader.jpg'

class Photoshoots extends React.Component {
  constructor(props) {
    super(props)
    let terms = (props.match && props.match.params && props.match.params.terms) || undefined
    let pageNumber = (props.match && props.match.params && props.match.params.pageNumber) || undefined
    let params = terms ? true : false
    let info = (props.data && props.data.info) || ''

    this.state = {
      terms,
      pageNumber,
      params,
      info
    }

    // this.onSubmit = this.onSubmit.bind(this)
    // this.onChange = this.onChange.bind(this)
    this.goPage = this.goPage.bind(this)
    this.onSearchSubmit = this.onSearchSubmit.bind(this)
  }

  componentDidMount() {
    //const { terms, info, pageNumber } = this.state

    var pageNumber = this.props.match.params.pageNumber;
    var terms = this.props.match.params.terms;



    this.loadResults(pageNumber, terms)

  
  }

  UNSAFE_componentWillReceiveProps(newProps)
  {
    const { terms, info, pageNumber } = this.state

    if ((newProps.match.params.terms && newProps.match.params.terms !== terms) ||
    (newProps.match.params.pageNumber && newProps.match.params.pageNumber !== pageNumber) ) {

      var newPageNumber = newProps.match.params.pageNumber;
      var newTerms = newProps.match.params.terms;
      console.log("TOBY UNSAFE_componentWillReceiveProps", newTerms, newPageNumber, terms, pageNumber)

      this.loadResults(newPageNumber, newTerms)


      // const searchForm = document.querySelector('#photoshoots__search')
      
      // let offset = 0;
      // let limit = defaultLimit;
      // if(pageNumber)
      //   offset = (pageNumber - 1) * limit;
      // this.props.loadFromServer({terms, offset, limit});
      // window.scrollTo(0, searchForm.offsetTop);
    }
    // if (newProps.match.params.terms && newProps.match.params.terms !== this.props.terms) {
    //   console.log("TOBY DID RECEIVE NEW PROPS")
    //   this.resetSearchFromUrl(newProps.match.params.terms);
    // }

  }

  loadResults(pageNumber, terms)
  {
    const searchForm = document.querySelector('#photoshoots__search')
    
    let offset = 0;
    let limit = defaultLimit;
    if(pageNumber)
      offset = (pageNumber - 1) * limit;
    this.props.loadFromServer({terms, offset, limit});
    this.setState({terms, pageNumber});
    window.scrollTo(0, searchForm.offsetTop);
  }

  shouldComponentUpdate( nextProps, nextState ){
    if ( (nextProps.data.info && nextProps.data && nextProps.data.info && !nextProps.data.info.terms) && (nextState.params === true)){
      return false
    }else{
      return true
    }

  }

  onSearchSubmit(value){
    this.props.loadFromServer({terms: value})
  }

  // onSubmit(e) {
  //   const { terms } = this.state

  //   e.preventDefault()
  //   this.props.loadFromServer({terms})

  // }

  // onChange(e) {
  //   this.setState({terms: e.target.value})
  // }

  goPage(offset, limit) {
    let page = offset / limit + 1;
    let url = "/shoots/";
    if(this.state.terms)
      url += this.state.terms + "/";
    url += page;
    this.props.history.push(url);

    this.props.loadFromServer({
      offset,
      limit,
      terms: this.state.terms
    })
  }

  get paginationUrlPrefix() {
    let location = this.props.location.pathname.split('/');
    if (location[location.length - 1] === '')
      location.pop();
    if (!isNaN(parseInt(location[location.length - 1])))
      location.pop();
  
    return location.join('/');
  }

  render() {
    const { data, shootTerms } = this.props
    const { terms } = this.state
    const metaData = {
      description :'Browse and search our shoots to easily find images on a theme featuring the same locations and models in a variety of scenarios. ',
      keywords : 'Hero Images, Stock Photography',
      title: 'Search Shoots | Hero Images',
      name : 'Hero Images',
      url : window && window.location && `${window.location.protocol}//${window.location.host}${this.props.location.pathname}`,
      image : logo_heroimages,
      TwitterSite : '@heroimages',
      TwitterCard : 'summary',
      ogType : 'article'
    }
    return (
      <section className="full-wd-page photoshoots">
        <Helmet>
          <title>{metaData.title}</title>
          <meta name="description"           content={metaData.description} />
          <meta name="keywords"              content={metaData.keywords} />

          <meta itemProp="name"              content={metaData.name}/>
          <meta itemProp="description"       content={metaData.description} />
          <meta itemProp="image"             content={metaData.image} />

          <meta name="twitter:card"          content={metaData.TwitterCard} />
          <meta name="twitter:site"          content={metaData.TwitterSite} />
          <meta name="twitter:title"         content={metaData.title} />
          <meta name="twitter:description"   content={metaData.description} />
          <meta name="twitter:creator"       content={metaData.TwitterSite} />
          <meta name="twitter:image"         content={metaData.image}/>

          <meta property="og:url"            content={metaData.url} />
          <meta property="og:type"           content={metaData.ogType} />
          <meta property="og:title"          content={metaData.title} />
          <meta property="og:description"    content={metaData.description} />
          <meta property="og:image"          content={metaData.image} />
        </Helmet>
        <section>
          <h1 className="spacer-padding-top-20" >Search by shoot</h1>
          <h4 className="header-text-width">Find images from a single shoot day using the same models and locations in a variety of situations. Search by theme or browse the titles. With shoots ongoing, this menu is always growing.  </h4>
          <components.SearchInput ref={instance => { this.searchInputChild = instance; }} onSubmit={this.onSearchSubmit} history={this.props.history} />
        </section>
        <section className="spacer-padding-bottom-80">
          {
            
            (
              data && data.data &&
              Array.isArray(data.data) &&
              data.info && data.info.total > 0 &&
              data.info.terms
            ) &&
              <h3 className="text-center">
                {data.info.total} shoot result{data.info.total > 1 && 's' } {!terms ? '' : `for “${data.info.terms}”`}
              </h3>
          }
          <components.containers.List data={data.data} terms={terms} showShootNumber={true} />
          {
            data && data.info &&
            <filter.components.Pagination urlPrefix={this.paginationUrlPrefix} info={data.info} goPage={this.goPage} filterSettings={{terms: this.state.terms}} changePagination={this.props.changePagination}/>
          }
        </section>
      </section>
    )
  }
}

Photoshoots.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(Shape),
    core.model.ErrorShape,
    core.model.LoadingShape
  ]),
  history: PropTypes.object,
  id: PropTypes.number
}

const stateToProps = (state) => ({
  data: selectors.getFeatured(state)
})

const dispatchToProps = (dispatch) => {
  return (bindActionCreators({
    ...actions,
    changePagination: ({offset, limit} = {}) => filter.actions.changePagination({offset, limit}),
    loadFromServer: ({terms, offset, limit} = {}) => {
      //console.log("LoadFromServer", offset, limit, terms)
      return actions.list({ terms, offset, limit});
    }
  }, dispatch)
  )
}

export default connect(stateToProps, dispatchToProps)(
  core.components.withError(
    withRouter(Photoshoots)
  )
)