/**
 * pages/routes.js
 */

import React from 'react'
import { Route, Switch } from 'react-router-dom'

import components from './components'
import NotFoundPage from '../../modules/pages/components/notFoundPage'

const routes = {
  admin: [],
  user: [
    {
      path: `/pages/about`,
      component: components.PageAbout,
      exact: true
    },
    {
      path: `/pages/blog`,
      component: components.PageBlog,
      exact: true
    },
    {
      path: `/pages/contact`,
      component: components.PageContact,
      exact: true
    },
    {
      path: `/pages/pricing`,
      component: components.PagePricing,
      exact: true
    },
    {
      path: `/pages/call-for-models`,
      component: components.PageStock101,
      exact: true
    },
    {
      path: `/pages/privacy`,
      component: components.PagePrivacy,
      exact: true
    },
    {
      path: `/pages/dblOptInThanks`,
      component: components.DblOptInThanks,
      exact: true
    },
    {
      path: `/pages/terms`,
      component: components.PageTerms,
      exact: true
    },
    {
      path: `/pages/eula`,
      component: components.PageEULA,
      exact: true
    }
  ]
}

const Routes = ({type}) => (
  <Switch>
    {
      routes[type].map((route, i) => (
        <Route {...route} key={`pages-${i}`}/>
      ))
    }
    <Route component={NotFoundPage} />
  </Switch>
)

export default Routes