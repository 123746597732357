import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import core from '../../../_core'
import user from '../../../user'
import order from '../../../order'

import * as actions from '../../actions'
import * as constants from '../../constants'

import modals from '../modals'

class DownloadCartContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      errorString: "",
      isSubmitted: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.checkout = this.checkout.bind(this);

    this.showAddBundleToCartModal = this.showAddBundleToCartModal.bind(this)
    this.showError = this.showError.bind(this)
    this.onCheckoutSuccess = this.onCheckoutSuccess.bind(this)
    this.onCheckoutError = this.onCheckoutError.bind(this)
  }

  showAddBundleToCartModal() {
    this.props.showAddBundleToCartModal();
  }

  coalesce(value) {
    return value == null ? "" : value
  }

  onSubmit(ev)
  {
    ev.preventDefault();

    if (this.state.isSubmitted) return;
    this.setState({
      isSubmitted: true
    })

    this.props.getUserBillingInfo();
  }

  checkout () {
    let { userBillingInfo } = this.props
    let newOrder = {
      orderDetails: {
        subTotal: 0,
        federalTaxLabel: 'Sales Tax',
        federalTaxUSD: 0,
        regionalTaxUSD: 0,
        total: 0,
        poNumber: '',
        project: '',
        licenceOwner: '',
        productOrderPrice: this.props.downloadCart,
        orderType: 'credit'
      },
      userDetails: {
        firstName: this.coalesce(userBillingInfo.firstName),
        lastName: this.coalesce(userBillingInfo.lastName),
        company: this.coalesce(userBillingInfo.company),
        addressOne: this.coalesce(userBillingInfo.addressOne),
        addressTwo: this.coalesce(userBillingInfo.addressTwo),
        city: this.coalesce(userBillingInfo.city),
        zipPostal: this.coalesce(userBillingInfo.zipPostal),
        country: this.coalesce(userBillingInfo.country),
        region: this.coalesce(userBillingInfo.region)
      }
    };

    this.props.checkout(newOrder, this.onCheckoutSuccess, this.onCheckoutError);
  }

  componentDidUpdate(prevProps) {
    if (this.state.isSubmitted && this.props.userBillingInfo !== prevProps.userBillingInfo) {
      this.checkout();
    }
    if (this.props.downloadCart.find(product => prevProps.downloading.includes(product.productId)) && !this.props.downloadCart.find(product => this.props.downloading.includes(product.productId))) {
      this.props.hideModal()
      this.setState({
        isSubmitted: false
      })
    }
  }

  onCheckoutSuccess(checkoutStatus) {
    this.props.listPrices()
    this.props.getUserProductOwnership(this.props.from == 'multiple' ? this.props.multipleAddToCart : [this.props.product])
    this.props.downloadCart.forEach(product => {
      this.props.downloadImage(product.fileName, product.productId)
    })
  }

  onCheckoutError(checkoutStatus) {
    this.showError(checkoutStatus.stripeFailureReason);
    this.setState({
      isSubmitted: false
    })
  }

  showError(errorString) {
    const { showModal } = this.props;
    this.setState({
      errorString
    });
    showModal({id: constants.modals.shoppingCartError});
  }

  render() {
    let { price } = this.props
    let remaining = price && price.credits ? price.credits.reduce((count, credit) => count + credit.remaining, 0) : 0;

    //console.log("DownloadCartContainer", this.props)

    return (
      <section id='downloadCart'>
        <modals.DownloadCartModal id={constants.modals.downloadCart}
          total={this.props.downloadCart.length}
          bundleClass={price && price.bundleClass}
          bundleName={price && price.name}
          remaining={remaining}
          onSubmit={!this.state.isSubmitted ? this.onSubmit : undefined}
          isDownloading={this.state.isSubmitted}
          showAddBundleToCartModal={this.showAddBundleToCartModal} />
        <modals.ShoppingCartError id={constants.modals.shoppingCartError} errorString={this.state.errorString}/>
      </section>
    );
  }
}


function stateToProps(state)
{
  return {
    userBillingInfo: state.user.userBillingInfo,
    checkoutStatus: state.shoppingcart.checkoutStatus,
    multipleAddToCart: state.shoppingcart.multipleAddToCart,
    product: state.product,
    downloading: state.order.downloading,
  }
}

const dispatchToProps = (dispatch, props) => (
  bindActionCreators({
    downloadImage: (fileName, productId) => order.actions.downloadImage(fileName, productId, props.auth),
    getUserBillingInfo: () => user.actions.getUserBillingInfo(props.auth),
    checkout: (newOrder, onSuccess, onError) => actions.checkout(newOrder, props.auth, onSuccess, onError),
    listPrices: () => actions.listPrices({token: props.auth}),
    getUserProductOwnership: (viewedProducts) => actions.getUserProductOwnership(viewedProducts.map(product => product.id), props.auth),
    showAddBundleToCartModal: () => actions.showAddBundleToCartModal(),
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(DownloadCartContainer)