import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {Elements} from 'react-stripe-elements';
import {StripeProvider} from 'react-stripe-elements';

import auth from '../../auth'
import core from '../../_core'

import CheckoutFormDetailed from '../components/CheckoutFormDetailed'


class Checkout extends React.Component {

  constructor(props){
    super(props);

    // this.state = {
    //   selectedOption: null
    // };

    // this.searchTermClick = this.searchTermClick.bind(this);
    // this.handleRadioChange = this.handleRadioChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    // this.onPhotoshootClick = this.onPhotoshootClick.bind(this);
    // this.onRemoveCartItem = this.onRemoveCartItem.bind(this);
  }

  // onPhotoshootClick(product) {
  //   const { history } = this.props;
  //   history.push(`/product/${product.id}`)

  // }

  // handleRadioChange(e){
  //   this.setState({
  //     selectedOption: e.target.value
  //   })
  // }

  // onRemoveCartItem(cartItem) {    
  //   console.log("REMOVE ", cartItem)
  //   this.props.removeFromCart(cartItem);
  //   setTimeout(this.props.removeJustAdded, 500);
  // }

  // calculateTotals() {
  //   let subTotal = 0;
  //   this.props.cart.forEach(cartItem => {
  //     let price = selectors.getPrice(this.props.prices, cartItem.priceId);
  //     if(price)
  //       subTotal += price.price
  //   });


  //   return {
  //     subTotal: subTotal,
  //     total: subTotal
  //   }
  // }
  // handleSubmit(e){
  //   e.preventDefault();
  // }

  // searchTermClick(term){
  //   let trimmedTerm = term.trim();
  //   let searchURI = `/search/${trimmedTerm}`;
  //   window.location.href = encodeURI(searchURI);
  // } 

  render(){
    // const {data} = this.props;
    // const keywordArray = data.keywords ? data.keywords.split(',').filter(term => term.trim().length > 0) : []
    // const photoshootsArr = data.photoshoots && data.photoshoots.length === 1 ? data.photoshoots[0] : []
    // const galleriesArr =  data.galleries  && data.galleries.length > 0 ? data.galleries : []
    // let keywordArrayLength = keywordArray.length
    let orderType = 'product'
    if (this.props.location) {
      if (this.props.location.search.includes('bundle'))
        orderType = 'bundle'
      else if (this.props.location.search.includes('gallery'))
        orderType = 'gallery'
    }

    let checkoutCart = this.props.cart;
    if (orderType == 'bundle')
      checkoutCart = this.props.bundleCart;
    else if (orderType == 'gallery')
      checkoutCart = this.props.galleryCart;

    return(
      <section className="full-wd-page checkout">
        <h1 >Checkout</h1>
        <section className="flex align-baseline justified spacer-padding-top-20">
          <StripeProvider apiKey={core.constants.Stripe_public_key}>
            <Elements >
              <CheckoutFormDetailed  auth={this.props.auth} cart={checkoutCart} orderType={orderType} history={this.props.history} location={this.props.location}  />
            </Elements>
          </StripeProvider>
        </section>
      </section>
    
    )
  }

}

Checkout.propTypes = {
  
  // info: core.model.InfoShape,
  // match: PropTypes.object,
  // photoshoot: Shape,
  // featured: product.Shape,
  // id: PropTypes.number
}


function stateToProps(state)
{
  return {
    cart: state.shoppingcart.cart,
    bundleCart: state.shoppingcart.bundleCart,
    galleryCart: state.shoppingcart.galleryCart,
    auth: auth.selectors.getUserAuthToken(state),
    user: state.user
  }
}

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    //removeFromCart: (cartItem) => actions.removeFromCart(cartItem),
    //removeJustAdded: () => actions.removeJustAdded()    
  }, dispatch)
)


export default withRouter(
  connect(stateToProps, dispatchToProps)(Checkout)
)
