import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {Link, withRouter} from 'react-router-dom'
import core from '../../_core'

import PriceOption from './PriceOption'
import {ProductIcon_AddToCart_wt_v2, Icon_ImageBundle_bk} from '../img/';
import * as selectors from '../selectors'
import auth from '../../auth'
import {ProductIcon_DownloadComp} from '../../product/img/';

class AddToCart extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      selectedOption: 0,
      isAdded: false
    }

    this.onOptionChange = this.onOptionChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.productsOwned = this.productsOwned.bind(this);
    this.productsNotOwned = this.productsNotOwned.bind(this);

    this.onFreeDownloadClick = this.onFreeDownloadClick.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.onSubmit(this.state.selectedOption);
    var userCredits = selectors.getPrice(this.props.prices, this.state.selectedOption).credits
    
    if (!userCredits || !userCredits.length) {
      this.setState({
        isAdded: true
      });
    }
    else {
      this.setState({
        selectedOption: 0
      })
    }
  }

  onFreeDownloadClick(e) {
    e.preventDefault();
    
    if (!this.props.auth) {
      this.props.showModal({id: auth.constants.modals.register});
      return;
    }
  }

  onOptionChange(e) {
    this.setState({
      selectedOption: e.target.value
    });
  }

  productsOwned(price) {
    //return this.props.userProductOwnership ? this.props.userProductOwnership.reduce((count, product) => product.highestResolutionPriceId == price.id ? count += 1 : count, 0) : 0
    return this.props.userProductOwnership 
            ? this.props.userProductOwnership.reduce((count, product) => (product.highestResolution == price.megapixels || product.productType === 'video') && product.priceIds.includes(price.id) ? count += 1 : count, 0) 
            : 0
  }

  productsNotOwned(price) {
    // todo: bundleClass instead of price
    //return this.props.userProductOwnership && this.props.userProductOwnership.length ? this.props.userProductOwnership.reduce((count, product) => product.highestResolutionPriceId < price.id && (product.productType === 'video' && price.bundleClass === 'Print + Video' || product.productType === price.productType) ? count += 1 : count, 0) : this.props.total
    return this.props.userProductOwnership && this.props.userProductOwnership.length 
      ? this.props.userProductOwnership.reduce((count, product) => product.highestResolution < price.megapixels && !product.priceIds.includes(price.id) && (product.productType === 'video' && price.bundleClass === 'Print + Video' || product.productType === price.productType) ? count += 1 : count, 0) 
      : this.props.total
  }

  render() {
    
    //console.log("AddToCart.js - props", this.props)

    const isFreePremiumDownloading = this.props.isFreePremiumDownloading
    const isFreePremiumDownload = this.props.isFreePremiumDownload
    const hasFreeDownloadKey = this.props.location.search.includes('download=freepremium')
    const showFreePremiumDownload = hasFreeDownloadKey && isFreePremiumDownload
    
    var freePremiumAdvertFragment = <React.Fragment>
      <a href='#' className='button slim' onClick={this.props.onFreePremiumDownloadClick} disabled={isFreePremiumDownloading}>Download now</a></React.Fragment>

    const currency = this.props.user && this.props.user.chargeInCad ? 'CAD' : 'USD'
    var cartProductTypes = [...new Set(this.props.userProductOwnership.map(owned => owned.productType))]
    var hasOwnedProducts = this.props.userProductOwnership && this.props.userProductOwnership.some(product => product.productId > 0 && (product.highestResolution > 0 || product.productType === 'video'))
    var cheapestBundle = this.props.prices.map(p => p.bundles.find(b => b.isActive && !b.isHidden)).find(b => b && (b.productType === 'product' || cartProductTypes.includes(b.productType)));
    // todo: bundleclass instead of price so we don't have to hardcode video
    var userHasCredits = this.props.prices.find(price => price.bundleClass && price.credits && price.credits.length && (price.bundleClass === 'Print + Video' || cartProductTypes.includes(price.productType)));
    var userHasFreeCredits = this.props.prices.find(price => price.bundleClass && price.isFree && price.credits && price.credits.length && cartProductTypes.includes(price.productType));
    var fromBundlePriceFragment = cheapestBundle && <React.Fragment>from {<span className={'enMoney ' + currency}>{cheapestBundle.bundledPrice}</span>}/{cheapestBundle.productType === 'video' ? 'clip' : cheapestBundle.productType === 'product' ? 'each' : cheapestBundle.productType}</React.Fragment>
    var bundleAdvertFragment = <React.Fragment>
      <p>{fromBundlePriceFragment}</p>
      <a className='button slim' onClick={this.props.showAddBundleToCartModal}>Details</a>
    </React.Fragment>
    var selectedPrice = this.state.selectedOption ? selectors.getPrice(this.props.prices, this.state.selectedOption) : undefined
    var selectedForPurchase = selectedPrice ? this.productsNotOwned(selectedPrice) : 0
    var cartButtonFragment = userHasCredits && selectedPrice && selectedPrice.credits.length ?
      <React.Fragment>Download now for {selectedForPurchase} <img alt="bundle-credit-icon" src={Icon_ImageBundle_bk} className='icon bundle_credit_icon' /></React.Fragment> 
      : <React.Fragment>{this.state.isAdded ? "Added!" : `Add ${selectedForPurchase > 0 ? selectedForPurchase : ''} to Cart`} <img alt="cart-icon" className="icon" src={ProductIcon_AddToCart_wt_v2}/></React.Fragment>
    
    const showFree = this.props.showFree;
    const userLoggedIn = this.props.auth && this.props.user;
    const userFreeEnabled = (this.props.auth && this.props.user && this.props.user.freeEnabled);

    return (
      <form className="flex column price_options" onSubmit={this.onSubmit}>
        { showFreePremiumDownload && userLoggedIn && 
          <PriceOption isOwned={true} name={<b>Free image!</b>} description={<React.Fragment>Available to download free this week! <br/> For digital use only</React.Fragment>} 
            currency={currency} priceFragment={freePremiumAdvertFragment} iconFragment={<a href="#" className="link-icon" onClick={this.props.onFreePremiumDownloadClick} disabled={isFreePremiumDownloading}> 
              <img alt="download-icon" src={ProductIcon_DownloadComp} className='isOwned' /></a>}
           />
        }

        { /* A la Carte Options */ }
        {this.props.prices.map(price => 
          this.productsNotOwned(price) > 0 
            && (!userHasCredits || (price.name === 'Video' ? this.props.prices.find(p => p.bundleClass === 'Print + Video' && p.credits.length === 0) : price.credits.length == 0)) 
            && price.isActive && !price.isHidden 
            && (cartProductTypes.includes(price.productType) || (cartProductTypes.includes('video') && price.bundleClass === 'Print + Video')) && 
          <PriceOption key={price.id} handleRadioChange={this.onOptionChange} {...price} selectedOption={this.state.selectedOption} currency={currency} />
        )}
        {/* <div className='divider' /> */}

        { hasOwnedProducts &&
          <React.Fragment>
            { this.props.prices.map(price => 
               (cartProductTypes.includes(price.productType) || (cartProductTypes.includes('video') && price.bundleClass === 'Print + Video'))
            && this.productsOwned(price) > 0 &&
              <PriceOption key={'owned' + price.id} isOwned={true} {...price} credits={[]}
                isDownloading={this.props.isDownloading.filter(id => this.props.userProductOwnership.find(product => product.highestResolution == price.megapixels && product.priceIds.includes(price.id) && product.productId == id))}
                onDownloadClick={this.props.onDownloadClick} 
                priceFragment={<b>PURCHASED {this.props.total > 1 ? this.productsOwned(price) + ' of ' + this.props.total : ''}</b>} />
            )}
            {/* { userHasCredits && <div className='divider' />} */}
          </React.Fragment>
        }

        { userHasCredits 
          ? this.props.prices.map(price => price.bundleClass && price.credits && price.credits.length > 0 && (price.bundleClass === 'Print + Video' || cartProductTypes.includes(price.productType)) && this.productsNotOwned(price) > 0 &&
            ((userFreeEnabled && showFree && price.isFree) || !price.isFree) &&
                <PriceOption key={price.id} handleRadioChange={this.onOptionChange} {...price} selectedOption={this.state.selectedOption} currency={currency} />)
          : <React.Fragment></React.Fragment>

          /*<PriceOption isAdvert={true} name={<b>Bundle and save.</b>} description="Prepaid options for video and print volume." priceFragment={bundleAdvertFragment} currency={currency} />*/
        }
        {/* { this.props.prices.find(price => this.productsNotOwned(price) > 0 && (!userHasCredits || price.credits.length == 0) && price.isActive && !price.isHidden && cartProductTypes.includes(price.productType)) && <div className='divider' />} */}

        { userFreeEnabled 
          ? showFree && !userHasFreeCredits && !hasOwnedProducts && <PriceOption isAdvert={true} name={<strong>Free</strong>} description="More credits added next month" currency={currency} />
          : showFree && userLoggedIn && <PriceOption isAdvert={true} name={<strong>Download for Free!</strong>} description={<React.Fragment>Update your <Link to={'/user/account-info'}>profile</Link> and receive 25 download credits each month towards our free catalog of&nbsp;images.</React.Fragment>} currency={currency} />
        }
        { showFree && !userLoggedIn && 
          <PriceOption isAdvert={false} name={<b>Download for Free!</b>} description={<React.Fragment>Sign up and receive 25 download credits each month towards our free catalog</React.Fragment>} currency={currency}
            isOwned={true}
            iconFragment={<a href="#" className="link-icon" onClick={this.onFreeDownloadClick}> <img alt="download-icon" src={ProductIcon_DownloadComp} className='isOwned' /></a>}
           />
        }
        
        <button type="submit" className="button" value="Add to Cart" disabled={!this.state.selectedOption}>{cartButtonFragment}</button>
        {/* {userHasCredits && <a className="button get_bundle" onClick={this.props.showAddBundleToCartModal}>Need another bundle? Buy {fromBundlePriceFragment}</a>} */}
      </form>
    );
  }
}


AddToCart.propTypes = {
  auth: PropTypes.string,
}

function stateToProps(state) {
  return {
    auth: auth.selectors.getUserAuthToken(state),
  }
}

const dispatchToProps = (dispatch, props) => (
  bindActionCreators({
    loadFromServer: (auth) => (dispatch) => {},
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal
  }, dispatch)
)
export default withRouter(
   connect(stateToProps, dispatchToProps)(
    core.components.withLoading(AddToCart, ['auth', 'product'])
  )
)


