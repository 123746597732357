/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-22 11:54:31
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-07-11 14:53:25
 */

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { Range } from 'rc-slider'
import "rc-slider/assets/index.css";

import core from '../../_core'
import layout from '../../layout'

import * as actions from '../actions'
import { defaultLimit } from '../constants'
import * as selectors from '../selectors'


/**
 * Given a string, return a title case version
 * @param {string} words
 * @param {string} delimiter
 * @param {string} joiner
 * @returns {string}
 */
const _toTitleCase = (words, delimiter = '_', joiner = ' ') => (
  words
    .split(delimiter)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(joiner)
)

/**
 * Helper function to render the checkboxes
 * @param {object}
 */
const _renderFilters = ({
  isMobile,
  filters,
  changeFilters,
  updateProducts,
  updateFilter,
  updateFilterAndReload,
  toggleFilters,
  changeSort,
  sort,
  terms,
  modelIds,
  data
}, durationChange, afterDurationChange, durationMin, durationMax) => {

  console.log('%cfilters', 'background-color:green', filters)

  const keys = Object.keys(filters) || []
  const applyFilters = () => {
    updateProducts(0, null, {filters, sort, terms, modelIds})
    toggleFilters();
  }

  const _updateSort = (sort) => {
    changeSort(sort)
  }

  return (
    <section className={isMobile ? 'hidden-large' : 'hidden-small'}>
      {
        isMobile
          ?
          <div className="text-center">
            <button onClick={applyFilters}>Apply Filters</button>
            <section className="flex centered spacer-padding-top-40 filter__sort__wrapper">
              <h4 className="spacer-padding-right-10">Sort by:</h4>
              <a href="#" className={`spacer-padding-right-10 ${sort === 'relevance' ? 'active': ''}`} onClick={() => _updateSort('relevance')}>Relevance</a>
              <a href="#" className={sort === 'date' ? 'active': ''} onClick={() => _updateSort('date')}>Most Recent</a>
            </section>
          </div>
          :
          <a href="#" className=" spacer-margin-vertical-20 button link-icon" onClick={(e) => { e.preventDefault(); toggleFilters()}}><FontAwesomeIcon icon={faArrowLeft} /> Hide filters</a>
      }
      {
        keys.map(category => {
          let numResults = 0;
          if(data && data.info && data.info.facets && data.info.facets[category])
          {
            Object.values(data.info.facets[category]).forEach(val => {
              numResults += val;
            })
          }
        
          let selectedFilter = Object.keys(filters[category] || {}).find(key => filters[category][key]);
          if ( (category === 'type' || category === 'collectionType') && selectedFilter) selectedFilter = selectedFilter.toLowerCase()

          if((numResults > 0 || selectedFilter || category === 'type' || category === 'collectionType') && category != 'photoshootId') {
            const facets = category === 'duration' ? undefined : Object.keys(filters[category]).map(
              filter => {
                let facetResults = 0;
                let facet = (category === 'type' || category === 'collectionType') ? filter.toLowerCase() : filter;
                if(data && data.info && data.info.facets && data.info.facets[category] && data.info.facets[category][facet])
                {
                  facetResults = data.info.facets[category][facet];
                }
                
                return (<label key={`${category}-${filter}`} disabled={facetResults > 0 || category === 'type' || category === 'collectionType' ? false : true}
                  style={{
                    position: 'relative',
                    cursor: facetResults > 0 || category === 'type' || category === 'collectionType' ? 'pointer' : undefined,
                    pointerEvents: facetResults > 0 || category === 'type' || category === 'collectionType' ? undefined : 'none',
                    opacity: facetResults > 0 || category === 'type' || category === 'collectionType' ? undefined : .5
                  }}>
                  {
                    isMobile ?
                      <input
                        type="checkbox"
                        name={`${category}[]`}
                        value={filter}
                        checked={filters[category][filter]}
                        onChange={
                          () => {
                            updateFilter(category, filter, !filters[category][filter], !(category === 'type' || category === 'collectionType') )
                          }}
                      /> :
                      <input
                        type="checkbox"
                        name={`${category}[]`}
                        value={filter}
                        checked={filters[category][filter]}
                        onChange={
                          () => {
                            updateFilterAndReload(category, filter, !filters[category][filter], !(category === 'type' || category === 'collectionType') )
                          }}
                      />
                  }
                  {filter === 'Group of People' ? 'Group' : filter === 'Image' ? 'Stills' : filter}  <div className="filter_count">{(category === 'type' || category === 'collectionType') && selectedFilter && facet !== selectedFilter ? '' : facetResults}</div>
                </label>)
              }
            )

            const min = data && data.info && data.info.facets && data.info.facets.duration && data.info.facets.duration.min || 0
            const max = data && data.info && data.info.facets && data.info.facets.duration && data.info.facets.duration.max || 0
            if (durationMin === false) durationMin = min
            if (durationMax === false) durationMax = max
            const durationSlider = category === 'duration' ? <div style={{margin: '0 37px 0 7px', position: 'relative'}}>
              <Range allowCross={false} value={[durationMin, durationMax]} min={min} max={max}
                onChange={(value) => durationChange(value, isMobile)}
                onAfterChange={afterDurationChange} />
              <div style={{position: 'relative', left: -7}}>{new Date((parseInt(durationMin) || 0) * 1000).toISOString().substr(14, 5)}</div>
              <div style={{position: 'absolute', bottom: 0, right: -7}}>{new Date((parseInt(durationMax) || 0) * 1000).toISOString().substr(14, 5)}</div>
            </div> : undefined

            return <div key={category} className="category">
              <label className="category__header">{_toTitleCase(category === 'type' ? 'Media Type' : category === 'collectionType' ? 'Collection' : category)}</label>

              {
                category === 'duration' ? durationSlider : facets
              }
            </div>
          }
          else {
            return "";
          }
        })
      }
      <div className="text-center spacer-padding-top-40">
        {
          isMobile && <button onClick={applyFilters}>Apply Filters</button>
        }
      </div>
    </section>
  )
}

class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      durationMin: false,
      durationMax: false,
      changing: false
    }

    this.durationChange = this.durationChange.bind(this)
  }


  componentDidUpdate(prevProps, prevState) {
    if (this.state.changing) return

    if (prevState.changing) {
      this.props.updateFilter('duration', 'Min', this.state.durationMin)

      if (this.state.isMobile) this.props.updateFilter('duration', 'Max', this.state.durationMax)
      else this.props.updateFilterAndReload('duration', 'Max', this.state.durationMax)
      return
    }
    else if (this.props.filters && this.props.filters.duration
      && (this.state.durationMin !== this.props.filters.duration.Min || this.state.durationMax !== this.props.filters.duration.Max)) {
      this.setState({
        durationMin: this.props.filters.duration.Min,
        durationMax: this.props.filters.duration.Max
      })
    }
  }

  durationChange(value, isMobile) {
    // the slider component calls when other attributes change, we need to check that it is the value
    if (value[0] !== this.state.durationMin || value[1] !== this.state.durationMax) {
      this.setState({isMobile, changing: true, durationMin: value[0], durationMax: value[1]})
    }
  }

  render() {
    const { areFiltersHidden } = this.props
    

    return (
      <section className={`filters ${areFiltersHidden?'closed':'open'}`} >        
        {
          !areFiltersHidden && _renderFilters({...this.props, isMobile: true}, this.durationChange, () => {console.log("FUCK"); this.setState({changing: false})}, this.state.durationMin, this.state.durationMax)
        }
        {
          !areFiltersHidden && _renderFilters({...this.props, isMobile: false}, this.durationChange, () => {console.log("FUCK"); this.setState({changing: false})}, this.state.durationMin, this.state.durationMax)
        }
      </section>

    )
  }
}

const stateToProps = (state) => ({
  filters: selectors.getFilters(state),
  terms: selectors.getTerms(state)
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    loadFromServer: () => actions.loadFilters()
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(
  core.components.withLoading(
    layout.components.withResize(Filter)
  )
)