import React from 'react';
import {injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement} from 'react-stripe-elements';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Transition from 'react-transition-group/Transition';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import core from '../../_core'
import user from '../../user'

import * as actions from '../actions'
import * as selectors from '../selectors'
import * as constants from '../constants'

import CartOrderSummary from './CartOrderSummary';
import modals from './modals'

const duration = 500;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
  height: 0
}

const transitionStyles = {
  entering: { opacity: 1, height: '220px' },
  entered: { opacity: 1, height: '220px' },
};

const canadianTaxRegions = [
  { province: 'Alberta', GST: 5 },
  { province: 'British Columbia', GST: 5, PST: 7 },
  { province: 'Manitoba', GST: 5, PST: 8 },
  { province: 'New Brunswick', HST: 15 },
  { province: 'Newfoundland and Labrador', HST: 15 },
  { province: 'Northwest Territories', GST: 5 },
  { province: 'Nova Scotia', HST: 15 },
  { province: 'Nunavut', GST: 5 },
  { province: 'Ontario', HST: 13 },
  { province: 'Prince Edward Island', HST: 15 },
  { province: 'Quebec', GST: 5, QST: 9.975 },
  { province: 'Saskatchewan', GST: 5, PST: 6 },
  { province: 'Yukon', GST: 5 },
]

class CheckoutFormDetailed extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isSubmitDisabled: true,
      firstName: "",
      lastName: "",
      company: "",
      addressOne: "",
      addressTwo: "",
      city: "",
      zipPostal: "",
      country: "",
      region: "",
      poNumber: "",
      licenceOwner: "",
      purchaserName: "",
      hidePaymentSection: false,
      useNewCard: false,
      saveCard: false,
      ccNumberComplete: false,
      ccExpiryComplete: false,
      ccCVCComplete: false,
      errorString: "",
      invoiceCC: ""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.postToCheckout = this.postToCheckout.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleUseSavedCardChange = this.handleUseSavedCardChange.bind(this);
    this.handleSaveNewCardChange = this.handleSaveNewCardChange.bind(this);

    this.selectCountry = this.selectCountry.bind(this);
    this.selectRegion = this.selectRegion.bind(this);
    this.onCheckoutClicked = this.onCheckoutClicked.bind(this);
    this.calculateTotals = this.calculateTotals.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.ccNumberChanged = this.ccNumberChanged.bind(this);
    this.ccExpiryChanged = this.ccExpiryChanged.bind(this);
    this.ccCVCChanged = this.ccCVCChanged.bind(this);

    this.showError = this.showError.bind(this)
    this.onCheckoutSuccess = this.onCheckoutSuccess.bind(this)
    this.onCheckoutError = this.onCheckoutError.bind(this)
    this.userBillingDraftChanged = this.userBillingDraftChanged.bind(this);
  }

  selectCountry (val) {
    this.userBillingDraftChanged('country', val)
    this.calculateTotals();
  }

  selectRegion (val) {
    this.userBillingDraftChanged('region', val)
    this.setState({ region: val });
    this.calculateTotals();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.userBillingDraftChanged(name, value)
  }

  userBillingDraftChanged(name, value){
    this.setState({
      [name]: value
    });
  }

  handleUseSavedCardChange(event) {
    this.setState({
      useNewCard: event.target.value == 'new'
    });
  }

  handleSaveNewCardChange(event) {
    this.setState({
      saveCard: !this.state.saveCard
    });
  }

  componentDidMount() {
    let totals = this.calculateTotals();
    const isInvoice = this.props.location && this.props.location.search && this.props.location.search.includes("invoice=true")
      && this.props.user ? this.props.user.allowInvoice : false
    var hidePaymentSection = isInvoice || totals.total === '0.00' && totals.subTotal === totals.discount;
    if (this.state.hidePaymentSection !== hidePaymentSection) {
      this.setState({
        hidePaymentSection,
        useNewCard: false,
        saveCard: false
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { userBillingInfo, coupon } = this.props
    if (prevProps.userBillingInfo !== userBillingInfo) {
      this.setState({
        firstName: this.coalesce(userBillingInfo.firstName),
        lastName: this.coalesce(userBillingInfo.lastName),
        company: this.coalesce(userBillingInfo.company),
        addressOne: this.coalesce(userBillingInfo.addressOne),
        addressTwo: this.coalesce(userBillingInfo.addressTwo),
        city: this.coalesce(userBillingInfo.city),
        zipPostal: this.coalesce(userBillingInfo.zipPostal),
        country: this.coalesce(userBillingInfo.country),
        region: this.coalesce(userBillingInfo.region)
      });
    }
    if (prevProps.coupon !== coupon || prevProps.prices !== this.props.prices) {
      let totals = this.calculateTotals();
      const isInvoice = this.props.location && this.props.location.search && this.props.location.search.includes("invoice=true")
        && this.props.user ? this.props.user.allowInvoice : false
      var hidePaymentSection = isInvoice || totals.total === '0.00' && totals.subTotal === totals.discount;
      if (this.state.hidePaymentSection !== hidePaymentSection) {
        this.setState({
          hidePaymentSection,
          useNewCard: false,
          saveCard: false
        })
      }
    }

    // The DOM must be fully rendered to call checkValidility
    this.validateForm()
  }

  coalesce(value) {
    return value == null ? "" : value
  }

  calculateTotals() {
    let subTotal = 0;
    let discount = '0.00';
    let federalTaxLabel = 'Sales Tax';
    let federalTaxUSD = '0.00';
    let regionalTaxLabel = '';
    let regionalTaxUSD = '0.00';
    this.props.cart.forEach(cartItem => {
      if (this.props.orderType == 'bundle' || this.props.orderType == 'gallery') {
        let bundle = selectors.getBundle(this.props.prices, cartItem.priceId, cartItem.bundleId);
        if (bundle)
          subTotal += bundle.totalPrice
      }
      else {
        let price = selectors.getPrice(this.props.prices, cartItem.priceId);
        if(price)
          subTotal += price.price
      }
    });
    if (this.props.coupon) {
      discount = (Math.min(this.props.coupon.flatDiscount, subTotal) + Math.max(subTotal - this.props.coupon.flatDiscount, 0) * this.props.coupon.percentDiscount / 100.0).toFixed(2)
    }

    if(this.state.country == "Canada" && canadianTaxRegions.find(region => region.province === this.state.region))
    {
      const isTaxExempt = this.props.user && this.props.user.isTaxExempt
      const taxRegion = canadianTaxRegions.find(region => region.province === this.state.region)
      federalTaxLabel = taxRegion.HST ? 'HST' : 'GST'
      federalTaxUSD = isTaxExempt ? '0.00' : ((subTotal - parseFloat(discount)) * (taxRegion.HST || taxRegion.GST) / 100).toFixed(2);
      regionalTaxLabel = taxRegion.PST ? 'PST' : taxRegion.QST ? 'QST' : ''
      regionalTaxUSD =  regionalTaxLabel && !isTaxExempt ? ((subTotal - parseFloat(discount)) * (taxRegion.PST || taxRegion.QST) / 100).toFixed(2) : '0.00';
    }

    return {
      subTotal: subTotal.toFixed(2),
      discount,
      total: (subTotal - parseFloat(discount) + parseFloat(federalTaxUSD) + parseFloat(regionalTaxUSD)).toFixed(2),
      federalTaxLabel,
      federalTaxUSD,
      regionalTaxLabel,
      regionalTaxUSD
    }
  }

  validateForm() {
    let isValid = false;

    if(document.getElementById('checkout-form')) {
      isValid = document.getElementById('checkout-form').checkValidity();
    }

    isValid = isValid &&
      this.state.country &&
      this.state.region &&
      (this.state.hidePaymentSection || this.state.useNewCard == false ||  this.state.ccNumberComplete && this.state.ccExpiryComplete && this.state.ccCVCComplete);

    if (this.state.isSubmitDisabled != !isValid) {
      this.setState({
        isSubmitDisabled: !isValid
      })
    }
  }

  ccNumberChanged(obj) {
    this.setState({
      ccNumberComplete: obj.complete
    })
  }

  ccExpiryChanged(obj) {
    this.setState({
      ccExpiryComplete: obj.complete
    })
  }

  ccCVCChanged(obj) {
    this.setState({
      ccCVCComplete: obj.complete
    })
  }


  onCheckoutClicked(e) {
    if (this.state.isSubmitted) return;
    this.setState({
      isSubmitted: true
    })
    this.handleSubmit(e);
  }

  handleSubmit(ev)
  {
    ev.preventDefault();

    //if they're using the existing card, go straight to post, otherwise, get a card token from stripe
    if(!this.state.hidePaymentSection && (this.state.useNewCard == true || this.props.userBillingInfo.hasCard == false)) {
      this.setState({
        useNewCard: true
      });

      this.props.stripe.createToken({
        type: 'card',
        name: this.state.firstName + ' ' + this.state.lastName,
        address_line1: this.state.addressOne,
        address_line2: this.state.addressTwo ? this.state.addressTwo : '',
        address_city: this.state.city,
        address_state: this.state.region,
        address_zip: this.state.zipPostal,
        address_country: this.state.country,

      }).then(({token}) => {
        if(token)
          this.postToCheckout(token.id)
        else
        {
          this.showError("Invalid card type: we currently only accept Mastercard, Visa or American Express")
        }
      }, err => {
        this.showError("Invalid card type: we currently only accept Mastercard, Visa or American Express")
      });
    }
    else {
      this.postToCheckout(null)
    }
  }

  postToCheckout(newCardToken) {
    let totals = this.calculateTotals();
    let productOrderPrice =  this.props.cart.map(cartItem => {
      let pricePaid = 0;
      let resourceId = -1;
      let gallery = undefined;
      if (this.props.orderType == 'bundle' || this.props.orderType == 'gallery') {
        let bundle = selectors.getBundle(this.props.prices, cartItem.priceId, cartItem.bundleId);
        resourceId = cartItem.bundleId;
        if (bundle)
          pricePaid = bundle.totalPrice
        
        gallery = cartItem.gallery;
      }
      else {
        let price = selectors.getPrice(this.props.prices, cartItem.priceId);
        resourceId = cartItem.product.id;
        if(price)
          pricePaid = price.price
      }

      return {
        priceId: cartItem.priceId,
        pricePaid: pricePaid,
        specs: cartItem.specs,
        productId: resourceId,
        gallery: gallery
      }
    });

    console.log('posting to checkout', this.props);

    const isInvoice = this.props.location && this.props.location.search && this.props.location.search.includes("invoice=true")
      && this.props.user ? this.props.user.allowInvoice : false
    let newOrder = {
      orderDetails: {
        ...totals,
        poNumber: this.state.poNumber,
        project: this.state.project,
        licenceOwner: this.state.licenceOwner,
        purchaserName: isInvoice ? this.state.purchaserName : undefined,
        productOrderPrice: productOrderPrice,
        orderType: this.props.orderType,
        couponId: this.props.coupon ? this.props.coupon.id : null,
        isInvoice,
        currency: this.props.user && this.props.user.chargeInCad ? 'CAD' : 'USD',
        invoiceCC: isInvoice ? this.state.invoiceCC : undefined
      },
      userDetails: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        company: this.state.company,
        addressOne: this.state.addressOne,
        addressTwo: this.state.addressTwo,
        city: this.state.city,
        region: this.state.region,
        zipPostal: this.state.zipPostal,
        country: this.state.country,
      },
      paymentDetails: {
        useNewCard: this.state.useNewCard,
        saveCard: this.state.saveCard,
        newCardToken
      }
    };

    console.log('checking out - new order', newOrder);
    this.props.checkout(newOrder, this.onCheckoutSuccess, this.onCheckoutError);
  }

  onCheckoutSuccess(checkoutStatus) {
    this.props.removeCoupon()
    this.props.listPrices()
    if (this.props.orderType == 'product') {
      this.props.clearCart();
    }
    this.setState({
      isSubmitted: false
    })
    this.props.history.push(`/order/invoice/${checkoutStatus.id}?success=true`, this.props.location.state ? {return: this.props.location.state.from } : undefined)
  }

  onCheckoutError(checkoutStatus) {
    this.showError(checkoutStatus.stripeFailureReason);
  }

  showError(errorString) {
    const { showModal } = this.props;
    this.setState({
      errorString,
      isSubmitted: false
    });
    showModal({id: constants.modals.shoppingCartError});
  }

  render() {
    const isInvoice = this.props.location && this.props.location.search && this.props.location.search.includes("invoice=true")
      && this.props.user ? this.props.user.allowInvoice : false

    return (
      !this.props.auth ? null :  <form id="checkout-form" onSubmit={this.handleSubmit}>
        <div className="flex space-around max-large-width checkout__flexwrapper">
          <div className="checkout-col checkout-billing">
            <h3>Billing information</h3>
            <div style={{paddingTop: '1px'}}>
              <input type="text" name="firstName" placeholder="First name" required value={this.state.firstName} onChange={this.handleInputChange} />
              <input type="text" name="lastName" placeholder="Last name" required value={this.state.lastName} onChange={this.handleInputChange} />
              <input type="text" name="company" placeholder="Company" value={this.state.company} onChange={this.handleInputChange} />
              <input type="text" name="addressOne" placeholder="Address 1" required value={this.state.addressOne} onChange={this.handleInputChange} />
              <input type="text" name="addressTwo" placeholder="Address 2" value={this.state.addressTwo} onChange={this.handleInputChange} />
              <input type="text" name="city" placeholder="City" required value={this.state.city} onChange={this.handleInputChange} />
              <CountryDropdown
                value={this.state.country}
                onChange={this.selectCountry} />
              <RegionDropdown
                defaultOptionLabel="State/Province"
                disableWhenEmpty={true}
                country={this.state.country}
                value={this.state.region}
                onChange={this.selectRegion} />
              <input type="text" name="zipPostal" placeholder="Zip/Postal Code" value={this.state.zipPostal} onChange={this.handleInputChange} />

            </div>
          </div>
          <div className="checkout-col checkout-payment">
            <h3>Payment information</h3>
            {isInvoice ? <div><label>INVOICE</label></div> : this.state.hidePaymentSection ? <div><label>N/A</label></div> : <div>
              {this.props.userBillingInfo && this.props.userBillingInfo.hasCard &&
              <div>
                <label className="flex">
                  <div className="radio">
                    <input id="userSavedCard" type="radio" name="userSavedCard" value='existing' checked={!this.state.useNewCard} onChange={this.handleUseSavedCardChange} />
                    <label htmlFor="userSavedCard"></label>
                  </div>
                  <label htmlFor="userSavedCard" className="radio-label">Use <span style={{fontWeight: 'bold'}}>{this.props.userBillingInfo.brand}</span> ending in {this.props.userBillingInfo.lastFour}</label>
                </label>

                <label className="flex">
                  <div className="radio">
                    <input id="userSavedCard2" type="radio" name="userSavedCard" value='new' checked={this.state.useNewCard} onChange={this.handleUseSavedCardChange} />
                    <label htmlFor="userSavedCard2"></label>
                  </div>
                  <label htmlFor="userSavedCard2" className="radio-label">Use another card</label>
                </label>
              </div>
              }

              <Transition in={this.state.useNewCard || !this.props.userBillingInfo || !this.props.userBillingInfo.hasCard} timeout={500}>
                {(state) => (
                  <div style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                    marginBottom: '80px',
                    overflow: 'hidden'
                  }}>


                    <div style={{ paddingTop: '1px' }}>
                      <CardNumberElement classes={{ base: 'hero-input' }} onChange={this.ccNumberChanged} style={{ base: { fontFamily: 'Arial', fontSize: '16px', fontWeight: 'normal' } }} placeholder='Card Number' />
                    </div>
                    <div style={{ paddingTop: '1px' }}>
                      <CardExpiryElement classes={{ base: 'hero-input' }} onChange={this.ccExpiryChanged} style={{ base: { fontFamily: 'Arial', fontSize: '16px', fontWeight: 'normal' } }} />
                    </div>
                    <div style={{ paddingTop: '1px' }}>
                      <CardCVCElement classes={{ base: 'hero-input' }} onChange={this.ccCVCChanged} style={{ base: { fontFamily: 'Arial', fontSize: '16px', fontWeight: 'normal' } }} placeholder='CVV' />
                    </div>
                    <label className="flex">
                      <div className="checkbox">
                        <input id="saveCard" type="checkbox" name="saveCard" value={this.state.saveCard} onChange={this.handleSaveNewCardChange} />
                        <label htmlFor="saveCard"></label>
                      </div>
                      <label htmlFor="saveCard" className="checkbox-label">Save this credit card</label>
                    </label>
                  </div>
                )}
              </Transition>
            </div>}





            <div style={{marginTop: '25px'}}>
              <h3>Order notes</h3>
              <input type="text" name="project" placeholder="Project" value={this.state.project} onChange={this.handleInputChange} />
              <input type="text" name="poNumber" placeholder="PO#" required={isInvoice ? true : undefined} value={this.state.poNumber} onChange={this.handleInputChange} />
              <input type="text" name="licenceOwner" placeholder="Licence owner" value={this.state.licenceOwner} onChange={this.handleInputChange} />
              { isInvoice && <input type="text" name="purchaserName" placeholder="Purchaser's name" value={this.state.purchaserName} onChange={this.handleInputChange} /> }
              { isInvoice && <input type="email" multiple name="invoiceCC" placeholder="purchasing@company.com" title="Invoice Recipient Emails" value={this.state.invoiceCC} onChange={this.handleInputChange} />}
            </div>
          </div>
          <div className="checkout-col checkout-summary">
            <CartOrderSummary checkoutStatus={this.props.checkoutStatus}
              totals={this.calculateTotals()}
              disclaimer={true}
              coupon={this.props.coupon}
              auth={this.props.auth}
              getCoupon={this.props.getCoupon}
              getPersistentCoupon={this.props.getPersistentCoupon}
              removeCoupon={this.props.removeCoupon}
              onCheckoutClicked={!this.state.isSubmitted ? this.onCheckoutClicked : undefined}
              isSubmitted={this.state.isSubmitted}
              disabled={this.state.isSubmitDisabled}
              checkoutText="Submit Order"
              currency={this.props.user && this.props.user.chargeInCad ? 'CAD' : 'USD'}
              showModal={this.props.showModal} />
          </div>
          <modals.ShoppingCartError id={constants.modals.shoppingCartError} errorString={this.state.errorString}/>
        </div>

      </form>





    );
  }
}


function stateToProps(state)
{
  return {
    prices: state.shoppingcart.prices,
    userBillingInfo: state.user.userBillingInfo,
    checkoutStatus: state.shoppingcart.checkoutStatus,
    coupon: state.shoppingcart.coupon,
    user: state.user
  }
}

const dispatchToProps = (dispatch, props) => (
  bindActionCreators({
    loadFromServer: () => (dispatch) => {
      dispatch(actions.listPrices({token: props.auth}));
      dispatch(user.actions.getUserBillingInfo(props.auth))
    },
    clearCart: () => actions.clearCart(),
    listPrices: () => actions.listPrices({token: props.auth}),
    checkout: (newOrder, onSuccess, onError) => actions.checkout(newOrder, props.auth, onSuccess, onError),
    getCoupon: (code, auth) => actions.getCoupon(code, props.orderType, auth),
    getPersistentCoupon: (auth) => actions.getPersistentCoupon(props.orderType, auth),
    removeCoupon: () => actions.removeCoupon(),
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(
  injectStripe(
    core.components.withLoading(CheckoutFormDetailed, ['auth'])
  )
)