import React from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import core from '../../_core'
//import { Shape } from '../model'

const RelatedGalleries = ({ data, galleriesArr, galleryItemClass = 'galleries' }) => {
  return(
    <section className="full-wd-section galleries__related">
      {
        Array.isArray(galleriesArr) && galleriesArr.length > 0 &&
          galleriesArr
            .map( (gallery) => {
              let galleryThumbUrl =
                      ( gallery.products && gallery.products.length>0 )
                        ? {backgroundImage: `url( ${gallery.products[0].thumbnailUrl})`, backgroundPosition:'center center',  backgroundSize:'cover', backgroundColor:'rgba(0,0,0,0.8)'}
                        : {}

              return (
                gallery.isActive != 0 && 
                <Link
                  to={`/galleries/${gallery.id}`}
                  key={`relatedGallery-${gallery.id}`}
                  className={`flex column centered ${galleryItemClass}__galleryItem`}
                  style={galleryThumbUrl}
                >
                  <div className="gallery_overlay"></div>
                  <h3 className="text-light">{core.util._truncate(gallery.title, 20)}</h3>
                </Link>
                
              )
            })
      }
    </section>
  )

}

RelatedGalleries.propTypes = {
  galleriesArr: PropTypes.array
}
export default RelatedGalleries
