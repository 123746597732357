/**
* @module search
* actions.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Thu May 17 2018 09:59:14 GMT-0600 (MDT)
* @copyright None
* @last-modified Thu Jul 05 2018 16:39:01 GMT-0600 (MDT)
*/

import core from '../_core'
import flash from '../flash'
import filter from '../filter'
import Service from './service';

import actionTypes from './actionTypes'
import {pageSize, moduleName} from './constants'
import * as errorTypes from './errorTypes'

var searchAndChangeUrl = function(params, service, dispatch) {

  

  dispatch(core.actions.onLoading(moduleName))
  dispatch(filter.actions.loadStarted());

  const modelIds = params.modelIds ? params.modelIds.split(',') : null
  console.log("Searching, modelIds2", modelIds, params.modelIds)
  
  

  console.log("Toby search.", params);

  let limit = params.limit;
  let offset = params.offset;
  limit = pageSize;
  if(!offset)
    offset = 0;
  
  let page = offset / limit + 1;

  console.log("PAGE NUMBER: ", page)

  if(params.history && params.match)
  {
    //push it on the URL
    let url = params.match.path;
    url = url.replace(":pageNumber", page);

    while(url.indexOf(":") > 0)
    {
      let baseUrl = url.substring(0, url.indexOf(":"))
      let restUrl = url.substring(url.indexOf(":"))
      let suffix = "";
      let variable = restUrl;
      if(restUrl.indexOf("/") > 0) //there's more
      {
        variable = restUrl.substring(1, restUrl.indexOf("/"))
        suffix = restUrl.substring(restUrl.indexOf("/"))
      }

      let variableValue =  params.match.params[variable];
      if(variable == "terms" && params.terms)
        variableValue = params.terms;
      url = baseUrl + variableValue + suffix
    }
    
    if(params.history.location.pathname != url)
      params.history.push(url);

  }
  else  
    console.log("No history or match to push urls")



  return Promise.all([
    service.search('products', {...params, modelIds}),
    //service.search('photoshoots', {terms: params.terms})
  ]).then(
    (responses) => { 
      dispatch(onSuccess(actionTypes.search, responses))
      dispatch(filter.actions.loadEnded());
      
    },
    (error) => dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.search)))
  )
}

var search = (params = {}, service = new Service()) => ((dispatch) => {
  return searchAndChangeUrl(params, service, dispatch);
})

var resetStateAndSearch = (page, defaultLimit, defaultSort, terms, modelIds, shootId, type, collectionType, props, service = new Service()) => ((dispatch) => {
  // dispatch(resetSearch());
  // dispatch(filter.actions.loadStarted());


  // console.log('%c resetStateAndSearch', 'background-color:red', props)
  // console.log('%c resetStateAndSearch SHOOT ID', 'background-color:red', props)

  let useDefaults = false;

  let filters = {};
  let currentPageNumber = Math.floor(props.pagination.offset / props.pagination.limit)
  
  //if everything is identical to the store -- this is probably from a back; so don't reset everything
  if(props.modelIds == modelIds && props.terms == terms && props.shootId == shootId 
    && (props.filters && props.filters.type && props.filters.type[type]) 
    && (props.filters && props.filters.collectionType && props.filters.collectionType[collectionType]))
  {
    //do nothing
    console.log("IDENTICAL, Back button click", page)
    filters = props.filters;

    if (page !== currentPageNumber) {
      dispatch(filter.actions.changePagination({offset: page * props.pagination.limit, limit: props.pagination.limit}));
    }
  }
  else
  {
    console.log("DIFFERENT", page, props.modelIds, modelIds, props.terms, terms, props.shootId, shootId)
    useDefaults = true
   
    if(modelIds)
      dispatch(filter.actions.changeModelIds(modelIds));
    else {
      dispatch(filter.actions.resetModelIds());
    }
    
    
    if(shootId)
      dispatch(filter.actions.changePhotoshoot(shootId));
    else
      dispatch(filter.actions.clearFilters());

    if(terms)
      dispatch(filter.actions.changeTerms(terms));
    else {
      dispatch(filter.actions.resetTerms());
    }

    console.log("%ctype", 'background-color: orange', type);
    console.log("%ccollectionType", 'background-color: orange', collectionType);
    
    
    dispatch(filter.actions.changeCollectionType(collectionType));
    filters.collectionType = { Premium: false, Free: false }
    console.log('%cChanging Collection Type 1', 'background-color: yellow', collectionType)
    if (collectionType !== 'All') {
      console.log('%cChanging Collection Type 2', 'background-color: yellow', collectionType)
      filters.collectionType[collectionType] = true;
    }
    
    dispatch(filter.actions.changeType(type));
    filters.type = { Image: false, Video: false }
    filters.type[type] = true
  
    // console.log('%cfilters', 'background-color:orange', filters)
  }
  //const splitModelIds = modelIds ? modelIds.split(',') : null
  //console.log("Searching, modelIds1", splitModelIds, modelIds)

  var tempParams = {
    terms,
    filters,
    offset: useDefaults ? 0 : page * props.pagination.limit, 
    limit: useDefaults ? defaultLimit : props.pagination.limit,
    sort: useDefaults ? defaultSort : props.sort, 
    modelIds,
    history: props.history,
    match: props.match
  }

  console.log("TempParams", tempParams)

  if(shootId && shootId.length > 0)
  {
    tempParams.filters.photoshootId = {};
    tempParams.filters.photoshootId[shootId] = true
  }

  return searchAndChangeUrl(tempParams, service, dispatch);
  // return Promise.all([
  //   service.search('products', {...tempParams}),
    
  // ]).then(
  //   (responses) => { 
  //     dispatch(onSuccess(actionTypes.search, responses))
  //     dispatch(filter.actions.loadEnded());
  //   },
  //   (error) => dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.search)))
  // )
})



var searchPhotoshoots = (params = {}, service = new Service()) => ((dispatch) => {
  dispatch(core.actions.onLoading(moduleName))
  dispatch(filter.actions.loadStarted());

  return service.search('photoshoots', params)
    .then(
      (response) => {
        dispatch(onSuccess(actionTypes.search, [response]));
        dispatch(filter.actions.loadEnded());
      },
      (error) => dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.search)))
    )
    // .then(
    //   ({type, data}) => dispatch(onSuccess(actionTypes.search, [response]))
    // )
})



var onSuccess = (type, [productResponse, photoshootResponse]) => {
  let photoshootsObj;
  const data = {type}

  // Weird data structure to fit how filter module was built
  if (productResponse && productResponse.data.data.products) {
    data.products = {
      data: { 
        products: productResponse.data.data.products,
      },
      
      info: productResponse.data.info
    }

    data.relatedPhotoShoots = {
      data: { 
        photoshoots: productResponse.data.data.relatedPhotoShoots,
      },
      //info: productResponse.data.info
    
    }
    
  }

  if (photoshootResponse && photoshootResponse.data.data.photoshoots) {
    photoshootsObj = photoshootResponse.data.data.photoshoots && photoshootResponse.data.data.photoshoots.length > 0 &&
        photoshootResponse.data.data.photoshoots.map( (photoshoot) => {
          const pdata = renameSingleProductsArr(photoshoot)
          return pdata
        })
    data.photoshoots = {
      data: { photoshoots: photoshootsObj }
    }
  }

  return data
}

var renameSingleProductsArr = (data) =>{
  data.products = data.photoshootProducts
  data.photoshootProducts = undefined
  return data
}


var resetSearch = () => ({
  type: actionTypes.resetSearch,  
})

export {
  search,
  resetStateAndSearch,
  searchPhotoshoots,
  onSuccess,
  renameSingleProductsArr,
  resetSearch
}