/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 19:58:42
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-06-19 16:48:13
 */

import core from '../_core'

import * as actionTypes from './actionTypes'
import { moduleName } from './constants'
import {generate} from './model'

export const reducer = (state = generate(), action) => {
  const loadingAction = core.actionTypes.loading(moduleName);
  const errorAction = core.actionTypes.error(moduleName);

  switch (action.type) {
  case actionTypes.list:
    return {
      data: action.photoshoots,
      info: action.info
    }
  case actionTypes.resetPhotoshoots:
    return({
      data: null,
      info: null
    })
  case loadingAction:
    return core.model.generateLoading()
  case errorAction:
    return action.state
  default:
    return state
  }
}

export const contextReducer = (state = null, action) => {
  const loadingAction = core.actionTypes.loading(`${moduleName}Context`);
  const errorAction = core.actionTypes.error(`${moduleName}Context`);

  switch (action.type) {
  case actionTypes.getPhotoshoot:
    return {
      data: action.photoshoot,
      info: action.info,
      stills: action.info.type === 'image' ? action.photoshoot.products : state.stills,
      videos: action.info.type === 'video' ? action.photoshoot.products : state.videos
    }
  case loadingAction:
    return core.model.generateLoading()
  case errorAction:
    return action.state
  default:
    return state
  }
}