/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-22 12:14:31
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-07-11 13:13:19
 */

import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Filter from './Filter'
import Header from './Header'
import Pagination from './Pagination'

import * as actions from '../actions'
import * as selectors from '../selectors'

/*
 * Simple HOC to add the filter component to the react hierarchy
 * Expected to be used with the any page / component that accepts filtering
 */

export default (WrappedComponent) => {
  const withFilter = (props) => {

    console.log("hello", props)
    if (!props.data) {
      return (null)
    }

    const { data, info } = props.data
    const { updateProducts, filterSettings, changePagination, history, match, paginationUrlPrefix } = props
    return (
      <section className="results">
        {
          data && info && (
            <section className="results__view">
              <section className="flex ">
                <Filter {...props} />
                <section className={ `filters__mobile_bg ${!props.areFiltersHidden && 'hidden-large'} `} onClick={props.toggleFilters}>
                </section>
                <section className="flex column filters__header_and_grid">
                  <Header {...props} data={data} info={info}  />
                  <WrappedComponent {...props} />
                </section>
              </section>
              {!props.loading &&
                <Pagination urlPrefix={paginationUrlPrefix} info={info} goPage={updateProducts} history={history} match={match} filterSettings={filterSettings} changePagination={changePagination} />
              }
            </section>
          )
        }
      </section>
    )
  }

  withFilter.propTypes = {
    data: PropTypes.object,
    areFiltersHidden: PropTypes.bool,
    updateProducts: PropTypes.func.isRequired

  }

  const stateToProps = (state) => ({
    filterSettings: selectors.getFilterSettings(state),
    filters: selectors.getFilters(state),
    sort: selectors.getSort(state),
    pagination: selectors.getPagination(state),
    terms: selectors.getTerms(state),
    modelIds: selectors.getModelIds(state),
    areFiltersHidden: selectors.getFiltersHidden(state),
    loading: selectors.getSearchLoading(state)
  })

  const dispatchToProps = (dispatch) => (
    bindActionCreators({
      ...actions
    }, dispatch)
  )

  return connect(stateToProps, dispatchToProps)(withFilter)
}
