/**
 * src/modules/flash/index.js
 */

import * as actions from './actions'
import components from './components'
import { moduleName } from './constants'
import reducer from './reducer'

export default {
  actions,
  components,
  moduleName,
  reducer
}