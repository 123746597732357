/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:16:34
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-07-11 16:50:51
 */

// dead component?

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import core from '../../_core'
import filter from '../../filter'
import product from '../../product'
import board from '../../board'
import shoppingcart from '../../shoppingcart'

import * as actions from '../actions'
import components from '../components'
import { Shape } from '../model'
import * as selectors from '../selectors'

class Photoshoot extends React.Component {
  constructor(props) {
    super(props)
    props.resetTerms()
  }

  render() {
    const { data } = this.props.data
    const metaData = {
      description : `Hero Images | ${data.title} | ${data.description}`,
      keywords : data.keywords,
      title: `${data.title} | Hero Images`,
      name : 'Hero Images',
      url : window && window.location && `${window.location.protocol}//${window.location.host}${this.props.location.pathname}`,
      image : data.thumbnail !== null ? data.thumbnail : data.products[0].thumbnailUrl,
      TwitterSite : '@heroimages',
      TwitterCard : 'summary',
      ogType : 'article'
    }
    return (
      <section className="photoshoot__wrapper">
        <Helmet>
          <title>{data.title}</title>
          <meta name="description"           content={metaData.description} />
          <meta name="keywords"              content={metaData.keywords} />

          <meta itemProp="name"              content={metaData.name}/>
          <meta itemProp="description"       content={metaData.description} />
          <meta itemProp="image"             content={metaData.image} />

          <meta name="twitter:card"          content={metaData.TwitterCard} />
          <meta name="twitter:site"          content={metaData.TwitterSite} />
          <meta name="twitter:title"         content={metaData.title} />
          <meta name="twitter:description"   content={metaData.description} />
          <meta name="twitter:creator"       content={metaData.TwitterSite} />
          <meta name="twitter:image"         content={metaData.image}/>

          <meta property="og:url"            content={metaData.url} />
          <meta property="og:type"           content={metaData.ogType} />
          <meta property="og:title"          content={metaData.title} />
          <meta property="og:description"    content={metaData.description} />
          <meta property="og:image"          content={metaData.image} />
        </Helmet>
        <shoppingcart.components.containers.AddMultipleToCart />
        <components.containers.Single
          {...this.props}
          data={data}
        />
      </section>
    )
  }
}

Photoshoot.propTypes = {
  data: PropTypes.oneOfType([
    Shape,
    core.model.ErrorShape,
    core.model.LoadingShape
  ]),
  info: core.model.InfoShape,
  match: PropTypes.object,
  photoshoot: Shape,
  featured: product.Shape,
  id: PropTypes.number
}

const stateToProps = (state, props) => {
  const stateData = selectors.getPhotoshoot(state, props.id ? props.id : props.match.params.id)

  return {
    data: stateData,
    info: (stateData && stateData.info) || stateData,
    featured: selectors.getFeatured(state, props.match.params.id),
    filters: filter.selectors.getFilters(state),
    pagination: filter.selectors.getPagination(state),
    sort: filter.selectors.getSort(state)
  }
}

const dispatchToProps = (dispatch, props) => (
  bindActionCreators({
    ...actions,
    updateProducts: (offset, limit, params) => (
      actions.filterProducts(props.id ? props.id : props.match.params.id, {...params, offset, limit})
    ),
    resetTerms: () => filter.actions.resetTerms(),
    showAddMultipleToCartModal: (products) => shoppingcart.actions.showAddMultipleToCartModal(products),
  }, dispatch)
)

export default withRouter(
  connect(stateToProps, dispatchToProps, (stateProps, dispatchProps, ownProps) => {
    return Object.assign({},
      stateProps,
      dispatchProps,
      ownProps,
      {
        loadFromServer: () => {
          return dispatchProps.filterProducts(ownProps.id ? ownProps.id : ownProps.match.params.id, {
            filters: stateProps.filters,
            offset: stateProps.pagination.offset,
            limit: stateProps.pagination.limit,
            sort: stateProps.sort
          })
        }
      }
    )
  })(
    core.components.withLoading(
      filter.components.withFilter(
        core.components.withError(Photoshoot)
      )
    )
  )
)