/**
 * src/modules/auth/components/pages/ResetPassword.js
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as constants from '../../constants'
import core from '../../../_core'
import modals from '../modals'
import Service from '../../service'

class ResetPassword extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      success: false
    }



    this.onClose = this.onClose.bind(this)
    this.onLoggingIn = this.onLoggingIn.bind(this)
    
  }

  componentDidMount() {

    this.props.showModal({id: constants.modals.resetPassword});
  }

  verifyPassword(passwordToken) {
    let service = new Service();
    return service.verifyPassword({passwordToken});
  }

  resetPassword(details) {
    let service = new Service();
    return service.resetPassword(details);
  }

  onLoggingIn(e) {
    e.preventDefault();
    this.props.showModal({id: constants.modals.login});
    this.setState({success: true});
  }

  
  onClose() {
    this.props.hideModal();
  }

  render() {
    return (
      // <AuthContainer modal={constants.modals.resetPassword} id={constants.modals.resetPassword} initialOpen={true}>
      // </AuthContainer>
      <section>
        <modals.ResetPassword {...this.props}  verifyPassword={this.verifyPassword} resetPassword={this.resetPassword} onClose={this.onClose} id={constants.modals.resetPassword} onSuccess={this.onLoggedIn} onLoggingIn={this.onLoggingIn}/>
        <h1>Password Reset</h1>
        {this.state.success && 
        <h2>Password changed successfully</h2>
        }
      </section>
    )
  }
}

ResetPassword.propTypes = {
  match: PropTypes.object
}

const stateToProps = (state) => {
  return   {
  }
}

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(ResetPassword)
