/**
* @module gallery
* errorTypes.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue May 29 2018 10:38:52 GMT-0600 (MDT)
* @copyright None
* @last-modified Mon Jun 11 2018 12:52:26 GMT-0600 (MDT)
*/

export default {
  list: {
  },
  get: {
    404: 'Unable to retrieve the gallery'
  }
}