/**
* @module gallery
* Galleries.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Thu May 31 2018 09:27:11 GMT-0600 (MDT)
* @copyright None
* @last-modified Thu Jul 12 2018 16:42:34 GMT-0600 (MDT)
*/

import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import auth from '../../auth'
import core from '../../_core'
import filter from '../../filter'

import * as actions from '../actions'
import components from '../components'
import * as selectors from '../selectors'
import {Shape} from '../model'
import {logo} from '../img'

const Galleries = ({data, history, loadFromServer, changePagination, isTailored, setTailoredView, auth, isAdmin}) => {

  //const goPage = () => {}

  const goPage = (offset, limit) => {
    loadFromServer(auth, {
      offset,
      limit,
      isTailored
    })
  }

  var imgMeta = logo;
  if(data.galleryProducts && data.galleryProducts.length > 0)
    imgMeta = data.galleryProducts[0].thumbnailUrl;

  const metaData = {
    description : 'Galleries | Hero Images',
    keywords : 'Hero Images, Galleries, Stock Photography',
    title: 'Galleries | Hero Images',
    name : 'Hero Images',
    url : window && window.location && `${window.location.protocol}//${window.location.host}${history.location.pathname}`,
    image : logo,
    TwitterSite : '@heroimages',
    TwitterCard : 'summary',
    ogType : 'article'
  }

  return (
    <section className="full-wd-page photoshoots">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description"           content={metaData.description} />
        <meta name="keywords"              content={metaData.keywords} />

        <meta itemProp="name"              content={metaData.name}/>
        <meta itemProp="description"       content={metaData.description} />
        <meta itemProp="image"             content={imgMeta} />

        <meta name="twitter:card"          content={metaData.TwitterCard} />
        <meta name="twitter:site"          content={metaData.TwitterSite} />
        <meta name="twitter:title"         content={metaData.title} />
        <meta name="twitter:description"   content={metaData.description} />
        <meta name="twitter:creator"       content={metaData.TwitterSite} />
        <meta name="twitter:image"         content={metaData.image}/>

        <meta property="og:url"            content={metaData.url} />
        <meta property="og:type"           content={metaData.ogType} />
        <meta property="og:title"          content={metaData.title} />
        <meta property="og:description"    content={metaData.description} />
        <meta property="og:image"          content={metaData.image} />
      </Helmet>
      <section>
        <h1 className="spacer-padding-top-20" >Galleries</h1>
        <h4 className="header-text-width">Explore our most popular and asked-for curated galleries. </h4>
        { isAdmin && <label htmlFor='tailoredView' className='button link-icon' style={{marginBottom: 15}}>
          <input type='checkbox' id='tailoredView' checked={isTailored} onChange={(e) => setTailoredView(e.target.checked)} />
          &nbsp;view tailored galleries
        </label> }
      </section>
      {
        data && data.data &&
        (
          <section className="spacer-padding-bottom-80">
            <components.containers.List data={data.data} rowHeight={300} />
            <filter.components.Pagination data={data.data} info={data.info} goPage={goPage} changePagination={changePagination}/>
          </section>
        )
      }
    </section>
  )
}

Galleries.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(Shape),
    core.model.ErrorShape,
    core.model.LoadingShape
  ])
}

const stateToProps = (state) => ({
  auth: auth.selectors.getUserAuthToken(state),
  isAdmin: auth.selectors.isUserAdmin(state),
  galleries: selectors.getGalleries(state),
  data: selectors.getGalleries(state),
  isTailored: selectors.getGalleries(state).isTailored
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    ...actions,
    changePagination: ({offset, limit} = {}) => filter.actions.changePagination({offset, limit}),
    loadFromServer: (auth, {offset, limit, isTailored} = {}) => actions.list({offset, limit, isTailored}, auth)
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(
  withRouter(
    core.components.withLoading(
      core.components.withError(
        core.components.withNotFound(Galleries)
      ), ['auth', 'isTailored']
    )
  )
)