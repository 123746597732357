/**
 * src/modules/flash/components/modals/Message.js
 */

import React from 'react'
import PropTypes from 'prop-types'

import core from '../../../_core'

const Message = ({message, shouldStay, onClose}) => (
  <div className="text-center">
    <div className="spacer-margin-bottom-40">
      {message}
    </div>
    {
      !shouldStay && (
        <div>
          <button onClick={onClose}>OK</button>
        </div>
      )
    }
  </div>
)

function createMessageMarkup(message) {
  return { __html: message }
}

Message.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  shouldStay: PropTypes.bool,
  onClose: PropTypes.func.isRequired
}

export default core.components.modals.Modal(Message)