/**
* @module search/components/containers
* Results.js
*
* @description Contains a list of plain search results
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Fri May 18 2018 11:29:56 GMT-0600 (MDT)
* @copyright None
* @last-modified Fri May 18 2018 16:07:37 GMT-0600 (MDT)
*/

import React from 'react'
import PropTypes from 'prop-types'

import core from '../../../_core'
import layout from '../../../layout'
import photoshoot from '../../../photoshoot'

import { moduleName } from '../../constants'
import { Shape } from '../../model'

class Result extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { data, terms, products } = this.props;
    let listItems = [];

    if (data && data.data && Array.isArray(data.data.photoshoots) && data.data.photoshoots.length >= 2) {
      listItems = data.data.photoshoots.map(ps => <photoshoot.components.Thumb
        data={ps}
        key={`${moduleName}-photoshoot-${ps.id}`}
        hasMenu={false}
      />)

      return (
        // <div className='grid__photoshoots'>
        //   {listItems}
        // </div>
        <section className="SearchResults__shoots spacer-padding-vertical-40">
          { data.data.photoshoots.length > 0 
            &&
            <div>
              <h3 className="text-left">shoots that contain these products</h3>              
              <photoshoot.components.containers.List data={data.data.photoshoots} products={products} updateFilterAndReload={this.props.updateFilterAndReload} rowHeight={200} showShootNumber={true} applyShootFilter={true}/>
            </div>
          }
        </section>
      )
    }

    return (null)
  }
}

Result.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(Shape),
    core.model.ErrorShape,
    core.model.LoadingShape
  ]),
  screen: layout.ScreenShape
}

export default Result