import React from 'react'
import PropTypes from 'prop-types'
// import { Helmet } from 'react-helmet';
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import core from '../../_core'
import auth from '../../auth'

import * as actions from '../actions'
import * as selectors from '../selectors'

import CartItems from '../components/CartItems'
import CartOrderSummary from '../components/CartOrderSummary'
import PriceOption from '../components/PriceOption'
import * as cartConstants from '../../shoppingcart/constants'
import AddBundleToCartModal from '../../shoppingcart/components/modals/AddBundleToCartModal'


class ViewCart extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      selectedOption: null
    };

    // this.searchTermClick = this.searchTermClick.bind(this);
    // this.handleRadioChange = this.handleRadioChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    // this.onPhotoshootClick = this.onPhotoshootClick.bind(this);
    this.onRemoveCartItem = this.onRemoveCartItem.bind(this);
    this.onCheckoutClicked = this.onCheckoutClicked.bind(this);
    this.calculateTotals = this.calculateTotals.bind(this);
    
    
  }

  componentDidUpdate(prevProps) {
    if (this.props.userProductOwnership !== prevProps.userProductOwnership) {
      this.props.cart.forEach(cartItem => {
        if (this.props.userProductOwnership.find(product => cartItem.product.id == product.productId && product.priceIds.includes(cartItem.priceId))) {
          this.props.removeFromCart(cartItem)
        }
      })
    }
  }

  // onPhotoshootClick(product) {
  //   const { history } = this.props;
  //   history.push(`/product/${product.id}`)

  // }

  // handleRadioChange(e){
  //   this.setState({
  //     selectedOption: e.target.value
  //   })
  // }

  onRemoveCartItem(cartItem) {    
    console.log("REMOVE ", cartItem)
    this.props.removeFromCart(cartItem);
    setTimeout(this.props.removeJustAdded, 500);
  }

  calculateTotals() {
    let subTotal = 0;
    let discount = '0.00';
    this.props.cart.forEach(cartItem => {
      let price = selectors.getPrice(this.props.prices, cartItem.priceId);
      if(price)
        subTotal += price.price
    });
    if (this.props.coupon) {
      discount = (Math.min(this.props.coupon.flatDiscount, subTotal) + Math.max(subTotal - this.props.coupon.flatDiscount, 0) * this.props.coupon.percentDiscount / 100.0).toFixed(2)
    }
    return {
      subTotal: subTotal.toFixed(2),
      discount,
      total: (subTotal - parseFloat(discount)).toFixed(2),
      federalTaxLabel: 'Sales Tax',
      federalTaxUSD: undefined,
      regionalTaxUSD: undefined
    }
  }

  onCheckoutClicked(e) {
    e.preventDefault();

    if(!this.props.auth)
    {
      this.props.showModal({id: auth.constants.modals.login});
      return false;
    }
    else
    {
      this.props.history.push(`/shoppingcart/checkout?invoice=${e.target.value === "Invoice"}`, this.props.location.state ? { from: this.props.location.state.from } : undefined)
      return true;
    }
  }
  // handleSubmit(e){
  //   e.preventDefault();
  // }

  // searchTermClick(term){
  //   let trimmedTerm = term.trim();
  //   let searchURI = `/search/${trimmedTerm}`;
  //   window.location.href = encodeURI(searchURI);
  // } 

  render(){
    // const {data} = this.props;
    // const keywordArray = data.keywords ? data.keywords.split(',').filter(term => term.trim().length > 0) : []
    // const photoshootsArr = data.photoshoots && data.photoshoots.length === 1 ? data.photoshoots[0] : []
    // const galleriesArr =  data.galleries  && data.galleries.length > 0 ? data.galleries : []
    // let keywordArrayLength = keywordArray.length
    const currency = this.props.user && this.props.user.chargeInCad ? 'CAD' : 'USD'
    var cheapestBundle = this.props.prices.map(p => p.bundles.find(b => b.isActive && !b.isHidden)).find(b => b);
    var fromBundlePriceFragment = <b>Bundle and save.<br /> Images from {<span className={"enMoney " + currency}>{cheapestBundle && cheapestBundle.bundledPrice}</span>}</b>
    return(
      <section className="full-wd-page view-cart">
        <AddBundleToCartModal id={cartConstants.modals.addBundleToCart}/>
        <h1>Cart</h1>
        <section className="flex align-baseline justified spacer-padding-top-20">
          <section className="flex space-around max-large-width view-cart__flexwrapper">

            {this.props.cart.length == 0 && 
              <span style={{fontStyle: 'italic'}}>Your cart is currently empty</span>
            }

            {this.props.cart.length > 0 && 
              <div className="cart-left">
                <CartItems prices={this.props.prices} cart={this.props.cart} onRemoveCartItem={this.onRemoveCartItem} currency={currency} />
              </div>
            }

            {this.props.cart.length > 0 &&
              <form className="cart-right text-left">
                <CartOrderSummary
                  cart={this.props.cart}
                  totals={this.calculateTotals()}
                  onCheckoutClicked={this.onCheckoutClicked}
                  coupon={this.props.coupon}
                  persistentCoupon={this.props.persistentCoupon}
                  auth={this.props.auth}
                  getCoupon={this.props.getCoupon}
                  getPersistentCoupon={this.props.getPersistentCoupon}
                  removeCoupon={this.props.removeCoupon}
                  showInvoiceOption={this.props.user ? this.props.user.allowInvoice : false}
                  disclaimer={false}
                  currency={currency}
                  showModal={this.props.showModal} />
                {/* <div className='bundle_advert'>
                  <PriceOption isAdvert={true} name={fromBundlePriceFragment}
                    description="Prepaid options for video and print volume."
                    priceFragment={<a className='button slim' onClick={this.props.showAddBundleToCartModal}>Details</a>}
                    currency={currency} />
                </div> */}
              </form>
            }


          </section>
        </section>
      </section>
    )
  }

}

ViewCart.propTypes = {
  
  // info: core.model.InfoShape,
  // match: PropTypes.object,
  // photoshoot: Shape,
  // featured: product.Shape,
  // id: PropTypes.number
  history: PropTypes.object
}


function stateToProps(state)
{
  return {
    prices: state.shoppingcart.prices,
    cart: state.shoppingcart.cart,
    userProductOwnership: state.shoppingcart.userProductOwnership,
    auth: auth.selectors.getUserAuthToken(state),
    user: state.user,
    coupon: state.shoppingcart.coupon
  }
}

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    loadFromServer: (auth, throughProps) => (dispatch) => {
      dispatch(actions.listPrices({token: auth}))
      dispatch(actions.getUserProductOwnership(throughProps.cart.map(cartItem => cartItem.product.id), auth))
    },
    getCoupon: (code, auth) => actions.getCoupon(code, 'product', auth),
    getPersistentCoupon: (auth) => actions.getPersistentCoupon('product', auth),
    removeCoupon: () => actions.removeCoupon(),
    removeFromCart: (cartItem) => actions.removeFromCart(cartItem),
    removeJustAdded: () => actions.removeJustAdded(),
    showAddBundleToCartModal: () => actions.showAddBundleToCartModal(),
    showModal: core.actions.showModal,
  }, dispatch)
)


export default withRouter(
  connect(stateToProps, dispatchToProps)(
    core.components.withLoading(ViewCart, ['auth', 'cart'])
  )
)
