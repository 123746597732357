/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:48:50
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-06-20 13:54:46
 */

import * as FileDownload from 'js-file-download'

import core from '../_core'
//import filter from '../filter'
import flash from '../flash'

import actionTypes from './actionTypes'
import {moduleName} from './constants'
import * as errorTypes from './errorTypes'
import Service from './service';

const _renameSingleProductsArray = (data) => {
  data.products = data.galleryProducts
  data.galleryProducts = undefined
  return data
}

const _distillModelsArray = (data) =>{
  let idArr = new Set() 
  let prodArr = []
  let modelSeachString = ''

  // let testData = [  
  //   {id:0, products:[{id:123, somethingelse:2}, {id:123},{id:124}]},
  //   {id:1, products:[{id:124}, {id:125},{id:124}]}
  // ]

  prodArr = data.map( model => {
    modelSeachString += modelSeachString === '' ?  `${String(model.id)}` : `,${String(model.id)}`
    let noduplicateProd = model.products.map( product => { 
      if (!idArr.has(product.id)){
        idArr.add(product.id)
        return product
      }  
    })
    return noduplicateProd
  } )

  prodArr = prodArr.concat.apply([], prodArr)

  prodArr = prodArr.reduce( (result, element) => { 
    if (element) {result.push(element)}
    return result
  }, [])
  
  data.modelSeachString = modelSeachString;
  data.modelProductSet = prodArr;


  
  return data
}

export const downloadComp = (filename, id = '', authToken = undefined, service = new Service()) => ((dispatch) => {
  return service.downloadComp(id, authToken)
    .then(
      response => service.downloadSignedUrl(response.data.data)
        .then(
          fileResponse => {
            FileDownload(fileResponse.data, response.data.info);
          },
          error => console.log(error)
        ),
      (error) => {
        console.log(error)
      }
    )
})
export const downloadFreePremium = (filename, id = '', authToken = undefined, service = new Service()) => ((dispatch) => {
  return service.downloadFreePremium(id, authToken)
    .then(
      response => service.downloadSignedUrl(response.data.data)
        .then(
          fileResponse => {
            FileDownload(fileResponse.data, response.data.info);
          },
          error => console.log(error)
        ),
      (error) => {
        console.log(error)
      }
    )
})

export const getProduct = (id, authToken = undefined, service = new Service()) => (dispatch) =>{
  dispatch(core.actions.onLoading(moduleName))

  return service.getProduct(id, authToken)
    .then(
      (response) => {
        response.data.data.photoshoots = response.data.data.photoshoots.length > 0
          ?
          response.data.data.photoshoots.map(
            photoshoot => {
              photoshoot.products = photoshoot.photoshootProducts
              photoshoot.photoshootProducts = undefined;
              return photoshoot
            }
          )
          : []
        response.data.data.galleries = response.data.data.galleries.length > 0
          ?
          response.data.data.galleries.map(
            gallery => {
              gallery = _renameSingleProductsArray(gallery)
              return gallery
            }
          )
          : []

        response.data.data.models = response.data.data.models.length > 0
          ?
          response.data.data.models = _distillModelsArray(response.data.data.models)
          : []

        let data = response.data.data ? response.data.data : null
        return data
      },
      (error) => {
        !error.toString().includes('404') && dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.list)));
        dispatch(core.actions.onError(moduleName, error));
      }
    )
    .then(
      //console.log('product',product);
      (product) => {
        product && 
        dispatch({
          type: actionTypes.get,
          product
        })
      }
    
    )


}

export const selectProduct = (product) => ({
  type: actionTypes.select,
  product
})

export const unselectProduct = () => ({
  type: actionTypes.unselect
})
