/**
 * src/modules/auth/service.js
 */

import core from '../_core'

class Service extends core.Service {
  login({email, password}) {
    return super.post('/user/login', {email, password})
  }

  check(authToken) {
    return super.get('/user/check', {}, authToken)
  }

  register({firstName, lastName, email, company, industry, password, newsletter, hubspotcookie, jobTitle, profileCountry }) {
    return super.post('/user/signUp', {firstName, lastName, email, company, industry, password, newsletter, hubspotcookie, jobTitle, profileCountry})
  }

  forgetPassword({email}) {
    return super.post('/user/forgetPassword', {email})
  }

  verifyPassword({passwordToken}) {
    return super.get('/user/resetPassword', {passwordToken})
  }

  resetPassword({passwordToken, password}) {
    return super.put('/user/resetPassword', {passwordToken, password})
  }
}

export default Service