/**
 * src/modules/auth/model.js
 */

import PropTypes from 'prop-types'
import * as constants from './constants'

export const Shape = PropTypes.shape({
  id: PropTypes.number,
  role: PropTypes.string,
  token: PropTypes.string,
  user: PropTypes.object
})

export const generate = (
  {
    id = undefined,
    role = undefined,
    token = undefined,
    user = undefined
  } = {}) => ({
  id,
  role,
  token,
  user
})

export const generateCredentials = (
  {
    email = undefined,
    password = undefined
  } = {}) => ({
  email,
  password
})

export const isAdmin = (auth) => (
  auth ? auth.role === constants.roles.admin : false
)

export const getAuthToken = (auth) => (
  auth ? auth.token : null
)