/**
 * src/modules/_core/utilities.js
 */

import React from 'react'
import moment from 'moment'

import { validators, dateFormat } from './constants'

/**
 * Quick shortcut to use moment to format a date to preset format
 * @param {string || Date} date
 * @returns {object}
 */
export const formatDate = (date, format = dateFormat) => (new moment(date).format(format))

/**
 * Merge and return a new copy of array, merging existing context object. If not found, object is appended.
 * Key must exist on every object of the original array
 * @param {array} array
 * @param {object} context
 * @param {string} key
 * @returns {Array}
 */
export const mergeByKey = (array, context, key = 'id') => {
  if (!array.find(object => object[key] && object[key] === context[key])) {
    return array.concat(context)
  } else {
    return array.map(object => (
      object[key] === context[key] ? {...object, ...context} : object
    ))
  }
}

/**
 * Remove an object from the array
 * @param {array} array
 * @param {object} context
 * @param {string} key
 * @returns {Array}
 */
export const deleteByKey = (array, context, key = 'id') => (
  array.filter(object => (object[key] !== context[key]))
)

/**
 * Sort an array by key with optional direction
 * @param {array} array
 * @param {string} key
 * @param {boolean} isAscending
 * @returns {Array}
 */
export const sortByKey = (array, key = 'id', isAscending = true) => {
  return array.sort((a, b) => (
    isAscending ?
      (a[key] < b[key] ? -1 : 1) :
      (a[key] > b[key] ? -1 : 1)
  ))
}

/**
 * Given a defined validator and a value, return the validity of the value
 * @param {RegExp} validator
 * @param {*} value
 * @returns {boolean}
 */
export const validate = (validator, value) => (
  typeof validators[validator] !== 'undefined' ?
    validators[validator].test(value) :
    false
)

/**
 * Pass properties onto children components, skips a child if it's not a component
 * @param {*} children
 * @param {*} props
 * @returns {object}
 */
export const childrenComponents = (children, props) => (
  React.Children.map(children, child => (
    typeof child.type === 'function' ?
      React.cloneElement(child, {...props}) :
      React.cloneElement(child)
  ))
)

/**
 * Given an error object and expected error codes, return an error message
 * @param {Error} error
 * @param {object} errorTypes
 * @returns {string}
 */
export const errorHandler = (error, errorTypes) => {
  if (error && error.response && error.response.status && errorTypes[error.response.status]) {
    return errorTypes[error.response.status]
  } else {
    return `unexpected error: ${error.message}`
  }
}

/**
 * Given a map of plural labels and a value, get the plural text for the value
 * @param {object} pluralMap
 * @param {Number} value
 * @returns {string}
 */
export const pluralize = (pluralMap, value) => {
  if (!value) return pluralMap.none
  else if (value === 1) return `${value} ${pluralMap.one}`
  else if (value > 1) return `${value} ${pluralMap.many}`
  else return value;
}

export const _truncate = ( mystring, n, useWordBoundary ) => {
  if (mystring.length <= n) { return mystring; }
  var subString = mystring.substr(0, n-1);
  return (useWordBoundary
    ? subString.substr(0, subString.lastIndexOf(' '))
    : subString) + '…';
};

export const capitalizeFirstLetter = ( str ) => {
  if (!str || str.length <= 0) { return str; }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};


