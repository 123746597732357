/**
* @module board
* actions.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Mon Jun 11 2018 09:56:36 GMT-0600 (MDT)
* @copyright None
* @last-modified Fri Jun 22 2018 16:24:11 GMT-0600 (MDT)
*/

import auth from '../auth'
import core from '../_core'
import flash from '../flash'

import { moduleName } from './constants'
import actionTypes from './actionTypes'
import errorTypes from './errorTypes'
import Service from './service'

const _renameSingleProductsArray = (data) => {
  if (data) {
    data.products = data.boardProducts || []
    //data.products = data.products || []
    data.boardProducts = undefined
  }
  return data
}

const _compileResponseData = (responseData) => {
  const board = _renameSingleProductsArray(responseData.data)
  const info = responseData.info
  return {board, info}
}

export const list = ({offset, limit, token} = {}, service = new Service()) => (dispatch) => {
  if (!token) {
    return dispatch(core.actions.showModal({id: auth.constants.modals.login}));
  }

  //dispatch(core.actions.onLoading(moduleName))

  return service.list({offset, limit, token})
    .then(
      (response) => {
        let info = response.data.info
        let boards = response.data.data.map(
          board => _renameSingleProductsArray(board)
        )

        dispatch({
          type: actionTypes.list,
          boards,
          info
        })
      },
      (error) => {
        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.list)));
        dispatch(core.actions.onError(moduleName, error));
      }
    )
}

export const get = (id, service = new Service()) => (dispatch) => {
  dispatch(core.actions.onLoading(`${moduleName}Context`))

  return service.get(id)
    .then(
      (response) => (_compileResponseData(response.data))
    )
    .then(
      ({board, info}) => dispatch({
        type: actionTypes.get,
        board,
        info
      }),
      (error) => {
        !error.toString().includes('404') && dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.list)));
        dispatch(core.actions.onError(moduleName, error));
      }
    )
}

export const addProduct = (data, service = new Service()) => (dispatch) => {
  const { id, productId, token } = data
  if (!token) {
    return dispatch(core.actions.showModal({id: auth.constants.modals.login}));
  }

  return service.addProduct(id, {productId, token})
    .then(
      response => _compileResponseData(response.data)
    )
    .then(
      ({board, info}) => {
        dispatch({
          type: actionTypes.addProduct,
          board,
          info
        })
        return board
      },
      (error) => {
        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.list)));
        dispatch(core.actions.onError(moduleName, error));
      }
    )
}

export const removeProduct = (data, service = new Service()) => (dispatch) => {
  const { id, productId, token } = data
  if (!token) {
    return dispatch(core.actions.showModal({id: auth.constants.modals.login}));
  }

  return service.removeProduct(id, {productId, token})
    .then(
      response => _compileResponseData(response.data)
    )
    .then(
      ({board, info}) => dispatch({
        type: actionTypes.removeProduct,
        board,
        info
      }),
      (error) => {
        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.list)));
        dispatch(core.actions.onError(moduleName, error));
      }
    )
}

export const create = (data, service = new Service()) => (dispatch) => {
  const { title, productId, token } = data
  if (!token) {
    return dispatch(core.actions.showModal({id: auth.constants.modals.login}));
  }
  return service.create({title, token})
    .then(
      (response) => (_compileResponseData(response.data))
    )
    .then(
      ({board, info}) => {
        dispatch({
          type: actionTypes.create,
          board,
          info
        })
        if (productId) {
          addProduct({
            id: board.id,
            productId: productId,
            token
          }, service)(dispatch)
        }
        return board
      },
      (error) => {
        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.list)));
        dispatch(core.actions.onError(moduleName, error));
      }
    )
}

export const update = (data, service = new Service()) => (dispatch) => {
  if (!data.token) {
    return dispatch(core.actions.showModal({id: auth.constants.modals.login}));
  }
  return service.update(data)
    .then(
      (response) => (_compileResponseData(response.data))
    )
    .then(
      ({board, info}) => dispatch({
        type: actionTypes.update,
        board,
        info
      }),
      (error) => {
        dispatch(flash.actions.show(core.util.errorHandler(error, errorTypes.list)));
        dispatch(core.actions.onError(moduleName, error));
      }
    )
}

export const destroy = (data, service = new Service()) => (dispatch) => {
  if (!data.token) {
    return dispatch(core.actions.showModal({id: auth.constants.modals.login}));
  }
  return service.delete(data)
    .then(
      (response) => dispatch({
        type: actionTypes.delete,
        board: {id: response.data.data.id},
        info: response.data.info
      })
    )
}