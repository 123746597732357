/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 19:58:42
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-05-22 11:55:45
 */

import core from '../_core'

import * as actionTypes from './actionTypes'
import { logout } from '../auth/actionTypes'
import { moduleName } from './constants'
import {generate, resetCheckoutStatus} from './model'

const reducer = (state = generate(), action) => {
  const loadingAction = core.actionTypes.loading(moduleName);
  const errorAction = core.actionTypes.error(moduleName);

  switch (action.type) {
  case actionTypes.getOrders:
    return Object.assign({}, state, {orders: action.orders});
  case actionTypes.getOrder:
    return Object.assign({}, state, {order: action.order});
  case actionTypes.addToDownloads:
    return Object.assign({}, state, {downloading: [...state.downloading, action.itemId]});
  case actionTypes.removeFromDownloads:
    return Object.assign({}, state, {downloading: [...state.downloading].filter(itemId => itemId != action.itemId)});
  case logout:
    return generate()
  case errorAction:
    return action.state
  default:
    return state
  }
}

const addToCart = (state, action) => {
  
  let retVal = Object.assign({}, state);
  retVal.cart = [...state.cart];

  for(var i = 0; i < retVal.cart.length; i++) {
    if(retVal.cart[i].product.id == action.cartItem.product.id) {
      retVal.cart.splice(i, 1);
      i--;
    }
  }

  retVal.cart.push(Object.assign({}, action.cartItem));
  retVal.itemJustAdded = true;
  return retVal;
}

const removeFromCart = (state, action) => {
  
  let retVal = Object.assign({}, state);
  retVal.cart = [...state.cart];

  for(var i = 0; i < retVal.cart.length; i++) {
    if(retVal.cart[i].product.id == action.cartItem.product.id) {
      retVal.cart.splice(i, 1);
      i--;
    }
  }

  retVal.itemJustAdded = true;
  return retVal;
}


export default reducer
