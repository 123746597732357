/**
 * _core/components/modals/Modal.js
 */

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import PropTypes from 'prop-types'
import { isModalVisible, getModal } from '../../selectors'
import * as actions from '../../actions';

import './Modal.scss'

const stopPropagation = (e) => {
  e.stopPropagation()
}

const Modal = (WrappedComponent) => {
  class WrappedModal extends React.Component {
    constructor(props) {
      super(props);

      this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
      const { id, initialOpen, initModal } = this.props;
      //console.log('init', id)
      initModal({ id, visible: initialOpen });
      
      //if (window.)
    }

    componentWillUnmount() {
      const { id, destroyModal } = this.props;
      destroyModal({ id });
    }

    onClose(e) {
      e.stopPropagation();
      const { id, hideModal } = this.props;
      hideModal();
    }

    render() {
      const { shouldStay, isVisible } = this.props;
      window && (
        window.innerWidth < 1024 && window.scrollTo(0,0)
      )
      /*
       * Render null if not visible
       */
      if (!isVisible) return null;

      if (shouldStay) {
        return (
          <section className="modal dark">
            <div className="content" onClick={stopPropagation}>
              <WrappedComponent {...this.props} />
            </div>
          </section>
        )
      } else {
        return (
          <section className="modal dark" onClick={this.onClose}>
            <div className="content" onClick={stopPropagation}>
              <button className="close link" onClick={this.onClose}><FontAwesomeIcon icon={faTimes} /></button>
              <WrappedComponent {...this.props} />
            </div>
          </section>
        )
      }
    }

  }

  WrappedModal.propTypes = {
    destroyModal: PropTypes.func,
    dispatch: PropTypes.func,
    hideModal: PropTypes.func,
    id: PropTypes.string,
    initialOpen: PropTypes.bool,
    initModal: PropTypes.func,
    isVisible: PropTypes.bool,
    shouldStay: PropTypes.bool,
    showModal: PropTypes.func
  }

  const stateToProps = (state, props) => {
    return {
      modal: getModal(state, props),
      isVisible: isModalVisible(state, props)
    }
  }

  const dispatchToProps = (dispatch) => (
    bindActionCreators({
      ...actions
    }, dispatch)
  )

  return connect(stateToProps, dispatchToProps)(WrappedModal);
}



export default Modal