/**
 * pages/components/notFoundPage.js
 */

import React from 'react';
import {Link} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import NotFound from '../../_core/components/NotFound'

const NotFoundPage = () => (
  <NotFound />
)

export default NotFoundPage