/**
* @module search/components
* index.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Thu May 17 2018 14:16:28 GMT-0600 (MDT)
* @copyright None
* @last-modified Tue May 22 2018 10:16:19 GMT-0600 (MDT)
*/

import ContainerFilterableResults from './containers/FilterableResults'
import ContainerResults from './containers/Results'

export default {
  ContainerFilterableResults,
  ContainerResults
}