/**
* @module search/components/containers
* FilterableResults.js
*
* @description Contains a list of search filterable results
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Fri May 18 2018 11:29:56 GMT-0600 (MDT)
* @copyright None
* @last-modified Fri Jun 08 2018 09:22:03 GMT-0600 (MDT)
*/

import React from 'react'
import PropTypes from 'prop-types'
import JustifiedLayout from 'react-justified-layout'

import core from '../../../_core'
import layout from '../../../layout'
import product from '../../../product'
import filter from '../../../filter'

import { moduleName } from '../../constants'
import { Shape } from '../../model'
import { isBoolean } from 'util';

class FilterableResult extends React.Component {
  constructor(props) {
    super(props);

    this.elementRef = React.createRef();
    this.onImageLoad = this.onImageLoad.bind(this);
    this.onResize = this.onResize.bind(this);

    this.state = {}
  }

  onImageLoad(item, {target:img}) {
    //Set the aspect ratio in the state
    this.setState({ [`${moduleName}_${item.id}`]: (img.offsetWidth / img.offsetHeight) })
  }

  onResize(screenSize){
    let returnWidth = this.elementRef.current && this.elementRef.current.offsetWidth ? this.elementRef.current.offsetWidth : undefined;
    return returnWidth;
  }

  componentDidUpdate() {
    this.props.onChildUpdated(this.elementRef)
  }

  render() {
    const { data, showAddMultipleToCartModal, filters } = this.props;
    let listItems = [];
    let containerWidth = this.onResize(this.props.screen.width);//this.elementRef.current && this.elementRef.current.offsetWidth ? this.elementRef.current.offsetWidth : undefined;
    const config = {
      boxSpacing: {
        horizontal: 10,
        vertical: 10
      },
      centerMode: true,
      containerPadding: {
        top: 0,
        right: 0,
        left: 0,
        bottom: 20
      },
      containerWidth,
      targetRowHeight: 225
    }

    const showBadge = filters.collectionType && !filters.collectionType.Free && !filters.collectionType.Free
    
    if (data.data && Array.isArray(data.data.products) && !this.props.loading) {
      listItems = data.data.products.map(
        item => <product.components.Thumb
          data={item}
          key={`${moduleName}-product-${item.id}`}
          aspectRatio={this.state[`${moduleName}_${item.id}`] || 1}
          onLoad={this.onImageLoad.bind(this, item)}
          hideCaption={true}
          hasMenu={true}
          showAddMultipleToCartModal={showAddMultipleToCartModal}
          routerPath={`/product/${item.id}`}
          badge={showBadge && item.collectionType && item.collectionType.toLowerCase() === 'free' ? 'Free' : ''}
        />
      )

      return (
        
        <div className='grid__photoshoots' ref={this.elementRef}>
          <JustifiedLayout {...config}>
            {listItems}
          </JustifiedLayout>
        </div>
      )
    }

    return (null)
  }
}

FilterableResult.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(Shape),
    core.model.ErrorShape,
    core.model.LoadingShape
  ]),
  screen: layout.ScreenShape
}

export default filter.components.withFilter(
  layout.components.withResize(FilterableResult)
)