/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-22 12:01:45
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-07-05 16:35:02
 */

import { moduleName } from './constants'

export const getFilters = `${moduleName}.getFilters`
export const loadFilters = `${moduleName}.loadFilters`
export const toggleFilters = `${moduleName}.toggleFilters`
export const changeFilters = `${moduleName}.updateFilters`
export const resetFilters = `${moduleName}.resetFilters`
export const clearFilters = `${moduleName}.clearFilters`
export const resetTerms = `${moduleName}.resetTerms`
export const resetSort = `${moduleName}.resetSort`
export const setDefaults = `${moduleName}.setDefaults`
export const resetPagination = `${moduleName}.resetPagination`
export const changeTerms = `${moduleName}.changeTerms`
export const changeSort = `${moduleName}.changeSort`
export const changeType = `${moduleName}.changeType`
export const changeCollectionType = `${moduleName}.changeCollectionType`
export const changePagination = `${moduleName}.changePagination`
export const changeModelIds = `${moduleName}.changeModelIds`
export const resetModelIds = `${moduleName}.resetModelIds`
export const updateLoading = `${moduleName}.updateLoading`
export const changePhotoshoot = `${moduleName}.changePhotoshoot`
