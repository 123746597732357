/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:43:20
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-06-20 14:36:34
 */

import {moduleName} from './constants'

export const getProduct = (state) => (state[moduleName])

export const getSelection = (state) => (state[`${moduleName}Selection`])