/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-16 13:36:30 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-16 16:20:56
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import { moduleName } from './constants'
import pages from './pages'
import NotFoundPage from '../../modules/pages/components/notFoundPage'


const routes = {
  admin: [],
  user: [
    {
      path: `/${moduleName}/account-info`,
      component: pages.AccountInfo,
      exact: true
    },
   
  ]
}

const Routes = ({ type }) => (
  <Switch>
    {
      routes[type].map((route, i) => (
        <Route {...route} key={`${moduleName}-${i}`} />
      ))
    }
    <Route component={NotFoundPage} />
  </Switch>
)

Routes.propTypes = {
  type: PropTypes.string.isRequired
}

export default Routes