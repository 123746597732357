import React from 'react'
import core from '../../../_core'

const CouponErrorModal = (props) => {

  return (

    <div className='page-pricing is-modal'>
        <div className="pricingBundle">
          <div className="flex centered column justified"> 
            <h2>Coupon Error</h2>

            <div>{props.errorString}</div>

            <div style={{marginTop: '20px', textAlign: 'center'}}>
              <button onClick={() => props.hideModal()}>Close </button>
            </div>

          </div>
        </div>
      </div>
  )
}


export default core.components.modals.Modal(CouponErrorModal)