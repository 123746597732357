/**
* @module layout
* SearchBox.js
*
* @description A simple component to render a search box and redirect to a search endpoint URL with the input string as end of route
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue May 22 2018 09:23:25 GMT-0600 (MDT)
* @copyright None
* @last-modified Wed Jul 11 2018 16:26:55 GMT-0600 (MDT)
*/

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom'

import filter from '../../filter'

class SearchBox extends React.Component {
  constructor(props) {

    super(props)

    this.state = {
      type: 'Image',
      terms: ''
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.onSearchTextChange = this.onSearchTextChange.bind(this)
  }
  
  onSearchChange(e) {
    this.setState({type: e.target.value})
  }
  
  onSearchTextChange(e) {
    this.setState({terms: e.target.value})
  }

  componentDidMount() {
    console.log("component did mount")
    if (this.props.terms)
      this.setState({ terms: this.props.terms })
    
    
    const currType = this.props.filters.type && Object.keys(this.props.filters.type).find(type => this.props.filters.type[type]) || 'Image'
    const currCollectionType = this.props.filters.collectionType && Object.keys(this.props.filters.collectionType).find(collectionType => this.props.filters.collectionType[collectionType]) || 'All'

    if (this.props.defaultSearchType) {
      this.setState({ type: this.props.defaultSearchType})
    }
    else if (currCollectionType === 'Free') {
      this.setState({
        type: 'Free'
      })
    } else if (currType !== 'Image') {
      this.setState({
        type: currType
      })
    }
  }

  componentDidUpdate(prevProps) {
    const oldType = prevProps.filters.type && Object.keys(prevProps.filters.type).find(type => prevProps.filters.type[type])
    const currType = this.props.filters.type && Object.keys(this.props.filters.type).find(type => this.props.filters.type[type])

    const prevCollectionType = prevProps.filters.collectionType && Object.keys(prevProps.filters.collectionType).find(collectionType => prevProps.filters.collectionType[collectionType])
    const currCollectionType = this.props.filters.collectionType && Object.keys(this.props.filters.collectionType).find(collectionType => this.props.filters.collectionType[collectionType])

    if ( (prevCollectionType !== currCollectionType && currCollectionType)
          || (oldType !== currType && currType)) {

      if (currCollectionType === 'Free' && currType === 'Image') {
        this.setState({ type: 'Free' })  
      } else if (currCollectionType === 'Premium' && currType === 'Image') {
        this.setState({ type: 'Image' })
      } else if (currCollectionType === 'Premium' && currType === 'Video') {
        this.setState({ type: 'Video' })
      }
    }

    if (prevProps.terms && prevProps.terms !== this.props.terms) {
      this.setState({ terms: this.props.terms })
    }
    
  }

  onSubmit(e) {
    e.preventDefault()

    const input = document.getElementById(this.props.inputId);

    let searchType = this.state.type
    let searchCollectionType = 'Premium'

    if (this.state.type === 'Free') {
      searchType = 'Image'
      searchCollectionType = 'Free'
    }
   
    this.props.history.push(`/search/${input.value ? encodeURI(input.value) : '-'}/${searchType}/${searchCollectionType}/1`)
    input.value='';
  }

  render() {
    const {className, placeholder, inputId, icon, terms} = this.props

    return (
      <div className={className}>
        <form onSubmit={this.onSubmit}>
          {icon}
          <input type="text" placeholder={placeholder} id={inputId} onChange={this.onSearchTextChange} value={this.state.terms} />
          <select value={this.state.type} onChange={this.onSearchChange}>
            <option value="Image">Premium&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
            <option value="Video">Video&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
            <option value="Free">Free&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
          </select>
        </form>
      </div>
    )
  }
}

SearchBox.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  inputId: PropTypes.string,
  icon: PropTypes.object,
  defaultSearchType: PropTypes.string,
}

const stateToProps = (state, props) => ({
  filters: filter.selectors.getFilters(state),
  terms: filter.selectors.getTerms(state),
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    clearFilters: filter.actions.clearFilters,
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(withRouter(SearchBox));