import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import { 
  PagesCaseStudies1,
  PagesCaseStudies2,
  PagesCaseStudies3
} from '../pages/img/';

const caseStudy1 = { image: PagesCaseStudies1, title: 'Global Communications Agency', url: 'https://spark.adobe.com/page/xygjyhkO8rETJ/', description: 'Holding hands' }
const caseStudy2 = { image: PagesCaseStudies2, title: 'ATB Financial', url: 'https://spark.adobe.com/page/38VjVnTBYcauY/', description: 'Woman sitting on living room sofa with older woman' }
const caseStudy3 = { image: PagesCaseStudies3, title: 'McCann Health Managed Markets', url: 'https://spark.adobe.com/page/GjNLEzjq1VrAf/', description: 'Woman working from home on the kitchen counter' }

const CaseStudies = () => (
  <div className='flex page-home__Galleries_desktop page-home__gallery-items-wrapper'>
      <Link className="page-home__Galleries_item flex-1" to={{ pathname: caseStudy3.url }} target="_blank">
        <img className="gallery-featured-product" src={caseStudy3.image} style={{width: '100%'}} alt={caseStudy1.description} />
        <h2 className='text-light'>{caseStudy3.title}</h2>
      </Link>
      <Link className="page-home__Galleries_item page-home__Galleries_item--dark flex-1" to={{ pathname: caseStudy2.url }} target="_blank">
        <img className="gallery-featured-product" src={caseStudy2.image} style={{width: '100%'}} alt={caseStudy2.description} />
        <h2 className='text-light'>{caseStudy2.title}</h2>
      </Link>
      <Link className="page-home__Galleries_item page-home__Galleries_item--dark flex-1" to={{ pathname: caseStudy1.url }} target="_blank">
        <img className="gallery-featured-product" src={caseStudy1.image} style={{width: '100%'}} alt={caseStudy3.description} />
        <h2 className='text-light'>{caseStudy1.title}</h2>
      </Link>
    </div>
)

CaseStudies.propTypes = {
}

export default CaseStudies