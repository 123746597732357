/**
 * src/modules/auth/components/modals/ForgetPassword.js
 */

import React from 'react'
import PropTypes from 'prop-types'

import core from '../../../_core'
import * as model from '../../model';

class ForgetPassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      resetComplete: false
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.validate = this.validate.bind(this)
  }

  componentDidMount() {
    this.setState({ resetComplete: false})
  }
  componentDidUpdate(prevProps) {
    if (this.props.auth.requestTime !== prevProps.auth.requestTime) {
      this.setState({resetComplete: true });
    }
  }

  validate(formField) {
    const validator = formField.dataset['validator']

    // If there's no validator configured, make it valid
    formField.dataset['valid'] = validator ? core.util.validate(validator, formField.value) : true
  }

  onChange(e) {
    const newState = {}

    newState[e.target.name] = e.target.value
    this.setState(newState)
    this.validate(e.target)
  }

  onSubmit(e) {
    e.preventDefault()

    const form = e.target
    const { forgetPassword } = this.props;

    if (form.querySelectorAll('[data-valid="false"]').length === 0) {
      forgetPassword(this.state)
    }
  }

  render() {
    
    if (this.state && this.state.resetComplete) {
      return (
        <section>
          <h1>Please Check Your Email</h1>
          <p>An email with instructions to reset your password has been sent to the provided email address (if it matches our record).</p>
          <div className="text-center spacer-padding-40">
            <button onClick={this.props.onSuccess}>Close</button>
          </div>
        </section>
      )
    } else {
      return (
        <form className="form__retrievePassword form__small" onSubmit={this.onSubmit}>
          <h2>Retrieve password</h2>

          <fieldset>
            <label>
              <input type="email" name="email" placeholder="Email" value={this.state.email} onChange={this.onChange} required data-validator="email"/>
            </label>
          </fieldset>

          <div className="spacer-padding-bottom-40">
            <button type="submit" disabled={!this.state.email}>Submit</button>
          </div>
          <div>
            <button className="link" onClick={this.props.onLoggingIn}>Log-in <span className="fal fa-arrow-right"></span></button>
          </div>
        </form>
      )
    }
  }
}

ForgetPassword.propTypes = {
  auth: model.Shape,
  forgetPassword: PropTypes.func.isRequired,
  onLoggingIn: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
}

export default core.components.modals.Modal(ForgetPassword)