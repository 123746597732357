/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-16 14:14:19 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-23 16:49:00
 */

import core from '../_core'
import { generate } from './model'

class Service extends core.Service {
  getUserInfo(id) {
    return super.get(`/user/${id}`)
  }
  getUserBillingInfo(authToken) {
    return super.post('/shoppingcart/userBillingInfo', {}, authToken);
  }
  deleteCreditCard(id, authToken) {
    return super.post(`/user/${id}/deleteCreditCard`, {}, authToken);
  }
  setUserInfo(user = generate(), authToken) {
    return super.put(`/user/${user.id}`, user, authToken)
  }
  subscribe({email}) {
    return super.post('/subscribe', {email})
  }
  listIndustries() {
    // before updating this, make sure it matches with the list in Hubspot
    const ret = {
      data: {
        data: [{name: 'Agency'},
          {name: 'Agriculture'},
          {name: 'Education'},
          {name: 'Energy, Mining, Oil & Gas'},
          {name: 'Financial, Insurance Services'},
          {name: 'Government'},
          {name: 'Health Care'},
          {name: 'Non Profit'},
          {name: 'Pharmaceuticals & Biotech'},
          {name: 'Technology'},
          {name: 'Telecommunications'},
          {name: 'Transportation & Warehousing'},
          {name: 'Real Estate, Rental, Leasing'},
          {name: 'Utilities'}]
      }
    }
    return Promise.resolve(ret)
  }

  listJobTitles() {
    const ret = {
      data: {
        data: [
          {name: 'Account Manager'},
          {name: 'Art Buyer'},
          {name: 'Art Director'},
          {name: 'Brand Manager'},
          {name: 'Chief Marketing Officer'},
          {name: 'Communication Specialist'},
          {name: 'Content Manager'},
          {name: 'Creative Director'},
          {name: 'Graphic Designer'},
          {name: 'Freelancer'},
          {name: 'Marketing Manager'},
          {name: 'Photo/video editor'},
          {name: 'Writer'}
        ]
      }
    }
    return Promise.resolve(ret)
  }
}

export default Service