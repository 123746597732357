import React from 'react'
import {withRouter} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-light-svg-icons'

class SearchInput extends React.Component{
  constructor(props){
    super(props)

    let input = props.currentTerms ? props.currentTerms : ''
    this.state = {
      input
    }
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.setValue = this.setValue.bind(this)
  }

  setValue(value){
    this.setState({input: value})
  }

  onChange(e){
    this.setState({input: e.target.value})
  }

  onSubmit(e){  
    e.preventDefault()

    //if ( (this.props.reloadWithSearch === true) && this.props.history){
    if (this.state.input) {
      this.props.history.push(`/shoots/${this.state.input}/1`)
    }
    else {
      this.props.history.push(`/shoots/1`)
    }
    // }else{
    //   this.props.onSubmit && this.props.onSubmit(this.state.input)
    // }

    
  }
  render(){
    let {input} = this.state
    return(
      <form onSubmit={this.onSubmit} id="photoshoots__search">
        <div className="photoshoots__search__wrapper">
          <input id="searchInput" type="text" placeholder="Search shoots by keywords" onChange={this.onChange} value={input}  />
          <FontAwesomeIcon icon={faSearch} />
        </div>
      </form>
    )

  }
}
export default SearchInput