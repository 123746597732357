/**
* @module gallery/components
* index.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue May 29 2018 13:05:31 GMT-0600 (MDT)
* @copyright None
* @last-modified Wed Jun 06 2018 14:44:26 GMT-0600 (MDT)
*/

import containers from './containers'
import RelatedGalleries from './RelatedGalleries'
import './gallery.scss'

export default {
  containers,
  RelatedGalleries
}