/**
 * src/modules/auth/components/modals/AddMultipleToCartModal.js
 */

import React from 'react'

import core from '../../../_core'

import AddToCart from '../AddToCart'

const AddMultipleToCartModal = (props) => {

  const showFree = (props.multipleAddToCart && props.multipleAddToCart.length === 1) && (props.multipleAddToCart.some(p => p.collectionType && p.collectionType.toLowerCase() === 'free'));

 console.log("AddMultipleToCartModal - Show Free", showFree)
  return (
    <div className="spacer-padding-bottom-20 flex column price_options">
      <h2>Select a price</h2>
      <AddToCart onSubmit={props.onSubmit}
        addToCart={props.addToCart}
        prices={props.prices}
        total={props.multipleAddToCart.length}
        showAddBundleToCartModal={props.showAddBundleToCartModal}
        userProductOwnership={props.userProductOwnership}
        onDownloadClick={props.onDownloadClick}
        isDownloading={props.isDownloading}
        user={props.user}
        showFree={showFree}
        />
    </div>
  )
}


export default core.components.modals.Modal(AddMultipleToCartModal)