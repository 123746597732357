/**
 * pages/components/DblOptInThanks.js
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import * as constants from '../../_core/constants'


class DblOptInThanks extends React.Component {
    constructor(props){
      super(props);
  
      this.state = {
  
      }
  
    }

render() {
    return(
    <section className='spacer-padding-bottom-60 spacer-padding-left-20 spacer-padding-right-20'>
        <Helmet>
        <title>Privacy | Hero Images</title>
        <meta name="description"     content="Thank You | Hero Images" />
        </Helmet>
        <h1 className='spacer-padding-top-20' >Subscription Confirmed</h1>
        <section className='pages-large-width'>
        <p style={{textAlign:"center"}}>Thank you for confirming your subscription! Your subscription to our list has been confirmed.</p>
        </section>
    </section>
    )
}

componentDidMount() {
    console.log("qs" + this.props.location.search) 

    var emailAddr = this.props.location.search.replace("?email=","");

    fetch(constants.apiHost + "/hubspot?email=" + encodeURIComponent(emailAddr));

  }


}

export default DblOptInThanks;