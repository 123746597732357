/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-16 13:53:05 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-16 21:29:59
 */

import React from 'react';
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'

import auth from '../../auth'
import user from '../../user'

import * as userActions from '../actions'
import withLoading from '../../_core/components/withLoading';
import OrderSummary from '../components/OrderSummary'

class PastPurchases extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {user} = this.props
    return (
      <section className="full-wd-page view-cart">
        <Helmet>
          <title>Past Purchases | Hero Images</title>
          <meta name="description" content='Past Purchases | Hero Images' />
        </Helmet>
        <h1>Past purchases</h1>
        {this.props.orders.length > 0 && 
          <h4>View invoice to download previously purchased images.</h4>
        }
        <section className="flex align-baseline justified spacer-padding-top-20">
          <section className="flex space-around max-large-width page__past-purchases__flexwrapper">

            {this.props.orders.length == 0 && 
              <span style={{fontStyle: 'italic'}}>You do not have any purchases yet</span>
            }

            <div className="order-summaries">
              <div className="order-row">
                <div className="order-cell order-cell-img order-header"></div>
                <div className="order-cell order-cell-count order-header">Images</div>
                <div className="order-cell order-cell-date order-header">Date:</div>
                <div className="order-cell order-cell-po order-header">PO#:</div>
                <div className="order-cell order-cell-type order-header">Type:</div>
                <div className="order-cell order-cell-total order-header">Amount:</div>
                <div className="order-cell order-cell-view order-header"></div>
              </div>
              {this.props.orders.filter(order => order.status == 'success').map(order =>
                <OrderSummary key={order.id} order={order} location={this.props.location} />
              )}
            </div>

          </section>
        </section>
      </section>
    )
  }
}

PastPurchases.propTypes = {
  auth: PropTypes.string,
  getPastPurchases: PropTypes.func,
  user: user.Shape
}

const stateToProps = (state) => ({
  orders: state.order.orders,
  auth: auth.selectors.getUserAuthToken(state),
})

const dispatchToProps = (dispatch, props) => (
  bindActionCreators({
    ...userActions,
    loadFromServer: (auth) => userActions.getOrders(auth)
  }, dispatch)  
)

export default connect(stateToProps, dispatchToProps)(
  withRouter(
    withLoading(PastPurchases, ['auth'])
  )
)