/**
 * src/modules/auth/actionTypes.js
 */

import { moduleName } from './constants'

export const destroyModal = `${moduleName}.destroyModal`
export const initModal = `${moduleName}.initModal`
export const showModal = `${moduleName}.showModal`
export const hideModal = `${moduleName}.hideModal`

export const loading = (store) => `${store}.loading`
export const error = (store) => `${store}.error`