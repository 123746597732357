import React from 'react'
import CartItem from '../components/CartItem'

const CartItems = (props) => {

  return (    
    <div className="cart-items">
      <div className="cart-item-row">
        <div className="cart-item-cell cart-item-cell-img cart-item-header">Image:</div>
        <div className="cart-item-cell cart-item-cell-description cart-item-header">Description:</div>
        <div className="cart-item-cell cart-item-cell-price cart-item-header">Price:</div>
        <div className="cart-item-cell cart-item-cell-remove cart-item-header"></div>
      </div>

      {props.cart.map(cartItem => 
        <CartItem key={cartItem.product.id} prices={props.prices} cartItem={cartItem} onRemoveCartItem={props.onRemoveCartItem} currency={props.currency} />
      )
      }
    </div>
  );
}

export default CartItems;