/**
 * src/modules/_core/reducer.js
 */

import * as actionTypes from './actionTypes'
import * as model from './model'

const reducer = (state = model.generate(), action) => {
  switch (action.type) {

  case actionTypes.initModal:
    var modal = model.generateModal(action.modal);
    return model.addModal(state, modal);

  case actionTypes.destroyModal:
    return model.delModal(state, action.modal);

  case actionTypes.showModal:
    return model.setModalVisibility(state, action.modal.id);
    
  case actionTypes.hideModal:
    return model.setModalVisibility(state);

  default:
    return state
  }
}

export default reducer