/**
* @module gallery
* actionTypes.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue May 29 2018 10:38:47 GMT-0600 (MDT)
* @copyright None
* @last-modified Thu May 31 2018 10:47:28 GMT-0600 (MDT)
*/

import { moduleName } from './constants'

const actions = [
  'list',
  'get',
  'addProduct',
  'removeProduct',
  'setTailoredView',
  'getFeaturedProduct'
]

export default actions.reduce(
  (object, action) => {
    object[action] = `${moduleName}.${action}`
    return object
  },
  {}
)