/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:46:07
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-07-11 16:42:08
 */

import core from '../_core'
import filter from '../filter'

import { pageSize } from './constants'

class Service extends core.Service {
  list({terms, offset = 0, limit = pageSize} = {}) {

    if (terms) {
      return super.get(`/search/search?index=photoshoots&terms=${terms}&pageSize=${limit}&offset=${offset}`)
    } else {
      return super.get(`/photoshoot?pageSize=${limit}&offset=${offset}`)
        .then(
          (response) => {
            response.data.data = response.data.data.map(
              photoshoot => {
                const products = photoshoot.photoshootProducts
                if (products && products.length > 0) {
                  photoshoot.thumbnail = products[0]
                }
                return photoshoot
              }
            )

            return response
          }
        )
    }
  }
  getPhotoshoot(id, {filters, sort, offset, limit}) {
    let filtersParam = filter.helpers.filtersToParams(filters)
    let params = `pageSize=${limit}`

    params += offset ? `&offset=${offset}` : ''
    params += sort ? `&sort=${sort}` : ''
    params += filtersParam ? `&${filtersParam}` : ''

    return super.get(`/photoshoot/${id}?${params}`)
  }
  filterProducts(id, {filters, limit = pageSize, offset = 0, sort = null} = {}) {
    let filtersParam = filter.helpers.filtersToParams(filters)
    let params = `pageSize=${limit}`

    params += offset ? `&offset=${offset}` : ''
    params += sort ? `&sort=${sort}` : ''
    params += filtersParam ? `&${filtersParam}` : ''

    return super.get(`/photoshoot/${id}/filter?${params}`)
  }
  getLatestPhotoshoot(authToken) {
    return super.get(`/photoshoot/getLatestPhotoshoot`, authToken)
  }
}

export default Service