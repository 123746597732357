/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-16 13:44:07 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-03 15:04:30
 */

import React from 'react';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { bindActionCreators } from 'redux'

import core from '../../_core'

import * as userActions from '../actions'

class AccountInfo extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      user,
      userBillingInfo,
      onDeleteCreditCard
    } = this.props;
    
    let phMask = String(user.phone || '').slice(-3)

    return (
      <section className="page__account-info"> 
        <header className="page-header-with-button flex">
          <h1>Your account info</h1>
        </header>
        <section className="pages-small-width">
          <div className="edit_container">
            <button onClick={this.props.onEditAccount}>Edit</button>    
          </div>
          <dl className='dl-horizontal'>
            <dt>Email:</dt>
            <dd>{user.email}</dd>
            <dt>Password:</dt>
            <dd>**************</dd>
            <dt>First name:</dt>
            <dd>{user.firstName}</dd>
            <dt>Last name:</dt>
            <dd>{user.lastName}</dd>
            <dt>Country:</dt>
            <dd>{user.profileCountry}</dd>
            <dt>Company/organization:</dt>
            <dd>{user.company}</dd>
            <dt>Job Title:</dt>
            <dd>{user.jobTitle}</dd>
            <dt>Primary Industry:</dt>
            <dd>{user.industry}</dd>
            <dt>Phone:</dt>
            <dd>*******{phMask}</dd>
          </dl>
          {userBillingInfo && userBillingInfo.hasCard && 
          <div>
            <h3 className='text-left'>Payment Info</h3>

            <dl className='dl-horizontal'>
              <dt>{userBillingInfo.brand}</dt>
              <dd>*****************{userBillingInfo.lastFour}</dd>
            </dl>
          </div>
          }
          <div>
            <h3 className='text-left'>Billing Address</h3>

            <dl className=''>
              <dt>{user.billingFirstName} {user.billingLastName}</dt>
              <dt>{user.billingCompany}</dt>
              <dt>{user.address1}</dt>
              <dt>{user.address2}</dt>
              <dt>{user.city + ', ' +  user.region}</dt>
              <dt>{user.country}</dt>
              <dt>{user.zipPostal}</dt>
            </dl>
          </div>
        </section>
      </section>
    )
  }
}

AccountInfo.propTypes = {
  user: PropTypes.object.isRequired,
  onEditAccount: PropTypes.func,
  onDeleteCreditCard: PropTypes.func
}

const stateToProps = (state) => ({
  userBillingInfo: state.user.userBillingInfo
})

const dispatchToProps = (dispatch, props) => (
  bindActionCreators({
    ...userActions,
    loadFromServer: () => userActions.getUserBillingInfo(props.authToken),
  }, dispatch)  
)

export default connect(stateToProps, dispatchToProps)(core.components.withLoading(AccountInfo, ['authToken']))