/**
 * src/modules/auth/components/RegisterForm.js
 */

 import React from 'react'
 import { Link } from 'react-router-dom'
 import { connect } from 'react-redux'
 import { bindActionCreators } from 'redux'
 import PropTypes from 'prop-types'
 import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
 
 import core from '../../_core'
 import * as constants from '../constants'
 import UserService from '../../user/service';
 
 class RegisterForm extends React.Component {
   constructor(props) {
     super(props)
 
     this.state = {
       firstName: '',
       lastName: '',
       email: '',
       company: '',
       industry: '',
       password: '',
       newsletter: true,
       hubspotcookie:'',
       jobTitle: '',
       profileCountry: '',
       
      //  countries: [],
      industries: [],
      jobTitles: [],
      submitting: false
     }
     this.onSubmit = this.onSubmit.bind(this)
     this.onChange = this.onChange.bind(this)
     this.validate = this.validate.bind(this)
     this.togglePassword = this.togglePassword.bind(this)
  }
 
  componentDidMount() {
    if (typeof this.props.newsletterCheckboxChecked != 'undefined')
      this.setState({ newsletter: this.props.newsletterCheckboxChecked})

    // const service = new UserService()
    // service.listIndustries().then((resp) => {
    //   if (resp.data && resp.data.data)
    //     this.setState({
    //       industries: resp.data.data
    //     })
    // })
    // service.listJobTitles().then((resp) => {
    //   if (resp.data && resp.data.data)
    //     this.setState({
    //       jobTitles: resp.data.data
    //     })
    // })
  }
   onChange(e) {
     const newState = {}
 
     newState[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value
     this.setState(newState)
     this.validate(e.target)
   }
 
   onSubmit(e) {
     e.preventDefault()
     this.setState({submitting: true})
 
     const form = e.target
     const { register, onSuccess, storeOperation } = this.props;
     
     this.state.hubspotcookie = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, "$1");
 
     if (form.querySelectorAll('[data-valid="false"]').length === 0) {
       register(this.state)
         .then(
           () => {
             this.setState({submitting: false})
             if (this.state.newsletter) {
               storeOperation({
                 type: 'showModal',
                 id: constants.modals.registerComplete
               })
             }
             onSuccess(e)
           }
         )
     }
   }
 
   validate(formField) {
     const validator = formField.dataset['validator']
 
     // If there's no validator configured, make it valid
     formField.dataset['valid'] = validator ? core.util.validate(validator, formField.value) : true
   }
 
   togglePassword(e) {
     e.preventDefault()
 
     const passwordInput = document.querySelector(`[name="${e.target.dataset.toggleInput}"]`)
 
     if (passwordInput) {
       const isHidden = passwordInput.type === 'password'
       passwordInput.type = isHidden ? 'text' : 'password'
       e.target.innerHTML = isHidden ? 'Hide' : 'Show'
     }
   }
 
   render() {
    const twoColumnFormat = this.props.twoColumnFormat ? 'fieldset--two-col' : '';
     return (
       <form onSubmit={this.onSubmit}>
         <h2 style={{marginBottom: '0'}}><strong>{this.props.heading}</strong></h2>
         { this.props.introText }
         
         <fieldset className={`${twoColumnFormat}`}>
           <label>
             <input type="text" name="firstName" placeholder="First Name*" value={this.state.firstName} onChange={this.onChange} required />
           </label>
           <label>
             <input type="text" name="lastName" placeholder="Last Name*" value={this.state.lastName} onChange={this.onChange} required />
           </label>  
           {/* <label>
            <CountryDropdown
              defaultOptionLabel="Country*"
              value={this.state.profileCountry || ""}
              onChange={(val) => this.setState({ profileCountry: val })} />
           </label> */}
           <label>
             <input type="email" name="email" placeholder="Business Email*" value={this.state.email} onChange={this.onChange} required data-validator="email" />
           </label>
           <label>
             <input type="text" name="company" placeholder="Company Name*" value={this.state.company} onChange={this.onChange} required />
           </label>
           {/* <label>
            {this.state.industries && <select name="jobTitle" onChange={e => this.setState({ jobTitle: e.target.value })} required>
              <option>Job Title*</option>
              { this.state.jobTitles.map(option => (
                <option key={option.name} value={option.name}>
                  {option.name}
                </option>
              ))}
             </select> }
           </label>
           <label>
             {this.state.industries && <select name="industry" onChange={e => this.setState({ industry: e.target.value })} required>
              <option>Primary Industry*</option>
              { this.state.industries.map(option => (
                <option key={option.name} value={option.name}>
                  {option.name}
                </option>
              ))}
             </select> }
           </label> */}
           <label>
             <input type="password" name="password" placeholder="Create Password*" value={this.state.password} onChange={this.onChange} maxLength="72" required />
             <a onClick={this.togglePassword} data-toggle-input="password" href="#" style={{marginRight: '6px'}}>Show</a>
           </label>
           <input type="hidden" name="hubspotcookie" value={this.state.hubspotcookie} />
           { this.props.showNewsletterCheckbox && 
           <label className="flex col-full">
             <div className="checkbox">
               <input id="newsletter" type="checkbox" name="newsletter" value={this.state.newsletter} onChange={this.onChange} />
               <label htmlFor="newsletter"></label>
             </div>
             <label htmlFor="newsletter" className="checkbox-label" style={{fontWeight: 'normal'}}>
                { this.props.newsletterCheckboxText ? this.props.newsletterCheckboxText : 'Send me inspiration, special offers and other cool stuff, which I can unsubscribe from whenever I like.' }
             </label>
           </label> }

           { this.props.newsletterCheckboxDisclaimerText && 
             <p style={{marginTop:'-12px'}}><small>{this.props.newsletterCheckboxDisclaimerText}</small></p> }
         </fieldset>
 
         <div className="spacer-padding-bottom-15 text-center">
           <button type="submit" style={{fontWeight: '600', fontSize: '2rem'}}>{ this.props.submitLabel ? this.props.submitLabel : 'Create account' }</button>
         </div>
         <div>
           <small>
            <p>By creating an account, you accept our <Link target="_blank" className="link" to={'/pages/eula'}>End User License Agreement</Link>, <Link target="_blank" className="link" to={'/pages/terms'}>Terms</Link> and <Link  target="_blank" className="link" to={'/pages/privacy'}>Privacy Policy</Link>.</p>
            <p>By registering, you consent to Hero Images contacting you with helpful newsletters and exciting updates. Your information will not be shared with any third party, and you can unsubscribe at any time. We respect your privacy.</p>
            <p>Already have an account? <button onClick={this.props.onLoggingIn} className="link"><small>Log in.</small></button></p>
           </small>  
         </div>
       </form>
     )
   }
 
 }
 
 RegisterForm.propTypes = {
   register: PropTypes.func,
   onLoggingIn: PropTypes.func,
   onSuccess: PropTypes.func,
 }


export default RegisterForm


