/**
* @module board
* model.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Mon Jun 11 2018 09:56:36 GMT-0600 (MDT)
* @copyright None
* @last-modified Thu Jun 21 2018 10:47:08 GMT-0600 (MDT)
*/

import PropTypes from 'prop-types'
import core from '../_core'

export const boardShape = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  createdAt: PropTypes.instanceOf(Date),
  updatedAt: PropTypes.instanceOf(Date),
  products: PropTypes.array

})

export const Shape = PropTypes.oneOfType([
  PropTypes.arrayOf(boardShape),
  boardShape,
  core.model.ErrorShape,
  core.model.LoadingShape
])

export const generate = () => []