/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-16 13:53:05 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-23 15:09:32
 */

import React from 'react';
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet';

import auth from '../../auth'
import core from '../../_core'

import * as userActions from '../actions'
import components from '../components'
import * as constants from '../constants'
import modals from '../modals'
import * as model from '../model'
import * as selectors from '../selectors'

class AccountInfo extends React.Component {
  constructor(props) {
    super(props)
    this.onEditAccount = this.onEditAccount.bind(this)
    // this.onEditBilling = this.onEditBilling.bind(this)
    this.onDeleteCreditCard = this.onDeleteCreditCard.bind(this)
  }

  onEditAccount(e) {
    e.preventDefault()
    const { showModal } = this.props;
    showModal({id: constants.modals.editAccountInfo});
  }

  // onEditBilling(e) {
  //   e.preventDefault()
  //   const { showModal } = this.props;
  //   showModal({id: constants.modals.editBillingInfo});
  // }

  onDeleteCreditCard(e) {
    e.preventDefault()
    this.props.deleteCreditCard(this.props.auth.id, this.props.authToken);
  }

  render() {
    const {user, authToken} = this.props
    return (
      <div className="pages-large-width">
        <Helmet>
          <title>Account Info | Hero Images</title>
          <meta name="description" content="Account Info" />
        </Helmet>
        <modals.EditAccountInfo {...this.props} id={constants.modals.editAccountInfo} onDeleteCreditCard={this.onDeleteCreditCard} />
        <modals.FreeEnabledModal {...this.props} id={constants.modals.freeEnabledModal} />
        {/* <modals.EditBillingInfo {...this.props} id={constants.modals.editBillingInfo} onSuccess={this.closeModals}/> */}
        <components.AccountInfo user={user} onEditAccount={this.onEditAccount} onEditBilling={this.onEditBilling} onDeleteCreditCard={this.onDeleteCreditCard} authToken={authToken}/>
      </div>
    )
  }
}

AccountInfo.propTypes = {
  auth: auth.Shape,
  authToken: PropTypes.string,
  getUserInfo: PropTypes.func,
  hideModal: PropTypes.func,
  setUserInfo: PropTypes.func,
  showModal: PropTypes.func,
  user: model.Shape
}

const stateToProps = (state) => ({
  auth: auth.selectors.getAuth(state),
  authToken: auth.selectors.getUserAuthToken(state),
  user: selectors.getUser(state),
})

const dispatchToProps = (dispatch, props) => (
  bindActionCreators({
    ...userActions,
    showModal: core.actions.showModal,
    hideModal: core.actions.hideModal,
    loadFromServer: (auth) => userActions.getUserInfo(auth)
  }, dispatch)  
)

export default connect(stateToProps, dispatchToProps)(
  withRouter(
    core.components.withLoading(AccountInfo, ['auth'])
  )
)