/**
 * src/modules/auth/components/modals/AddMultipleToCartModal.js
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import core from '../../../_core'
import auth from '../../../auth'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

import AddBundleToCart from '../AddBundleToCart'

class AddBundleToCartModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPrice: 0,
      selectedOption: 0,
      isInvoice: false
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.onOptionChange = this.onOptionChange.bind(this);
    this.onAddBundleToCartSuccess = this.onAddBundleToCartSuccess.bind(this)
  }

  onSubmit(e) {
    e.preventDefault();
    const selectedBundleObj = selectors.getBundle(this.props.prices, parseInt(this.state.selectedPrice), parseInt(this.state.selectedOption));

    this.props.addBundleToCart(selectedBundleObj, this.onAddBundleToCartSuccess, this.state.isInvoice);
  }

  onAddBundleToCartSuccess(isInvoice) {
    this.props.hideModal();
    this.props.history.push(`/shoppingcart/checkout?bundle=true&invoice=${isInvoice}`, {from: this.props.location.pathname})
  }

  onOptionChange(selectedPrice, selectedBundle) {
    console.log("selected Price", selectedPrice);
    console.log("selected Bundle", selectedBundle);
    this.setState({
      selectedOption: selectedBundle,
      selectedPrice
    });
  }

  render() {
    return (
      <div className='page-pricing is-modal'>
        <div className="pricingBundle">
          <div className="flex centered column justified"> 
            <h2>Bundle and save</h2>
            <div className="pricingBundle_wrapper">
              <form className="flex column price_options" onSubmit={this.onSubmit}>
                {this.props.prices.map(price => core.util.sortByKey(price.bundles, 'quantity', true).some(b => b.isActive && !b.isHidden && (!this.props.product.type || b.mediaTypes.split(',').some(m => m === this.props.product.type))) &&
                  <div className="pricingBundle_price" key={price.id}>
                    <p className="text-left pricingBundle_specs"><strong>{price.title}</strong></p>
                    <p className="text-left pricingBundle_morespecs">{price.specs}</p>
                    <AddBundleToCart {...price} onOptionChange={this.onOptionChange} selectedOption={this.state.selectedOption} user={this.props.user} shortPricing={true} />
                  </div>
                )}
                <button type="submit" className="button" value="Buy Now" onClick={() => this.setState({isInvoice: false})} disabled={this.state.selectedPrice == 0}>Buy Now</button>
                {this.props.user && this.props.user.allowInvoice && <button type="submit" className="button get-invoice" onClick={() => this.setState({isInvoice: true})} value="Complete Invoice" disabled={this.state.selectedPrice == 0}>Complete Invoice</button>}
              </form>
            </div>
            <p className="smallText">All bundles expire 12 months from date of purchase.</p>
          </div>
        </div>
      </div>
    )
  }
}

AddBundleToCartModal.propTypes = {
  auth: PropTypes.string,
  prices: PropTypes.array,
  cart: PropTypes.array
}

function stateToProps(state)
{
  return {
    auth: auth.selectors.getUserAuthToken(state),
    prices: state.shoppingcart.prices,
    cart: state.shoppingcart.cart,
    user: state.user,
    product: state.product
  }
}

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    loadFromServer: (auth) => actions.listPrices({token: auth}),
    addBundleToCart: (bundle, onSuccess, isInvoice) => actions.addBundleToCart(bundle, onSuccess, isInvoice),
    removeJustAdded: () => actions.removeJustAdded(),
    hideModal: core.actions.hideModal
  }, dispatch)
)

export default withRouter(
  connect(stateToProps, dispatchToProps)(
    core.components.modals.Modal(
      core.components.withLoading(AddBundleToCartModal, ['auth'])
    )
  )
)
