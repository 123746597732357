/**
* @module gallery
* service.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue May 29 2018 10:38:02 GMT-0600 (MDT)
* @copyright None
* @last-modified Wed Jul 04 2018 09:49:03 GMT-0600 (MDT)
*/

import core from '../_core'

import { pageSize } from './constants'

class Service extends core.Service {
  list({offset = 0, limit = pageSize, isTailored = false, onlyFeatured = false } = {}, authToken) {
    return super.get(`/gallery?pageSize=${limit}&offset=${offset}&isTailored=${isTailored}&onlyFeatured=${onlyFeatured}`, {}, authToken)
  }
  get(id) {
    return super.get(`/gallery/${id}?pageSize=200`)
  }

  addProduct(id, productId, authToken) {
    return super.post(`/admin/gallery/${id}/product/${productId}`, {}, authToken)
  }

  removeProduct(id, productId, authToken) {
    return super.delete(`/admin/gallery/${id}/product/${productId}`, authToken)
  }

  getFeaturedProduct(productId, authToken) {
    return super.get(`/gallery/featuredproduct/${productId}`, authToken)
  }
}

export default Service