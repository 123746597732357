/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-18 18:16:34
 * @Last Modified by: Weslie Leung
 * @Last Modified time: 2018-07-13 09:16:04
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import JustifiedLayout from 'react-justified-layout'

import core from '../../../_core'
import layout from '../../../layout'

import components from '../'
import {moduleName} from '../../constants'
import { Shape } from '../../model'

import * as filterSelectors from '../../../filter/selectors'

class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.elementRef = React.createRef();
    this.onImageLoad = this.onImageLoad.bind(this);
  }

  onImageLoad(item, {target:img}) {
    //Set the aspect ratio in the state
    this.setState({ [`${moduleName}_${item.id}`]: (img.offsetWidth / img.offsetHeight) })
  }

  onResize(screenSize, rowHeight){
    // shrink rowheight for mobile
    if (screenSize < 1000 && rowHeight){
      if(rowHeight === 300){
        return rowHeight*.4;
      }else if(rowHeight < 300){
        return rowHeight*.6;
      }
    }else if (screenSize > 1000 && rowHeight){
      return rowHeight;
    }
    let returnWidth = this.elementRef.current && this.elementRef.current.offsetWidth ? this.elementRef.current.offsetWidth : undefined;
    return returnWidth;
  }

  componentDidUpdate() {
    this.props.onChildUpdated(this.elementRef)
  }

  render() {
    const { data, products, terms,  rowHeight = 300, showShootNumber = false, applyShootFilter = false } = this.props;
    
    let listItems = [];
    let containerWidth =this.onResize(this.props.screen.width)// this.elementRef.current && this.elementRef.current.offsetWidth ? this.elementRef.current.offsetWidth : undefined;
    let targetHeight=this.onResize(this.props.screen.width, rowHeight)

    const config = {
      boxSpacing: {
        horizontal: 10,
        vertical: 120
      },
      centerMode: true,
      containerPadding: {
        top: 0,
        right: 0,
        left: 0,
        bottom: 20
      },
      containerWidth,
      targetRowHeight: targetHeight
    }

    if(Array.isArray(data)) {
      listItems = data.map(item => <components.Thumb
        data={item}
        key={`${moduleName}-${item.id}`}
        aspectRatio={this.state[`${moduleName}_${item.id}`] || 1}
        hasMenu={false}
        onLoad={this.onImageLoad.bind(this, item)}
        routerPath={`/shoot/${item.id}`}
        updateFilterAndReload={this.props.updateFilterAndReload}
        showShootNumber = {showShootNumber}
        filters = {this.props.filters}
        facets = {products && products.info && products.info.facets ? products.info.facets : null}
        applyShootFilter = {applyShootFilter}
      />)
    } else {
      return (null)
    }

    if (data.length > 0) {
      return (
        <div className='grid__photoshoots spacer-padding-bottom-100' ref={this.elementRef}>
          {listItems}
        </div>
      )
    } else {
      return (
        <div style={{textAlign: 'center'}}>
          <h1>No results found</h1>
          <p>No shoots matched your search term, would you like to <a href={'/search/' + terms + "/1"}>search photos for <em>{terms}</em> instead</a>?</p>
        </div>
      )
    }

  }
}

List.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(Shape),
    core.model.ErrorShape,
    core.model.LoadingShape
  ])
}

const stateToProps = (state) => ({
  filters: filterSelectors.getFilters(state),
  
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(
  layout.components.withResize(List)
)

