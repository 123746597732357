import React from 'react'

import core from '../../_core'
import Thumb from '../../product/components/Thumb'

const CreditHistoryItem = (props) => {

  return (
    <div className="order-row">
      <div className="order-cell order-cell-img">
        <Thumb
          data={{title: props.bundle.name, thumbnailUrl: props.bundle.thumbnailUrl}}
          className={'productDetail'}
          hideCaption={true}
          isTiny={true} />
      </div>
      <div className="order-cell order-cell-description">
        { props.bundle.specs }<br />
        EXPIRES {core.util.formatDate(props.newExpiredAt, 'D/M/YYYY')}
      </div>
      <div className="order-cell order-cell-total">
        <span className={'enMoney ' + props.currency || 'USD'}>{ props.pricePaid.toFixed(2)}</span>
      </div>
    </div>
  )
}

export default CreditHistoryItem