/**
* @module search
* routes.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Thu May 17 2018 13:58:56 GMT-0600 (MDT)
* @copyright None
* @last-modified Wed Jul 11 2018 16:27:03 GMT-0600 (MDT)
*/


import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import { moduleName } from './constants'
import pages from './pages'
import NotFoundPage from '../../modules/pages/components/notFoundPage'


const routes = {
  admin: [],
  user: [
    {
      path: '/search/models/:modelIds',
      component: pages.Search,
      exact: true
    },
    {
      path: '/search/models/:modelIds/:pageNumber',
      component: pages.Search,
      exact: true
    },
    {
      path: '/search/models/:modelIds/:type/:pageNumber',
      component: pages.Search,
      exact: true
    },
    {
      path: '/search/models/:modelIds/:type/:collectionType/:pageNumber',
      component: pages.Search,
      exact: true
    },
    {
      path: '/search/shoot/:shootId',
      component: pages.Search,
      exact: true
    },
    {
      path: '/search/shoot/:shootId/:pageNumber',
      component: pages.Search,
      exact: true
    },
    {
      path: '/search/shoot/:shootId/:type/:pageNumber',
      component: pages.Search,
      exact: true
    },
    {
      path: '/search/shoot/:shootId/:type/:collectionType/:pageNumber',
      component: pages.Search,
      exact: true
    },
    {
      path: '/search/:pageNumber',
      component: pages.Search,
      exact: true
    },
    {
      path: '/search/:terms/:type/:collectionType/:pageNumber',
      component: pages.Search,
      exact: true
    },
    {
      path: '/search/:terms/:pageNumber',
      component: pages.Search,
      exact: true
    },
    {
      path: '/search/:terms/:type/:pageNumber',
      component: pages.Search,
      exact: true
    },
    
    {
      path: '/search',
      component: pages.Search,
      exact: true
    }
  ]
}

const Routes = ({ type }) => (
  <Switch>
    {
      routes[type].map((route, i) => (
        <Route {...route} key={`${moduleName}-${i}`} />
      ))
    }
    <Route component={NotFoundPage} />
  </Switch>
)

Routes.propTypes = {
  type: PropTypes.string.isRequired
}

export default Routes