/**
 * pages/components/about.js
 */

import React from 'react';
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Helmet} from 'react-helmet'
import {faInstagram} from '@fortawesome/free-brands-svg-icons';
import {
  PagesAboutHeader, 
  PagesAboutTeam1,PagesAboutTeam2,PagesAboutTeam3,
  PagesAboutTeam5,PagesAboutTeam6,PagesAboutTeam7
} from '../img/';
import BeauLark from '../img/about/BeauLark.jpg'
import DylanLeeder from '../img/about/DylanLeeder.jpg'
import LiberteeMuzyka from '../img/about/LiberteeMuzyka.jpg'
import Instagram from './Instagram'
import core from '../../_core'
import JustifiedLayout from '../../_core/components/justifiedLayout'

const metaData = {
  description : `About | Hero Images`,
  keywords :'Hero Images, Stock Photography',
  title: 'About | Hero Images',
  name : 'Hero Images',
  url : window && window.location && `${window.location.protocol}//${window.location.host}/pages/about`,
  image : PagesAboutHeader,
  TwitterSite : '@heroimages',
  TwitterCard : 'summary',
  ogType : 'article'
}

const _us = [
  { fullName: 'Heather McNeill', title: 'CEO', thumbnailUrl: PagesAboutTeam1, description: 'photo of Heather McNeill' },
  { fullName: 'Devra Brick', title: 'Senior Art Director', thumbnailUrl: PagesAboutTeam7, description: 'photo of Devra Brick' },
  { fullName: 'Drew Myers', title: 'Senior Photographer/Art Director', thumbnailUrl: PagesAboutTeam2, description: 'photo of Drew Myers' },
  { fullName: 'Beau Lark', title: 'Senior Photographer/Art Director', thumbnailUrl: BeauLark, description: 'photo of Beau Lark' },
  { fullName: 'Kathleen Howell', title: 'Executive Producer', thumbnailUrl: PagesAboutTeam5, description: 'photo of Kathleen Howell' },
  { fullName: 'Leah Brandt', title: 'Digital Retoucher', thumbnailUrl: PagesAboutTeam6, description: 'photo of Leah Brandt' },
]

const PageAbout = () => (

  <section className="full-wd-page page-about">
    <Helmet>
      <title>{metaData.title}</title>
      <meta name="description"           content={metaData.description} />
      <meta name="keywords"              content={metaData.keywords} />

      <meta itemProp="name"              content={metaData.name}/>
      <meta itemProp="description"       content={metaData.description} />
      <meta itemProp="image"             content={metaData.image} />

      <meta name="twitter:card"          content={metaData.TwitterCard} />
      <meta name="twitter:site"          content={metaData.TwitterSite} />
      <meta name="twitter:title"         content={metaData.title} />
      <meta name="twitter:description"   content={metaData.description} />
      <meta name="twitter:creator"       content={metaData.TwitterSite} />
      <meta name="twitter:image"         content={metaData.image}/>

      <meta property="og:url"            content={metaData.url} />
      <meta property="og:type"           content={metaData.ogType} />
      <meta property="og:title"          content={metaData.title} />
      <meta property="og:description"    content={metaData.description} />
      <meta property="og:image"          content={metaData.image} />
    </Helmet>
    <Link to="/product/12628" className="slider" >
      <section className="full-wd-img spacer-height-vh-60" style={{backgroundImage: `url( ${PagesAboutHeader})`, backgroundPosition:'50% 0%'}}>     
        <p className="slide__image_credit">223_1045_hro_3207</p>
      </section>
    </Link>

    <section>  
      <div className="regTextWidth spacer-padding-top-50">
        <h1>We’re image obsessed.</h1>
        <p>
          From day one our story has been about our passion to create great images.
          We eat, sleep, and breathe the details&mdash;constantly evolving ways to make more unique, relevant, and engaging imagery.
        </p>
        <p>
          Canadian made, we started Hero in 2012 after a decade building the industry’s top performing global stock photography brands.
          It was a risky time for a group of makers to strike out on our own, but we knew our deep understanding of the power of images and their impact on brand identity gave us something unique to offer the marketing world.
          Today, images from our collection represent top brands around the globe.
        </p>
        <p>
          Also, we get it. Your timeline, budget, and experience matters.
          We’re dedicated to providing best-in-class content and custom image solutions that help you do your job better and easier.
          As a team we value personal relationships and it shows through the way we work and how we serve &ndash; as a tightly-knit, agile company.
        </p>
      </div>
    </section>
    <section className='max-large-width spacer-padding-top-50 spacer-padding-bottom-40'>
      <JustifiedLayout tiles={_us} tileKey={tile => tile.fullName}
        justifiedMargin={20}
        targetHeight={375}
        textAlign={'center'}
        renderImageContainer={(imgNode, tile) =>
          <figure className='one-of-us'>
            {imgNode}
            <figcaption>
              <h3>{tile.fullName}</h3>
              <h4>{tile.title}</h4>
            </figcaption>
          </figure>} />
    </section>
    
    <Instagram >
      <section className="text-center spacer-padding-top-30 spacer-padding-bottom-20">
        <a  href={core.constants.instagramUrl} target="_blank" rel="noopener noreferrer" className=" h3 "><strong>@heroimages <FontAwesomeIcon icon={faInstagram}/></strong></a>
      </section>
      <ul className="image-grid full-wd-grid-instagram" id={instafeedTarget}>
      </ul>
    </Instagram>
  </section>
)

const instafeedTarget = 'instafeed';
export default PageAbout