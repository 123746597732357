/**
* @module gallery
* constants.js
*
* @description
*
* @author Weslie Leung < wleung@madebyuppercut.com >
* @created Tue May 29 2018 10:38:42 GMT-0600 (MDT)
* @copyright None
* @last-modified Fri Jun 22 2018 17:00:08 GMT-0600 (MDT)
*/

export const moduleName = 'gallery'

export const pageSize = 24

export const modals = {
  list: 'gallery-list'
}